<template>
  <div class="section-reg-text" v-html="content.text"></div>
</template>

<script>
export default {
  name: "section-reg-text",

  props: {
    content: Object
  },

  data: () => ({
    nameSection: "text_full_width"
  })
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-reg-text {
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  h4 {
    color: #00a03b;
    font-family: $fontR;
    font-size: 30px;
    font-weight: normal;
    @media screen and (max-width: 670px) {
      font-size: 16px;
    }

    b,
    strong {
      font-family: $fontDemi;
      font-weight: normal;
    }
  }

  p {
    color: #545454;
    font-family: $fontR;
    font-size: 16px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 670px) {
      font-size: 14px;
    }
  }
}
</style>
