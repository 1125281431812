<template>
  <div class="module-theory">
    <button
      class="module-theory__back-btn"
      @click="nextState(1, '')"
    >
      <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      Назад
    </button>
    <div class="module-theory__title">
      Сумамед® має 17 форм випуску<sup>5</sup>
    </div>
    <div class="module-theory__text">
      <strong>Форми випуску, що можуть бути зручними для дорослих:</strong> 
    </div>
    <div class="module-theory__accordions">
      <div
        class="module-theory__accordion"
        :class="{ opened: accordion.isOpened }"
        v-for="(accordion, key) in accordions"
        :key="key"
        @click="toggleAccordion(key)"
      >
        <div class="module-theory__accordion-title">
          <img
            class="module-theory__accordion-icon"
            v-for="(icon, index) of accordion.icons"
            :key="index"
            :src="require(`@/assets/img/training-module5/theme-1/step-2/${icon}.svg`)"
          >
          {{ accordion.title }}
        </div>
        <div
          class="module-theory__accordion-text"
          v-show="accordion.isOpened"
        >
          <p
            class="module-theory__accordion-row"
            v-for="item of accordion.items"
            :key="item.number"
          >
            <span class="number">
              {{ item.number }}.
            </span>
            {{ item.text }}
          </p>
        </div>
      </div>
    </div>
    <div class="module-theory__forms">
      <div class="module-theory__h2-title">
        Основні форми випуску для дорослих:
      </div>
      <div class="module-theory__forms-container">
        <div class="module-theory__forms-item">
          <img src="@/assets/img/training-module5/theme-1/step-2/pack-1.png" alt="">
          <div class="module-theory__text">
            <p>500 мг, №3 <strong>таблетки, вкриті плівковою оболонкою</strong></p>
            <p>Приймаються незалежно від прийому їжі<sup>1</sup>, на відміну від препаратів азитроміцину у формі капсул<sup>6</sup>.</p>
          </div>
        </div> 
        <div class="module-theory__forms-item">
          <img src="@/assets/img/training-module5/theme-1/step-2/pack-2.png" alt="">
          <div class="module-theory__text">
            <p>500 мг, №3 <strong>таблетки, що диспергуються</strong></p>
            <p>Єдиний, зареєстрований в Україні азитроміцин у формі таблеток, що диспергуються<sup>5</sup></p>
          </div>
        </div> 
      </div>
      <a href="https://rx.teva.ua/article/pharmacist/p-oberit-svii-preparat-z-liniiki-sumamed-r-p" class="module-theory__link">
        Перейти до каталогу дозувань Сумамед
      </a>
    </div>
    <div class="module-theory__next next-button" @click="nextState(3, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
export default {
  name: "Theory2",
    data() {
    return {
      accordions: [
        {
          id: 1,
          isOpened: false,
          title: "«Класичні» форми",
          icons: [
            'droplist-1',
            'droplist-2',
          ],
          items: [
            {
              number: 1,
              text: "Сумамед® 500 мг, №3, таблетки, вкриті плівковою оболонкою"
            },
            {
              number: 2,
              text: "Сумамед® 250 мг, №6, капсули"
            },
            {
              number: 3,
              text: "Сумамед® 500 мг, №2, таблетки, вкриті плівковою оболонкою"
            },
          ]
        },
        {
          id: 2,
          isOpened: false,
          title:
            "Розчинні форми",
          icons: [
            'droplist-3'
          ],
          items: [
            {
              number: 1,
              text: "Сумамед® 500 мг, №3, таблетки, що диспергуються"
            },
            {
              number: 2,
              text: "Сумамед® 250 мг, №6, таблетки, що диспергуються"
            },
            {
              number: 3,
              text: "Сумамед® 500 мг, №6, таблетки, що диспергуються"
            },
            {
              number: 4,
              text: "Сумамед® 1000 мг, №1, таблетки, що диспергуються"
            },
            {
              number: 5,
              text: "Сумамед® 1000 мг, №3, таблетки, що диспергуються"
            },
          ]
        },
      ]
    };
  },
  methods: {
    toggleAccordion(key) {
      this.accordions[key].isOpened = !this.accordions[key].isOpened;
      gtag("event", "click", {
        event_category: `'Взаимодействие "Раскрывающийся список №${key + 1}"'`,
        event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.1'
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.1'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__accordions {
    margin: 32px 0 48px;
  }

  &__accordion {
    position: relative;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 160px 0 12px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(46, 113, 138, 0.16);
    border-radius: 2px;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      top: 24px;
      right: 24px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #005a85;
    }
    &.opened {
      min-height: 186px;
      padding: 0;
      padding: 12px;
      justify-content: unset;
      background: rgba($color: #e4eaed, $alpha: 0.5);
      box-shadow: none;

      .module-theory__accordion-title {
        margin-bottom: 12px;
      }

      &:after {
        transform: rotate(-180deg);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    &-icon {
      max-width: 32px;
      margin-right: 8px;
    }
    &-title {
      display: flex;
      align-items: center;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #005984;
    }
    &-row {
      display: flex;
      border-bottom: 1px solid rgba(33, 38, 97, .3);
      padding: 12px;

      &:last-of-type {
        border: none;
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        height: 24px;
        margin-right: 12px;
        background-color: #2DC7AC;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.1em;
        color: #212661;
        border-radius: 50%;
      }
    }
    &-text {
      position: relative;
      padding-top: 16px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: -12px;
        right: -12px;
        height: 1px;
        background-color: #212661;
      }
    }
  }

  &__forms-container {
    position: relative;
    padding: 35px 20px 35px 100px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top:  0;
      z-index: -1;
      height: 100%;
      width: 30%;
      background: linear-gradient(180deg, #73A2A3 0%, #BCFCFA 100%);
      border-radius: 12px;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__forms-item {
    display: flex;
    align-items: center;

    img {
      max-width: 350px;
    }

    .module-theory__text {
      margin-left: 20px;
      border-bottom: 1px solid #E4EAED;

      p {
        margin-bottom: 16px;
      }
    }
  }

  &__link {
    display: block;
    margin-top: 24px;
    font-size: 14px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #005984;
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__accordions {
      margin: 24px 0 32px;
    }

    &__accordion {
      min-height: 64px;
      padding: 12px;

      &.opened {
        min-height: 186px;
        padding: 12px;
      }

      &-icon {
        max-width: 32px;
        margin-right: 8px;
      }

      &-title {
        font-size: 16px;
        line-height: 20px;
      }

      &-text {
        font-size: 14px;
        line-height: 120%;
      }
    }

    &__forms-container {
      padding: 35px 16px;

      &::before {
        width: 40%;
      }
    }

    &__forms-item {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 38px;

      img {
        max-width: 80%;
      }

      .module-theory__text {
        margin-left: 45%;
        margin-top: -50px;
      }
    }

    &__link {
      text-align: center;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
