<template>
  <div class="module-theory">
    <div class="module-theory__back" v-if="state > 1" @click="previousStep()">
      Назад
    </div>
    <div class="module-theory__state" v-show="state === 1">
      <div class="module-theory__title">
        Грип є найпоширенішою інфекційною хворобою на земній кулі<sup>1</sup>
      </div>
      <div class="module-theory__subtitle">
        За оцінками <strong>ЦГЗ</strong>, за епідемічний сезон 2022/2023 року в Україні було зафіксовано<sup>2</sup>:
      </div>

      <div class="module-theory__item">
        <picture class="module-theory__item-img">
          <source srcset="@/assets/img/training-module6/theme-1/step-1/img-1-desk.jpg" media="(min-width: 768px)"/>
          <img src="@/assets/img/training-module6/theme-1/step-1/img-1-mob.jpg" alt=""/>
        </picture>
        <div class="module-theory__item-list">
          <div>
            <div class="icon">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1643_21995)">
                  <path
                      d="M17.0776 25.0522C17.0776 24.0018 16.6603 22.9944 15.9175 22.2516C15.1748 21.5089 14.1674 21.0916 13.1169 21.0916C12.0665 21.0916 11.0591 21.5089 10.3163 22.2516C9.57353 22.9944 9.15625 24.0018 9.15625 25.0522M13.1169 19.7721C13.4104 19.7721 13.701 19.7143 13.9722 19.602C14.2433 19.4896 14.4897 19.325 14.6972 19.1175C14.9048 18.91 15.0694 18.6636 15.1817 18.3924C15.294 18.1213 15.3518 17.8307 15.3518 17.5372C15.3518 17.2437 15.294 16.953 15.1817 16.6819C15.0694 16.4107 14.9048 16.1644 14.6972 15.9568C14.4897 15.7493 14.2433 15.5847 13.9722 15.4724C13.701 15.3601 13.4104 15.3022 13.1169 15.3022C12.5242 15.3022 11.9557 15.5377 11.5366 15.9568C11.1175 16.376 10.882 16.9444 10.882 17.5372C10.882 18.1299 11.1175 18.6984 11.5366 19.1175C11.9557 19.5366 12.5242 19.7721 13.1169 19.7721Z"
                      stroke="#751B20" stroke-width="1.08023" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M24.8411 22.1121C24.8413 21.3679 24.6272 20.6394 24.2244 20.0137C23.8215 19.3881 23.2469 18.8917 22.5693 18.5841C21.8917 18.2764 21.1398 18.1705 20.4036 18.279C19.6674 18.3874 18.978 18.7057 18.418 19.1957M20.9671 16.9478C21.5466 16.9478 22.1024 16.7176 22.5121 16.3078C22.9219 15.898 23.1521 15.3422 23.1521 14.7627C23.1521 14.1832 22.9219 13.6274 22.5121 13.2176C22.1024 12.8079 21.5466 12.5776 20.9671 12.5776C20.3875 12.5776 19.8317 12.8079 19.422 13.2176C19.0122 13.6274 18.782 14.1832 18.782 14.7627C18.782 15.3422 19.0122 15.898 19.422 16.3078C19.8317 16.7176 20.3875 16.9478 20.9671 16.9478Z"
                      stroke="#751B20" stroke-width="1.08023" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M1.16016 22.1121C1.15986 21.3679 1.37397 20.6394 1.77685 20.0137C2.17974 19.3881 2.75434 18.8917 3.43193 18.5841C4.10951 18.2764 4.86138 18.1705 5.59759 18.279C6.3338 18.3874 7.02318 18.7057 7.58324 19.1957M5.03416 16.9478C5.61368 16.9478 6.16946 16.7176 6.57924 16.3078C6.98903 15.898 7.21924 15.3422 7.21924 14.7627C7.21924 14.1832 6.98903 13.6274 6.57924 13.2176C6.16946 12.8079 5.61368 12.5776 5.03416 12.5776C4.45464 12.5776 3.89885 12.8079 3.48907 13.2176C3.07929 13.6274 2.84907 14.1832 2.84907 14.7627C2.84907 15.3422 3.07929 15.898 3.48907 16.3078C3.89885 16.7176 4.45464 16.9478 5.03416 16.9478Z"
                      stroke="#751B20" stroke-width="1.08023" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M13 9.88525C13.4268 9.88525 13.8494 9.80119 14.2437 9.63786C14.638 9.47454 14.9963 9.23514 15.2981 8.93335M13 9.88525C12.138 9.88525 11.3114 9.54284 10.7019 8.93335M13 9.88525V12.3228M15.2981 8.93335C15.5999 8.63156 15.8393 8.27328 16.0026 7.87898C16.1659 7.48467 16.25 7.06205 16.25 6.63525M15.2981 8.93335L17.0213 10.6566M16.25 6.63525C16.25 6.20846 16.1659 5.78584 16.0026 5.39153C15.8393 4.99722 15.5999 4.63895 15.2981 4.33716M16.25 6.63525H18.6875M15.2981 4.33716C14.9963 4.03537 14.638 3.79597 14.2437 3.63265C13.8494 3.46932 13.4268 3.38525 13 3.38525M15.2981 4.33716L17.0213 2.61392M13 3.38525C12.138 3.38525 11.3114 3.72766 10.7019 4.33716M13 3.38525V0.947754M10.7019 4.33716C10.0924 4.94665 9.75 5.7733 9.75 6.63525M10.7019 4.33716L8.97867 2.61392M9.75 6.63525C9.75 7.49721 10.0924 8.32386 10.7019 8.93335M9.75 6.63525H7.3125M10.7019 8.93335L8.97867 10.6566M12.4583 0.947754H13.5417M16.6389 2.23042L17.4048 2.99634M18.6875 6.09359V7.17692M17.4048 10.2742L16.6389 11.0401M13.5417 12.3228H12.4583M9.36108 11.0401L8.59517 10.2742M7.3125 7.17692V6.09359M8.59517 2.99634L9.36108 2.23042"
                      stroke="#FFFAF6" stroke-width="1.08023" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1643_21995">
                    <rect width="26" height="26" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
            <div class="text">
              <span>3,9 мільйонів</span>
              <br>
              випадків грипу та ГРВІ
            </div>
          </div>

          <div>
            <div class="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1643_22005)">
                  <path
                      d="M23.7877 23.1916C21.9051 20.3596 18.6966 18.4673 14.508 17.7198C14.334 17.6893 14.1634 17.7696 14.0723 17.911C14.0717 17.9118 14.0706 17.9124 14.07 17.9133L12.5386 20.343C12.4126 20.5433 12.4726 20.8078 12.6729 20.9341C12.7437 20.9787 12.8229 20.9998 12.9011 20.9998C13.0434 20.9998 13.1826 20.929 13.2643 20.7998L14.6394 18.6176C17.3654 19.1456 20.9374 20.4507 23.0743 23.6656C23.1569 23.7898 23.2929 23.857 23.4314 23.857C23.5129 23.857 23.5954 23.8338 23.6683 23.7853C23.8654 23.6547 23.9189 23.3884 23.7877 23.1916Z"
                      fill="#FFFAF6"/>
                  <path
                      d="M12.3634 21.7715L9.93458 17.9135C9.93429 17.9127 9.93344 17.9124 9.93286 17.9118C9.84229 17.7698 9.67201 17.6892 9.49629 17.7198C5.30744 18.4678 2.09858 20.3598 0.216292 23.1918C0.0851492 23.3887 0.138863 23.655 0.33572 23.7858C0.408578 23.8344 0.491149 23.8575 0.572578 23.8575C0.711149 23.8575 0.847435 23.7904 0.929721 23.6661C3.06658 20.4513 6.63886 19.1464 9.36486 18.6181L11.5717 22.1235V23.429C11.5717 23.6655 11.7634 23.8575 12.0003 23.8575C12.2369 23.8575 12.4289 23.6655 12.4289 23.429V21.9998C12.4289 21.9975 12.4274 21.9958 12.4274 21.9938C12.4266 21.9175 12.4069 21.8407 12.3634 21.7715Z"
                      fill="#FFFAF6"/>
                  <path
                      d="M5.6889 10.9776C6.32033 15.0256 8.82375 17.7199 12.0003 17.7199C15.1772 17.7199 17.6809 15.0256 18.3123 10.9776C19.2066 10.8445 19.8955 10.0736 19.8955 9.14307C19.8955 8.25678 19.2709 7.51478 18.4392 7.33078L18.1432 3.39649C18.1432 3.39507 18.1426 3.39392 18.1423 3.39249C18.1229 1.59764 16.658 0.143066 14.8586 0.143066H9.14433C7.34461 0.143066 5.87975 1.59792 5.86033 3.39335C5.86033 3.3945 5.86004 3.39535 5.85975 3.39649L5.56204 7.33078C4.73004 7.5145 4.10547 8.25678 4.10547 9.14307C4.10547 10.0736 4.79461 10.8445 5.6889 10.9776ZM9.14433 1.00021H14.8586C16.1978 1.00021 17.2872 2.08964 17.2872 3.42878C17.2872 3.43421 17.2886 3.43907 17.2889 3.44421C17.2889 3.44992 17.2878 3.45535 17.2883 3.46107L17.5763 7.28592H6.4249L6.71461 3.46107C6.7149 3.45564 6.71404 3.4505 6.71433 3.44507C6.71433 3.43964 6.71575 3.4345 6.71575 3.42878C6.71575 2.08964 7.80518 1.00021 9.14433 1.00021ZM5.96261 8.14307H18.0383C18.5898 8.14307 19.0383 8.59164 19.0383 9.14307C19.0383 9.6945 18.5898 10.1431 18.0383 10.1431C18.0149 10.1431 17.9915 10.1425 17.9686 10.1408C17.7546 10.1282 17.5512 10.2851 17.5166 10.5034C17.5158 10.5071 17.5132 10.5099 17.5126 10.5139C17.0326 14.3116 14.8175 16.8631 12.0003 16.8631C9.18318 16.8631 6.96804 14.3114 6.48833 10.5136C6.48804 10.5105 6.48575 10.5079 6.48518 10.5048C6.45204 10.2851 6.25575 10.1214 6.03318 10.1408C5.46233 10.1796 4.96261 9.71821 4.96261 9.14307C4.96261 8.59164 5.41147 8.14307 5.96261 8.14307Z"
                      fill="#751B20"/>
                  <path
                      d="M11.5065 5.76511H12.4974V4.84453H13.4179V3.85339H12.4974V2.93311H11.5065V3.85339H10.5859V4.84453H11.5065V5.76511Z"
                      fill="#751B20"/>
                </g>
                <defs>
                  <clipPath id="clip0_1643_22005">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>


            </div>
            <div class="text">
              <span>9,6% населення</span>
              <br>
              звернулося до лікарів
            </div>
          </div>

          <div>
            <div class="icon">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22.6863 23.7652V9.18216C22.6863 7.66552 22.6863 6.90612 22.3222 6.36169C22.1646 6.12569 21.9619 5.92307 21.7259 5.7654C21.1815 5.40137 20.4221 5.40137 18.9055 5.40137M3.24219 23.7652V9.18216C3.24219 7.66552 3.24219 6.90612 3.60622 6.36169C3.76389 6.12569 3.96651 5.92307 4.20251 5.7654C4.74694 5.40137 5.50634 5.40137 7.02298 5.40137"
                    stroke="#FFFAF6" stroke-width="1.08023"/>
                <path d="M23.7647 23.7651H2.16016" stroke="#751B20" stroke-width="1.08023" stroke-linecap="round"/>
                <path
                    d="M10.8024 12.963H15.1233M5.94141 11.8828H7.56175M5.94141 15.1234H7.56175M18.364 11.8828H19.9843M18.364 15.1234H19.9843M5.94141 8.64209H7.56175M18.364 8.64209H19.9843M10.8024 16.2037H15.1233"
                    stroke="#FFFAF6" stroke-width="1.08023" stroke-linecap="round"/>
                <path
                    d="M18.3648 23.7652V6.48155C18.3648 4.44424 18.3648 3.42667 17.7317 2.79366C17.0987 2.16064 16.0812 2.16064 14.0439 2.16064H11.8834C9.8461 2.16064 8.82852 2.16064 8.19551 2.79366C7.5625 3.42667 7.5625 4.44424 7.5625 6.48155V23.7652"
                    stroke="#751B20" stroke-width="1.08023"/>
                <path d="M12.9609 23.7651V20.5244" stroke="#751B20" stroke-width="1.08023" stroke-linecap="round"/>
                <path d="M12.9612 9.72227V5.40137M15.1217 7.56182H10.8008" stroke="#751B20" stroke-width="1.08023"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <div class="text">
              <span>140 122 захворілих</span>
              <br>
              на грип та ГРВІ було госпіталізовано
            </div>
          </div>

          <div>
            <div class="icon">
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1643_22032)">
                  <path
                      d="M11.5175 14.3751V7.83317M17.5684 11.5332L12.9915 2.20795C12.8562 1.93239 12.6464 1.70027 12.3859 1.53794C12.1253 1.3756 11.8245 1.28955 11.5175 1.28955C11.2105 1.28955 10.9097 1.3756 10.6491 1.53794C10.3886 1.70027 10.1787 1.93239 10.0435 2.20795L1.43035 19.7537C1.32699 19.9646 1.27898 20.1983 1.29083 20.4329C1.30267 20.6674 1.37399 20.8951 1.49808 21.0945C1.62216 21.2939 1.79493 21.4585 2.00013 21.5727C2.20532 21.6869 2.43621 21.7471 2.67106 21.7475H11.241"
                      stroke="#751B20" stroke-width="1.08023" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M11.5162 18.135C11.4063 18.135 11.3008 18.0913 11.223 18.0136C11.1453 17.9358 11.1016 17.8303 11.1016 17.7203C11.1016 17.6104 11.1453 17.5049 11.223 17.4271C11.3008 17.3494 11.4063 17.3057 11.5162 17.3057M11.5162 18.135C11.6262 18.135 11.7317 18.0913 11.8095 18.0136C11.8872 17.9358 11.9309 17.8303 11.9309 17.7203C11.9309 17.6104 11.8872 17.5049 11.8095 17.4271C11.7317 17.3494 11.6262 17.3057 11.5162 17.3057"
                      stroke="#751B20" stroke-width="1.08023"/>
                  <path
                      d="M20.3641 23.6825C21.2439 23.6825 22.0877 23.3329 22.7098 22.7108M20.3641 23.6825C19.4842 23.6825 18.6404 23.3329 18.0183 22.7108M20.3641 23.6825V26.1705M22.7098 22.7108C23.332 22.0887 23.6815 21.2449 23.6815 20.365M22.7098 22.7108L24.4688 24.4698M23.6815 20.365C23.6815 19.4852 23.332 18.6414 22.7098 18.0193M23.6815 20.365H26.1695M22.7098 18.0193C22.0877 17.3971 21.2439 17.0476 20.3641 17.0476M22.7098 18.0193L24.4688 16.2603M20.3641 17.0476C19.4842 17.0476 18.6404 17.3971 18.0183 18.0193M20.3641 17.0476V14.5596M18.0183 18.0193C17.3962 18.6414 17.0467 19.4852 17.0467 20.365M18.0183 18.0193L16.2593 16.2603M17.0467 20.365C17.0467 21.2449 17.3962 22.0887 18.0183 22.7108M17.0467 20.365H14.5586M18.0183 22.7108L16.2593 24.4698M19.8112 14.5596H20.917M24.0785 15.8688L24.8603 16.6506M26.1695 19.8121V20.9179M24.8603 24.0794L24.0785 24.8612M20.917 26.1705H19.8112M16.6497 24.8612L15.8679 24.0794M14.5586 20.9179V19.8121M15.8679 16.6506L16.6497 15.8688"
                      stroke="#FFFAF6" stroke-width="1.08023" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1643_22032">
                    <rect width="26.5393" height="26.5393" fill="white" transform="translate(0.460938 0.460938)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="text">
              <span>23 летальні</span>
              <br>
              випадки внаслідок грипу
            </div>
          </div>
        </div>

        <div class="module-theory__item-next" @click="nextStep()">
          Далі
        </div>
      </div>
    </div>

    <div class="module-theory__state" v-show="state === 2">

      <div class="module-theory__double-block">
        <div class="module-theory__double-block--img">
          <picture>
            <source srcset="../../assets/img/training-module6/theme-1/step-2/img-2-desk.png"
                    media="(min-width: 768px)"/>
            <img src="../../assets/img/training-module6/theme-1/step-2/img-2-mob.png" alt=""/>
          </picture>
        </div>
        <div class="module-theory__double-block--test">
          <p>Спробуйте свої сили.<br>
            До якої родини вірусів відноситься вірус грипу?</p>
          <div v-bind:class="[{ incorrectAns: answer === false },{ select: answer !== null && answer=== true  }]"
               class="button"
               @click="selectAnswer(false, 'Параміксовіруси')">
            Параміксовіруси
          </div>
          <div v-bind:class="[{ correctAns: answer === true }, { select: answer !== null && answer=== true  }]"
               class="button"
               @click="selectAnswer(true, 'Ортоміксовіруси')">
            Ортоміксовіруси
          </div>
        </div>
      </div>

      <div class="module-theory__correct" v-if="answer === true">
        <h3>Так, все вірно!<br>Вірус грипу справді належить до родини ортоміксовірусів<sup>1</sup>.</h3>
        <p>Пропонуємо ознайомитись з детальнішою інформацією про нього<sup>1,3</sup>:</p>
        <ul>
          <li>
            <span>Виділяють <b>3 серотипи вірусу грипу, які здатні спричиняти захворювання у людей: А, В та С</b></span>
          </li>
          <li><span><b>Гемаглютинін та нейрамінідаза</b> — фактори агресії вірусу грипу</span></li>
          <li><span><b>Геном вірусу</b> представлений <b>РНК</b></span></li>
          <li><span>Джерело інфекції — <b>хвора людина, інколи тварини</b></span></li>
          <li><span>Механізм передачі збудника — <b>повітряно-крапельний</b></span></li>
        </ul>

        <div class="module-theory__next next-button" @click="nextState(2, 'next')">
          Далі
        </div>
      </div>

      <div v-if="answer === false" class="module-theory__incorrect">
        <p>Неправильно! Спробуйте ще</p>
      </div>

    </div>

  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory1",
  data() {
    return {
      state: 1,
      answer: null
    };
  },
  methods: {
    selectAnswer(key, title) {
      if (this.answer === null || this.answer === false) {

        this.answer = key;
        // this.selectedAnswer = this.answers[key]
        gtag("event", "click", {
          event_category: `'Выбор "${title}'`,
          event_label: `'М.6  - Раздел "Про вірус грипу" - Э.${this.state}'`
        });
      }
    },

    nextStep() {
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.6  - Раздел "Про вірус грипу" - Э.${this.state}'`
      });
      this.state += 1;

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    },
    previousStep() {
      this.state -= 1;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    },
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.6  - Раздел "Про вірус грипу" - Э.${this.state}'`
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$fontSB: "Commissioner-SemiBold";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    max-width: 776px;
    width: 100%;
  }

  &__subtitle {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }

  &__item {
    &-img {
      img {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }

    &-next {
      width: 262px;
      height: 64px;
      padding: 0 83px;
      background-color: #aa198b;
      background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
      border-radius: 90px;
      font-family: $fontDemi;
      font-size: 22px;
      text-indent: -50px;
      text-align: center;
      line-height: 64px;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s;
      margin: 48px auto 0 auto;

      &:hover {
        background-position: 110% center;
      }
    }

    &-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      background: #FAF5E9;
      padding: 32px;

      > div {
        max-width: calc(50% - 20px);
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .icon {
          min-width: 50px;
          min-height: 50px;

          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 100px;
          border: 1.08px solid #660E13;

          background: #E4B6BC;
          margin-right: 10px;

          svg {
            display: block;
          }
        }

        .text {
          color: #751B20;
          font-family: $fontR;
          font-size: 14px;
          line-height: 120%;

          span {
            font-size: 24px;
            line-height: normal;
            font-family: $fontSB;
            letter-spacing: -0.24px;
          }
        }
      }
    }
  }

  &__text {
    max-width: 730px;
    margin-bottom: 64px;

    &.small-margin {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 15px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      b {
        font-family: $fontDemi;
      }

      span {
        &.show-info-modal {
          position: relative;
          border-bottom: 1px solid #545454;
          cursor: pointer;

          &:hover {
            .info-modal {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      &.text-green {
        color: #00a03b;
      }

      &.info-modal-wrapper {
        br {
          display: none;
        }
      }

      .info-modal {
        position: absolute;
        max-width: 420px;
        width: max-content;
        top: 32px;
        left: 74px;
        transform: translateX(-50%);
        padding: 8px;
        background: #ffffff;
        box-shadow: 0px 0px 16px rgba(0, 89, 132, 0.12);
        border-radius: 2px;
        font-family: $fontR;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        visibility: hidden;
        opacity: 0;
        cursor: default;
        transition: all 0.5s;

        &:before {
          content: "";
          position: absolute;
          top: -7.5px;
          left: 45%;
          width: 15px;
          height: 15px;
          background: #fff;
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }
  }

  &__back {
    margin-bottom: 16px;
    padding-left: 22px;
    background-image: url("~@/assets/img/training-module4/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #9f9f9f;
    cursor: pointer;
  }

  &__answers {
    max-width: 401px;
    margin: 0 auto 32px;
    padding-top: 32px;

    &-item {
      position: relative;
      margin-bottom: 24px;
      padding: 14px 42px 14px 42px;
      background: rgba(228, 234, 237, 0.4);
      border: 1px solid #00a03b;
      border-radius: 48px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #005a85;
      cursor: pointer;
      transition: all 0.5s;

      &:last-child {
        margin-bottom: 0;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.selected,
      &:hover {
        background: #00a03b;
        color: #fff;

        &:before {
          border: 3px solid #fff;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #00a03b;
      }
    }
  }

  &__message {
    padding: 24px 48px 32px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #fff;

    &.error {
      background: rgba($color: #fb3449, $alpha: 0.5);
    }

    &.correct {
      background: rgba($color: #00a03b, $alpha: 0.5);
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -20px;
    }

    &-col {
      &-left {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 20px;
      }

      &-right {
        display: flex;
        justify-content: flex-end;
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 20px;
      }
    }

    &-title {
      margin-bottom: 12px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
    }

    &-text {
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
    }

    &-reload {
      padding: 19px 46px 18px;
      background: #aa198b;
      border-radius: 90px;
      font-family: $fontDemi;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      cursor: pointer;
    }

    &-next {
      margin: 0;
    }
  }

  &__double-block {
    display: flex;
    align-items: center;

    &--img {
      max-width: 356px;
      width: 100%;
      height: auto;
      margin-right: 20px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &--test {
      max-width: 296px;
      width: 100%;
      margin: 0 auto;

      p {
        color: var(--Green, #00A03B);
        text-align: center;
        font-family: $fontSB;
        font-size: 18px;
        margin-bottom: 24px;
      }

      .button {
        margin-bottom: 10px;
        width: 100%;
        padding: 12px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 80px;
        border: 1px solid var(--Green, #00A03B);
        background: rgba(228, 234, 237, 0.40);

        color: var(--Blue, #005984);
        text-align: center;

        font-family: $fontSB;
        font-size: 16px;
        cursor: pointer;

        transition: all .2s ease;

        &.correctAns {
          background: #00A03B;
          color: white;
        }

        &.incorrectAns {
          background: var(--Red, #FB3449);
          border-color: #FB3449;
          color: white;
        }

        &:not(.select):not(.incorrectAns) {
          @media screen and (min-width: 1024px) {
            &:hover {
              background: #00A03B;
              color: white;
            }
          }

          &:active {
            background: #00A03B;
            color: white;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__correct {
    margin-top: 32px;

    h3 {
      color: var(--Green, #00A03B);
      text-align: center;
      font-family: $fontSB;
      font-size: 24px;
    }

    p {
      margin-top: 16px;
      color: var(--Black, #545454);
      text-align: center;
      font-family: $fontSB;
      font-size: 16px;
      line-height: 120%; /* 19.2px */
      margin-bottom: 16px;
    }

    ul {
      padding-left: 20px;
    }

    li {
      list-style: disc;
      color: #00A03B;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: var(--Black, #545454);
        font-family: $fontR;
        font-size: 16px;

        b {
          font-family: $fontSB;
        }
      }
    }
  }

  &__incorrect {
    margin-top: 32px;

    p {
      color: var(--Red, #FB3449);
      text-align: center;

      /* H1 */
      font-family: $fontSB;
      font-size: 24px;
      letter-spacing: -0.24px;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    margin: 48px auto 0 auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__back {
      font-size: 12px;
      line-height: 15px;
    }

    &__title {
      max-width: 100%;
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;

      &.question-title {
        margin-bottom: 24px;
        text-align: center;
      }
    }

    &__subtitle {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
    }

    &__item {
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-title {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 20px;
      }

      &-img {
        margin-bottom: 12px;

        img {
          width: 100%;
        }
      }

      &-subtitle {
        font-size: 18px;
        line-height: 22px;

        &.subtitle-green {
          margin-bottom: 32px;
          font-size: 18px;
          line-height: 22px;
        }
      }

      &-list {
        margin-bottom: 15px;

        ul {
          li {
            font-family: $fontR;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      &-next {
        width: 266px;
        height: 44px;
        margin: auto;
        padding: 0 95px;
        background-size: 24px;
        font-size: 16px;
        line-height: 43px;
        text-indent: -40px;
      }

      &-list {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 20px 15px;

        > div {
          max-width: 300px;
          align-items: center;
          margin-bottom: 12px;

        }
      }
    }

    &__text {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 17px;

      p {
        font-size: 14px;
        line-height: 17px;

        span {
          font-family: $fontDemi;
          font-weight: 700;
        }

        &.info-modal-wrapper {
          br {
            display: inline;
          }
        }

        .info-modal {
          max-width: 290px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    &__answers {
      padding-top: 0;

      &-item {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__message {
      margin: 0 -20px;
      padding: 24px 24px 36px;

      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-title {
        display: none;
      }

      &-text {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }

      &-reload {
        margin: auto;
        padding: 12px 30px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__double-block {
      display: flex;
      align-items: center;
      flex-direction: column;

      &--img {
        max-width: 360px;
        margin-right: 0;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &--test {
        margin: 26px auto 0 auto;
      }
    }

    &__correct {
      margin-top: 24px;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;

      }

      ul {
        padding-left: 20px;
      }

      li {

        span {
          font-size: 14px;
        }
      }
    }

    &__incorrect {
      margin-top: 24px;

      p {
        font-size: 16px;
        line-height: 20px; /* 125% */
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 32px auto 0 auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}

@media screen and (max-width: 340px) {
  .module-theory {

    &__item {
      &-list {
        > div {
          .text {
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
