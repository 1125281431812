<template>
  <div class="section-row-list-text">
    <div class="item-wrapper" v-for="(key, value) in dataContent">
      <div
        v-for="secondKey in key"
        :class="`item`"
        v-bind:style="{ borderColor: secondKey.color }"
      >
        <span
          v-bind:style="{
            borderColor: secondKey.color,
            color: secondKey.color
          }"
          >{{ secondKey.num }}</span
        >
        <div class="list" v-html="secondKey.text"></div>
      </div>
      <div class="item-hidden" v-if="key.length === 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-list-text",
  props: {
    content: Object
  },
  data: () => ({
    dataContent: []
  }),
  beforeMount() {
    let array = [];
    this.content.blocks.forEach((el, index) => {
      array.push({
        color: el.color,
        text: el.text,
        num: index + 1
      });
    });
    let size = 2;
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      this.dataContent[i] = array.slice(i * size, i * size + size);
    }
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-row-list-text {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  .item-wrapper {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 670px) {
      flex-direction: column;
    }

    > div {
      max-width: 400px;
      min-width: 280px;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 25px 0;
      border-top: 1px solid red;
      @media screen and (max-width: 1024px) {
        max-width: 550px;
      }

      @media screen and (max-width: 670px) {
        max-width: 670px;
      }

      &.item-hidden {
        visibility: hidden;
        margin: 0;
        padding: 0;
      }

      &:nth-child(2n) {
        margin-left: 15px;
        @media screen and (max-width: 670px) {
          margin-left: 0;
        }
      }

      &:nth-child(2n + 1) {
        margin-right: 15px;
        @media screen and (max-width: 670px) {
          margin-right: 0;
        }
      }

      &.color1 {
        border-color: #00a03b;

        span {
          color: #00a03b;
        }
      }

      &.color2 {
        border-color: #b3d06b;

        span {
          color: #b3d06b;
        }
      }

      &.color3 {
        border-color: #005a85;

        span {
          color: #005a85;
        }
      }

      &.color4 {
        border-color: #317575;

        span {
          color: #317575;
        }
      }

      span {
        line-height: 0.9em;
        display: block;
        margin-right: 30px;
        color: #545454;
        font-size: 70px;
        font-family: $fontR;
        @media screen and (max-width: 670px) {
          margin-right: 15px;
        }
      }

      p {
        margin-bottom: 15px;
        color: #545454;
        font-family: $fontR;
        font-size: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
