<template>
  <div class="training-theory">
    <h3 class="training-theory__title">
      Для нормального функціонування органів та систем нашому організму
      необхідні вітаміни В<sub>1</sub>, B<sub>6</sub> та B<sub>12</sub
      ><sup>1-3</sup>
    </h3>
    <div class="training-theory__list">
      <div class="training-theory__item">
        <div class="training-theory__col-left">
          <div class="training-theory__image">
            <img src="~@/assets/img/training-module1/theory/1.gif" alt="" />
          </div>
        </div>
        <div class="training-theory__col-right">
          <div class="training-theory__detail">
            <div class="training-theory__detail-title">
              Вітамін B<sub>1</sub> (тіамін)
            </div>
            <div class="training-theory__detail-text">
              Допомагає клітинам перетворювати вуглеводи в енергію. Відіграє
              роль у скороченні м’язів та проведенні нервових сигналів.<sup
                >1</sup
              >
            </div>
          </div>
        </div>
      </div>
      <div class="training-theory__item">
        <div class="training-theory__col-left">
          <div class="training-theory__image">
            <img src="~@/assets/img/training-module1/theory/2.gif" alt="" />
          </div>
        </div>
        <div class="training-theory__col-right">
          <div class="training-theory__detail">
            <div class="training-theory__detail-title">
              Вітамін B<sub>6</sub> (піридоксин)
            </div>
            <div class="training-theory__detail-text">
              Бере учась у біосинтезі нейромедіаторів, покращуючи нервову
              регуляцію. Відіграє важливу роль у білковому, жировому та
              вуглеводному обміні.<sup>2</sup>
            </div>
          </div>
        </div>
      </div>
      <div class="training-theory__item">
        <div class="training-theory__col-left">
          <div class="training-theory__image">
            <img src="~@/assets/img/training-module1/theory/3.jpg" alt="" />
          </div>
        </div>
        <div class="training-theory__col-right">
          <div class="training-theory__detail">
            <div class="training-theory__detail-title">
              Вітамін B<sub>12</sub>
              (ціанокобаламін)
            </div>
            <div class="training-theory__detail-text">
              Відіграє важливу роль в метаболізмі кожної клітини організму. Його
              коензими необхідні для розвитку нервових тканин, синтезу ДНК,
              енергообміну, мітозу.<sup>3</sup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="training-theory__next" @click="nextState(2, 'next')">Далі</div>
  </div>
</template>

<script>
export default {
  name: "Theory",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_1_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.training-theory {
  &__title {
    margin-bottom: 48px;
    font-family: $fontDemi;
    font-size: 40px;
    line-height: 49px;
    color: #00a03b;
    sub,
    sup {
      font-family: $fontDemi;
    }
  }
  &__list {
    margin-bottom: 64px;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 46px;
  }
  &__col {
    &-left {
      flex-basis: 56%;
      max-width: 56%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 44%;
      max-width: 44%;
      padding: 0 10px;
    }
  }
  &__image {
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__detail {
    &-title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
      sub,
      sup {
        font-family: $fontDemi;
      }
    }
    &-text {
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}
@media screen and (max-width: 767px) {
  .training-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
    &__list {
      margin-bottom: 24px;
    }
    &__item {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-bottom: 32px;
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__detail {
      &-title {
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 22px;
      }
      &-text {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &__next {
      width: 100%;
      max-width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
  }
}
</style>
