<template>
  <div class="section-row-icon-background-text">
    <div
      v-bind:style="{ background: key.color }"
      :class="`item-icon`"
      v-for="(key, value) in content.blocks"
    >
      <img
        :src="`${$root.globalUrl}${key.file}`"
        alt=""
        width="45"
        height="45"
      />
      <p v-html="key.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-icon-background-text",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "four_icons_with_background_text"
  })
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-row-icon-background-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
  @media screen and (max-width: 670px) {
    margin: 5px 0;
    overflow-x: scroll;
    justify-content: flex-start;
    position: relative;
  }
  @media screen and (max-width: 425px) {
    margin-right: -15px;
    width: auto;
  }

  .item-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 192px;
    width: 100%;
    padding: 29px 21px 20px 21px;
    margin-right: 15px;
    @media screen and (max-width: 670px) {
      width: 192px;
      min-width: 192px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.color1 {
      background: #00a03b;
    }

    &.color2 {
      background: #005a85;
    }

    &.color3 {
      background: #317575;
    }

    &.color4 {
      background: #7fc25d;
    }

    img {
      display: block;
      margin-bottom: 15px;
    }

    p {
      word-break: break-word;
      text-align: center;
      font-family: $fontM;
      font-size: 13px;
      color: white;
    }
  }
}
</style>
