<template>
  <div class="training-test" v-if="questions.length !== 0">
    <template v-if="isActiveTest">
      <div class="training-test__dots">
        <div
          v-for="(question, key) in questions"
          :key="key"
          class="training-test__dots-item"
          :class="{
            current: currentQuestion === key + 1,
            correct: question.isCorrect === true,
            incorrect: question.isCorrect === false
          }"
        >
          <div class="training-test__dots-item-inner"></div>
        </div>
      </div>
      <div class="training-test__count">
        <span>
          {{ currentQuestion >= 10 ? "" : "0" }}{{ currentQuestion }}
        </span>
        / {{ questionsCount >= 10 ? "" : "0" }}{{ questionsCount }}
      </div>
      <template v-for="(question, questionKey) in questions">
        <div
          class="training-test__item"
          :key="questionKey"
          v-if="questionKey + 1 === currentQuestion"
        >
          <div class="training-test__title" v-html="question.title"></div>
          <div class="training-test__answers">
            <div
              v-for="(answer, answerKey) in question.answers"
              :key="answerKey"
              v-html="answer.title"
              @click="
                selectAnswer(question.id, answer.id, questionKey, answerKey)
              "
              class="training-test__answers-item"
              :class="[
                { selected: answer.isSelected },
                `question-${questionKey + 1}`
              ]"
            ></div>
          </div>
        </div>
      </template>
      <div
        class="training-test__next"
        :class="{ disabled: !isSelectedAnswer || isDisabledNextQuestion }"
        @click="nextQuestion()"
      >
        Далі
      </div>
    </template>
    <template v-if="isActiveCode">
      <div class="training-test__code">
        <div class="training-test__code-title">
          Введіть ваш унікальний <br />
          ID-код
          <div class="training-test__code-info">
            <div class="training-test__code-modal">
              6-значний код, який ви отримали від вашої аптечної мережі
            </div>
          </div>
        </div>
        <div class="training-test__code-form">
          <input
            type="text"
            :class="{ error: isCodeHasError }"
            data-letter-field="1"
            @keypress.space.prevent
            @keyup="enterCodeHandler($event)"
            @keyup.delete="deleteCodeHandler($event)"
            @keydown="keyDownCodeHandler($event)"
            @paste="pasteCodeHandler($event)"
            autofocus
            maxlength="1"
            placeholder=""
          />
          <input
            type="text"
            :class="{ error: isCodeHasError }"
            data-letter-field="2"
            @keypress.space.prevent
            @keyup="enterCodeHandler($event)"
            @keyup.delete="deleteCodeHandler($event)"
            @keydown="keyDownCodeHandler($event)"
            @paste="pasteCodeHandler($event)"
            maxlength="1"
            placeholder=""
          />
          <input
            type="text"
            :class="{ error: isCodeHasError }"
            data-letter-field="3"
            @keypress.space.prevent
            @keyup="enterCodeHandler($event)"
            @keyup.delete="deleteCodeHandler($event)"
            @keydown="keyDownCodeHandler($event)"
            @paste="pasteCodeHandler($event)"
            maxlength="1"
            placeholder=""
          />
          <input
            type="text"
            :class="{ error: isCodeHasError }"
            data-letter-field="4"
            @keypress.space.prevent
            @keyup="enterCodeHandler($event)"
            @keyup.delete="deleteCodeHandler($event)"
            @keydown="keyDownCodeHandler($event)"
            @paste="pasteCodeHandler($event)"
            maxlength="1"
            placeholder=""
          />
          <input
            type="text"
            :class="{ error: isCodeHasError }"
            data-letter-field="5"
            @keypress.space.prevent
            @keyup="enterCodeHandler($event)"
            @keyup.delete="deleteCodeHandler($event)"
            @keydown="keyDownCodeHandler($event)"
            @paste="pasteCodeHandler($event)"
            maxlength="1"
            placeholder=""
          />
          <input
            type="text"
            :class="{ error: isCodeHasError }"
            data-letter-field="6"
            @keypress.space.prevent
            @keyup="enterCodeHandler($event)"
            @keyup.delete="deleteCodeHandler($event)"
            @keydown="keyDownCodeHandler($event)"
            @paste="pasteCodeHandler($event)"
            maxlength="1"
            placeholder=""
          />
        </div>
        <div
          class="training-test__code-error"
          :class="{ active: isCodeHasError }"
        >
          Такого коду не існує! <br />
          Перевірте, чи немає помилок у коді.
        </div>
        <div class="training-test__code-btn" @click="noUserCode()">
          У мене немає коду
        </div>
      </div>
    </template>
    <template v-if="isBedResultTest">
      <div class="bed-resullt">
        <div class="bed-resullt__row">
          <div class="bed-resullt__col">
            <div class="bed-resullt__detail">
              <div class="bed-resullt__answers">
                Правильних відповідей: {{ correctAnswers }}
              </div>
              <div class="bed-resullt__title">
                На жаль, ви не пройшли тестування
              </div>
              <div class="bed-resullt__image-mobile">
                <img
                  src="~@/assets/img/training-module2/test/bed-result.png"
                  alt=""
                />
              </div>
              <div class="bed-resullt__btn" @click="reloadTest()">
                Пройти тестування ще раз
              </div>
              <div
                class="bed-resullt__btn bed-resullt__btn--transparent"
                @click="goToTheory(1, '')"
              >
                Повернутись до теорії
              </div>
            </div>
          </div>
          <div class="bed-resullt__col">
            <div class="bed-resullt__image">
              <img
                src="~@/assets/img/training-module2/test/bed-result.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isGoodResultTest">
      <div class="good-resullt">
        <div class="good-resullt__row">
          <div class="good-resullt__col">
            <div class="good-resullt__detail">
              <div class="good-resullt__answers">
                Правильних відповідей: {{ correctAnswers }}
              </div>
              <div class="good-resullt__title">
                Вітаємо! Ваш тест був зарахований!
              </div>
              <div class="good-resullt__image-mobile">
                <img
                  src="~@/assets/img/training-module2/test/good-result.png"
                  alt=""
                />
              </div>
              <template v-if="correctAnswers < questions.length">
                <div class="good-resullt__info">
                  Ви можете покращити свій результат
                </div>
                <div
                  class="good-resullt__btn good-resullt__btn--transparent"
                  @click="reloadTest()"
                >
                  Пройти тестування ще раз
                </div>
                <div class="good-resullt__info">
                  або переходьте до вибору тем
                </div>
                <a
                  @click="nextTheme()"
                  href="/article/pharmacist/p-osvitnii-modul-3-p"
                  class="good-resullt__btn good-resullt__btn--next"
                >
                  Перейти до вибору тем
                </a>
              </template>
              <template v-else>
                <div class="good-resullt__info">
                  Скоріш переходьте до наступної теми
                </div>
                <a
                  @click="nextTheme()"
                  href="/article/pharmacist/p-osvitnii-modul-3-p"
                  class="good-resullt__btn good-resullt__btn--next"
                >
                  Перейти до вибору тем
                </a>
              </template>
            </div>
          </div>
          <div class="good-resullt__col">
            <div class="good-resullt__image">
              <img
                src="~@/assets/img/training-module2/test/good-result.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isGoodResultTestWitoutCode">
      <div class="good-resullt">
        <div class="good-resullt__row">
          <div class="good-resullt__col">
            <div class="good-resullt__detail">
              <div class="good-resullt__answers">
                Правильних відповідей: {{ correctAnswers }}
              </div>
              <div class="good-resullt__title">
                Чудово! Ви пройшли тестування!
              </div>
              <div class="good-resullt__image-mobile">
                <img
                  src="~@/assets/img/training-module2/test/good-result.png"
                  alt=""
                />
              </div>
              <template v-if="correctAnswers < questions.length">
                <div class="good-resullt__info">
                  Ви можете покращити свій результат
                </div>
                <div
                  class="good-resullt__btn good-resullt__btn--transparent"
                  @click="reloadTest()"
                >
                  Пройти тестування ще раз
                </div>
                <div class="good-resullt__info">
                  або переходьте до вибору тем
                </div>
                <a
                  @click="nextTheme()"
                  href="/article/pharmacist/p-osvitnii-modul-3-p"
                  class="good-resullt__btn good-resullt__btn--next"
                >
                  Перейти до вибору тем
                </a>
              </template>
              <template v-else>
                <div class="good-resullt__info">
                  Скоріш переходьте до наступної теми
                </div>
                <a
                  @click="nextTheme()"
                  href="/article/pharmacist/p-osvitnii-modul-3-p"
                  class="good-resullt__btn good-resullt__btn--next"
                >
                  Перейти до вибору тем
                </a>
              </template>
            </div>
          </div>
          <div class="good-resullt__col">
            <div class="good-resullt__image">
              <img
                src="~@/assets/img/training-module2/test/good-result.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from "axios";
export default {
  name: "Test",
  data() {
    return {
      questions: [],
      questionId: null,
      answerId: null,
      questionKey: null,
      answerKey: null,
      currentQuestion: 1,
      correctAnswers: 0,
      previousEvent: null,
      userCode: {
        letter1: null,
        letter2: null,
        letter3: null,
        letter4: null,
        letter5: null,
        letter6: null
      },
      answers: [],
      isCodeHasError: false,
      isSelectedAnswer: false,
      isDisabledNextQuestion: false,
      isActiveTest: true,
      isActiveCode: false,
      isBedResultTest: false,
      isGoodResultTest: false,
      isGoodResultTestWitoutCode: false
    };
  },
  computed: {
    questionsCount() {
      return this.questions.length;
    }
  },
  methods: {
    selectAnswer(questionId, answerId, questionKey, answerKey) {
      this.isSelectedAnswer = true;
      this.questionId = questionId;
      this.answerId = answerId;

      this.questionKey = questionKey + 1;
      this.answerKey = answerKey + 1;

      this.questions.forEach(question => {
        if (question.id === questionId) {
          question.answers.forEach(answer => {
            if (answer.id === answerId) {
              answer.isSelected = true;
            } else {
              answer.isSelected = false;
            }
          });
        }
      });
    },
    nextQuestion() {
      if (this.currentQuestion <= this.questions.length) {
        this.isDisabledNextQuestion = true;
        this.answers.push({
          question_id: this.questionId,
          answers: [
            {
              answer_id: this.answerId
            }
          ]
        });

        axios
          .get(
            this.$root.globalUrl +
              `/api/v1/pharmacy_tests/answers/${this.answerId}/is_correct`
          )
          .then(res => {
            this.isSelectedAnswer = false;
            this.isDisabledNextQuestion = false;

            this.questions.forEach(question => {
              if (question.id === this.questionId) {
                question.isCorrect = res.data.is_correct;
                if (res.data.is_correct) {
                  this.correctAnswers += 1;
                }
              }
            });
            gtag("event", "click", {
              event_category: `Выбор "${
                this.questions[this.questionKey - 1].answers[this.answerKey - 1]
                  .title
              }"`,
              event_label: `М.3 - Т.3 - Раздел "Перевірка знань" - Э.${this.currentQuestion}`
            });

            this.currentQuestion += 1;

            let themeData = JSON.parse(localStorage.getItem("module3_theme3"));
            themeData.test.currentQuestion = this.currentQuestion;
            themeData.test.correctAnswers = this.correctAnswers;
            themeData.test.questions = this.questions;
            themeData.test.answers = this.answers;
            localStorage.setItem("module3_theme3", JSON.stringify(themeData));

            if (this.currentQuestion > this.questions.length) {
              this.isActiveTest = false;
              this.isActiveCode = true;
              let themeData = JSON.parse(
                localStorage.getItem("module3_theme3")
              );
              themeData.test.isActiveCode = true;
              localStorage.setItem("module3_theme3", JSON.stringify(themeData));
              gtag("event", "click", {
                event_category: "Экран ввода кода пользователя",
                event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
              });
            }

            window.scrollTo({
              top: 0,
              behavior: "auto"
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    deleteCodeHandler() {
      var previousField = event.target.previousElementSibling;
      var fieldNumber = event.target.getAttribute("data-letter-field");
      if (previousField !== null) {
        event.target.previousElementSibling.focus();
      }
      this.userCode["letter" + fieldNumber] = null;
      event.target.value = null;
      this.previousEvent = "delete";
    },
    enterCodeHandler() {
      var fieldNumber = event.target.getAttribute("data-letter-field");
      var nextField = event.target.nextElementSibling;

      // check if enter valid characters or numbers
      if (!event.target.value.match(/^[a-zA-Z0-9]{1}$/)) {
        this.userCode["letter" + fieldNumber] = null;
        event.target.value = null;
      } else {
        if (nextField !== null) {
          event.target.nextElementSibling.focus();
          this.previousEvent = null;
        }
        this.userCode["letter" + fieldNumber] = event.target.value;
      }
      // send user code
      if (!this.isCodeHasError && fieldNumber === "6" && event.target.value) {
        this.sendUserCode();
      } else if (this.isCodeHasError && event.target.value) {
        this.sendUserCode();
      }
    },
    keyDownCodeHandler() {
      var fieldNumber = event.target.getAttribute("data-letter-field");
      // delete not valid symbols
      if (!event.target.value.match(/^[a-zA-Z0-9]{1}$/)) {
        this.userCode["letter" + fieldNumber] = null;
        event.target.value = null;
      }
      // // set caret to next field
      if (
        event.keyCode !== 8 &&
        event.keyCode !== 37 &&
        this.previousEvent === "delete" &&
        event.target.value
      ) {
        event.target.nextElementSibling.focus();
        this.previousEvent = null;
      }
    },
    pasteCodeHandler(event) {
      var codeFields = document.querySelectorAll(
        ".training-test__code-form input"
      );
      codeFields.forEach(() => {
        event.stopPropagation();
        event.preventDefault();
        var clipboardData = (
          event.originalEvent || event
        ).clipboardData.getData("text/plain");

        codeFields[0].value = clipboardData.split("")[0];
        this.userCode.letter1 = clipboardData.split("")[0];
        codeFields[1].value = clipboardData.split("")[1];
        this.userCode.letter2 = clipboardData.split("")[1];
        codeFields[2].value = clipboardData.split("")[2];
        this.userCode.letter3 = clipboardData.split("")[2];
        codeFields[3].value = clipboardData.split("")[3];
        this.userCode.letter4 = clipboardData.split("")[3];
        codeFields[4].value = clipboardData.split("")[4];
        this.userCode.letter5 = clipboardData.split("")[4];
        codeFields[5].value = clipboardData.split("")[5];
        this.userCode.letter6 = clipboardData.split("")[5];
      });
      this.sendUserCode();
    },
    sendUserCode() {
      let code =
        this.userCode.letter1 +
        this.userCode.letter2 +
        this.userCode.letter3 +
        this.userCode.letter4 +
        this.userCode.letter5 +
        this.userCode.letter6;
      code = code.toLowerCase();
      axios
        .post(this.$root.globalUrl + "/api/v1/pharmacy_tests/7/submit", {
          user_code: code,
          votes: this.answers
        })
        .then(res => {
          var themeData = JSON.parse(localStorage.getItem("module3_theme3"));
          if (res.data.user_exists) {
            if (this.correctAnswers < (80 * this.questions.length) / 100) {
              this.isActiveCode = false;
              themeData.test.isActiveCode = null;
              this.isBedResultTest = true;
              themeData.test.isBedResult = true;
              this.isCodeHasError = false;
            } else {
              this.isActiveCode = false;
              themeData.test.isActiveCode = null;
              this.isGoodResultTest = true;
              themeData.test.isGoodResult = true;
              this.isCodeHasError = false;
            }
          } else {
            this.isCodeHasError = true;
          }
          localStorage.setItem("module3_theme3", JSON.stringify(themeData));
        })
        .catch(error => {
          console.log(error);
        });
      gtag("event", "click", {
        event_category: "Отправка кода пользователя",
        event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
      });
    },
    noUserCode() {
      var themeData = JSON.parse(localStorage.getItem("module3_theme3"));
      if (this.correctAnswers < (80 * this.questions.length) / 100) {
        this.isActiveCode = false;
        this.isBedResultTest = true;
        themeData.test.isActiveCode = null;
        themeData.test.isBedResult = true;
      } else {
        this.isActiveCode = false;
        themeData.test.isActiveCode = null;
        this.isGoodResultTestWitoutCode = true;
        themeData.test.isGoodResultWitoutCode = true;
      }
      localStorage.setItem("module3_theme3", JSON.stringify(themeData));
      axios
        .post(this.$root.globalUrl + "/api/v1/pharmacy_tests/7/submit", {
          user_code: null,
          votes: this.answers
        })
        .then(() => {
          this.isCodeHasError = false;
        })

        .catch(error => {
          console.log(error);
        });
      gtag("event", "click", {
        event_category: 'Кнопка "В мене немає коду"',
        event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
      });
    },
    reloadTest() {
      this.currentQuestion = 1;
      this.correctAnswers = 0;
      this.isBedResultTest = false;
      this.isGoodResultTest = false;
      this.isGoodResultTestWitoutCode = false;
      this.isActiveTest = true;
      this.isSelectedAnswer = false;
      this.answers = [];
      this.userCode.letter1 = "";
      this.userCode.letter2 = "";
      this.userCode.letter3 = "";
      this.userCode.letter4 = "";
      this.userCode.letter5 = "";
      this.userCode.letter6 = "";

      var themeData = JSON.parse(localStorage.getItem("module3_theme3"));
      themeData.test.isActiveCode = null;
      themeData.test.isBedResult = null;
      themeData.test.isGoodResult = null;
      themeData.test.isGoodResultWitoutCode = null;
      themeData.test.currentQuestion = null;
      themeData.test.correctAnswers = null;
      themeData.test.questions = null;
      themeData.test.answers = null;
      localStorage.setItem("module3_theme3", JSON.stringify(themeData));

      this.questions.forEach(question => {
        question.isCorrect = null;
        question.answers.forEach(answer => {
          answer.isSelected = false;
        });
      });
      gtag("event", "click", {
        event_category: 'Кнопка "Сыграть еще раз"',
        event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    goToTheory(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до теорії"',
        event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextTheme() {
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до наступної теми"',
        event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  },
  beforeMount() {
    var themeData = JSON.parse(localStorage.getItem("module3_theme3"));

    if (themeData.test.currentQuestion !== null) {
      this.currentQuestion = Number(themeData.test.currentQuestion);
    }
    if (themeData.test.correctAnswers !== null) {
      this.correctAnswers = Number(themeData.test.correctAnswers);
    }
    if (themeData.test.answers !== null) {
      this.answers = themeData.test.answers;
    }
    if (themeData.test.questions !== null) {
      this.questions = themeData.test.questions;
    } else {
      axios
        .get(this.$root.globalUrl + "/api/v1/pharmacy_tests/?ids=7")
        .then(res => {
          res.data[0].questions.forEach(question => {
            question.isCorrect = null;
            question.answers.forEach(answer => {
              answer.isSelected = false;
            });
          });
          this.questions = res.data[0].questions;
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (themeData.test.isActiveCode !== null) {
      this.isActiveCode = true;
      this.isActiveTest = false;
      gtag("event", "click", {
        event_category: "Экран ввода кода пользователя",
        event_label: 'М.3 - Т.3 - Раздел "Перевірка знань"'
      });
    }
    if (themeData.test.isBedResult !== null) {
      this.isBedResultTest = true;
      this.isActiveTest = false;
    }
    if (themeData.test.isGoodResult !== null) {
      this.isGoodResultTest = true;
      this.isActiveTest = false;
    }
    if (themeData.test.isGoodResultWitoutCode !== null) {
      this.isGoodResultTestWitoutCode = true;
      this.isActiveTest = false;
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input,
  textarea {
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(255, 255, 255, 0)),
      to(rgba(255, 255, 255, 0))
    );
    border-radius: 0;
  }
}

.training-test {
  &__dots {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    padding-top: 16px;
    &-item {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 17px;
      background: #e4eaed;
      &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: -13px;
        width: 8px;
        height: 1px;
        background: #e4eaed;
      }
      &.correct {
        background: #00a03b;
      }
      &.incorrect {
        background: #fb3449;
      }
      &.current {
        background: #fff;
        border: 1px solid #005a85;
        .training-test {
          &__dots {
            &-item {
              &-inner {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background: #005a85;
              }
            }
          }
        }
      }

      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
  &__count {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #317575;
    span {
      color: #7fc25d;
    }
  }
  &__title {
    margin-bottom: 48px;
    font-size: 22px;
    font-family: $fontDemi;
    line-height: 27px;
    text-align: center;
    color: #00a03b;
    ::v-deep {
      span,
      p {
        font-family: $fontDemi;
        color: #00a03b !important;
      }
      sub,
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__answers {
    max-width: 401px;
    margin: 0 auto 32px;
    &-item {
      position: relative;
      margin-bottom: 24px;
      padding: 14px 42px 14px 42px;
      background: rgba(228, 234, 237, 0.4);
      border: 1px solid #00a03b;
      border-radius: 48px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #005a85;
      cursor: pointer;
      transition: all 0.5s;
      &:last-child {
        margin-bottom: 0;
      }
      &.selected,
      &:hover {
        background: #00a03b;
        color: #fff;
        &:before {
          border: 3px solid #fff;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #00a03b;
      }
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    @media screen and (min-width: 1200px) {
      &:hover {
        background-position: 110% center;
      }
    }
    &.disabled {
      background-color: rgba(170, 25, 139, 0.16);
      pointer-events: none;
    }
  }
  &__code {
    padding-top: 24px;

    &-title {
      margin-bottom: 150px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #00a03b;
      br {
        display: none;
      }
    }
    &-info {
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url("~@/assets/img/training-module2/test/question-icon.svg")
        no-repeat;
      cursor: pointer;
      &:hover {
        .training-test {
          &__code {
            &-modal {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
    &-modal {
      position: absolute;
      top: 35px;
      left: 7px;
      transform: translateX(-50%);
      width: 240px;
      padding: 8px;
      background: #ffffff;
      box-shadow: 0px 0px 16px rgba(0, 90, 133, 0.12);
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #545454;
      visibility: hidden;
      opacity: 0;
      cursor: default;
      transition: all 0.5s;
      &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        width: 20px;
        height: 20px;
        background: #fff;
        transform: translateX(-50%) rotate(45deg);
      }
    }
    &-form {
      display: flex;
      justify-content: center;
      margin-bottom: 48px;
      input {
        display: block;
        max-width: 60px;
        height: 65px;
        margin-right: 16px;
        border: none;
        border-bottom: 2px solid #cccccc;
        font-weight: 600;
        font-size: 40px;
        text-align: center;
        text-transform: uppercase;
        color: #204e77;
        user-select: text;
        overflow: visible;
        caret-color: #204e77;
        &.error {
          border-color: #fb3449;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &:focus {
          outline: none;
        }
      }
    }
    &-error {
      max-width: 450px;
      margin: 0 auto 64px;
      padding: 16px 24px;
      background: #ffffff;
      border: 1px solid #fb3449;
      box-sizing: border-box;
      border-radius: 8px;
      opacity: 0;
      font-family: $fontM;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #204e77;
      &.active {
        opacity: 1;
      }
    }
    &-btn {
      width: 178px;
      height: 44px;
      margin: auto;
      background: rgba($color: #dddddd, $alpha: 0.16);
      border-radius: 90px;
      font-family: $fontM;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      color: #545454;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background: #dddddd;
      }
    }
  }
  .bed-resullt {
    padding-top: 24px;
    &__row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -15px;
    }
    &__col {
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 15px;
    }
    &__answers {
      margin-bottom: 16px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #005a85;
    }
    &__title {
      margin-bottom: 48px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #fb3449;
    }
    &__btn {
      width: 100%;
      max-width: 376px;
      height: 64px;
      margin-bottom: 24px;
      background: #aa198b;
      border: 1px solid #aa198b;
      border-radius: 90px;
      font-weight: 450;
      font-size: 22px;
      line-height: 64px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &--transparent {
        background: #fff;
        color: #aa198b;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__image {
      display: flex;
      justify-content: center;
      &-mobile {
        display: none;
        img {
          display: inline-block;
          max-width: 100%;
        }
      }
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
  .good-resullt {
    padding-top: 24px;
    &__row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -15px;
    }
    &__col {
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 15px;
    }
    &__answers {
      margin-bottom: 16px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #005a85;
    }
    &__title {
      margin-bottom: 48px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #009f3b;
    }
    &__info {
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
    &__btn {
      display: block;
      width: 100%;
      max-width: 376px;
      height: 64px;
      margin-top: 24px;
      margin-bottom: 35px;
      background: #aa198b;
      border: 1px solid #aa198b;
      border-radius: 90px;
      font-family: $fontM;
      font-size: 22px;
      line-height: 64px;
      text-decoration: none;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &--transparent {
        background: #fff;
        color: #aa198b;
      }
      &--next {
        padding: 0 24px;
        background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-origin: content-box;
        text-indent: -50px;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      &-mobile {
        display: none;
        img {
          display: inline-block;
          max-width: 100%;
        }
      }
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .training-test {
    &__dots {
      padding-top: 0;
      &-item {
        margin-right: 10px;
        &:after {
          right: -7px;
          width: 4px;
        }
      }
    }
    &__count {
      margin-bottom: 12px;
    }
    &__title {
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 22px;
    }
    &__answers {
      &-item {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 17px;
        &.question-3 {
          text-align: left;
        }
      }
    }
    &__next {
      width: 100%;
      max-width: 312px;
      height: 44px;
      padding: 0 110px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
    
    &__code {
      &-title {
        margin-bottom: 130px;
        font-size: 20px;
        line-height: 24px;
        br {
          display: inline-block;
        }
      }
      &-form {
        margin-bottom: 32px;
        input {
          max-width: 40px;
          height: 55px;
          font-size: 32px;
          line-height: 39px;
        }
      }
      &-error {
        margin: 0 auto 54px;
        padding: 16px 16px;
      }
    }
    .bed-resullt {
      padding-top: 0;
      &__col {
        flex-basis: 100%;
        max-width: 100%;
      }
      &__answers {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      &__title {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
      }
      &__image {
        display: none;
        &-mobile {
          display: block;
          margin-bottom: 33px;
          padding: 0 35px;
          text-align: center;
        }
      }
      &__btn {
        max-width: 307px;
        height: 44px;
        margin: 0 auto 12px;
        font-size: 16px;
        line-height: 44px;
      }
    }
    .good-resullt {
      padding-top: 0;
      &__col {
        flex-basis: 100%;
        max-width: 100%;
      }
      &__answers {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      &__title {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
      }
      &__info {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
      &__image {
        display: none;
        &-mobile {
          display: block;
          margin-bottom: 33px;
          padding: 0 35px;
          text-align: center;
        }
      }
      &__btn {
        width: 100%;
        max-width: 307px;
        height: 44px;
        margin: 12px auto 22px;
        font-size: 16px;
        line-height: 44px;
        &--next {
          padding: 0 29px;
          background-size: 24px;
          text-indent: -40px;
        }
      }
    }
  }
}
@media screen and (max-width: 359px) {
  .training-test {
    &__dots {
      &-item {
        width: 12px;
        height: 12px;
        &:after {
          right: -7px;
          width: 4px;
          top: 6px;
        }
        &.current {
          .training-test {
            &__dots {
              &-item {
                &-inner {
                  height: 6px;
                  width: 6px;
                }
              }
            }
          }
        }
      }
    }
    &__code {
      &-form {
        input {
          max-width: 33px;
          height: 45px;
          font-size: 22px;
          line-height: 39px;
        }
      }
      &-error {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>
