<template>
  <div class="module-theory">
    <template v-if="state === 1">
      <div class="module-theory__title hidden-mobile">
        Страх ін’єкцій — поширене явище як серед дорослих, так і серед дітей<sup
          >5,6</sup
        >
      </div>
      <div class="question">
        <div class="question__row">
          <div class="question__col-left">
            <div class="question__img">
              <img
                src="@/assets/img/training-module3/theme-1/step-3/1.jpg"
                alt=""
              />
            </div>
            <div class="question__img-detail">
              Страх ін’єкцій — поширене явище як серед дорослих, так і серед
              дітей<sup>5,6</sup>
            </div>
          </div>
          <div class="question__col-right">
            <div class="question__title">
              Чи знаєте ви, яка поширеність <br />
              страху ін’єкцій?
            </div>
            <div class="question__answers">
              <div class="question__answers-item" @click="changeState('yes')">
                Так
              </div>
              <div class="question__answers-item" @click="changeState('no')">
                Ні
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="state === 2">
      <div class="module-theory__info">
        Мета-аналіз 2018 року McLenon J. et al. Проаналізовано 35 статей
      </div>
      <div class="module-theory__title">Страх голок мають:<sup>6</sup></div>
      <div class="module-theory__list">
        <ul>
          <li>20-50% підлітків</li>
          <li>20-30% молодих дорослих</li>
        </ul>
      </div>
      <div class="module-theory__text">
        Страх голок і голкофобія
        <b>
          переважають <br />
          у жінок
        </b>
        в порівнянні з чоловіками.<sup>6</sup>
      </div>
      <div class="module-theory__title">
        За даними декількох досліджень страх ін'єкцій мають від 14% до 38%
        дорослого населення<sup>5</sup>
      </div>
      <div class="module-theory__video">
        <div class="module-theory__video-frame">
          <iframe
            ref="video"
            width="100%"
            height="100%"
            :src="
              `https://www.youtube.com/embed/uIzzGHXWMvM?si=wNzMvF2k-g4rLks1?rel=0?&autoplay=${
                !isVideoPlayed ? '0' : '1'
              }`
            "
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div
          class="module-theory__video-preview"
          v-show="!isVideoPlayed"
          :style="{
            backgroundImage:
              'url(' +
              require('@/assets/img/training-module3/theme-1/step-3/video-preview.png') +
              ')'
          }"
        >
          <div class="module-theory__video-play" @click="playVideo()"></div>
        </div>
      </div>
      <div class="module-theory__tagline">
        <div class="module-theory__tagline-text">
          Страх перед ін’єкціями — це не тільки емоції!
        </div>
        <div class="module-theory__tagline-title">Ми знаємо рішення!</div>
      </div>
      <div
        class="module-theory__next next-button"
        @click="nextState(4, 'next')"
      >
        Далі
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory3",
  data() {
    return {
      state: 1,
      isVideoPlayed: false
    };
  },
  methods: {
    playVideo() {
      this.isVideoPlayed = true;
    },
    changeState(answer) {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
      if (answer === "yes") {
        gtag("event", "click", {
          event_category: 'Выбор "Так"',
          event_label: 'М.3 - Т.1  - Раздел "Страх ін’єкцій"  - Э.1'
        });
      }
      if (answer === "no") {
        gtag("event", "click", {
          event_category: 'Выбор "Ні"',
          event_label: 'М.3 - Т.1  - Раздел "Страх ін’єкцій"  - Э.1'
        });
      }
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.3 - Т.1  - Раздел "Страх ін’єкцій"  - Э.2'
      });
    },
    mounted() {
      let myVideo = document.getElementsByClassName("module-theory__video")[0];
      let elemV = myVideo.getBoundingClientRect().top;
      let header = document.querySelector('.header-block').offsetHeight;
      const offsetPos = elemV - header;

      this.content.blocks.forEach(element => {
        const embedId = element.link.replace('https://www.youtube.com/embed/', '');
        if (c === embedId) {
          window.scrollBy({
            top: offsetPos,
            behavior: 'smooth'
          })
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  .question {
    &__row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -10px;
    }
    &__col {
      &-left {
        padding: 0 10px;
        flex-basis: 55%;
        max-width: 55%;
      }
      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }
    &__img {
      &-detail {
        display: none;
      }
      img {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #00a03b;
    }
    &__answers {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &-item {
        width: 124px;
        height: 44px;
        margin-right: 18px;
        background: rgba(228, 234, 237, 0.4);
        border: 1px solid #00a03b;
        border-radius: 80px;
        font-weight: 600;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        color: #005984;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: #00a03b;
          color: #fff;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__info {
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 15px;
    color: #545454;
  }
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__list {
    margin-bottom: 12px;
    ul {
      padding-left: 17px;
      margin: 0;
      li {
        font-size: 16px;
        line-height: 20px;
        color: #545454;
      }
    }
  }
  &__text {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    b {
      font-family: $fontDemi;
    }
  }
  &__video {
    position: relative;
    margin-top: 48px;
    margin-bottom: 48px;
    &-frame {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
    &-preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 138px;
      height: 138px;
      background: url("~@/assets/img/training-module3/theme-1/step-3/play-icon.svg")
        no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
  &__tagline {
    margin-bottom: 24px;
    text-align: center;
    &-text {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
    &-title {
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }
  }
  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 9px;
      font-size: 18px;
      line-height: 22px;
      &.hidden-mobile {
        display: none;
      }
    }
    &__info {
      margin-bottom: 14px;
      font-size: 11px;
      line-height: 13px;
    }
    &__list {
      margin-bottom: 19px;
      ul {
        li {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    &__text {
      font-size: 14px;
      line-height: 17px;
      br {
        display: none;
      }
    }
    &__video {
      margin-top: 27px;
      margin-bottom: 32px;
      &-play {
        width: 89px;
        height: 89px;
      }
    }
    &__tagline {
      text-align: left;
      &-text {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 17px;
      }
      &-title {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: 30px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
    .question {
      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__img {
        margin-bottom: 17px;
        &-detail {
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 17px;
          color: #545454;
        }
      }
      &__title {
        text-align: left;
      }
      &__answers {
        &-item {
          width: 100%;
          max-width: 312px;
          margin: 0 auto 10px;

          &:last-child {
            margin-bottom: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}
</style>
