// import { debounce } from "lodash";

import router from "../router";
import $api from "../service";

const capitalize = (s) => s[0].toUpperCase() + s.split("").slice(1).join("");
const generateSetMutation = (list) =>
  list.reduce((r, key) => {
    const name = `set${capitalize(key)}`;
    r[name] = (state, payload) => {
      state[key] = payload;
    };
    return r;
  }, {});

export default {
  namespaced: true,

  state: {
    items: [],
    isFetch: false
  },

  getters: {
    specById(state) {
      return state.items.reduce((r, el) => {
        r[el.id] = el;
        return r;
      }, {});
    },
    specBySlug(state) {
      return state.items.reduce((r, el) => {
        r[el.slug] = el;
        return r;
      }, {});
    },
    currentSpec(state, getters) {
      const { spec, specSlug } = router.currentRoute.params;
      if (specSlug) {
        return getters.specBySlug[specSlug] || {};
      } else if (spec) {
        return getters.specById[spec] || {};
      }

      return {};
    },
  },

  actions: {
    async fetchSpec({ commit }) {
      commit('setIsFetch', true);
      try {
        const data = await $api.fetchSpecialties();
        commit("setItems", data);
        commit('setIsFetch', false);
        return data;
      } catch (e) {
        console.log(e);
      }
      commit('setIsFetch', false);
    },
  },

  mutations: {
    ...generateSetMutation(["items", "isFetch"]),

    reset(state) {
      state.items = [];
      state.isFetch = false;
    },
  },
};
