<template>
  <div class="section-sticky-btn" :class="{ 'section-sticky-btn--unpinned': scrolled }">
    <a :href="content.link_bt_input" class="btn-wrapper">
      {{ content.text_bt_input }}
    </a>
  </div>
</template>

<script>
export default {
  name: "section-sticky-btn",
  props: {
    content: Object
  },
  data: () => ({
    showVideo: false,
    limitPosition: 100,
    scrolled: false,
    lastPosition: 0,
  }),
  methods: {
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
      }

      this.lastPosition = window.scrollY;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1128px;

.section-sticky-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;

  will-change: transform;
  transition: transform 200ms linear;


  &--unpinned {
    transform: translateY(100%);
  }

  @media (min-width: 768px) {
    display: none;
  }

  .btn-wrapper {
    width: 100%;
    height: 48px;

    background: #317575;
    border-radius: 150px;

    font-size: 14px;
    line-height: 21px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;

    color: #FFFFFF;
  }
}
</style>
