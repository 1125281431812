<template>
  <section class="article-page training-module-5">
    <div class="main-page">
      <div class="content">
        <div class="article-wrapper">
          <Navigation
            :state="state"
            :currentState="currentState"
            :isVisibleLeftArrow="isVisibleLeftArrow"
            :isVisibleRightArrow="isVisibleRightArrow"
            v-on:changeState="changeState($event)"
            v-on:scrollMenuTo="scrollMenuTo($event)"
          />
          <Theory1
            v-if="currentState === 1"
            v-on:changeState="changeState($event)"
          />
          <Theory2
            v-if="currentState === 2"
            v-on:changeState="changeState($event)"
          />
          <Theory3
            v-if="currentState === 3"
            v-on:changeState="changeState($event)"
          />
          <Theory4
            v-if="currentState === 4"
            v-on:changeState="changeState($event)"
          />
          <Theory5
            v-if="currentState === 5"
            v-on:changeState="changeState($event)"
          />
          <Theory6
            v-if="currentState === 6"
            v-on:changeState="changeState($event)"
          />
          <Test
            v-if="currentState === 7"
            v-on:changeState="changeState($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navigation from "@/components/training-module5/theme-1/Navigation";
import Theory1 from "@/components/training-module5/theme-1/Theory1";
import Theory2 from "@/components/training-module5/theme-1/Theory2";
import Theory3 from "@/components/training-module5/theme-1/Theory3";
import Theory4 from "@/components/training-module5/theme-1/Theory4";
import Theory5 from "@/components/training-module5/theme-1/Theory5";
import Theory6 from "@/components/training-module5/theme-1/Theory6";
import Test from "@/components/training-module5/theme-1/Test";

export default {
  name: "Module5Theme1",
  data() {
    return {
      state: 1,
      currentState: 1,
      isVisibleLeftArrow: false,
      isVisibleRightArrow: true
    };
  },
  components: {
    Navigation,
    Theory1,
    Theory2,
    Theory3,
    Theory4,
    Theory5,
    Theory6,
    Test
  },
  methods: {
    changeState(value) {
      this.currentState = value.state;
      var themeData = JSON.parse(localStorage.getItem("module5_theme1"));
      themeData.currentStage = value.state;
      localStorage.setItem("module5_theme1", JSON.stringify(themeData));
      if (value.direction === "next" && this.state <= this.currentState) {
        this.state = value.state;
        themeData.state = value.state;
        localStorage.setItem("module5_theme1", JSON.stringify(themeData));
      }
      this.scrollToActiveMenu();
    },
    scrollToActiveMenu() {
      var menu = document.querySelector(".training-nav__inner");
      var menuItems = document.querySelectorAll(".training-nav__item");
      menuItems.forEach((item, key) => {
        if (this.currentState === key + 1) {
          menu.scrollLeft = menuItems[key].offsetLeft;
          if (menu.scrollLeft === 0) {
            this.isVisibleLeftArrow = false;
            this.isVisibleRightArrow = true;
          } else {
            this.isVisibleLeftArrow = true;
            this.isVisibleRightArrow = false;
          }
        }
      });
    },
    scrollMenuTo(direction) {
      var menu = document.querySelector(".training-nav__inner");

      if (direction === "left") {
        menu.scrollLeft = 0;
        this.isVisibleLeftArrow = false;
        this.isVisibleRightArrow = true;
      }
      if (direction === "right") {
        menu.scrollLeft = menu.scrollWidth;
        this.isVisibleLeftArrow = true;
        this.isVisibleRightArrow = false;
      }
    }
  },
  mounted() {
    if (localStorage.getItem("module5_theme1") === null) {
      localStorage.setItem(
        "module5_theme1",
        JSON.stringify({
          state: null,
          currentStage: null,
          test: {
            questions: null,
            answers: null,
            currentQuestion: null,
            correctAnswers: null,
            isActiveCode: null,
            isBedResult: null,
            isGoodResult: null,
            isGoodResultWitoutCode: null
          }
        })
      );
    }

    var themeData = JSON.parse(localStorage.getItem("module5_theme1"));

    if (themeData.currentStage !== null) {
      this.currentState = Number(themeData.currentStage);
    }
    if (themeData.stage !== null) {
      this.state = Number(themeData.state);
    }
    this.scrollToActiveMenu();
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

// Begin main
.training-module-5 {
  margin-bottom: 50px;
  
  strong, b {
    font-family: $fontDemi;
  }

  .main-page {
    .content {
      margin-top: 0;

      .module-theory {
        &__back-btn {
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;

          @media screen and (max-width: 767px) {
            margin-bottom: 12px;
          }
        }

        &__h2-title {
          font-weight: 600;
          font-family: $fontDemi;
          font-size: 22px;
          line-height: 27px;
          color: #00A03B;
          margin-bottom: 23px;

          @media screen and (max-width: 764px) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 16px;
          }
        }

        &__text {
          font-size: 16px;
          line-height: 20px;
          color: #545454;

          @media screen and (max-width: 764px) {
            font-size: 14px;
            line-height: 120%;
            color: #545454;
          }
        }

        &__links {
          padding: 32px 48px 45px;
          margin-top: 160px;
          background: #F5F5F5;
          font-size: 14px;
          line-height: 120%;
          text-align: left;
          color: #545454;

          @media screen and (max-width: 767px) {
            padding: 24px 15px;
            margin-top: 32px;
          }
        }

        &__links-item {
          margin-top: 16px;
          display: flex;

          br {
            display: none;
  
            @media screen and (max-width: 767px) {
              display: block;
            }
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 21px;
            height: 21px;
            margin-right: 16px;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #FFFFFF;
            background: #00A03B;

            @media screen and (max-width: 767px) {
              min-width: 16px;
              height: 16px;
              font-size: 11px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
