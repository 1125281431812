<template>
  <div class="module-theory">
    <template v-if="state === 1">
      <div class="questions">
        <div class="questions__row">
          <div class="questions__col-left">
            <div class="questions__img">
              <img src="@/assets/img/training-module4/theory-4/1.jpg" alt="" />
            </div>
          </div>
          <div class="questions__col-right">
            <div class="questions__title">
              Якщо до Вас звертається пацієнт з рецептом на Армодафініл. <br />
              Ваші дії?
            </div>
            <div class="questions__answers">
              <div
                class="questions__answers-item"
                v-for="(answer, key) in answers"
                :key="key"
                v-html="answer.title"
                @click="selectAnswer(answer)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="state === 2">
      <div class="message">
        <div class="message__row">
          <div class="message__col-left">
            <div class="message__img">
              <img src="@/assets/img/training-module4/theory-4/2.jpg" alt="" />
            </div>
          </div>
          <div class="message__col-right">
            <div class="message__detail">
              <div class="message__title">
                Візьму з полиці Нувіджил®
              </div>
              <div class="message__text">
                Правильно! Діюча речовина Нувіджил® — армодафініл.<sup>5</sup>
                Ви виконали свою роботу на відмінно.
              </div>
              <div class="message__text text-green">
                Нувіджил® — для підтримання бадьорості* протягом робочої
                зміни!<sup>5</sup>
              </div>
              <div class="message__text text-green text-center-mobile">
                А тепер час перевірити ваші знання за підсумками цієї теми.
              </div>
              <div class="message__text text-center-mobile">
                Натискайте на кнопку “Перейти до тестування”, щоб пройти тест
              </div>
              <div
                class="message__btn next-button"
                @click="nextState(6, 'next')"
              >
                Перейти до тестування
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="state === 3">
      <div class="message">
        <div class="message__row">
          <div class="message__col-left">
            <div class="message__img">
              <img src="@/assets/img/training-module4/theory-4/3.jpg" alt="" />
            </div>
          </div>
          <div class="message__col-right">
            <div class="message__detail">
              <div class="message__title">
                Не видам препарат, адже пацієнт прийшов без спеціального
                рецептурного бланку ф-3
              </div>
              <div class="message__text">
                Ви вчинили неправильно! Відпуск Нувіджил® (армодафініл) не
                потребує спеціальних рецептурних бланків №3, адже армодафініл не
                є наркотичною речовиною, психотропним засобом або
                прекурсором.<sup>6</sup>
              </div>
              <div class="message__btn previous-button" @click="toStart()">
                Спробувати ще раз
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="state === 4">
      <div class="message">
        <div class="message__row">
          <div class="message__col-left">
            <div class="message__img">
              <img src="@/assets/img/training-module4/theory-4/3.jpg" alt="" />
            </div>
          </div>
          <div class="message__col-right">
            <div class="message__detail">
              <div class="message__title">
                Відмовлю, в Україні не зареєстрований препарат з такою діючою
                речовиною
              </div>
              <div class="message__text">
                Армодафініл — це діюча речовина Нувіджил®. Станом на сьогодні
                він не має аналогів і це єдиний препарат з такою діючою
                речовиною в Україні.<sup>5</sup>
              </div>
              <div class="message__btn previous-button" @click="toStart()">
                Спробувати ще раз
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory4",
  data() {
    return {
      state: 1,
      answers: [
        {
          id: 1,
          title: "Візьму з полиці Нувіджил®"
        },
        {
          id: 2,
          title:
            "Не видам препарат, адже пацієнт прийшов без спеціального рецептурного бланку ф-3"
        },
        {
          id: 3,
          title:
            "Відмовлю, в Україні не зареєстрований препарат з такою діючою речовиною"
        }
      ]
    };
  },
  methods: {
    selectAnswer(answer) {
      if (answer.id === 1) {
        this.state = 2;
        gtag("event", "click", {
          event_category: 'Выбор "Вариант №1"',
          event_label: 'М.4  - Раздел "Випадок в аптеці" '
        });
      }
      if (answer.id === 2) {
        this.state = 3;
        gtag("event", "click", {
          event_category: 'Выбор "Вариант №2"',
          event_label: 'М.4  - Раздел "Випадок в аптеці" '
        });
      }
      if (answer.id === 3) {
        this.state = 4;
        gtag("event", "click", {
          event_category: 'Выбор "Вариант №3"',
          event_label: 'М.4  - Раздел "Випадок в аптеці" '
        });
      }
    },
    toStart() {
      if (this.state === 3) {
        gtag("event", "click", {
          event_category: 'Вариант №2 - Выбор "Спробувати ще раз"',
          event_label: 'М.4  - Раздел "Випадок в аптеці" '
        });
      }
      if (this.state === 4) {
        gtag("event", "click", {
          event_category: 'Вариант №3 - Выбор "Спробувати ще раз"',
          event_label: 'М.4  - Раздел "Випадок в аптеці" '
        });
      }
      this.state = 1;
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до тестування"',
        event_label: 'М.4  - Раздел "Випадок в аптеці" '
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  .questions {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &__col {
      &-left {
        flex-basis: 56%;
        max-width: 56%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 44%;
        max-width: 44%;
        padding: 0 10px;
      }
    }
    &__img {
      img {
        display: block;
        max-width: 100%;
      }
    }
    &__title {
      margin-bottom: 19px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;
    }
    &__answers {
      max-width: 312px;
      &-item {
        margin-bottom: 12px;
        padding: 14px 24px;
        background: rgba(228, 234, 237, 0.4);
        border: 1px solid #00a03b;
        border-radius: 24px;
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #005984;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .message {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &__col {
      &-left {
        flex-basis: 56%;
        max-width: 56%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 44%;
        max-width: 44%;
        padding: 0 10px;
      }
    }
    &__img {
      img {
        display: block;
        max-width: 100%;
      }
    }
    &__detail {
      max-width: 330px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    &__title {
      margin-bottom: 11px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;
    }
    &__text {
      margin-bottom: 15px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
      &.text-green {
        font-family: $fontDemi;
        color: #00a03b;
      }
    }
    &__btn {
      width: 100%;
      max-width: 330px;
      height: 64px;
      background-color: #aa198b;
      background-repeat: no-repeat;
      background-origin: content-box;
      border-radius: 90px;
      font-size: 22px;
      text-align: center;
      line-height: 64px;
      color: #ffffff;
      cursor: pointer;
      &.previous-button {
        margin-top: auto;
        padding: 0 42px;
        background-image: url("~@/assets/img/training-module4/arrow-prev-icon.svg");
        background-position: left center;
        font-family: $fontR;
        text-indent: 40px;
      }
      &.next-button {
        margin-top: auto;
        padding: 0 22px;
        background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
        background-position: right center;
        font-family: $fontDemi;
        text-indent: -40px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    .questions {
      &__col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__img {
        margin-bottom: 24px;
        img {
          width: 100%;
        }
      }
      &__title {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 22px;
      }
      &__answers {
        margin: auto;
        &-item {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .message {
      &__col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__img {
        margin-bottom: 24px;
        img {
          width: 100%;
        }
      }
      &__detail {
        display: block;
        max-width: 100%;
        height: auto;
      }
      &__title {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 22px;
      }
      &__text {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 17px;
        &.text-center-mobile {
          margin-bottom: 8px;
          text-align: center;
        }
      }
      &__btn {
        max-width: 280px;
        height: 44px;
        background-size: 27px;
        font-size: 16px;
        line-height: 44px;
        &.previous-button {
          margin: 48px auto 0;
        }
        &.next-button {
          padding: 0 30px;
          margin: 24px auto 0;
        }
      }
    }
  }
}
</style>
