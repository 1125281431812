<template>
  <div class="module-theory">
    <div class="module-theory__title">Рекомендовані противірусні препарати для лікування грипу<sup>1,4,5</sup></div>

    <table>
      <thead>
      <tr>
        <td>Організації охорони здоров'я</td>
        <td>Рекомендації</td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><b>Центри з контролю та профілактики захворювань</b> (CDC). Настанова для лікарів «Противірусні препарати
          для лікування грипу»<sup>4</sup></td>
        <td><b>Озельтамівір</b> — препарат вибору в разі грипу<sup>4</sup></td>
      </tr>
      <tr>
        <td><b>ВООЗ</b>. Рекомендації «Лікування пацієнтів із тяжким перебігом грипу»<sup>5</sup></td>
        <td><b>Якомога швидше призначення озельтамівіру</b> пацієнтам із ризиком тяжкого перебігу грипу<sup>5</sup></td>
      </tr>
      <tr>
        <td><b>МОЗ України.</b> Уніфікований клінічний протокол «Грип»<sup>1</sup></td>
        <td><b>Озельтамівір</b> та занамівір ефективні для профілактики та лікування грипу<sup>1</sup></td>
      </tr>
      </tbody>
    </table>

    <div class="module-theory__sub-title">Озельтамівір — противірусний засіб із доведеною активністю щодо вірусів грипу
      А та В<sup>4</sup></div>

    <div class="module-theory__next next-button" @click="nextState(4, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory3",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.6  - Раздел "Рекомендації щодо лікування" - Э.1'
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$fontSB: "Commissioner-SemiBold";


.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
  }

  &__subtitle {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }

  .next-button {
    width: 262px;
    height: 64px;
    margin: 48px auto 0 auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-position: 110% center;
    }
  }

  &__item {
    margin-bottom: 32px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }

    &-img {
      img {
        display: block;
        max-width: 100%;
      }

      &-description {
        padding-top: 8px;
        padding-left: 15px;
        font-family: $fontR;
        font-size: 12px;
        line-height: 15px;
        color: #545454;
      }
    }

    &-text {
      p {
        margin-bottom: 15px;
        font-family: $fontR;
        font-size: 16px;
        line-height: 20px;
        color: #545454;

        strong {
          font-family: $fontDemi;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__info {
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }

    &-title {
      margin-bottom: 16px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;
    }

    &-list {
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }

    &-text {
      max-width: 312px;
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 12px;
      line-height: 15px;
      color: #545454;
    }

    &-next {
      &.next-button {
        margin: 0;
      }
    }
  }

  table {
    width: 100%;
    padding-top: 16px;

    thead {

      td {
        width: 50%;
        background: #E1F8E9;
        padding: 24px;
        text-align: center;

        color: var(--text, #424242);
        font-family: $fontM;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        background: var(--neutral-3, #F8F8F8);

        &:nth-child(2n) {
          background: var(--neutral-2, #EEE);
        }
      }

      td {
        text-align: center;
        padding: 24px;

        color: var(--Black, #545454);
        font-family: $fontR;
        font-size: 16px;

        b {
          font-family: $fontSB;
        }
      }
    }
  }

  &__sub-title {
    color: var(--Green, #00A03B);
    text-align: center;
    font-family: $fontSB;
    font-size: 24px;
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }

    &__subtitle {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 17px;
    }

    &__item {
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-img {
        img {
          width: 100%;
        }

        &-description {
          margin-bottom: 24px;
        }
      }

      &-text {
        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    &__info {
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
      }

      &-list {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
      }

      &-text {
        margin-bottom: 32px;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 32px auto 0 auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }

    table {
      width: 100%;
      padding-top: 8px;

      thead {

        td {
          padding: 10px;
          font-size: 13px;
        }
      }

      tbody {

        td {
          padding: 10px;
          font-size: 13px;
        }
      }
    }

    &__sub-title {
      font-size: 18px;
      margin-top: 24px;
    }
  }
}
</style>
