<template>
  <div class="section-drug-description">
    <div class="item">
      <div
        class="item_preview_title"
        v-if="content.type === 'item_preview_title'"
      >
        <div class="name-drug-wrapper">
          <p class="name" v-html="content.title"></p>

          <div class="line"></div>

          <div class="video-btn" @click="showVideo = !showVideo">
            <p v-if="showVideo === false">Дивитись відео</p>
            <p v-if="showVideo === true">Закрити</p>

            <span class="icon" v-if="showVideo === false">
              <svg
                width="14"
                height="19"
                viewBox="0 0 14 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5221 7.73903L2.52376 0.894234C2.06202 0.577907 1.59678 0.410492 1.21008 0.410492C0.462469 0.410492 0 1.01051 0 2.01485V16.8085C0 17.8116 0.461886 18.4105 1.20775 18.4105C1.59504 18.4105 2.05284 18.2429 2.5156 17.9257L12.5186 11.0811C13.1619 10.6402 13.5181 10.0469 13.5181 9.40969C13.5182 8.77296 13.1661 8.17979 12.5221 7.73903Z"
                  fill="white"
                />
              </svg>
            </span>

            <span class="icon" v-if="showVideo === true">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9825 9.01541L17.7124 2.28516C17.8976 2.09989 17.9997 1.85272 18 1.58916C18 1.32545 17.8979 1.07799 17.7124 0.893016L17.1227 0.303406C16.9373 0.117699 16.6901 0.0159912 16.4262 0.0159912C16.1628 0.0159912 15.9157 0.117699 15.7302 0.303406L9.00029 7.03321L2.27005 0.303406C2.08493 0.117699 1.83761 0.0159912 1.5739 0.0159912C1.31049 0.0159912 1.06317 0.117699 0.878049 0.303406L0.288 0.893016C-0.096 1.27702 -0.096 1.9016 0.288 2.28516L7.0181 9.01541L0.288 15.7454C0.102732 15.9309 0.000731707 16.1781 0.000731707 16.4417C0.000731707 16.7052 0.102732 16.9524 0.288 17.1378L0.877902 17.7274C1.06302 17.913 1.31049 18.0148 1.57376 18.0148C1.83746 18.0148 2.08478 17.913 2.2699 17.7274L9.00015 10.9975L15.7301 17.7274C15.9155 17.913 16.1627 18.0148 16.4261 18.0148H16.4264C16.69 18.0148 16.9371 17.913 17.1225 17.7274L17.7123 17.1378C17.8974 16.9525 17.9996 16.7052 17.9996 16.4417C17.9996 16.1781 17.8974 15.9309 17.7123 15.7455L10.9825 9.01541Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </div>

        <div class="show-hide-video-block" v-if="showVideo">
          <iframe
            ref="video"
            width="560"
            height="315"
            :src="`${content.video_link}`"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div
        class="item_preview_content"
        v-if="content.type === 'item_preview_content'"
      >
        <div class="pack-wrapper">
          <div class="img">
            <img :src="`${$root.globalUrl}${content.image}`" alt="" />
          </div>

          <div class="description">
            <div class="release-form">
              <h3>Форма випуску:</h3>
              <p v-html="content.release"></p>
            </div>

            <div class="dosage">
              <h3>Дозування:</h3>
              <p v-html="content.dosing"></p>
            </div>

            <div class="indication">
              <h3>Показання:</h3>

              <div
                class="indication-wrapper"
                v-for="(key, value) in content.blocks"
              >
                <div class="img">
                  <img :src="`${$root.globalUrl}${key.file}`" alt="" />
                </div>

                <div class="text">
                  <h3 v-html="key.title"></h3>
                  <div class="text-wrapper" v-html="key.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="item_preview_dosing"
        v-if="content.type === 'item_preview_dosing'"
      >
        <h2>Спосіб застосування та дози:</h2>

        <div class="method-of-administration">
          <div
            v-if="content.adult !== undefined"
            v-bind:style="{ background: content.adult.bg }"
          >
            <div class="img">
              <img :src="require('../assets/img/article/old.png')" alt="" />
            </div>

            <div class="text">
              <h3>Дорослим</h3>

              <div
                class="text-wrapper"
                v-for="key in content.adult.blocks"
                v-html="key"
              ></div>
            </div>
          </div>

          <div
            v-if="content.child !== undefined"
            v-bind:style="{ background: content.child.bg }"
          >
            <div class="img">
              <img :src="require('../assets/img/article/child.png')" alt="" />
            </div>

            <div class="text">
              <h3>Дітям</h3>

              <div
                class="text-wrapper"
                v-for="key in content.child.blocks"
                v-html="key"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="item_preview_instruction"
        v-if="content.type === 'item_preview_instruction'"
      >
        <div class="btn-wrapper">
          <a
            :download="`${'instruction__'+$route.path.split('/')[3].slice(2,-2)}`"
            target="_blank"
            :href="`${$root.globalUrl}${content.manual}`"
          >
            <div
              v-bind:style="{ background: content.bg }"
              class="download-pdf-btn"
            >
              <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.0872 9.50903H18.5663C19.2238 9.50903 19.8544 9.77023 20.3194 10.2352C20.7843 10.7001 21.0455 11.3307 21.0455 11.9882V24.8799C21.0455 25.5374 20.7843 26.168 20.3194 26.6329C19.8544 27.0978 19.2238 27.359 18.5663 27.359H3.69132C3.03381 27.359 2.40322 27.0978 1.93829 26.6329C1.47336 26.168 1.21216 25.5374 1.21216 24.8799V11.9882C1.21216 11.3307 1.47336 10.7001 1.93829 10.2352C2.40322 9.77023 3.03381 9.50903 3.69132 9.50903H6.17049" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.17041 15.459L11.1287 20.4174L16.0871 15.459" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.1288 1.57568V19.4257" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <div v-html="content.text" class="included-block"></div>
            </div>
          </a>
        </div>
      </div>

      <div class="button_concent" v-if="content.type === 'button_concent'">
        <p class="text" v-html="content.title"></p>

        <a
          target="_blank"
          :href="`${content.button_link}`"
          class="link-btn"
          v-bind:style="{ background: content.button_color }"
        >
          <p v-html="content.button_text"></p>

          <div class="icon">
            <svg
              width="21"
              height="10"
              viewBox="0 0 21 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.71521 4.82166L17.4643 4.82166"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M15.6182 1L19.3156 4.84693L15.6182 8.69387"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-drug-description",
  props: {
    content: Object
  },
  data: () => ({
    showVideo: false
  }),
  mounted() {
    console.log(this.content);
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

.section-drug-description {
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  .show-hide-video-block {
    margin-top: 15px;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .item {
    margin-bottom: 45px;

    @media screen and (max-width: 425px) {
      margin-bottom: 15px;
    }

    .text-wrapper {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .name-drug-wrapper {
      display: flex;
      align-items: center;

      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 425px) {
        margin: 0 -15px 0 0;
      }

      .name {
        color: #00a03b;
        font-size: 30px;
        font-family: $fontDemi;
      }

      .line {
        width: 100%;
        height: 1px;
        background: #d2d2d2;
        margin: 0 15px;
        @media screen and (max-width: 425px) {
          margin: 0 5px 0 5px;
        }
      }

      a {
        text-decoration: none;
      }

      .video-btn {
        min-width: 105px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #005a85;

        @media screen and (max-width: 425px) {
          min-width: 35px;
          height: 35px;

          border-radius: 50%;
        }

        svg {
          display: none;
          @media screen and (max-width: 425px) {
            display: block;
          }
        }

        p {
          color: white;
          font-size: 9px;
          font-family: $fontR;
          @media screen and (max-width: 425px) {
            display: none;
          }
        }
      }
    }

    .pack-wrapper {
      display: flex;

      @media screen and (max-width: 425px) {
        flex-direction: column;
      }

      > div {
        max-width: 400px;
        width: 100%;

        @media screen and (max-width: 1024px) {
          max-width: 500px;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:nth-child(1) {
          margin-right: 15px;
          @media screen and (max-width: 670px) {
            margin-right: 5px;
          }
          @media screen and (max-width: 425px) {
            margin-right: 0;
          }
        }

        &:nth-child(2) {
          margin-left: 15px;
          @media screen and (max-width: 670px) {
            margin-left: 5px;
          }
          @media screen and (max-width: 425px) {
            margin-left: 0;
          }
        }

        &.description {
          @media screen and (max-width: 425px) {
            margin-top: 25px;
          }

          .release-form {
            margin-bottom: 25px;

            h3 {
              color: #00a03b;
              font-family: $fontDemi;
              font-size: 20px;
              font-weight: normal;
              @media screen and (max-width: 670px) {
                font-size: 16px;
              }
            }

            p {
              color: #545454;
              font-size: 16px;
              font-family: $fontR;
            }
          }

          .dosage {
            margin-bottom: 25px;

            h3 {
              color: #00a03b;
              font-size: 20px;
              font-family: $fontDemi;
              @media screen and (max-width: 670px) {
                font-size: 16px;
              }
            }

            p {
              color: #484a4b;
              font-family: $fontR;
              font-size: 35px;
            }
          }

          .indication {
            h3 {
              color: #00a03b;
              font-family: $fontDemi;
              font-size: 20px;
              margin-bottom: 15px;
              font-weight: normal;
              @media screen and (max-width: 670px) {
                font-size: 16px;
              }
            }

            .indication-wrapper {
              display: flex;
              margin-bottom: 25px;
              max-width: 270px;
              min-width: 150px;
              width: 100%;

              @media screen and (max-width: 425px) {
                max-width: 425px;
              }

              h3 {
                font-size: 13px;
                font-family: $fontM;
                font-weight: normal;
                margin-bottom: 0;
              }

              p {
                color: #545454;
                font-family: $fontR;
                font-size: 13px;
              }

              img {
                display: block;
                margin-right: 25px;
                max-width: 45px;
                min-width: 45px;
                @media screen and (max-width: 670px) {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }

    h2 {
      color: #00a03b;
      font-family: $fontDemi;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 20px;
      @media screen and (max-width: 670px) {
        font-size: 16px;
      }
    }

    .method-of-administration {
      display: flex;
      margin-bottom: 20px;

      @media screen and (max-width: 425px) {
        flex-direction: column;
        margin: 0 -15px 15px -15px;
      }

      > div {
        max-width: 400px;
        width: 100%;
        padding: 30px;
        display: flex;
        flex-direction: row;
        height: max-content;

        @media screen and (max-width: 1024px) {
          max-width: 500px;
        }
        @media screen and (max-width: 670px) {
          padding: 20px 15px;
        }
        @media screen and (max-width: 425px) {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        img {
          width: 100%;
          height: auto;
          max-width: 50px;
          min-width: 50px;
          display: block;
          margin-right: 30px;
          @media screen and (max-width: 670px) {
            margin-right: 15px;
          }
        }

        h3 {
          margin-bottom: 15px;
          color: white;
          font-size: 20px;
          font-family: $fontDemi;
          font-weight: normal;
        }

        p {
          margin-bottom: 15px;
          color: white;
          font-size: 13px;
          font-family: $fontR;

          span {
            text-decoration: underline;
            display: block;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:nth-child(1) {
          margin-right: 15px;
          background: #00a03b;

          @media screen and (max-width: 670px) {
            margin-right: 5px;
          }

          @media screen and (max-width: 425px) {
            margin-right: 0;
          }
        }

        &:nth-child(2) {
          background: #317575;
          margin-left: 15px;
          @media screen and (max-width: 670px) {
            margin-left: 5px;
          }
          @media screen and (max-width: 425px) {
            margin-left: 0;
          }
        }
      }
    }

    a {
      display: flex;
      text-decoration: none;
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .video-btn {
        min-width: 86px;
        height: 26px;
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #005a85;
        @media screen and (max-width: 425px) {
          display: flex;
          margin-right: 15px;
        }

        p {
          color: white;
          font-size: 9px;
          font-family: $fontR;
        }
      }

      .download-pdf-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0 25px;
        margin: 0 auto;
        height: 53px;
        background: #aa198b;
        border-radius: 150px;
        @media screen and (max-width: 425px) {
          padding: 0 20px;
        }

        p {
          color: white;
          font-family: $fontR;
          font-size: 16px;
          line-height: 21px;

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }
        }

        svg {
          margin-right: 10px;
        }

        & > .included-block {
          & > p > span {
            background: transparent !important;
          }
        }
      }
    }
  }

  .button_concent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #317575;
    padding: 15px 43px;
    min-height: 155px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      padding: 30px 25px;
    }

    .text {
      max-width: 411px;
      width: 100%;
      margin-right: 15px;

      color: white;
      font-size: 17px;
      font-family: $fontR;

      @media screen and (max-width: 600px) {
        max-width: 600px;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .link-btn {
      padding: 11px 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      border-radius: 100px;

      @media screen and (max-width: 600px) {
        align-self: flex-start;
      }

      &:hover {
        .icon {
          transform: translateX(10px);
        }
      }

      p {
        font-size: 15px;
        font-family: $fontR;
        color: white;
      }

      .icon {
        transition: all 0.2s ease;
        margin-left: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
