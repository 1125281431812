<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Курс лікування інфекцій дихальних шляхів та ЛОР-органів лікарським засобом Сумамед® становить 3 дні.<sup>1</sup>
    </div>
    <div class="module-theory__large-text">
      <strong>Чому саме 3 дні</strong>, а не 5 чи 7?<br>
      Давайте розбиратися разом.
    </div>

    <div class="module-theory__text">
      Азитроміцин <span>здатен проникати і створювати високу концентрацію</span> у лейкоцитах.<sup>2</sup>
    </div>
    <div class="module-theory__text">
      Азитроміцин концентрується у лізосомах фагоцитів. 
      Потрапляючи всередину, молекула змінює свій електричний заряд, 
      через що вийти назовні вона вже не може.
    </div>
    <div class="module-theory__text">
      Таким чином, <strong>препарат концентрується саме в тому місці, де є патогени.<sup>3</sup></strong>
    </div>

    <div class="module-theory__image">
      <p class="module-theory__text">
        Переконайтеся самі
      </p>
      <img src="@/assets/gif/t_m5-theme2.gif" alt="" class="module-theory__img">
    </div>

    <div class="module-theory__next next-button" @click="nextState(2, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory1",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.5 - Т.2 - Раздел "Про Азитроміцин" - Э.1'`
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;

    sup {
      font-family: $fontDemi;
    }
  }

  &__large-text {
    font-size: 18px;
    line-height: 22px;
    color: #545454;
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 16px;

    span {
      color: #00A03B;
      font-weight: 600;
      font-family: $fontDemi;
    }
  }

  &__image {
    margin: 32px 0 64px;
    text-align: center;
  }

  &__img {
    max-width: 100%;
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__large-text {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__image {
      margin: 24px 0 32px;
      text-align: center;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
