var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-theory"},[_c('div',{staticClass:"module-theory__title"},[_vm._v(" Як, на Вашу думку, об’єм ін’єкційного препарату впливає на рівень стресу у клієнта? ")]),_c('p',{staticClass:"module-theory__text"},[_vm._v(" Перемістіть повзунок вгору, щоб обрати рівень стресу ")]),_c('div',{staticClass:"interactive-ampules"},[_c('div',{staticClass:"interactive-ampules__list"},[_c('div',{staticClass:"interactive-ampules__item"},[_c('div',{staticClass:"interactive-ampules__value"},[_vm._v("3 мл")]),_c('div',{staticClass:"interactive-ampules__elements"},[_vm._m(0),_c('div',{staticClass:"interactive-ampules__range"},[_c('veeno',{attrs:{"rtl":"","vertical":"","handles":1,"step":1,"pipsy":{
                mode: 'steps',
                density: 20
              },"range":{
                min: 1,
                max: 5
              }},model:{value:(_vm.ampule1),callback:function ($$v) {_vm.ampule1=$$v},expression:"ampule1"}})],1),_c('div',{staticClass:"interactive-ampules__image interactive-ampules__image--3ml"},[_c('img',{attrs:{"src":require(("@/assets/img/training-module3/theme-2/step-2/ampule-" + _vm.getAmpule1 + ".svg")),"alt":""}})])]),_c('div',{staticClass:"interactive-ampules__name"},[_vm._v("Стрес")])]),_c('div',{staticClass:"interactive-ampules__item"},[_c('div',{staticClass:"interactive-ampules__value"},[_vm._v("2 мл")]),_c('div',{staticClass:"interactive-ampules__elements"},[_vm._m(1),_c('div',{staticClass:"interactive-ampules__range"},[_c('veeno',{attrs:{"rtl":"","vertical":"","handles":1,"step":1,"pipsy":{
                mode: 'steps',
                density: 20
              },"range":{
                min: 1,
                max: 5
              }},model:{value:(_vm.ampule2),callback:function ($$v) {_vm.ampule2=$$v},expression:"ampule2"}})],1),_c('div',{staticClass:"interactive-ampules__image interactive-ampules__image--2ml"},[_c('img',{attrs:{"src":require(("@/assets/img/training-module3/theme-2/step-2/ampule-" + _vm.getAmpule2 + ".svg")),"alt":""}})])]),_c('div',{staticClass:"interactive-ampules__name"},[_vm._v("Стрес")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAmpuseSelected),expression:"isAmpuseSelected"}],staticClass:"interactive-ampules__message"},[_vm._v(" Ого, скільки стресу! "),_c('br'),_vm._v(" Проте ми знаємо рішення ")])]),_c('div',{staticClass:"module-theory__next next-button",class:{ disabled: !_vm.isAmpuseSelected },on:{"click":function($event){return _vm.nextState(3, 'next')}}},[_vm._v(" Далі ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interactive-ampules__pips"},[_c('div',{staticClass:"interactive-ampules__pips-item max"},[_vm._v("max")]),_c('div',{staticClass:"interactive-ampules__pips-item min"},[_vm._v("min")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interactive-ampules__pips"},[_c('div',{staticClass:"interactive-ampules__pips-item max"},[_vm._v("max")]),_c('div',{staticClass:"interactive-ampules__pips-item min"},[_vm._v("min")])])}]

export { render, staticRenderFns }