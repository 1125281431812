<template>
  <div class="module-theory">
    <template v-if="state === 1">
      <div class="module-theory__title">
        Олфен<sup>®</sup>-АФ: ацеклофенак з технологією модифікованого
        вивільнення<sup>*5,6</sup>
      </div>
      <div class="module-theory__text">
        Саме завдяки цій технологій, Олфен<sup>®</sup>-АФ діє до 24 годин!
      </div>
      <div class="module-item">
        <div class="module-item__row">
          <div class="module-item__col-left">
            <div class="module-item__image">
              <img
                src="~@/assets/img/training-module2/theme-2/step-2/1.svg"
                alt=""
              />
            </div>
          </div>
          <div class="module-item__col-right">
            <div class="module-item__detail">
              <div class="module-item__title">Як це працює?<sup>6,7</sup></div>
              <div class="module-item__text">
                • Верхній <b>шар негайного вивільнення</b> швидко розчиняється
                <br />
                • Внутрішній <b>шар модифікованого вивільнення</b> розчиняється
                повільно та підтримує концентрацію діючої речовини протягом 24
                годин
              </div>
              <div class="module-item__title">Які переваги?<sup>5,6</sup></div>
              <div class="module-item__text">
                • менша кількість піків концентрації <br />
                • вища прихильність до лікування <br />
                • зменшення потреби у додатковому знеболенні<sup>9</sup>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module-theory__subtitle">
        Переконайтеся самі! Порівняйте дію звичайної таблетки НПЗП<sup>**</sup>
        з Олфен<sup>®</sup>-АФ
      </div>
      <div class="module-theory__subtitle subtitle-black">
        Схематичний принцип роботи технології таблетки з модифікованим
        вивільненням в порівнянні з таблеткою, покритою плівковою оболонкою
      </div>
      <div class="module-theory__image">
        <img
          class="module-theory__image-graph"
          src="~@/assets/img/training-module2/theme-2/step-2/2.gif"
          alt=""
        />
        <img
          class="module-theory__image-info"
          src="~@/assets/img/training-module2/theme-2/step-2/2-1.svg"
          alt=""
        />
      </div>
      <div class="module-theory__next" @click="changeState()">
        Далі
      </div>
    </template>
    <template v-if="state === 2">
      <div class="module-theory__title">
        Олфен<sup>®</sup>-АФ: зручне застосування 1 раз на добу<sup>6</sup>
      </div>
      <div class="module-theory__text">
        Чи знали ви, що зі зменшенням частоти прийому збільшується прихильність
        до <br />
        призначеної терапії?<sup>8</sup>
      </div>
      <div class="module-graph">
        <div class="module-graph__row">
          <div class="module-graph__col-left">
            <div class="module-graph__image image-desktop">
              <img
                src="~@/assets/img/training-module2/theme-2/step-2/3.svg"
                alt=""
              />
            </div>
            <div class="module-graph__image image-mobile">
              <img
                src="~@/assets/img/training-module2/theme-2/step-2/3-mobile.svg"
                alt=""
              />
            </div>
          </div>
          <div class="module-graph__col-right">
            <div class="module-graph__detail">
              Готовність пацієнта виконувати рекомендації лікаря в залежності
              від частоти прийому препарату<sup>8</sup>
            </div>
          </div>
        </div>
      </div>
      <div class="module-product">
        <div class="module-product__row">
          <div class="module-product__col-left">
            <div class="module-product__image">
              <img
                src="~@/assets/img/training-module2/theme-2/step-2/4.png"
                alt=""
              />
            </div>
          </div>
          <div class="module-product__col-right">
            <div class="module-product__detail">
              <div class="module-product__text">
                Єдиний в Україні ацеклофенак 200мг, що діє 24 години та
                приймається 1 раз на добу.<sup>6</sup>
              </div>
              <div class="module-product__text">
                Однієї упаковки вистачить на 10 днів терапії.
              </div>
              <div class="module-product__text">
                А тепер час перевірити ваші знання за підсумками цієї теми.
              </div>
              <div class="module-product__text text-black">
                Натискайте на кнопку “Перейти до тестування”, щоб пройти
                фінальний тест
              </div>
              <div class="module-product__next" @click="nextState(3, 'next')">
                Перейти до тестування
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory2",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до тестування"',
        event_label: 'М.2 - Т.2 - Раздел "Переваги Олфен-АФ" - Э.2'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    changeState() {
      this.state += 1;
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.2 - Раздел "Переваги Олфен-АФ" - Э.1'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__text {
    margin-bottom: 48px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__subtitle {
    margin-bottom: 54px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
    &.subtitle-black {
      margin-bottom: 24px;
      color: #545454;
    }
  }
  &__image {
    margin-bottom: 64px;
    &-graph {
      margin-bottom: 24px;
    }
    &-info {
      margin: auto;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}
.module-item {
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 56%;
      max-width: 56%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 44%;
      max-width: 44%;
      padding: 0 10px;
    }
  }
  &__image {
    margin-left: -21px;
    img {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
    }
  }
  &__detail {
    max-width: 310px;
  }
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__text {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.module-graph {
  margin-bottom: 50px;
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 56%;
      max-width: 56%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 44%;
      max-width: 44%;
      padding: 0 10px;
    }
  }
  &__image {
    &.image-mobile {
      display: none;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__detail {
    max-width: 310px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
  }
}
.module-product {
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 45%;
      max-width: 45%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 55%;
      max-width: 55%;
      padding: 0 10px;
    }
  }
  &__image {
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__text {
    margin-bottom: 20px;
    font-family: $fontDemi;
    font-size: 18px;
    line-height: 22px;
    color: #00a03b;
    &.text-black {
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
    sup {
      font-family: $fontDemi;
    }
  }
  &__next {
    width: 375px;
    height: 64px;
    padding: 0 40px;
    margin: 23px 0 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 105% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
      br {
        display: none;
      }
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      margin-bottom: 20px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      &--green {
        margin-bottom: 12px;
        font-family: $fontDemi;
      }
      br {
        display: none;
      }
      b {
        font-family: $fontDemi;
      }
    }
    &__image {
      margin-bottom: 24px;
    }
    &__next {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
  .module-item {
    margin-bottom: 32px;
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__image {
      margin-bottom: 32px;
    }
    &__detail {
      max-width: 100%;
    }
    &__title {
      margin-bottom: 6px;
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      b {
        font-family: $fontDemi;
      }
    }
  }
  .module-graph {
    margin-bottom: 24px;
    &__row {
      flex-wrap: wrap-reverse;
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__image {
      &.image-mobile {
        display: block;
      }
      &.image-desktop {
        display: none;
      }
      img {
        width: 100%;
        margin: 0 -7px;
      }
    }
    &__detail {
      max-width: 100%;
      margin-bottom: 12px;
    }
  }
  .module-product {
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__image {
      margin-bottom: 12px;
      img {
        width: 100%;
      }
    }
    &__text {
      &:nth-child(1) {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 17px;
      }
      &:nth-child(2) {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 20px;
      }
      &:nth-child(3) {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
      &:nth-child(4) {
        margin-bottom: 24px;
        font-family: $fontR;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
    }
    &__next {
      width: 280px;
      height: 44px;
      margin: auto;
      padding: 0 30px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
