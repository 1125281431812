import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/home-page.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/:spec/category/:category",
    name: "list",
    component: () => import("../views/article-list-page.vue"),
  },
  {
    path: "/:spec/category/:category/article/:id",
    name: "article",
    component: () => import("../views/article-page.vue"),
  },

  {
    path: "/article/:specSlug/category/:category",
    name: "list-old",
    component: () => import("../views/article-list-page.vue"),
  },
  {
    path: "/article/:specSlug/:id",
    name: "article-old",
    component: () => import("../views/article-page.vue"),
  },

  {
    path: "/:category/remains",
    name: "remains",
    component: () => import("../views/remains.vue"),
  },

  // 404 page
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
