<template>
  <div class="game">
    <h3 class="game__title">При виборі вітамінів групи В для лікування болю у спині потрібно бути дуже уважним та знати усі переваги препарату. </h3>
    <div class="game__description">Давайте перевіримо, чи зможете ви відгадати <span>переваги Нейрорубін<sup>™</sup> - Форте Лактаб!</span></div>
    <div class="game__dots">
      <div
        v-for="(item, key) in questions"
        :key="key"
        class="game__dots-item"
        :class="{
          current: question === key + 1,
          correct: item.isHasCorrectAnswer === true,
          incorrect: item.isHasCorrectAnswer === false
        }"
      >
        <div class="game__dots-item-inner"></div>
      </div>
    </div>
    <div class="game__count">
        <span>
          {{ question >= 3 ? "0" : "0" }}{{ question }}
        </span>
        / {{ question >= 3 ? "0" : "0" }}{{ questions.length }}
    </div>
    <div class="questions">
      <div class="questions__title" v-html="currentQuestion.title"></div>
      <template v-if="!currentQuestion.isHasAnswer">
        <div class="questions__answers">
          <div
            class="questions__answers-item"
            v-for="(answer, key) in currentQuestion.answers"
            :key="key"
            @click="selectAnswer(currentQuestion, answer)"
          >
            {{ answer.title }}
          </div>
        </div>
        <div
          class="questions__next next-button disabled"
        >
          Далі
        </div>
      </template>

      <template v-else>
        <div class="questions__answers">
          <button
            class="questions__answers-item"
            v-for="(answer, key) in currentQuestion.answers"
            :disabled="selectedAnswer.id !== answer.id"
            :key="key"
            @click="selectAnswer(currentQuestion, answer)"
            :class="{
              correct: answer.isCorrect === true,
              inCorrect: answer.isCorrect === false,
              select: answer.isSelected === true,
              disablade: answer.isSelected === false
            }"
          >
            {{ answer.title }}
          </button>
        </div>
        <div class="questions__bottom">
          <div class="questions__message" v-html="currentQuestion.message.text">
          </div>
          <div
            class="questions__to-test next-button"
            v-show="currentQuestion.isHasAnswer && question === questions.length"
            @click="nextState(4, 'next')"
          >
            Далі
          </div>
          <div
            class="questions__next next-button"
            v-show="currentQuestion.isHasAnswer && question !== questions.length"
            :class="{disablade: !currentQuestion.isHasAnswer}"
            @click="nextQuestion()"
          >
            Далі
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "Game",
  data() {
    return {
      question: 1,
      selectedAnswer: {},
      questions: [
        {
          id: 1,
          isHasAnswer: false,
          isHasCorrectAnswer: null,
          title: "Що спільного у Нейрорубін<sup>™</sup>- Форте Лактаб з <span style='color: #CB2E23; font-weight: 700; font-style: italic;'>НПЗП?​</span>",
          answers: [
            {
              id: 1,
              isSelected: false,
              isCorrect: true,
              title: "Проявляє знеболюючий ефект"
            },
            {
              id: 2,
              isSelected: false,
              isCorrect: false,
              title: "Відпускається тільки за рецептом"
            },
            {
              id: 3,
              isSelected: false,
              isCorrect: false,
              title: "Має солодкий смак​"
            }
          ],
          message: {
            text: "За рахунок високих доз вітамінів групи В, Нейрорубін™-Форте Лактаб здатен проявляти знеболюючий ефект.<sup>3</sup>"
          }
        },
        {
          id: 2,
          isHasAnswer: false,
          isHasCorrectAnswer: null,
          title: "Що спільного у Нейрорубін™-Форте Лактаб з <span style='color: #CB2E23; font-weight: 700; font-style: italic;'>точними годинниками, надійними банками та відмінним шоколадом?</span>",
          answers: [
            {
              id: 1,
              isSelected: false,
              isCorrect: false,
              title: "Підвищує настрій"
            },
            {
              id: 2,
              isSelected: false,
              isCorrect: false,
              title: "Може рекомендувати тільки лікар"
            },
            {
              id: 3,
              isSelected: false,
              isCorrect: true,
              title: "Родом з Швейцарії"
            }
          ],
          message: {
            text: "Швейцарське виробництво Нейрорубін™-Форте Лактаб забезпечує високу якість препарату. <sup>3</sup>"
          }
        },
        {
          id: 3,
          isHasAnswer: false,
          isHasCorrectAnswer: null,
          title: "Що спільного у Нейрорубін™-Форте Лактаб з <span style='color: #CB2E23; font-weight: 700; font-style: italic;'>подушками?</span>",
          answers: [
            {
              id: 1,
              isSelected: false,
              isCorrect: false,
              title: "Випукла форма таблеток"
            },
            {
              id: 2,
              isSelected: false,
              isCorrect: false,
              title: "Більш ефективний у нічні години"
            },
            {
              id: 3,
              isSelected: false,
              isCorrect: true,
              title: "Комфорт у використанні"
            }
          ],
          message: {
            text: "Комфортне використання Нейрорубін™-Форте Лактаб – всього 1-2 таблетки на добу.<sup>3</sup>"
          }
        }
      ]
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.question - 1];
    }
  },
  methods: {
    selectAnswer(currentQuestion, answer) {
      currentQuestion.isHasAnswer = true;
      answer.isSelected = true;
      if (answer.isCorrect) {
        currentQuestion.isHasCorrectAnswer = true;
        this.selectedAnswer = answer;
      } else {
        currentQuestion.isHasCorrectAnswer = false;
      }
    },
    nextQuestion() {
      this.question += 1;
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", { event_category: "sec_3_go_test" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$fontNB: "NotoSans-Bold";
$fontNSB: "NotoSans-SemiBold";
$fontNR: "NotoSans-Regular";
$fontNL: "NotoSans-Light";

.game {
  &__title {
    font-size: 32px;
    line-height: 44px;
    color: #00A03B;
    font-family: $fontNSB;
    margin-bottom: 16px;
  }
  &__description {
    font-size: 16px;
    line-height: 22px;
    font-family: $fontNR;
    color: #545454;
    span {
      font-family: $fontNSB;
      color: #00A03B;
    }
  }
  &__count {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #317575;
    span {
      color: #7fc25d;
    }
  }
  &__dots {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    padding-top: 16px;
    &-item {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 17px;
      background: #e4eaed;
      &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: -13px;
        width: 8px;
        height: 1px;
        background: #e4eaed;
      }
      &.correct {
        background: #00a03b;
      }
      &.incorrect {
        background: #fb3449;
      }
      &.current {
        background: #fff;
        border: 1px solid #005a85;
        .game {
          &__dots {
            &-item {
              &-inner {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                z-index: 1;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background: #005a85;
              }
            }
          }
        }
      }

      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
  .questions {
    &__title {
      font-size: 22px;
      line-height: 30px;
      font-family: $fontNSB;
      text-align: center;
      color: #545454;
      & span {
        font-family: $fontNSB;
        color: #CB2E23;
      }
      & sup {
        font-family: $fontNSB;
      }
    }
    &__answers {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 48px;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(49, 117, 117, 0.2);
        border-radius: 6px;
        border-color: transparent;
        outline: none;
        padding: 18px 10px;
        color: #545454;
        font-family: $fontNSB;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        width: 32%;
        &:hover {
          cursor: pointer;
        }
        &.correct::before {
          content: '';
          width: 20px;
          height: 100%;
          min-height: 22px;
          max-height: 22px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("~@/assets/img/training-module1/theme-3/correct.svg");
          margin-bottom: 16px;
        }
        &.inCorrect::before {
          content: '';
          width: 22px;
          height: 100%;
          min-height: 22px;
          max-height: 22px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("~@/assets/img/training-module1/theme-3/wrong.svg");
          margin-bottom: 16px;
        }
        &.select {
          background-color: #E4EAED;
          color: #317575;
        }
        &.disabled {
          cursor: default;
        }
      }
    }
    &__bottom {
      background-color: rgba(0, 160, 59, 0.6);
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding-top: 24px;
      padding-bottom: 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    &__message {
      color: #fff;
      font-family: $fontNSB;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      max-width: 450px;
      margin-bottom: 32px;
    }
    .next-button {
      width: 262px;
      height: 64px;
      padding: 0 83px;
      margin: auto;
      background-color: #aa198b;
      background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
      border-radius: 90px;
      font-family: $fontNR;
      font-size: 22px;
      text-indent: -50px;
      text-align: center;
      line-height: 64px;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s;
      &.disabled {
        opacity: 0.16;
        cursor: default;
      }
      &:hover {
        background-position: 110% center;
      }
    }
  }
}
@media (max-width: 768px) {
  .game {
    &__title {
      font-size: 18px;
      line-height: 25px;
    }
    &__description {
      font-size: 14px;
      line-height: 120%;
      font-family: $fontNR;
      span {
        color: #545454;
        font-family: $fontNR;
      }
    }
    .questions {
      &__title {
        font-size: 16px;
        line-height: 22px;
      }
      &__message {
        margin-bottom: 12px;
        font-family: $fontNSB;
      }
      &__bottom {
        padding-bottom: 26px;
        padding-left: 24px;
        padding-right: 24px;
      }
      &__answers {
        margin-bottom: 32px;
        flex-direction: column;
        &-item {
          width: 100%;
          margin-bottom: 12px;
          font-size: 14px;
          flex-direction: row;
          justify-content: flex-start;
          &.correct::before {
            width: 14px;
            min-height: 14px;
            max-height: 14px;
            margin-bottom: 0;
            margin-right: 17px;
          }
          &.inCorrect::before {
            width: 14px;
            min-height: 14px;
            max-height: 14px;
            margin-bottom: 0;
            margin-right: 17px;
          }
        }
      }
      .next-button {
        width: 266px;
        height: 44px;
        margin: 0 auto 0;
        padding: 0 95px;
        background-size: 24px;
        font-size: 16px;
        line-height: 43px;
        text-indent: -40px;
      }
    }
    
  }
}
</style>
