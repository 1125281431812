<template>
  <div>
    <div class="accordions">
      <template v-for="(item, key, i) in list">
        <div
            v-if="item.full_instuction"
            :key="item.nrp"
            class="accordion"
            :class="{ active: activeId === item.nrp }"
        >
          <div
              :id="`accordion__header_n${i}`"
              class="accordion__header"
              @click="toggleAccordeon(item.nrp, `accordion__header_n${i}`)"
          >
            <div class="accordion__icon">
              <svg
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.77803 2.21416H5.55581V5.53542H2.22247V7.74959H5.56386C6.78671 7.74959 7.77803 6.75827 7.77803 5.53542V2.21416Z"
                    fill="#00A03B"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 4.83109V23.2488C0 24.4716 0.991318 25.463 2.21417 25.463H17.7858C19.0087 25.463 20 24.4716 20 23.2488V2.21417C20 0.991318 19.0087 0 17.7858 0H4.32078L4.36011 0.0439692L0.0389066 4.87459L0 4.83109ZM2.22245 6.21016L5.55578 2.48387H17.778V22.9785H2.22245V6.21016Z"
                    fill="#00A03B"
                />
                <rect
                    x="5"
                    y="10.5173"
                    width="10"
                    height="2.21417"
                    rx="1.10709"
                    fill="#00A03B"
                />
                <rect
                    x="5"
                    y="15.4992"
                    width="10"
                    height="2.21417"
                    rx="1.10709"
                    fill="#00A03B"
                />
              </svg>
            </div>

            <div class="accordion__label">
              Повна інструкція {{ content.name }}
              {{ item.pharmaceutical_form }}
              {{ item.nrp }}
              {{ (item.dosage || "").trim() ? `- ${item.dosage}` : null }}
            </div>

            <div class="accordion__btn">
              <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M10.646 15.1818L5.7564 9.04546L15.5357 9.04546L10.646 15.1818Z"
                    fill="#00A03B"
                />
              </svg>
            </div>
          </div>

          <div class="accordion__content">
            <div
                class="accordion__content--wrap"
                v-html="item.full_instuction"
            ></div>

            <button
                @click="toggleAccordeon(null, `accordion__header_n${i}`)"
                class="accordion__btn"
                style="margin-left: auto; padding: 0px; border: none"
            >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                    d="M10.646 15.1818L5.7564 9.04546L15.5357 9.04546L10.646 15.1818Z"
                    fill="#00A03B"
                />
              </svg>
            </button>
          </div>
        </div>
      </template>
    </div>

    <div class="section-links" v-html="content.legalText"></div>
  </div>
</template>

<script>
export default {
  name: "section-links",
  props: {
    content: Object,
  },

  data() {
    return {
      activeId: null,
    };
  },

  computed: {
    list() {
      const data = {
        ...this.content,
        // instructions: {
        //   key: {
        //     pharmaceutical_form: "Капсули",
        //     nrp: "UA/2396/03/01",
        //     full_istruction: null,
        //     short_istruction: null,
        //   },
        //   key2: {
        //     pharmaceutical_form: "Капсули",
        //     nrp: "UA/2396/03/02",
        //     full_istruction: null,
        //     short_istruction: null,
        //   },
        // },
      };

      const list = data.instructions || {};
      return list;

      // Object.keys(list).reduce(() => {}, []);
    },
  },

  methods: {
    toggleAccordeon(value, scrollId) {
      const scrollOnHeaderClick = this.activeId !== value;

      this.activeId = this.activeId === value ? null : value;

      if (scrollId && scrollOnHeaderClick) {
        this.$nextTick(() => {
          const element = document.getElementById(scrollId);

          if (element) {
            const top =
                element.getBoundingClientRect().top + window.pageYOffset - 100;
            window.scrollTo({top});
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;

.section-links {
  margin: 10px 0;
  padding: 30px 45px;
  background: #f5f5f5;

  * {
    font-family: $fontR;
    word-break: break-word;
    font-size: 12px;
  }

  ul {
    padding-left: 15px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 650px) {
    margin: 5px 0;
  }

  @media screen and (max-width: 425px) {
    padding: 20px 15px;
    margin: 15px -15px;
  }

  p {
    color: #484a4b;
    font-family: $fontR;
    font-size: 12px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    b,
    strong {
      font-family: $fontB;
      font-weight: normal;
    }

    a {
      color: #484a4b;
      text-decoration: underline;
    }
  }
}

.accordion {
  margin-bottom: 10px;

  &.active {
    .accordion__content {
      max-height: max-content;
    }

    .accordion__btn {
      transform: rotate(180deg);
    }
  }

  &__header {
    display: flex;
    align-items: center;

    padding: 18px 20px 18px 55px;
    background-color: #eeeeee;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-items: center;

    margin-right: 20px;
  }

  &__label {
    margin-right: auto;

    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #00a03b;
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  &__content {
    background-color: #f9f9f9;
    max-height: 0;
    overflow: hidden;

    color: #484a4b;
    font-family: "Commissioner-Regular";
    font-size: 12px;

    &--wrap {
      padding: 30px 40px 30px 50px;
    }
  }
}
</style>
