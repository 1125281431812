import Vue from "vue";
import { get } from "lodash";

import { $axios, hostUrl, isDev } from "../plugins/axios";

const LOGS = false && isDev;

async function getRequest(url, params) {
  let result = [];
  try {
    const response = await $axios.get(url, { params });
    result = response.data;
  } catch (e) {
    console.log(e);
  }
  if (LOGS) console.log(url, result);
  return result;
}

const api = {
  dev: isDev,
  host: hostUrl,

  fetchSpecialties: async function () {
    return await getRequest("/specialties");
  },

  fetchCategories: async function (spec) {
    const res = await getRequest("/pages", { spec, page: 1, count: 1 });
    return get(res, "filters.categories", {});
  },

  fetchArticle: async function (id, params) {
    return await getRequest(`/pages/${ id }`, params);
  },

  fetchPages: async function (params) {
    return await getRequest("/pages", params);
  },

  fetchCount: async function (params) {
    const res = await getRequest("/count", params);
    return get(res, "count", 0);
  },

  fetchFilters: async function (params) {
    return await getRequest("/filters", params);
  },
};

Vue.prototype.$api = api;

export default api;
