<template>
  <div class="game" :class="{ disabled: isDisabledGame }">
    <div class="game__containers" :class="{ disabled: isOnboardingSelect }">
      <div
        class="game__shelf"
        :class="{ 'onboarding-active': isOnboardingPaste }"
        @click="pastePack('shelf')"
      >
        <img
          :src="
            require(`@/assets/img/training-module4/game/${getShelfContainerImage}`)
          "
          alt=""
        />
      </div>
      <div
        class="game__safe"
        :class="{ 'onboarding-active': isOnboardingPaste }"
        @click="pastePack('safe')"
      >
        <img
          :src="
            require(`@/assets/img/training-module4/game/${getSafeContainerImage}`)
          "
          alt=""
        />
      </div>
      <div
        class="game__fridge"
        :class="{ 'onboarding-active': isOnboardingPaste }"
        @click="pastePack('fridge')"
      >
        <img
          :src="
            require(`@/assets/img/training-module4/game/${getFridgeContainerImage}`)
          "
          alt=""
        />
      </div>
    </div>
    <div class="game__onboarding onboarding-select" v-show="isOnboardingSelect">
      <p>
        Пропонуємо перевірити свої знання та розподілити препарати по місяцях їх
        зберігання<sup>9</sup>
      </p>
      <p>
        Натисніть на упаковку будь-якого препарату
      </p>
    </div>
    <div class="game__onboarding onboarding-paste" v-show="isOnboardingPaste">
      <p>
        Натисніть на полицю, сейф або холодильник, щоб розмістити упаковку у
        відповідному місці для зберігання
      </p>
    </div>
    <div class="game__messages">
      <div class="game__messages-item success" v-show="isSuccessMessage">
        Правильно!
        <div class="game__messages-close" @click="closeMessage('success')">
          &times;
        </div>
      </div>
      <div class="game__messages-item error" v-show="isErrorMessage">
        Неправильно! Спробуйте ще
        <div class="game__messages-close" @click="closeMessage('error')">
          &times;
        </div>
      </div>
    </div>
    <div class="game__packs">
      <div
        class="game__packs-item"
        v-for="(pack, key) in packs"
        :key="key"
        v-show="pack.isVisible"
        :class="{ selected: pack.isSelected, error: pack.isNotCorrect }"
        @click="seleсtPack(key)"
      >
        <img
          :src="require(`@/assets/img/training-module4/game/${pack.img}`)"
          alt=""
        />
      </div>
    </div>
    <div class="game__modal" v-show="isGameOver">
      <div class="game__modal-main">
        <div class="game__modal-title">
          Вітаємо! Ви правильно впорались із завданням.
        </div>
        <div class="game__modal-text">
          Ви успішно крокуєте далі. Упевнені, що Ви з легкістю вирішите ще одну
          задачу.
        </div>
        <div class="game__modal-next" @click="nextState(5, 'next')">Далі</div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Game",
  data() {
    return {
      containers: {
        shelf: {
          isOpened: null,
          packs: []
        },
        safe: {
          isOpened: false,
          packs: []
        },
        fridge: {
          isOpened: false,
          packs: []
        }
      },
      packs: [
        {
          id: 1,
          title: "Нувіджил®",
          img: "pack-1.svg",
          container: "shelf",
          isSelected: false,
          isNotCorrect: false,
          isVisible: true
        },
        {
          id: 2,
          title: "Морфін",
          img: "pack-2.svg",
          container: "safe",
          isSelected: false,
          isNotCorrect: false,
          isVisible: true
        },
        {
          id: 3,
          title: "Атропін",
          img: "pack-3.svg",
          container: "safe",
          isSelected: false,
          isNotCorrect: false,
          isVisible: true
        },
        {
          id: 4,
          title: "Олфен-АФ",
          img: "pack-4.svg",
          container: "shelf",
          isSelected: false,
          isNotCorrect: false,
          isVisible: true
        },
        {
          id: 5,
          title: "Хумулін НПХ",
          img: "pack-5.svg",
          container: "fridge",
          isSelected: false,
          isNotCorrect: false,
          isVisible: true
        },
        {
          id: 6,
          title: "Йогурт",
          img: "pack-6.svg",
          container: "fridge",
          isSelected: false,
          isVisible: true
        }
      ],
      isOnboardingSelect: true,
      isOnboardingPaste: false,
      isSuccessMessage: false,
      successMessageTimeout: null,
      isErrorMessage: false,
      errorMessageTimeout: null,
      isDisabledGame: false,
      isGameOver: false
    };
  },
  computed: {
    getShelfContainerImage() {
      var currentContainerImage = null;
      if (this.containers.shelf.packs.length === 0) {
        currentContainerImage = "shelf.svg";
      }
      if (
        this.containers.shelf.packs.length === 1 &&
        this.containers.shelf.packs[0] === 1
      ) {
        currentContainerImage = "shelf-pack-1.svg";
      }
      if (
        this.containers.shelf.packs.length === 1 &&
        this.containers.shelf.packs[0] === 4
      ) {
        currentContainerImage = "shelf-pack-4.svg";
      }
      if (this.containers.shelf.packs.length === 2) {
        currentContainerImage = "shelf-complete.svg";
      }
      return currentContainerImage;
    },
    getSafeContainerImage() {
      var currentContainerImage = null;
      if (this.containers.safe.packs.length === 0) {
        currentContainerImage = "safe.svg";
      }
      if (this.containers.safe.isOpened) {
        currentContainerImage = "safe-opened.svg";
      }
      if (
        this.containers.safe.packs.length === 1 &&
        this.containers.safe.packs[0] === 2
      ) {
        currentContainerImage = "safe-pack-2.svg";
      }
      if (
        this.containers.safe.packs.length === 1 &&
        this.containers.safe.packs[0] === 3
      ) {
        currentContainerImage = "safe-pack-3.svg";
      }
      if (this.containers.safe.packs.length === 2) {
        currentContainerImage = "safe-complete.svg";
      }
      return currentContainerImage;
    },
    getFridgeContainerImage() {
      var currentContainerImage = null;
      if (this.containers.fridge.packs.length === 0) {
        currentContainerImage = "fridge.svg";
      }
      if (this.containers.fridge.isOpened) {
        currentContainerImage = "fridge-opened.svg";
      }
      if (
        this.containers.fridge.packs.length === 1 &&
        this.containers.fridge.packs[0] === 5
      ) {
        currentContainerImage = "fridge-pack-5.svg";
      }
      if (
        this.containers.fridge.packs.length === 1 &&
        this.containers.fridge.packs[0] === 6
      ) {
        currentContainerImage = "fridge-pack-6.svg";
      }
      if (this.containers.fridge.packs.length === 2) {
        currentContainerImage = "fridge-complete.svg";
      }
      return currentContainerImage;
    }
  },
  methods: {
    seleсtPack(selectedPackKey) {
      var moduleData = JSON.parse(localStorage.getItem("module4"));
      if (moduleData.game.onboardSelect === null) {
        this.isOnboardingSelect = false;
        this.isOnboardingPaste = true;
        gtag("event", "onLoad", {
          event_category: "Экран №2",
          event_label: 'М.4 - Раздел "Інтерактивна гра" - OnBoarding'
        });
      }
      this.packs.forEach((pack, packKey) => {
        if (packKey === selectedPackKey) {
          pack.isSelected = true;
        } else {
          pack.isSelected = false;
        }
      });
    },
    pastePack(packContainer) {
      var moduleData = JSON.parse(localStorage.getItem("module4"));

      this.isOnboardingPaste = false;
      moduleData.game.onboardSelect = false;
      moduleData.game.onboardPaste = false;
      localStorage.setItem("module4", JSON.stringify(moduleData));
      if (
        packContainer !== "shelf" &&
        !this.containers[packContainer].isOpened
      ) {
        this.containers[packContainer].isOpened = true;
      }
      this.packs.forEach(pack => {
        if (pack.isSelected) {
          if (pack.container === packContainer) {
            if (packContainer === "shelf") {
              gtag("event", "click", {
                event_category: `'Выбор "${pack.title}"'`,
                event_label: 'М.4  - Раздел "Інтерактивний квест" - Полиця'
              });
            }
            if (packContainer === "safe") {
              gtag("event", "click", {
                event_category: `'Выбор "${pack.title}"'`,
                event_label: 'М.4  - Раздел "Інтерактивний квест" - Сейф'
              });
            }
            if (packContainer === "fridge") {
              gtag("event", "click", {
                event_category: `'Выбор "${pack.title}"'`,
                event_label: 'М.4  - Раздел "Інтерактивний квест" - Холодильник'
              });
            }
            this.containers[packContainer].packs.push(pack.id);
            pack.isVisible = false;
            pack.isSelected = false;
            this.isSuccessMessage = true;
            this.isDisabledGame = true;
            if (this.isSuccessMessage) {
              this.successMessageTimeout = setTimeout(() => {
                this.isSuccessMessage = false;
                this.isDisabledGame = false;
                if (
                  this.containers.shelf.packs.length === 2 &&
                  this.containers.safe.packs.length === 2 &&
                  this.containers.fridge.packs.length === 2
                ) {
                  this.isGameOver = true;
                }
              }, 2000);
            }
          } else {
            pack.isNotCorrect = true;
            pack.isSelected = false;
            this.isErrorMessage = true;
            this.isDisabledGame = true;
            if (this.isErrorMessage) {
              this.errorMessageTimeout = setTimeout(() => {
                this.isErrorMessage = false;
                this.isDisabledGame = false;
                pack.isNotCorrect = false;
              }, 2000);
            }
          }
        }
      });
    },
    closeMessage(message) {
      if (message === "success") {
        this.isSuccessMessage = false;
        clearTimeout(this.successMessageTimeout);
        if (
          this.containers.shelf.packs.length === 2 &&
          this.containers.safe.packs.length === 2 &&
          this.containers.fridge.packs.length === 2
        ) {
          this.isGameOver = true;
        }
      }
      if (message === "error") {
        this.isErrorMessage = false;
        clearTimeout(this.errorMessageTimeout);
      }
      this.isDisabledGame = false;
      this.packs.forEach(pack => {
        pack.isNotCorrect = false;
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.4  - Раздел "Інтерактивний квест"'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  },
  beforeMount() {
    var moduleData = JSON.parse(localStorage.getItem("module4"));
    if (moduleData.game.onboardSelect !== null) {
      this.isOnboardingSelect = false;
    } else {
      gtag("event", "onLoad", {
        event_category: "Экран №1",
        event_label: 'М.4 - Раздел "Інтерактивна гра" - OnBoarding'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.game {
  position: relative;
  height: 475px;
  background: url("~@/assets/img/training-module4/game/bg.svg") no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  &.disabled {
    pointer-events: none;
  }
  &__containers {
    position: absolute;
    width: 577px;
    top: 12px;
    left: 140px;
    right: 109px;
    bottom: 50px;
    &.disabled {
      pointer-events: none;
    }
  }
  &__shelf {
    position: absolute;
    top: 42px;
    left: 106px;
    cursor: pointer;
    z-index: 1;
    &.onboarding-active {
      &:before {
        visibility: visible;
        opacity: 1;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -24px;
      left: -20px;
      right: -20px;
      bottom: -15px;
      background: url("~@/assets/img/training-module4/game/shelf-highlight.svg")
        no-repeat;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }
  &__safe {
    position: absolute;
    bottom: -4px;
    left: 0px;
    cursor: pointer;
    z-index: 1;
    &.onboarding-active {
      &:before {
        visibility: visible;
        opacity: 1;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -20px;
      left: -22px;
      right: -20px;
      bottom: -15px;
      background: url("~@/assets/img/training-module4/game/safe-highlight.svg")
        no-repeat;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }
  &__fridge {
    position: absolute;
    top: 68px;
    right: 0px;
    cursor: pointer;
    z-index: 1;
    &.onboarding-active {
      &:before {
        visibility: visible;
        opacity: 1;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -20px;
      left: -22px;
      right: -20px;
      bottom: -15px;
      background: url("~@/assets/img/training-module4/game/fridge-highlight.svg")
        no-repeat;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }
  &__onboarding {
    position: absolute;
    z-index: 2;
    p {
      margin-bottom: 15px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.onboarding-select {
      width: 100%;
      max-width: 325px;
      bottom: 124px;
      right: 195px;
      padding: 16px 18px 31px 16px;
      background: url("~@/assets/img/training-module4/game/onboarding-select-bg.svg")
        no-repeat;
      background-size: cover;
    }
    &.onboarding-paste {
      width: 100%;
      max-width: 301px;
      bottom: 142px;
      right: 138px;
      padding: 36px 16px 16px;
      background: url("~@/assets/img/training-module4/game/onboarding-paste-bg.svg")
        no-repeat;
      background-size: cover;
    }
  }
  &__messages {
    position: absolute;
    right: 32px;
    bottom: 118px;
    width: 100%;
    max-width: 330px;
    pointer-events: all;
    z-index: 2;
    &-item {
      width: 100%;
      padding: 12px 16px;
      backdrop-filter: blur(4px);
      border-radius: 8px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      &.success {
        background: rgba(0, 160, 59, 0.8);
        box-shadow: 0px 20px 40px rgba(0, 160, 59, 0.4);
      }
      &.error {
        background: rgba(251, 52, 73, 0.8);
        box-shadow: 0px 20px 40px rgba(251, 52, 73, 0.2);
      }
    }
    &-close {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      line-height: 12px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  &__packs {
    position: absolute;
    width: 488px;
    left: 307px;
    bottom: 32px;
    display: flex;
    justify-content: flex-end;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: auto;
    z-index: 2;
    &::-webkit-scrollbar {
      display: none;
    }
    &-item {
      flex-basis: 78px;
      max-width: 78px;
      min-width: 78px;
      height: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      background: linear-gradient(
        168.53deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.6) 97.46%
      );
      backdrop-filter: blur(12px);
      border-radius: 12px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.selected {
        background: #7fc25d;
      }
      &.error {
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(251, 52, 73, 0.1) 50.45%,
            rgba(251, 52, 73, 0.5) 100%
          ),
          linear-gradient(
            168.53deg,
            rgba(215, 227, 119, 0.2) 0%,
            rgba(215, 227, 119, 0.6) 97.46%
          );
      }
    }
  }
  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba($color: #6441a5, $alpha: 0.3) 0%,
      rgba($color: #2a0845, $alpha: 0.3) 100%
    );
    border-radius: 2px;
    z-index: 3;
    &-main {
      position: absolute;
      width: 100%;
      max-width: 330px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 24px 9px;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          #ffffff 15.17%,
          rgba(255, 255, 255, 0.1) 90%
        )
        1;
      background: rgba(0, 160, 59, 0.5);
      box-shadow: 0px 20px 40px rgba(0, 160, 59, 0.4);
      backdrop-filter: blur(12px);
      border-radius: 4px;
      color: #fff;
    }
    &-title {
      margin-bottom: 12px;
      font-family: $fontDemi;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.01em;
    }
    &-text {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
    &-next {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      margin: auto;
      background-color: #aa198b;
      background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
      border-radius: 90px;
      font-family: $fontDemi;
      font-size: 16px;
      text-indent: -40px;
      text-align: center;
      line-height: 44px;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-position: 110% center;
      }
    }
  }
}

@media screen and (max-width: 1129px) {
  .game {
    height: 565px;
    margin: -29px -15px 0;
    background-position: 58% 0px;

    &__containers {
      max-width: 470px;
      min-width: 360px;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
    }
    &__shelf {
      top: 10px;
      left: 20px;
    }
    &__safe {
      bottom: 45px;
      left: 32px;
    }
    &__fridge {
      top: 40px;
      right: 15px;
    }
    &__onboarding {
      &.onboarding-select {
        bottom: 98px;
        right: 20px;
      }
      &.onboarding-paste {
        left: 60%;
        bottom: 255px;
        transform: translateX(-57%);
      }
    }
    &__messages {
      right: 15px;
    }
    &__packs {
      width: 345px;
      justify-content: flex-start;
      left: unset;
      right: 0;
      bottom: 15px;
    }
    &__modal {
      &-main {
        top: unset;
        bottom: 0;
        transform: translate(-50%, -15px);
      }
    }
  }
}
@media screen and (max-width: 359px) {
  .game {
    &__onboarding {
      &.onboarding-select {
        right: 15px;
        max-width: 300px;
        background-size: contain;
      }
      &.onboarding-paste {
        max-width: 281px;
        padding: 27px 16px 16px;
        background-size: contain;
      }
    }
    &__messages {
      max-width: 290px;
    }
    &__packs {
      width: 255px;
    }
  }
}
</style>
