<template>
  <div id="threeContainer" ref="threeRootElement"></div>
</template>

<script>
import threeEntryPoint from "@/components/training-module5/theme-1/threeEntryPoint.js";
export default {
  name: "Model",
  props: {
    rotate: Boolean,
  },
  data() {
    return {
      threeEntryPoint: null,
    };
  },
  watch: {
    rotate(val) {
      if (val) {
        this.$data.threeEntrypoint.controls.reset();
      }
    }
  },
  mounted() {
    this.$data.threeEntrypoint = threeEntryPoint(this.$refs.threeRootElement);
  },
};
</script>

<style>
#threeContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

canvas {
  height: 400px;
}
</style>
