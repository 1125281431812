<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Олфен<sup>®</sup>-АФ
    </div>
    <div class="module-theory__text">
      Олфен<sup>®</sup>-АФ — це ацеклофенак, що поєднує анальгетичний,
      протизапальний та <br />
      жарознижуючий ефекти<sup>8</sup>
    </div>
    <div class="module-theory__text">
      Він показаний для лікування остеоартриту, ревматоїдного артриту та <br />
      анкілозуючого спондиліту<sup>8</sup>
    </div>

    <div class="module-product">
      <div class="module-product__row">
        <div class="module-product__col-left">
          <div class="module-product__text">
            Однієї упаковки вистачить на 10 днів терапії!
          </div>
          <div class="module-product__image">
            <img
              src="~@/assets/img/training-module2/theme-3/step-4/1.png"
              alt=""
            />
          </div>
        </div>
        <div class="module-product__col-right">
          <div class="module-product__detail">
            <div class="module-product__list">
              <ul>
                <li>• Має у своєму складі ацеклофенак 200 мг</li>
                <li>
                  • Висока ефективність усунення болю при остеоартриті колінного
                  суглоба<sup>9</sup>
                </li>
                <li>
                  • Низький ризик шлунково-кишкових кровотеч в порівнянні з
                  іншими НПЗП<sup>1,2</sup>
                </li>
                <li>
                  • Зручний у застосуванні: 1 таблетка — до 24 годин дії<sup
                    >8</sup
                  >
                </li>
              </ul>
            </div>
            <div class="module-product__text">
              А тепер час перевірити ваші знання за підсумками цієї теми.
            </div>
            <div class="module-product__text text-black">
              Натискайте на кнопку “Перейти до тестування”, щоб пройти фінальний
              тест
            </div>
            <div class="module-product__next" @click="nextState(5, 'next')">
              Перейти до тестування
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Theory3",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до тестування"',
        event_label: 'М.2 - Т.3 - Раздел "Олфен-АФ" '
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__text {
    margin-bottom: 19px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 54px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
    &.subtitle-black {
      margin-bottom: 24px;
      color: #545454;
    }
  }

  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

.module-product {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 45%;
      max-width: 45%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 55%;
      max-width: 55%;
      padding: 0 10px;
    }
  }
  &__image {
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__list {
    ul {
      margin-bottom: 38px;
      padding-left: 0;
      list-style: none;
      li {
        margin-bottom: 12px;
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        sup {
          font-family: $fontDemi;
        }
      }
    }
  }
  &__text {
    margin-bottom: 20px;
    font-family: $fontDemi;
    font-size: 18px;
    line-height: 22px;
    color: #00a03b;
    &.text-black {
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
  }
  &__next {
    width: 375px;
    height: 64px;
    padding: 0 40px;
    margin: 23px 0 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 105% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
      br {
        display: none;
      }
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      margin-bottom: 20px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      &--green {
        margin-bottom: 12px;
        font-family: $fontDemi;
      }
      br {
        display: none;
      }
      b {
        font-family: $fontDemi;
      }
    }
    &__image {
      margin-bottom: 24px;
    }
    &__next {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }

  .module-product {
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
      &-left {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &__image {
      margin-bottom: 12px;
      img {
        width: 100%;
      }
    }
    &__list {
      ul {
        li {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    &__text {
      &:nth-child(1) {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
      }
      &:nth-child(2) {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
      &:nth-child(3) {
        margin-bottom: 24px;
        font-family: $fontR;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
    }
    &__next {
      width: 280px;
      height: 44px;
      margin: auto;
      padding: 0 30px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
