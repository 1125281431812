<template>
  <div class="section-table">
    <div class="title-text" v-html="content.title"></div>
    <div class="table-wrapper">
      <table cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <td
              v-for="(value, key) in content.header"
              v-html="value"
              :key="key"
            ></td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(list, key) in content.blocks" :key="key">
            <td v-for="(secondKey, i) in list" v-html="secondKey" :key="i"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-table",
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

.section-table {
  margin: 10px 0;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 650px) {
    margin: 5px 0;
  }

  @media screen and (max-width: 425px) {
    margin-right: -15px;
  }

  .title-text {
    margin-bottom: 30px;
    color: #00a03b;
    font-family: $fontR;
    font-size: 30px;

    @media screen and (max-width: 670px) {
      margin: 15px 0;
      font-size: 18px;
    }

    b,
    strong {
      font-family: $fontDemi;
      font-weight: normal;
    }
  }

  .table-wrapper {
    position: relative;
    width: 100%;
    overflow: auto;

    @media screen and (max-width: 670px) {
      overflow-x: auto;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    @media screen and (max-width: 670px) {
      min-width: 600px;
    }

    thead {
      tr {
        background: #e6f6ec;

        td {
          border-right: 1px solid #ddd;
          font-family: $fontDemi;
          padding: 15px 20px;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n) {
          background: #eeeeee;
        }

        &:nth-child(2n + 1) {
          background: #f8f8f8;
        }

        td {
          // &:last-child {
          //   text-align: center;
          //   color: #00a03b;
          //   font-size: 20px;
          //   font-family: $fontDemi;
          //   @media screen and (max-width: 670px) {
          //     font-size: 16px;
          //   }
          // }
        }
      }
    }

    td {
      color: #545454;
      font-family: $fontR;
      font-size: 13px;
      padding: 10px 20px;
      border-right: 1px solid #ddd;

      @media screen and (max-width: 670px) {
        font-size: 14px;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
