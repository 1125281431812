<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Вертебральний больовий синдром: <br />
      лікування зі знеболенням
    </div>
    <div class="info-block">
      <div class="info-block__row">
        <div class="info-block__col-left">
          <div class="info-block__text">
            <p>
              Розпізнати клієнта з вертебральним больовим синдромом не так і
              важко<sup>1,2</sup>:
            </p>
            <ul>
              <li>Напівзігнуте положення</li>
              <li>Рука на попереку</li>
              <li>Гримаса болю</li>
            </ul>
            <div class="info-block__image-mobile">
              <img
                src="@/assets/img/training-module3/theme-1/step-1/1.svg"
                alt=""
              />
            </div>
            <p class="text-bordered">
              <b>
                Вертебральний больовий синдром
              </b>
            </p>
            <p>
              Вертебральний больовий синдром – це біль у спині, який виникає
              внаслідок захворювання кістково–м’язової системи. Його
              <b>поширеність</b> становить до <b>80%.<sup>1</sup></b>
            </p>
          </div>
        </div>
        <div class="info-block__col-right">
          <div class="info-block__image-desktop">
            <img
              src="@/assets/img/training-module3/theme-1/step-1/1.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Begin slider 1 -->
    <div class="interactive-slider">
      <div class="interactive-slider__title">
        Типові причини болю у спині<sup>2</sup>
      </div>
      <div class="interactive-slider__content">
        <div
          class="interactive-slider__image interactive-slider__image-desktop"
        >
          <Slider1 @click="sliderClickHandler($event, 1)" ref="slider1" />
        </div>
        <div class="interactive-slider__image interactive-slider__image-mobile">
          <Slider1Mobile
            @click="sliderClickHandler($event, 1)"
            ref="slider1Mobile"
          />
        </div>
        <div class="interactive-slider__info">
          <div
            class="interactive-slider__slider-nav prev"
            v-show="slider1.currentSlide > 1"
            @click="sliderNavigation(1, 'previous')"
          >
            <span>{{ slider1.currentSlide - 1 }}</span>
          </div>
          <div class="interactive-slider__detail">
            <div class="interactive-slider__detail-number">
              {{ slider1.slides[slider1.currentSlide - 1].id }}
            </div>
            <div
              class="interactive-slider__detail-text"
              v-html="slider1.slides[slider1.currentSlide - 1].title"
            ></div>
          </div>
          <div
            class="interactive-slider__slider-nav next"
            v-show="slider1.currentSlide < slider1.slides.length"
            @click="sliderNavigation(1, 'next')"
          >
            <span>{{ slider1.currentSlide + 1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Begin slider 2 -->
    <div class="interactive-slider">
      <div class="interactive-slider__title">
        Найчастіше біль виникає у поперековому <br />
        та шийно-плечовому відділі.<sup>3</sup>
      </div>
      <div class="interactive-slider__subtitle">
        Типова локалізація болю у спині<sup>3</sup>
      </div>
      <div class="interactive-slider__content">
        <div
          class="interactive-slider__image interactive-slider__image-desktop"
        >
          <Slider2 @click="sliderClickHandler($event, 2)" ref="slider2" />
        </div>
        <div class="interactive-slider__image interactive-slider__image-mobile">
          <Slider2Mobile
            @click="sliderClickHandler($event, 2)"
            ref="slider2Mobile"
          />
        </div>
        <div class="interactive-slider__info">
          <div
            class="interactive-slider__slider-nav prev"
            v-show="slider2.currentSlide > 1"
            @click="sliderNavigation(2, 'previous')"
          >
            <span>{{ slider2.currentSlide - 1 }}</span>
          </div>
          <div class="interactive-slider__detail">
            <div class="interactive-slider__detail-number">
              {{ slider2.slides[slider2.currentSlide - 1].id }}
            </div>
            <div
              class="interactive-slider__detail-text"
              v-html="slider2.slides[slider2.currentSlide - 1].title"
            ></div>
          </div>
          <div
            class="interactive-slider__slider-nav next"
            v-show="slider2.currentSlide < slider2.slides.length"
            @click="sliderNavigation(2, 'next')"
          >
            <span>{{ slider2.currentSlide + 1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="module-theory__next next-button" @click="nextState(2, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Slider1 from "@/assets/img/training-module3/theme-1/step-1/slider-1.svg?inline";
import Slider1Mobile from "@/assets/img/training-module3/theme-1/step-1/slider-1-mobile.svg?inline";
import Slider2 from "@/assets/img/training-module3/theme-1/step-1/slider-2.svg?inline";
import Slider2Mobile from "@/assets/img/training-module3/theme-1/step-1/slider-2-mobile.svg?inline";
export default {
  name: "Theory1",
  data() {
    return {
      state: 1,
      windowWidth: null,
      slider1: {
        currentSlide: 1,
        slides: [
          {
            id: 1,
            title: "Нормальний <b>диск</b>"
          },
          {
            id: 2,
            title: "<b>Диск</b> з дегенеративними змінами"
          },
          {
            id: 3,
            title: "<b>Диск</b> з вип’ячуванням"
          },
          {
            id: 4,
            title: "Спинномозкова <b>грижа</b>"
          },
          {
            id: 5,
            title: "Стончений <b>диск</b>"
          },
          {
            id: 6,
            title: "Дегенерація <b>диску</b> з утворенням остеофітів"
          }
        ]
      },
      slider2: {
        currentSlide: 1,
        slides: [
          {
            id: 1,
            title: "Поперековий відділ <b>73%</b>"
          },
          {
            id: 2,
            title: "Плечовий / </br> шийний регіон <b>44%</b>"
          },
          {
            id: 3,
            title: "Шийний відділ <b>40%</b>"
          },
          {
            id: 4,
            title: "Між лопатками <b>28%</b>"
          },
          {
            id: 5,
            title: "З іррадіацією у верхні кінцівки <b>19%</b>"
          }
        ]
      }
    };
  },
  components: {
    Slider1,
    Slider1Mobile,
    Slider2,
    Slider2Mobile
  },
  watch: {
    "slider1.currentSlide"(currentSlide) {
      var slides = this.$refs[
        this.windowWidth <= 767 ? "slider1Mobile" : "slider1"
      ].querySelectorAll(".slide");
      this.changeActiveSlide(slides, currentSlide);
    },
    "slider2.currentSlide"(currentSlide) {
      var slides = this.$refs[
        this.windowWidth <= 767 ? "slider2Mobile" : "slider2"
      ].querySelectorAll(".slide");
      this.changeActiveSlide(slides, currentSlide);
    }
  },
  methods: {
    sliderClickHandler(event, sliderNumber) {
      if (event.target.closest(".slide") !== null) {
        this[`slider${sliderNumber}`].currentSlide = Number(
          event.target.closest(".slide").getAttribute("data-slide")
        );

        if (sliderNumber === 1) {
          if (this[`slider${sliderNumber}`].currentSlide === 1) {
            gtag("event", "click", {
              event_category: 'Выбор "Нормальный диск"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №1'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 2) {
            gtag("event", "click", {
              event_category: 'Выбор "Диск з дегенеративними змінами"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №1'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 3) {
            gtag("event", "click", {
              event_category: 'Выбор "Диск з вип\'ячуванням"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №1'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 4) {
            gtag("event", "click", {
              event_category: 'Выбор "Спинномозкова грижа"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №1'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 5) {
            gtag("event", "click", {
              event_category: 'Выбор "Стончений диск"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №1'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 6) {
            gtag("event", "click", {
              event_category: 'Выбор "Дегенерація диску"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №1'
            });
          }
        }
        if (sliderNumber === 2) {
          if (this[`slider${sliderNumber}`].currentSlide === 1) {
            gtag("event", "click", {
              event_category: 'Выбор "Поперековий відділ 73%"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №2'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 2) {
            gtag("event", "click", {
              event_category: 'Выбор "Плечовий/шийний регіон 44%"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №2'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 3) {
            gtag("event", "click", {
              event_category: 'Выбор "Шийний відділ 40%"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №2'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 4) {
            gtag("event", "click", {
              event_category: 'Выбор "Між лопатками 28%"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №2'
            });
          }
          if (this[`slider${sliderNumber}`].currentSlide === 5) {
            gtag("event", "click", {
              event_category: 'Выбор "З іррадіацією у верхні кінцівки 19%"',
              event_label:
                'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - И.К. №2'
            });
          }
        }
      }
    },
    changeActiveSlide(slides, currentSlide) {
      slides.forEach(slide => {
        if (Number(slide.getAttribute("data-slide")) === currentSlide) {
          if (this.windowWidth > 767) {
            slide.querySelector(".slide-circle").setAttribute("r", 16);
          }
          slide.classList.add("active");
        } else {
          slide.querySelector(".slide-circle").setAttribute("r", 12);
          slide.classList.remove("active");
        }
      });
    },
    sliderNavigation(sliderNumber, direction) {
      if (direction === "previous") {
        this[`slider${sliderNumber}`].currentSlide -= 1;
      }
      if (direction === "next") {
        this[`slider${sliderNumber}`].currentSlide += 1;
      }

      if (sliderNumber === 1) {
        if (this[`slider${sliderNumber}`].currentSlide === 1) {
          gtag("event", "click", {
            event_category: 'Выбор "Нормальный диск"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №1'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 2) {
          gtag("event", "click", {
            event_category: 'Выбор "Диск з дегенеративними змінами"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №1'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 3) {
          gtag("event", "click", {
            event_category: 'Выбор "Диск з вип\'ячуванням"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №1'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 4) {
          gtag("event", "click", {
            event_category: 'Выбор "Спинномозкова грижа"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №1'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 5) {
          gtag("event", "click", {
            event_category: 'Выбор "Стончений диск"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №1'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 6) {
          gtag("event", "click", {
            event_category: 'Выбор "Дегенерація диску"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №1'
          });
        }
      }
      if (sliderNumber === 2) {
        if (this[`slider${sliderNumber}`].currentSlide === 1) {
          gtag("event", "click", {
            event_category: 'Выбор "Поперековий відділ 73%"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №2'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 2) {
          gtag("event", "click", {
            event_category: 'Выбор "Плечовий/шийний регіон 44%"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №2'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 3) {
          gtag("event", "click", {
            event_category: 'Выбор "Шийний відділ 40%"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №2'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 4) {
          gtag("event", "click", {
            event_category: 'Выбор "Між лопатками 28%"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №2'
          });
        }
        if (this[`slider${sliderNumber}`].currentSlide === 5) {
          gtag("event", "click", {
            event_category: 'Выбор "З іррадіацією у верхні кінцівки 19%"',
            event_label:
              'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " - Слайдер №2'
          });
        }
      }
    },
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.3 - Т.1  - Раздел "Вертебральний больовий синдром " '
      });
    }
  },
  mounted() {
    this.windowWidth = document.body.clientWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = document.body.clientWidth;
    });
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  .info-block {
    margin-bottom: 48px;
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &__col {
      &-left {
        flex-basis: 44%;
        max-width: 44%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 56%;
        max-width: 56%;
        padding: 0 10px;
      }
    }
    &__text {
      p {
        margin-bottom: 18px;
        font-family: $fontR;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        &:last-of-type {
          margin-bottom: 0;
        }
        b {
          font-family: $fontDemi;
          font-weight: 700;
          sup {
            font-family: $fontDemi;
          }
        }
        &.text-bordered {
          position: relative;
          padding-top: 24px;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 41px;
            height: 3px;
            background: #00a03b;
          }
        }
      }
      ul {
        margin-bottom: 51px;
        padding-left: 17px;
        li {
          font-family: $fontR;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #545454;
        }
      }
    }
    &__image {
      &-desktop {
        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }
      }
      &-mobile {
        display: none;
        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .interactive-slider {
    margin-bottom: 48px;
    &__title {
      margin-bottom: 12px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
      sup {
        font-family: $fontDemi;
      }
    }
    &__subtitle {
      font-family: $fontR;
      font-size: 18px;
      line-height: 22px;
      color: #545454;
    }
    &__content {
      position: relative;
      margin-top: 24px;
    }
    &__image {
      svg {
        display: block;
        width: 100%;
      }
      &-desktop {
        .slide {
          cursor: pointer;
          text {
            font-family: $fontR;
            font-size: 16px;
          }
          &.active {
            circle {
              fill: #00a03b;
            }
            path {
              stroke: #00a03b;
            }
            text {
              font-family: $fontDemi;
              font-size: 18px;
            }
          }
        }
      }
      &-mobile {
        display: none;
        .slide {
          cursor: pointer;
          text {
            font-family: $fontM;
            font-size: 13.3881px;
          }
          &.active {
            circle {
              fill: #00a03b;
            }
          }
        }
      }
    }
    &__info {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: 315px;
      padding: 22px 55px 22px 66px;
      background: #00a03b;
    }
    &__detail {
      display: flex;
      align-items: center;
      &-number {
        min-width: 30px;
        width: 30px;
        height: 30px;
        margin-right: 8px;
        border-radius: 50%;
        background: #fff;
        font-family: $fontM;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #00a03b;
      }
      &-text {
        font-family: $fontR;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
        b {
          font-family: $fontDemi;
          font-weight: 700;
        }
      }
    }
    &__slider-nav {
      position: absolute;
      width: 42px;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
      cursor: pointer;
      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 4px;
        border-radius: 50%;
        background: #005a85;
        font-family: $fontM;
        line-height: 16px;
        font-size: 10.4px;
        text-align: center;
        color: #ffffff;
      }
      &.prev {
        left: 0;
        border-radius: 0 63px 63px 0;
        text-align: right;
      }
      &.next {
        right: 0;
        border-radius: 63px 0 0 63px;
      }
    }
  }
  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }
    .info-block {
      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__text {
        p {
          margin-bottom: 11px;
          font-size: 14px;
          line-height: 17px;
        }
        ul {
          margin-bottom: 31px;
          li {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      &__image {
        &-desktop {
          display: none;
        }
        &-mobile {
          display: block;
          margin-bottom: 24px;
        }
      }
    }
    .interactive-slider {
      margin-bottom: 32px;
      &__title {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 20px;
      }
      &__subtitle {
        margin-bottom: 23px;
        font-size: 14px;
        line-height: 17px;
      }
      &__image {
        &-desktop {
          display: none;
        }
        &-mobile {
          display: block;
        }
      }
      &__info {
        position: relative;
        width: 100%;
        max-width: 100%;
        min-height: 73px;
        padding: 25px 75px 22px 80px;
      }
      &__detail {
        &-number {
          min-width: 23px;
          width: 23px;
          height: 23px;
          font-size: 14.4179px;
          line-height: 23px;
        }
        &-text {
          font-size: 10.2985px;
          line-height: 13px;
        }
      }
      &__slider-nav {
        width: 56px;
        span {
          width: 23px;
          height: 23px;
          font-size: 13.3881px;
          line-height: 23px;
        }
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
