<template>
  <div id="app">
    <div v-if="isIE === true" class="ie-block">
      <div class="ie-content">
        <headed-block></headed-block>
        <div class="h1-title">Ваш браузер застарів!</div>

        <div class="double-text">
          <div>
            <div class="text1">
              Ви користуєтеся застарілою версією браузера
              <b>Internet Explorer.</b>
            </div>
          </div>
          <div>
            <div class="text2">
              Вона не підтримує багато сучасних технологій, через що
              веб-сторінки часто будуть відображатися неправильно і не всі
              функції сайтів будуть вам доступні. Тому пропонуємо до вашої уваги
              сучасніші браузери. Усі вони безкоштовні, легко встановлюються і
              прості у користуванні. У разі переходу на будь-який із перелічених
              нижче браузерів усі ваші закладки і паролі будуть перенесені з
              поточного браузера у новий – ви нічого не втратите.
            </div>
          </div>
        </div>

        <div class="br-list">
          <div class="item">
            <div class="img">
              <img :src="require('./assets/img/chrome.png')" alt />
            </div>
            <div class="name">Google Chrome</div>
            <div class="text">
              Найпопулярніший на сьогодні браузер від гіганта пошукової
              індустрії компанії Google. Має простий та зручний інтерфейс і
              багато розширень.
            </div>
            <a
              href="https://www.google.com/intl/ru/chrome/"
              target="_blank"
              class="link"
              >Завантажити Google Chrome</a
            >
          </div>
          <div class="item">
            <div class="img">
              <img :src="require('./assets/img/fire.png')" alt />
            </div>
            <div class="name">Firefox</div>
            <div class="text">
              Один з найпоширеніших і гнучкіших браузерів. Браузер можна
              налаштований на будь-який смак за допомогою величезної кількості
              доповнень на всі випадки життя і тем оформлення, які можна знайти
              на офіційному сайті доповнень.
            </div>
            <a
              href="https://www.mozilla.org/ru/firefox/new/"
              target="_blank"
              class="link"
              >Завантажити Firefoх</a
            >
          </div>
          <div class="item">
            <div class="img">
              <img :src="require('./assets/img/opera.png')" alt />
            </div>
            <div class="name">Opera</div>
            <div class="text">
              Цей браузер завжди позиціонувався, як дуже зручний і швидкий. Має
              внутрішні утиліти для прискорення завантаження сторінок, що
              особливо актуально для користувачів із повільним інтернетом.
            </div>
            <a
              href="https://www.opera.com/ru/download"
              target="_blank"
              class="link"
              >Завантажити Ореrа</a
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="isIE === false" id="main-main-wrapper">
      <router-view />
    </div>

    <Cookies/>

    <transition name="fade">
      <div class="popup-wrapper" v-if="checkPopUp === true && isIE === false">
        <div class="popup-block">
          <p v-if="ifNo === false" class="h1-title">
            Чи є ви спеціалістом
            <br />з охорони здоров’я?
          </p>
          <p v-if="ifNo === true" class="h1-title h1-title-no">
            Вибачте, будь ласка, інформація призначена лише для спеціалістів
            охорони здоров’я
          </p>

          <div class="btn-wrapper" v-if="ifNo === false">
            <div class="yes" @click="closePopUp()">
              <p>Так</p>
            </div>
            <div class="no" @click="noText()">
              <p>Ні</p>
            </div>
          </div>

          <div
            class="btn-wrapper"
            v-if="ifNo === true"
            @click="reloadPagePopup()"
          >
            <div class="yes">
              <p>Добре</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="popup-wrapper2" v-if="showPopup">
        <div class="popup-block">
          <div class="header-popup">
            <div class="left-img">
              <svg
                width="76"
                height="35"
                viewBox="0 0 76 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.7336 34.8166C24.4231 34.8166 31.4673 33.9868 31.4673 32.9633C31.4673 31.9397 24.4231 31.11 15.7336 31.11C7.04419 31.11 0 31.9397 0 32.9633C0 33.9868 7.04419 34.8166 15.7336 34.8166Z"
                  fill="url(#paint0_radial)"
                />
                <path
                  d="M17.6758 29.5823C10.8326 29.5823 5.28555 23.9312 5.28555 16.9598C5.28555 9.98841 10.8326 4.33734 17.6758 4.33734H62.7625C69.6057 4.33734 75.1528 9.98841 75.1528 16.9598C75.1528 23.9312 69.6057 29.5823 62.7625 29.5823H17.6758Z"
                  fill="url(#paint1_linear)"
                />
                <path
                  d="M15.7336 32.9633C24.4231 32.9633 31.4673 25.7871 31.4673 16.9348C31.4673 8.08247 24.4231 0.90625 15.7336 0.90625C7.04419 0.90625 0 8.08247 0 16.9348C0 25.7871 7.04419 32.9633 15.7336 32.9633Z"
                  fill="#FF3031"
                />
                <path
                  d="M15.7336 30.4588C12.1877 30.4588 8.8541 29.0518 6.34655 26.4978C3.839 23.9432 2.45837 20.5472 2.45837 16.9348C2.45837 13.3223 3.839 9.92628 6.34655 7.37173C8.8541 4.81718 12.1877 3.41068 15.7336 3.41068C19.2796 3.41068 22.6132 4.81718 25.1207 7.37173C27.6283 9.92628 29.0089 13.3223 29.0089 16.9348C29.0089 20.5472 27.6278 23.9432 25.1207 26.4978C22.6132 29.0518 19.2796 30.4588 15.7336 30.4588Z"
                  fill="white"
                />
                <path
                  d="M35.9844 22.3744C35.8152 22.2026 35.7312 21.9657 35.7312 21.6647V12.2133C35.7312 11.9018 35.8236 11.6518 36.0085 11.4635C36.1929 11.2756 36.4382 11.181 36.7445 11.181C37.0612 11.181 37.3119 11.2751 37.4963 11.4635C37.6807 11.6518 37.7731 11.9018 37.7731 12.2133V20.9228H42.2218C42.8551 20.9228 43.1717 21.2078 43.1717 21.7779C43.1717 22.3479 42.8551 22.6329 42.2218 22.6329H36.6968C36.3905 22.6329 36.153 22.5467 35.9844 22.3744Z"
                  fill="white"
                />
                <path
                  d="M44.7947 22.4401C44.6098 22.2467 44.5174 21.9938 44.5174 21.6822V12.2143C44.5174 11.9028 44.6098 11.6528 44.7947 11.4645C44.9791 11.2767 45.2244 11.182 45.5307 11.182C45.8474 11.182 46.0981 11.2762 46.2825 11.4645C46.4669 11.6528 46.5593 11.9028 46.5593 12.2143V21.6817C46.5593 21.9938 46.4669 22.2462 46.2825 22.4396C46.0976 22.6329 45.8469 22.7301 45.5307 22.7301C45.2244 22.7306 44.9791 22.6339 44.7947 22.4401Z"
                  fill="white"
                />
                <path
                  d="M57.9274 11.166C58.191 11.166 58.4284 11.2521 58.6399 11.4239C58.8508 11.5963 58.9565 11.8111 58.9565 12.0691C58.9565 12.1873 58.925 12.322 58.8616 12.4723L54.4764 22.0849C54.3815 22.2893 54.2359 22.4481 54.0407 22.5608C53.8455 22.6735 53.6371 22.7301 53.4153 22.7301C53.1936 22.7301 52.9851 22.6735 52.7899 22.5608C52.5947 22.4481 52.4497 22.2893 52.3543 22.0849L47.9848 12.4723C47.9214 12.322 47.8899 12.1928 47.8899 12.0851C47.8899 11.8166 47.9981 11.5958 48.2144 11.4239C48.4307 11.2521 48.6761 11.166 48.9504 11.166C49.1299 11.166 49.2961 11.2121 49.449 11.3032C49.6019 11.3949 49.7204 11.5316 49.8055 11.7145L53.4468 19.9562L57.0881 11.7145C57.2568 11.3493 57.5365 11.166 57.9274 11.166Z"
                  fill="white"
                />
                <path
                  d="M60.5313 22.3755C60.3572 22.2037 60.2702 21.9617 60.2702 21.6497V12.2469C60.2702 11.9354 60.3572 11.6934 60.5313 11.5211C60.7053 11.3493 60.9453 11.2632 61.2516 11.2632H66.9668C67.2727 11.2632 67.5101 11.3333 67.6793 11.4731C67.8479 11.6128 67.9325 11.8117 67.9325 12.0696C67.9325 12.3386 67.8479 12.5455 67.6793 12.6907C67.5101 12.836 67.2727 12.9086 66.9668 12.9086H62.2649V16.0377H66.6502C66.956 16.0377 67.1935 16.1078 67.3626 16.2476C67.5313 16.3873 67.6158 16.5917 67.6158 16.8607C67.6158 17.1186 67.5313 17.318 67.3626 17.4572C67.1935 17.597 66.956 17.6671 66.6502 17.6671H62.2649V20.9895H66.9668C67.2727 20.9895 67.5101 21.0621 67.6793 21.2074C67.8479 21.3526 67.9325 21.5595 67.9325 21.8285C67.9325 22.0865 67.8479 22.2858 67.6793 22.4251C67.5101 22.5648 67.2727 22.6349 66.9668 22.6349H61.2516C60.9453 22.6339 60.7053 22.5478 60.5313 22.3755Z"
                  fill="white"
                />
                <path
                  d="M21.9362 16.9348L12.4075 11.3303V22.5393L21.9362 16.9348Z"
                  fill="#FF3031"
                />
                <path
                  opacity="0.6"
                  d="M21.9362 16.9348L12.4075 22.5392L19.6096 29.8763C21.6692 29.2392 23.5558 28.0921 25.1208 26.4978C26.1936 25.4048 27.059 24.1581 27.6996 22.8062L21.9362 16.9348Z"
                  fill="url(#paint2_linear)"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(15.7336 32.9633) scale(15.4755 1.73421)"
                  >
                    <stop stop-color="#D93031" />
                    <stop offset="0.00968927" stop-color="#DA3435" />
                    <stop offset="0.1532" stop-color="#E3696A" />
                    <stop offset="0.2985" stop-color="#EC9798" />
                    <stop offset="0.4427" stop-color="#F3BDBD" />
                    <stop offset="0.5856" stop-color="#F8DADA" />
                    <stop offset="0.7269" stop-color="#FCEEEE" />
                    <stop offset="0.8659" stop-color="#FEFBFB" />
                    <stop offset="1" stop-color="white" />
                  </radialGradient>
                  <linearGradient
                    id="paint1_linear"
                    x1="5.28555"
                    y1="16.9598"
                    x2="75.1528"
                    y2="16.9598"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#7B0734" />
                    <stop offset="0.0327" stop-color="#850A34" />
                    <stop offset="0.1684" stop-color="#AA1633" />
                    <stop offset="0.3103" stop-color="#C91F32" />
                    <stop offset="0.4584" stop-color="#E12732" />
                    <stop offset="0.6153" stop-color="#F22C31" />
                    <stop offset="0.7867" stop-color="#FC2F31" />
                    <stop offset="1" stop-color="#FF3031" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear"
                    x1="23.1751"
                    y1="28.1808"
                    x2="17.9422"
                    y2="19.2839"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" />
                    <stop offset="0.4818" stop-color="#FDFDFE" />
                    <stop offset="0.6553" stop-color="#F6F7F9" />
                    <stop offset="0.779" stop-color="#EBECF0" />
                    <stop offset="0.879" stop-color="#DADCE4" />
                    <stop offset="0.9637" stop-color="#C4C8D5" />
                    <stop offset="1" stop-color="#B8BDCC" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div class="popup-title">
              <img
                class="icon-popup"
                :src="`${$root.globalUrl}${popupData.icon}`"
                alt=""
              />
              <div class="text" v-html="popupData.title"></div>
            </div>

            <div class="close-btn" @click="closePopUp2()">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 21.4012L21.1629 1.23822"
                  stroke="#005A85"
                  stroke-width="0.874101"
                  stroke-linecap="round"
                />
                <path
                  d="M21.163 21.4012L1.00003 1.23822"
                  stroke="#005A85"
                  stroke-width="0.874101"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>

          <div class="content-popup">
            <img
              v-if="!getPagePopup.isActive && allPopupsData.length === 0"
              class="img desk"
              :src="`${$root.globalUrl}${popupData.desktopImage}`"
              alt=""
            />
            <img
              v-if="!getPagePopup.isActive && allPopupsData.length === 0"
              class="img mob"
              :src="`${$root.globalUrl}${popupData.mobileImage}`"
              alt=""
            />
            <img
              v-if="getPagePopup.isActive || allPopupsData.length !== 0"
              class="img desk"
              :src="`${$root.globalUrl}${popupData.desktop_image}`"
              alt=""
            />
            <img
              v-if="getPagePopup.isActive || allPopupsData.length !== 0"
              class="img mob"
              :src="`${$root.globalUrl}${popupData.mobile_image}`"
              alt=""
            />
          </div>

          <div class="bottom">
            <a
              class="more-btn"
              @click="closePopUp2()"
              :href="`${popupData.button_link}`"
              >{{ popupData.button_text }}</a
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Cookies from '@/components/Cookies'

const { detect } = require("detect-browser");
const browser = detect();

export default {
  name: "app",

  components: {
    Cookies
  },

  data: () => ({
    activeClass: "active",
    mobile: false,
    checkPopUp: false,
    infoPopup: false,
    ifNo: false,
    isIE: false,
    // popupData: [],
    allPopupsData: [],
    showPopup: false,
  }),

  computed: {
    ...mapGetters(["getScrollBehavior", "getScrollValue", "getPagePopup"]),

    popupUnit() {
      switch (this.getScrollBehavior) {
        case "Нахождение на странице более х секунд":
          return "c";
        case "Скролл":
          return "%";
        case "Попытка закрытия страницы":
          return "leave";
        default:
          return "nothing";
      }
    },

    popupData() {
      if (this.allPopupsData.length !== 0) {
        return this.allPopupsData[this.allPopupsData.length - 1];
      } else {
        return this.getPagePopup;
      }
    },
  },
  methods: {
    ...mapMutations(["setScrollBehavior", "setScrollValue"]),
    ...mapActions("Spec", ["fetchSpec"]),

    reloadPagePopup() {
      this.$router.go();
    },

    noText() {
      this.ifNo = true;
    },

    closePopUp() {
      sessionStorage.setItem("popupCheck", "true");
      this.checkPopUp = false;
      if (this.allPopupsData.length !== 0) {
        this.showPopup = true;
      }
    },

    closePopUp2() {
      sessionStorage.setItem("popupInfo", "true");
      this.infoPopup = false;
      this.showPopup = false;
    },

    axiosApiUrl() {
      let URLPATH = "";
      if (location.hostname === "localhost" || location.port === "8080") {
        // URLPATH = "https://rx.teva.ua";
        URLPATH = "https://rxstage.inchkiev.ua"
        // URLPATH = "http://13.74.250.32";
      } else {
        URLPATH = window.location.origin;
      }
      return URLPATH;
    },

    getPopups() {
      axios.get(this.$root.globalUrl + "/api/v1/popups").then((res) => {
        this.allPopupsData = res.data;
      });
    },

    visibleScrollPopup() {
      const bodyElement = document.body;

      const height = bodyElement.scrollHeight;
      const val = height * (this.getScrollValue / 100);
      const compare = val <= window.pageYOffset;

      if (
        this.getScrollBehavior &&
        compare &&
        !this.checkPopUp &&
        this.infoPopup &&
        !this.isIE
      ) {
        this.showPopup = true;
        console.log("scroll");
      }
    },

    visibleTimePopup() {
      if (
        this.getScrollBehavior &&
        !this.checkPopUp &&
        this.infoPopup &&
        !this.isIE
      ) {
        this.showPopup = true;
        console.log("time");
      }
    },

    visibleLeavePopup() {
      if (
        this.getScrollBehavior &&
        !this.checkPopUp &&
        this.infoPopup &&
        !this.isIE
      ) {
        this.showPopup = true;
        console.log("leave");
      }
    },
  },

  created() {
    if (browser.name === "ie") {
      this.isIE = true;
      sessionStorage.setItem("ie", "true");
    }
    this.$root.globalUrl = this.axiosApiUrl();

    this.getPopups();
    this.fetchSpec();
  },

  watch: {
    popupUnit() {
      if (this.allPopupsData.length === 0) {
        if (this.popupUnit === "%") {
          document.addEventListener("scroll", this.visibleScrollPopup);
        } else if (this.popupUnit === "c") {
          const delay = this.getScrollValue * 1000;
          setTimeout(() => {
            this.visibleTimePopup();
          }, delay);
        } else if (this.popupUnit === "leave") {
          document.addEventListener("mouseleave", this.visibleLeavePopup);
        } else {
          return;
        }
      } else {
        return;
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (sessionStorage.getItem("popupCheck") === null) {
        this.checkPopUp = true;
      }

      if (sessionStorage.getItem("popupInfo") === null) {
        this.infoPopup = true;
      }

      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS =
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (isAndroid || iOS === true) {
        this.mobile = true;
      }
    });
  },
};
</script>

<style lang="scss">
@import "./css/variables";

$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontDemi: "Commissioner-SemiBold";

* {
  scroll-behavior: smooth;
  // user-select: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: $fontPrimary;
}

#app {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ie-block {
  width: 100%;
  min-height: 100vh;

  background: url("assets/img/ie-bg.png") no-repeat center bottom;
  background-size: contain;

  .ie-content {
    margin: 0 auto;
    max-width: 1100px;
    padding: 100px 15px 0 15px;

    .h1-title {
      color: #00a03b;
      font-family: $fontPrimary;
      font-size: 40px;
      margin-bottom: 75px;
    }

    .double-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      > div {
        .text1 {
          color: #00a03b;
          font-family: $fontPrimary;
          font-size: 30px;
        }

        .text2 {
          color: #545454;
          font-family: $fontPrimary;
          font-size: 16px;
        }

        &:first-child {
          margin-right: 15px;
          max-width: 400px;
          width: 100%;
        }

        &:last-child {
          margin-left: 15px;
          max-width: 650px;
          width: 100%;
        }
      }
    }

    .br-list {
      padding-top: 70px;
      position: relative;
      display: flex;
      justify-content: space-between;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 3px;
        background: #00a03b;
      }

      .item {
        margin-right: 20px;
        max-width: 354px;
        width: 100%;

        .img {
          max-width: 80px;
          width: 100%;

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }

        .name {
          margin-top: 32px;

          font-family: $fontPrimary;
          font-size: 20px;
          color: #00a03b;
        }

        .text {
          margin-top: 15px;

          font-family: $fontPrimary;
          font-size: 16px;
          color: #545454;
        }

        .link {
          display: inline-block;
          margin-top: 24px;

          font-family: $fontPrimary;
          font-weight: 600;
          font-size: 16px;
          color: #00a03b;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.popup-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(53, 120, 110, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .popup-block {
    background: white;
    max-width: 419px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 550px) {
      padding: 20px;
    }

    * {
      // user-select: none;
    }

    .h1-title {
      font-family: $fontPrimary;
      font-weight: 600;
      font-size: 20px;
      color: #317575;
      text-align: center;

      @media screen and (max-width: 550px) {
        font-size: 15px;
      }
    }

    .h1-title-no {
      text-align: center;
      font-size: 20px;
    }

    .btn-wrapper {
      margin-top: 40px;
      display: flex;
      @media screen and (max-width: 550px) {
        margin-top: 20px;
      }

      .yes {
        cursor: pointer;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        height: 42px;
        background: #317575;
        border-radius: 100px;

        @media screen and (max-width: 550px) {
          width: 90px;
          height: 40px;
          margin-right: 10px;
        }

        p {
          font-family: $fontPrimary;
          font-weight: 600;
          font-size: 15px;
          color: white;

          @media screen and (max-width: 550px) {
            font-size: 12px;
          }
        }
      }

      .no {
        cursor: pointer;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        height: 42px;
        border-radius: 100px;
        border: 2px solid #317575;

        @media screen and (max-width: 550px) {
          width: 90px;
          height: 40px;
          margin-right: 10px;
        }

        p {
          font-family: $fontPrimary;
          font-weight: 600;
          font-size: 15px;
          color: #317575;

          @media screen and (max-width: 550px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.popup-wrapper2 {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(53, 120, 110, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  @media screen and (max-width: 425px) {
    padding: 0;
  }

  .popup-block {
    max-width: 800px;
    width: 100%;
    background: white;
    position: relative;

    .header-popup {
      width: 100%;
      background: white;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      padding: 13px 40px 13px 40px;

      @media screen and (max-width: 600px) {
        padding: 13px 40px 13px 70px;
      }

      .left-img {
        position: absolute;
        left: 14px;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 600px) {
          svg {
            width: 40px;
          }
        }
      }

      .popup-title {
        display: flex;
        align-items: center;
        width: 100%;

        justify-content: center;

        .text {
          p {
            word-break: break-all;
            font-family: $fontPrimary;
            font-size: 15px;

            @media screen and (max-width: 600px) {
              font-size: 12px;
            }

            strong {
              font-family: $fontPrimary;
              font-weight: 600;
              font-size: 30px;
              @media screen and (max-width: 600px) {
                font-size: 18px;
              }
            }
          }
        }
      }

      .close-btn {
        position: absolute;
        right: 14px;
        top: 0;

        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: 600px) {
          right: 0px;
          padding: 0 14px 0 0;

          svg {
            width: 15px;
          }
        }
      }

      .icon-popup {
        width: 35px;
        height: auto;
        display: block;
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 600px) {
      max-width: 425px;

      max-height: 100%;
      overflow-y: scroll;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      max-height: 400px;
      object-fit: contain;

      &.desk {
        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      &.mob {
        display: none;
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 13px;

      @media screen and (max-width: 600px) {
        padding: 24px 13px;
        justify-content: center;
      }

      .more-btn {
        cursor: pointer;

        background: #317575;
        border-radius: 87.4101px;

        font-family: $fontPrimary;
        font-weight: 600;
        font-size: 14px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 11px 30px;

        text-decoration: none;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

* sub,
* sup {
  font-size: 60%;
  line-height: 0;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

@import "./css/fontOld";
</style>
