<template>
  <div class="module-theory">
    <div class="module-theory__back" v-if="state > 1" @click="previousStep()">
      Назад
    </div>
    <div class="module-theory__state" v-show="state === 1">

      <div class="module-theory__title">Симптоми неускладненого грипу<sup>1</sup></div>
      <div class="module-theory__img">
        <picture class="module-theory__item-img">
          <source srcset="@/assets/img/training-module6/theme-2/step-1/img-3-desk.png" media="(min-width: 768px)"/>
          <img src="@/assets/img/training-module6/theme-2/step-1/img-3-mob.png" alt=""/>
        </picture>
      </div>
      <div class="module-theory__list-title">Хто в групі високого ризику розвитку ускладнень грипу?<sup>1</sup></div>
      <ul>
        <li><span><b>Нещеплені діти</b> віком 12–24 місяці</span></li>
        <li><span>Особи із <b>хронічними хворобами серця та легень</b></span></li>
        <li><span>Особи, які мають <b>імуносупресивні розлади</b> або отримують <b>імуносупресивну терапію</b></span>
        </li>
        <li><span><b>ВІЛ-інфіковані</b></span></li>
        <li><span><b>Онкохворі</b></span></li>
        <li><span>Пацієнти з <b>серповидно-клітинною анемією</b> та іншими <b>гемоглобінопатіями</b></span></li>
        <li><span>Пацієнти із хворобами, що потребують <b>тривалого лікування АСК</b></span></li>
        <li><span>Пацієнти з <b>цукровим діабетом, ожирінням</b></span></li>
        <li>
          <span>Особи з <b>хронічними хворобами нирок, хронічними хворобами печінки, неврологічними хворобами</b></span>
        </li>
        <li><span><b>Нещеплені вагітні та породіллі</b> (впродовж 14 днів після пологів)</span></li>
        <li><span>Дорослі віком <b>>65 років</b></span></li>
        <li><span>Соціально <b>незахищені верстви населення</b></span></li>
        <li><span>Населення будь-якого віку, що проживає <b>у місцях великого скупчення людей:</b> гуртожитки, будинки для людей похилого віку або інші заклади тривалого догляду за пацієнтами</span>
        </li>
      </ul>
      <div class="module-theory__next next-button" @click="nextState(3, 'next')">
        Далі
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory2",
  data() {
    return {
      state: 1,
    };
  },
  methods: {
    previousStep() {
      this.state -= 1;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    },
    nextStep() {
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.6  - Раздел "Симптоми грипу та групи ризику" - Э.${this.state}'`
      });
      this.state += 1;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    },
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.6  - Раздел "Симптоми грипу та групи ризику" - Э.${this.state}'`
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$fontSB: "Commissioner-SemiBold";


.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
  }

  &__text {
    max-width: 730px;
    margin-bottom: 64px;

    &.small-margin {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 15px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      b {
        font-family: $fontDemi;
      }

      &.text-green {
        color: #00a03b;
      }
    }
  }

  &__back {
    margin-bottom: 16px;
    padding-left: 22px;
    background-image: url("~@/assets/img/training-module4/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #9f9f9f;
    cursor: pointer;
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    margin-bottom: 48px;
    padding-right: 50px;
  }

  &__mechanism {
    margin-bottom: 64px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }

    &-img {
      img {
        display: block;
        max-width: 100%;
      }
    }

    &-title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }

    &-text {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      &.text-mobile {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      b {
        font-family: $fontDemi;
      }
    }
  }

  &__accordions {
    margin-bottom: 64px;
  }

  &__accordion {
    position: relative;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 160px 0 48px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(46, 113, 138, 0.16);
    border-radius: 2px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 24px;
      right: 24px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #005a85;
    }

    &.opened {
      min-height: 186px;
      padding: 32px 160px 32px 48px;
      justify-content: unset;
      background: rgba($color: #e4eaed, $alpha: 0.5);
      box-shadow: none;

      &:after {
        transform: rotate(-180deg);
      }

      &:nth-child(2) {
        padding: 18px 160px 32px 48px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-number {
      position: absolute;
      top: 31px;
      left: 12px;
      width: 24px;
      height: 24px;
      background: #f3d074;
      border-radius: 50%;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.05em;
      text-align: center;
      color: #ed804a;
    }

    &-title {
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #005984;
    }

    &-text {
      padding-top: 16px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
  }

  &__product {
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }

    &-img {
      &.product-img-mobile {
        display: none;
      }

      img {
        display: block;
        max-width: 100%;
      }
    }

    &-title {
      margin-bottom: 32px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;

      &:last-child {
        margin-bottom: 0;
      }

      sup {
        font-family: $fontDemi;
      }
    }

    &-text {
      margin-bottom: 32px;

      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }

    .module-theory & {
      &-next {
        margin-top: 32px;
        margin-left: 0;
      }
    }
  }

  &__item-img {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__list-title {
    color: var(--Green, #00A03B);
    font-family: $fontSB;
    font-size: 24px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style: disc;
    color: #00A03B;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: var(--Black, #545454);
      font-family: $fontR;
      font-size: 16px;

      b {
        font-family: $fontSB;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    margin: 48px auto 0 auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__back {
      font-size: 12px;
      line-height: 15px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }

    &__text {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 17px;

      p {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__image {
      padding-right: 0;

      img {
        width: 100%;
      }
    }

    &__mechanism {
      margin-bottom: 32px;

      &-row {
        flex-wrap: wrap-reverse;
      }

      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
      }

      &-text {
        font-size: 14px;
        line-height: 17px;

        &.text-desktop {
          display: none;
        }

        &.text-mobile {
          display: block;
        }

        b {
          font-family: $fontR;
          font-weight: 400;
        }

        &:last-child {
          margin-bottom: 24px;
        }
      }

      &-img {
        margin-bottom: 16px;

        img {
          width: 100%;
        }
      }
    }

    &__accordions {
      margin-bottom: 32px;
    }

    &__accordion {
      display: block;
      min-height: auto;
      padding: 16px 36px 24px 48px;

      &.opened {
        min-height: auto;

        &:nth-child(1n) {
          padding: 16px 36px 24px 48px;
        }
      }

      &-number {
        top: 16px;
        left: 12px;
      }

      &-title {
        font-size: 16px;
        line-height: 20px;
      }

      &-text {
        margin: 8px -36px 0 -48px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__product {
      &-row {
        flex-wrap: wrap-reverse;
      }

      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;

        &.product-title-mobile {
          margin-bottom: 24px;
        }
      }

      &-text {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 17px;
      }

      &-img {
        margin-bottom: 32px;

        &.product-img-desktop {
          display: none;
        }

        &.product-img-mobile {
          display: block;
        }

        img {
          width: 100%;
        }
      }
    }

    &__list-title {
      font-size: 16px;
      margin-top: 24px;
    }

    ul {
      padding-left: 20px;
    }

    li {

      span {
        font-size: 14px;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin:  32px auto 0 auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
