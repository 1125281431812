var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-nav"},[_c('div',{staticClass:"training-nav__inner"},[_c('div',{staticClass:"training-nav__list"},[_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 1,
          current: _vm.currentState === 1
        },on:{"click":function($event){return _vm.nextState(1, '')}}},[_vm._v(" Вітаміни групи B ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 2,
          current: _vm.currentState === 2
        },on:{"click":function($event){return _vm.nextState(2, '')}}},[_vm._v(" Нейрорубін ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 3,
          current: _vm.currentState === 3
        },on:{"click":function($event){return _vm.nextState(3, '')}}},[_vm._v(" Інтерактивна гра ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 4,
          current: _vm.currentState === 4
        },on:{"click":function($event){return _vm.nextState(4, '')}}},[_vm._v(" Фінальний тест ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }