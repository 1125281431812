<template>
  <div class="section-col-img-text">
    <div class="item" v-for="(key, value) in content.blocks">
      <div class="img-wrapper">
        <img :src="`${$root.globalUrl}${key.file}`" alt="" />
      </div>
      <div class="text">
        <h3 v-html="key.title"></h3>
        <div class="text-wrapper" v-html="key.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-col-img-text",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-col-img-text {
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    @media screen and (max-width: 670px) {
      margin: 15px 0;
      font-size: 18px;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    > div {
      max-width: 400px;
      width: 100%;

      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }

      &:nth-child(1) {
        margin-right: 15px;
        @media screen and (max-width: 670px) {
          margin-right: 5px;
        }
        @media screen and (max-width: 500px) {
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        margin-left: 15px;
        @media screen and (max-width: 670px) {
          margin-left: 5px;
        }
        @media screen and (max-width: 500px) {
          margin-left: 0;
        }
      }

      &.img-wrapper {
        @media screen and (max-width: 425px) {
          margin: 0 -15px !important;
          width: auto;
        }
      }
    }

    img {
      display: block;
      max-width: 400px;
      width: 100%;
      height: auto;
      min-width: 220px;
      margin-right: 15px;
      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }
    }

    > .text {
      padding-top: 25px;
      position: relative;
      max-width: 400px;
      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }
      @media screen and (max-width: 500px) {
        margin-top: 15px;
        padding-top: 15px;
      }
      @media screen and (max-width: 425px) {
        margin-top: 15px !important;
      }

      h3 {
        color: #545454;
        font-family: $fontDemi;
        font-size: 16px;
        margin-bottom: 15px;
        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
        @media screen and (max-width: 500px) {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:before {
        content: "";
        width: 41px;
        height: 3px;
        background: #00a03b;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
