import { debounce } from "lodash";

import router from "../router";
import $api from "../service";

const capitalize = (s) => s[0].toUpperCase() + s.split("").slice(1).join("");

const generateSetMutation = (list) =>
  list.reduce((r, key) => {
    const name = `set${capitalize(key)}`;
    r[name] = (state, payload) => {
      state[key] = payload;
    };
    return r;
  }, {});

const addRandColor = (data) => {
  const { category } = router.currentRoute.params;
  const isDis = category === "distributor";

  const color = ["color1", "color2", "color3"];
  const colorDis = ["disColor1", "disColor2", "disColor3", "disColor4"];

  return data.map((el) => {
    const randNumber = Math.floor(Math.random() * color.length);
    el.color = isDis ? colorDis[randNumber] : color[randNumber];
    return el;
  });
};

export default {
  namespaced: true,

  state: {
    refElement: null,

    items: [],
    filterMap: {},
    paging: {},

    page: 1,
    perPage: 18,
    filter: {},
    preload: false,
    loading: false,

    currentCategory: {},
    categoryList: {},

    showFilter: false,
  },

  getters: {
    totalPages(state) {
      const { total, count } = state.paging;
      return !total ? 1 : Math.ceil(total / count);
    },

    filterString(state) {
      return Object.keys(state.filter || {}).reduce((r, key) => {
        const value = state.filter[key];
        r[key] = value ? value.join() : "";
        return r;
      }, {});
    },
  },

  actions: {
    async fetchMenu({ state, commit, dispatch }, filter) {
      commit("setPreload", true);

      try {
        const data = await dispatch("Spec/fetchSpec", {}, { root: true });
        commit("setCategoryList", data);
        dispatch("updateCurrentSpec");

        if (state.currentCategory) {
          const { slug, name_ua } = state.currentCategory;
          const specTitle = slug !== "all" ? `Для ${name_ua}ів` : name_ua;
          document.title = `${specTitle} - Rx-портал Тева`;
        }
      } catch (e) {
        console.log(e);
      }

      await dispatch("initFetch", filter);
      commit("setPreload", false);
    },

    async fetchFilters({ commit, rootGetters }) {
      const spec = rootGetters["Spec/currentSpec"].id;
      const filters = await $api.fetchFilters({ spec });
      commit("setFilterMap", filters);
    },

    async initFetch({ state, getters, commit, dispatch, rootGetters }, filter) {
      commit("setPreload", true);
      commit("reset");

      const { category } = router.currentRoute.params;
      const spec = rootGetters["Spec/currentSpec"].id;

      commit("setFilter", {
        category: category !== "all" ? [category] : [],
        ...filter,
      });

      try {
        const res = await $api.fetchPages({
          spec: spec,
          page: state.page,
          count: state.perPage,
          ...getters.filterString,
        });
        const { paging, data = [] } = res;

        await dispatch('fetchFilters');

        commit("setPaging", paging);
        commit("setItems", addRandColor(data));
      } catch (e) {
        console.log(e);
      }

      if (getters.totalPages > 1) {
        dispatch("addInfinityScroll");
      }
      commit("setPreload", false);
    },

    async fetchMore({ state, getters, commit, dispatch, rootGetters }) {
      commit("setLoading", true);
      commit("setPage", state.page + 1);

      if (state.page > getters.totalPages) {
        commit("setLoading", false);
        return;
      }

      const spec = rootGetters["Spec/currentSpec"].id;

      try {
        const res = await $api.fetchPages({
          spec,
          page: state.page,
          count: state.perPage,
          ...getters.filterString,
        });
        const { data = [] } = res;
        commit("setItems", [...state.items, ...addRandColor(data)]);
      } catch (e) {
        console.log(e);
      }

      if (state.page === getters.totalPages) {
        dispatch("removeInfinityScroll");
      }

      commit("setLoading", false);
    },

    updateCurrentSpec({ state, commit }) {
      const { spec } = router.currentRoute.params;
      commit(
        "setCurrentCategory",
        state.categoryList.find(({ id }) => id + "" === spec + "")
      );
    },

    initRefElement({ commit }, payload) {
      commit("setRefElement", payload);
    },

    addInfinityScroll({ state, dispatch }) {
      window.onscroll = window.onresize = debounce(function () {
        if (!state.refElement) return;

        const { innerHeight } = window;
        const pos = state.refElement.getBoundingClientRect();
        const isVisible = innerHeight - pos.top > 0;

        if (isVisible && !state.loading) {
          dispatch("fetchMore");
        }
      }, 100);
    },

    removeInfinityScroll() {
      window.onscroll = window.onresize = null;
    },
  },

  mutations: {
    ...generateSetMutation([
      "showFilter",
      "refElement",
      "filterMap",
      "paging",
      "items",
      "currentCategory",
      "categoryList",
      "page",
      "filter",
      "preload",
      "loading",
    ]),

    reset(state) {
      state.items = [];
      state.page = 1;
      state.filter = {};
    },
  },
};
