var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game"},[_c('h3',{staticClass:"game__title"},[_vm._v("При виборі вітамінів групи В для лікування болю у спині потрібно бути дуже уважним та знати усі переваги препарату. ")]),_vm._m(0),_c('div',{staticClass:"game__dots"},_vm._l((_vm.questions),function(item,key){return _c('div',{key:key,staticClass:"game__dots-item",class:{
        current: _vm.question === key + 1,
        correct: item.isHasCorrectAnswer === true,
        incorrect: item.isHasCorrectAnswer === false
      }},[_c('div',{staticClass:"game__dots-item-inner"})])}),0),_c('div',{staticClass:"game__count"},[_c('span',[_vm._v(" "+_vm._s(_vm.question >= 3 ? "0" : "0")+_vm._s(_vm.question)+" ")]),_vm._v(" / "+_vm._s(_vm.question >= 3 ? "0" : "0")+_vm._s(_vm.questions.length)+" ")]),_c('div',{staticClass:"questions"},[_c('div',{staticClass:"questions__title",domProps:{"innerHTML":_vm._s(_vm.currentQuestion.title)}}),(!_vm.currentQuestion.isHasAnswer)?[_c('div',{staticClass:"questions__answers"},_vm._l((_vm.currentQuestion.answers),function(answer,key){return _c('div',{key:key,staticClass:"questions__answers-item",on:{"click":function($event){return _vm.selectAnswer(_vm.currentQuestion, answer)}}},[_vm._v(" "+_vm._s(answer.title)+" ")])}),0),_c('div',{staticClass:"questions__next next-button disabled"},[_vm._v(" Далі ")])]:[_c('div',{staticClass:"questions__answers"},_vm._l((_vm.currentQuestion.answers),function(answer,key){return _c('button',{key:key,staticClass:"questions__answers-item",class:{
            correct: answer.isCorrect === true,
            inCorrect: answer.isCorrect === false,
            select: answer.isSelected === true,
            disablade: answer.isSelected === false
          },attrs:{"disabled":_vm.selectedAnswer.id !== answer.id},on:{"click":function($event){return _vm.selectAnswer(_vm.currentQuestion, answer)}}},[_vm._v(" "+_vm._s(answer.title)+" ")])}),0),_c('div',{staticClass:"questions__bottom"},[_c('div',{staticClass:"questions__message",domProps:{"innerHTML":_vm._s(_vm.currentQuestion.message.text)}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentQuestion.isHasAnswer && _vm.question === _vm.questions.length),expression:"currentQuestion.isHasAnswer && question === questions.length"}],staticClass:"questions__to-test next-button",on:{"click":function($event){return _vm.nextState(4, 'next')}}},[_vm._v(" Далі ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentQuestion.isHasAnswer && _vm.question !== _vm.questions.length),expression:"currentQuestion.isHasAnswer && question !== questions.length"}],staticClass:"questions__next next-button",class:{disablade: !_vm.currentQuestion.isHasAnswer},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" Далі ")])])]],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game__description"},[_vm._v("Давайте перевіримо, чи зможете ви відгадати "),_c('span',[_vm._v("переваги Нейрорубін"),_c('sup',[_vm._v("™")]),_vm._v(" - Форте Лактаб!")])])}]

export { render, staticRenderFns }