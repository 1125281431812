<template>
  <div class="module-theory">
    <div class="module-theory__back" v-if="state > 1" @click="previousStep()">
      Назад
    </div>
    <div class="module-theory__state" v-show="state === 1">

      <div class="module-theory__title">Агріппа (озельтамівір) — інгібітор нейрамінідази, противірусний засіб прямої дії<sup>8</sup>
      </div>

      <div class="module-theory__text">Показання<sup>8</sup>:</div>

      <div class="module-theory__block">
        <div class="icon">
          <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M64.0813 74.0313C84.4723 74.0313 101.002 57.4588 101.002 37.0156C101.002 16.5725 84.4723 0 64.0813 0C43.6903 0 27.1602 16.5725 27.1602 37.0156C27.1602 57.4588 43.6903 74.0313 64.0813 74.0313Z"
                fill="#E6F2C4"/>
            <path
                d="M81.0506 85.5943C87.6872 83.5194 91.3895 76.4435 89.3199 69.7899C87.2503 63.1362 80.1924 59.4244 73.5558 61.4994C66.9191 63.5743 63.2168 70.6502 65.2865 77.3038C67.3561 83.9575 74.4139 87.6692 81.0506 85.5943Z"
                fill="#E6F2C4"/>
            <path
                d="M53.0437 58.5637C54.0953 58.1601 55.4094 59.4132 55.802 60.7286C56.1946 62.044 54.7804 66.4561 54.4579 67.8016C53.7828 70.6272 52.6371 71.9365 52.0742 72.3181C51.5113 72.6996 50.3315 73.1495 49.8047 72.6997"
                fill="white"/>
            <path
                d="M53.0437 58.5637C54.0953 58.1601 55.4094 59.4132 55.802 60.7286C56.1946 62.044 54.7804 66.4561 54.4579 67.8016C53.7828 70.6272 52.6371 71.9365 52.0742 72.3181C51.5113 72.6996 50.3315 73.1495 49.8047 72.6997"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M3.76512 58.5637C2.73753 58.1601 1.45155 59.4132 1.06695 60.7286C0.682355 62.044 2.0665 66.4561 2.38098 67.8016C3.04201 70.6272 4.16174 71.9365 4.71259 72.3181C5.26345 72.6996 6.41722 73.1495 6.93202 72.6997"
                fill="white"/>
            <path
                d="M3.76512 58.5637C2.73753 58.1601 1.45155 59.4132 1.06695 60.7286C0.682355 62.044 2.0665 66.4561 2.38098 67.8016C3.04201 70.6272 4.16174 71.9365 4.71259 72.3181C5.26345 72.6996 6.41722 73.1495 6.93202 72.6997"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.2024 82.4624C13.2024 82.4624 14.6025 92.0557 11.041 100L13.2024 82.4624Z" fill="white"/>
            <path d="M13.2024 82.4624C13.2024 82.4624 14.6025 92.0557 11.041 100" stroke="#601841"
                  stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M43.1163 83.1851C43.1163 83.1851 42.5234 93.5154 45.7884 99.9979L43.1163 83.1851Z" fill="white"/>
            <path d="M43.1163 83.1851C43.1163 83.1851 42.5234 93.5154 45.7884 99.9979" stroke="#601841"
                  stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M29.6103 66.6864C43.5348 65.7173 54.0879 54.4209 53.1814 41.455C52.275 28.4891 40.2521 18.7637 26.3277 19.7327C12.4032 20.7018 1.85008 31.9982 2.75655 44.9641C3.66302 57.93 15.6859 67.6554 29.6103 66.6864Z"
                fill="white"/>
            <path
                d="M52.8638 59.8976C52.8638 68.2157 42.8182 74.9593 30.4251 74.9593C18.0319 74.9593 7.98633 68.2157 7.98633 59.8976C7.98633 51.5795 28.6042 36.7407 40.9954 36.7407C53.3866 36.7407 52.8638 51.5795 52.8638 59.8976Z"
                fill="white"/>
            <path
                d="M27.5124 32.5995C33.8392 32.5995 38.9681 27.4575 38.9681 21.1144C38.9681 14.7714 33.8392 9.62939 27.5124 9.62939C21.1855 9.62939 16.0566 14.7714 16.0566 21.1144C16.0566 27.4575 21.1855 32.5995 27.5124 32.5995Z"
                fill="white"/>
            <path
                d="M23.7137 55.6419C23.7137 55.6419 22.5018 56.7688 21.4422 56.6663C20.4427 56.5699 19.385 55.3486 16.5426 55.6158C14.6637 55.7925 12.9551 57.6828 12.9551 57.6828"
                fill="white"/>
            <path
                d="M23.7137 55.6419C23.7137 55.6419 22.5018 56.7688 21.4422 56.6663C20.4427 56.5699 19.385 55.3486 16.5426 55.6158C14.6637 55.7925 12.9551 57.6828 12.9551 57.6828"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M35.4978 21.0907C35.7422 20.2071 35.6601 20.1689 35.6601 19.205C35.6601 13.6141 32.9719 10.2222 27.5735 10.2222C22.1752 10.2222 19.9558 13.5197 19.9558 19.1106C19.9558 20.0765 19.8736 20.1127 20.118 20.9963C12.5984 24.6573 5.44531 33.9373 5.44531 43.1109C5.44531 47.5029 6.63916 49.6818 8.70836 53.383C15.605 49.7983 33.5488 46.0349 36.9761 33.5337C38.5545 44.1511 44.3976 44.9886 48.1514 50.4911C49.153 47.7619 49.7018 46.0911 49.7018 43.1089C49.7018 33.9353 43.0195 24.7477 35.4998 21.0867L35.4978 21.0907Z"
                fill="#E4B6BC"/>
            <path
                d="M26.606 34.0996C24.7752 38.6844 21.7926 41.2529 18.3993 43.9941C15.0061 46.7373 4.31954 48.6492 4.31954 58.1903C4.31954 58.1903 1.8457 51.3683 1.8457 45.665C1.8457 31.2479 13.0871 19.562 27.5795 19.562C42.0719 19.562 53.3133 31.2479 53.3133 45.665C53.3133 48.4182 52.5642 56.5254 52.5642 56.5254"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.3414 22.0486C16.3013 21.6591 16.2793 21.3578 16.2793 20.9562C16.2793 14.7026 21.3351 9.63379 27.5728 9.63379C33.8104 9.63379 38.8662 14.7026 38.8662 20.9562C38.8662 21.2976 38.8502 21.5406 38.8222 21.8739"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M6.43164 64.2089C6.89436 67.9964 6.80422 71.2498 8.09822 74.8284C10.2856 80.8772 15.5017 84.4478 20.4974 88.5245C21.9677 89.7235 23.452 90.9324 25.1426 91.7959C27.4642 92.9808 29.3832 92.9808 31.7048 91.7959C33.3954 90.9324 34.8797 89.7235 36.3499 88.5245C41.3457 84.4499 46.5617 80.8772 48.7491 74.8284C50.0271 71.294 50.3095 66.2553 50.8404 62.2891"
                fill="white"/>
            <path
                d="M6.43164 64.2089C6.89436 67.9964 6.80422 71.2498 8.09822 74.8284C10.2856 80.8772 15.5017 84.4478 20.4974 88.5245C21.9677 89.7235 23.452 90.9324 25.1426 91.7959C27.4642 92.9808 29.3832 92.9808 31.7048 91.7959C33.3954 90.9324 34.8797 89.7235 36.3499 88.5245C41.3457 84.4499 46.5617 80.8772 48.7491 74.8284C50.0271 71.2939 50.3095 66.2553 50.8404 62.2891"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M36.7012 32.9028C36.7352 35.4553 37.8369 38.0399 38.6602 39.6746C40.3308 43.0022 46.0236 48.0529 48.0467 50.0712C51.2236 53.2401 52.8562 56.4513 53.0204 59.297"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M36.6147 31.2861C37.1455 34.0254 36.0518 37.1261 34.3592 39.4516C26.2286 50.6133 4.14844 50.5611 4.14844 60.2006"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M31.0499 72.4551C29.828 73.6704 27.8469 73.6704 26.625 72.4551H31.0499Z" fill="white"/>
            <path d="M31.0499 72.4551C29.828 73.6704 27.8469 73.6704 26.625 72.4551" stroke="#601841"
                  stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M22.9661 60.9478C22.0647 62.149 20.5383 62.8561 19.034 62.7717C17.4155 62.6813 14.8535 62.0385 14.8535 62.0385"
                fill="white"/>
            <path
                d="M22.9661 60.9478C22.0647 62.149 20.5383 62.8561 19.034 62.7717C17.4155 62.6813 14.8535 62.0385 14.8535 62.0385"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M33.6758 55.6864C33.6758 55.6864 34.8957 56.8033 35.9553 56.6928C36.9549 56.5884 38.0025 55.359 40.8469 55.6021C42.7258 55.7628 44.4525 57.639 44.4525 57.639"
                fill="white"/>
            <path
                d="M33.6758 55.6864C33.6758 55.6864 34.8957 56.8033 35.9553 56.6928C36.9549 56.5884 38.0025 55.359 40.8469 55.6021C42.7258 55.7628 44.4525 57.639 44.4525 57.639"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M34.4277 60.9478C35.3291 62.149 36.8555 62.8561 38.3598 62.7717C39.9783 62.6813 42.34 62.0385 42.34 62.0385"
                fill="white"/>
            <path
                d="M34.4277 60.9478C35.3291 62.149 36.8555 62.8561 38.3598 62.7717C39.9783 62.6813 42.34 62.0385 42.34 62.0385"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M57.4075 89.8842C60.2894 89.8842 62.6256 87.5421 62.6256 84.6528C62.6256 81.7636 60.2894 79.4214 57.4075 79.4214C54.5257 79.4214 52.1895 81.7636 52.1895 84.6528C52.1895 87.5421 54.5257 89.8842 57.4075 89.8842Z"
                fill="#E6F2C4"/>
            <path
                d="M89.3022 40.2183L92.1786 41.0638L89.5265 39.3146C89.2321 39.1218 89.0879 38.7644 89.168 38.421C89.3182 37.7763 89.3983 37.1056 89.3983 36.4147C89.3983 35.6094 89.2902 34.8302 89.0878 34.0892C88.9837 33.7117 89.152 33.31 89.4945 33.1212L91.8661 31.8219L89.1019 32.3923C88.7473 32.4646 88.3868 32.3039 88.2045 31.9906C87.5074 30.7957 86.5359 29.7836 85.3781 29.0365C85.0396 28.8196 84.9074 28.3879 85.0576 28.0143L86.1934 25.1787L84.1181 27.7533C83.9018 28.0204 83.5473 28.1328 83.2188 28.0304C82.4075 27.7814 81.5482 27.6468 80.6548 27.6468C79.8816 27.6468 79.1304 27.7472 78.4173 27.938C78.0988 28.0224 77.7603 27.9079 77.552 27.6508L75.5609 25.1787L76.6105 27.7954C76.7668 28.187 76.6105 28.6309 76.246 28.8437C74.7737 29.7093 73.5758 30.9925 72.8147 32.5328L69.3613 31.8199L71.8071 33.1614C72.1496 33.3502 72.3179 33.7478 72.2157 34.1254C72.0194 34.8543 71.9153 35.6215 71.9153 36.4127C71.9153 37.204 72.0134 37.9269 72.1997 38.6358C72.2918 38.9853 72.1496 39.3548 71.8472 39.5536L69.5656 41.0598L72.0735 40.3227C72.4501 40.2123 72.8507 40.377 73.045 40.7184C73.8062 42.0699 74.9159 43.1985 76.252 43.9797C76.6146 44.1926 76.7708 44.6364 76.6145 45.028L75.641 47.458L77.564 45.0702C77.7343 44.8593 78.0127 44.771 78.2731 44.8453C79.0303 45.0602 79.8315 45.1746 80.6588 45.1746C81.6083 45.1746 82.5217 45.022 83.377 44.7428C83.6434 44.6545 83.9359 44.7408 84.1122 44.9597L86.1733 47.5202L85.0816 44.7971C84.9294 44.4195 85.0696 43.9898 85.4102 43.7689C86.622 42.9796 87.6256 41.8992 88.3247 40.626C88.517 40.2766 88.9216 40.1059 89.3022 40.2163V40.2183Z"
                fill="#EE7F93" stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M65.4647 19.0722L66.8869 19.4899L65.5749 18.6244C65.4307 18.528 65.3586 18.3533 65.3986 18.1826C65.4727 17.8632 65.5128 17.5319 65.5128 17.1905C65.5128 16.7929 65.4587 16.4073 65.3586 16.0418C65.3065 15.855 65.3906 15.6562 65.5609 15.5638L66.7327 14.9212L65.3666 15.2044C65.1923 15.2405 65.014 15.1602 64.9219 15.0055C64.5774 14.4151 64.0966 13.9151 63.5237 13.5456C63.3575 13.4371 63.2914 13.2242 63.3655 13.0395L63.9264 11.6378L62.9008 12.911C62.7946 13.0435 62.6183 13.0977 62.4561 13.0475C62.0555 12.925 61.6308 12.8588 61.1881 12.8588C60.8055 12.8588 60.4349 12.909 60.0824 13.0013C59.9242 13.0435 59.7579 12.9873 59.6557 12.8588L58.6702 11.6357L59.189 12.929C59.2671 13.1218 59.189 13.3427 59.0087 13.4472C58.2796 13.8749 57.6887 14.5095 57.3121 15.2706L55.6055 14.9172L56.8133 15.5799C56.9836 15.6723 57.0657 15.8691 57.0157 16.0559C56.9195 16.4173 56.8674 16.7949 56.8674 17.1865C56.8674 17.5781 56.9155 17.9355 57.0076 18.285C57.0537 18.4577 56.9836 18.6404 56.8334 18.7388L55.7056 19.4839L56.9455 19.1204C57.1318 19.0662 57.3301 19.1465 57.4263 19.3152C57.8029 19.9839 58.3517 20.5402 59.0127 20.9278C59.193 21.0322 59.2691 21.2531 59.191 21.4459L58.7103 22.6469L59.6617 21.466C59.7459 21.3616 59.8841 21.3174 60.0123 21.3556C60.3869 21.462 60.7835 21.5182 61.1921 21.5182C61.6608 21.5182 62.1135 21.4439 62.5362 21.3054C62.6684 21.2612 62.8126 21.3054 62.9008 21.4118L63.9203 22.677L63.3815 21.3295C63.3074 21.1427 63.3755 20.9298 63.5438 20.8214C64.1427 20.4318 64.6394 19.8976 64.984 19.267C65.0781 19.0943 65.2784 19.0099 65.4667 19.0642L65.4647 19.0722Z"
                fill="#EE7F93" stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M80.8633 7.01904V13.2445" stroke="#601841" stroke-width="1.05488" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M83.9674 10.1318H77.7578" stroke="#601841" stroke-width="1.05488" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M97.2016 79.6994C96.3804 80.3119 95.467 80.7095 94.5275 80.9103C94.3232 82.7579 93.3858 84.5171 91.7793 85.712C89.8162 87.174 87.3364 87.431 85.2151 86.6177C85.0829 86.7322 84.9487 86.8426 84.8065 86.9491C81.7497 89.2244 77.4371 88.5838 75.1736 85.5192C74.6908 84.8665 74.3423 84.1556 74.1199 83.4186C72.4213 84.483 70.1678 84.0773 68.9559 82.4366C68.5293 81.8582 68.2869 81.1975 68.2168 80.5288L70.8148 74.1567C70.0617 73.5763 69.4507 72.7891 69.0801 71.8372C68.0005 69.0578 69.3706 65.9229 72.1409 64.8345C72.7138 64.6096 73.3007 64.4911 73.8816 64.467C74.785 62.9006 76.2011 61.6173 78.0099 60.9064C82.2465 59.2416 87.0179 61.3382 88.6705 65.5896C89.035 66.5254 89.2153 67.4873 89.2333 68.4372C92.274 66.3527 96.4324 67.0335 98.6419 70.0257C100.903 73.0923 100.26 77.422 97.2016 79.6994Z"
                fill="white" stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M61.2871 79.8062L68.1417 81.2746" stroke="#601841" stroke-width="1.05488" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M97.2016 79.6994C96.3804 80.3119 95.467 80.7095 94.5275 80.9103C94.3232 82.7579 93.3858 84.5171 91.7793 85.712C89.8162 87.174 87.3364 87.431 85.2151 86.6177C85.0829 86.7322 84.9487 86.8426 84.8065 86.9491C81.7497 89.2244 77.4371 88.5838 75.1736 85.5192C74.6908 84.8665 74.3423 84.1556 74.1199 83.4186C72.4213 84.483 70.1678 84.0773 68.9559 82.4366C68.5293 81.8582 68.2869 81.1975 68.2168 80.5288L70.8148 74.1567C70.0617 73.5763 69.4507 72.7891 69.0801 71.8372C68.0005 69.0578 69.3706 65.9229 72.1409 64.8345C72.7138 64.6096 73.3007 64.4911 73.8816 64.467C74.785 62.9006 76.2011 61.6173 78.0099 60.9064C82.2465 59.2416 87.0179 61.3382 88.6705 65.5896C89.035 66.5254 89.2153 67.4873 89.2333 68.4372C92.274 66.3527 96.4324 67.0335 98.6419 70.0257C100.903 73.0923 100.26 77.422 97.2016 79.6994Z"
                fill="white" stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M68.6325 68.4434L61.2871 69.8475" stroke="#601841" stroke-width="1.05488" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M83.9902 80.635C85.2382 82.3259 87.6178 82.6794 89.3044 81.4242C90.991 80.1691 91.3476 77.7793 90.0997 76.0884"
                fill="white"/>
            <path
                d="M83.9902 80.635C85.2382 82.3259 87.6178 82.6794 89.3044 81.4242C90.991 80.1691 91.3476 77.7793 90.0997 76.0884"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M71.1831 74.4162C70.2617 73.8217 69.5105 72.9421 69.0819 71.8376C68.0022 69.0582 69.3723 65.9234 72.1426 64.8349C74.9129 63.7464 78.0337 65.1181 79.1134 67.8974C79.6522 69.2871 79.5801 70.7652 79.0273 72.0324"
                fill="white"/>
            <path
                d="M71.1831 74.4162C70.2617 73.8217 69.5105 72.9421 69.0819 71.8376C68.0022 69.0582 69.3723 65.9234 72.1426 64.8349C74.9129 63.7464 78.0337 65.1181 79.1134 67.8974C79.6522 69.2871 79.5801 70.7652 79.0273 72.0324"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M58.5293 75.103H66.2553" stroke="#601841" stroke-width="1.05488" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M74.1406 83.4908C74.365 84.2017 74.7075 84.8885 75.1742 85.5211C77.4357 88.5877 81.7484 89.2283 84.8071 86.951C86.3856 85.7762 87.319 84.0551 87.5454 82.2417"
                fill="white"/>
            <path
                d="M74.1406 83.4908C74.365 84.2017 74.7075 84.8885 75.1742 85.5211C77.4357 88.5877 81.7484 89.2283 84.8071 86.951C86.3856 85.7762 87.319 84.0551 87.5454 82.2417"
                stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M23.8379 80.9198C24.4769 82.7076 25.9411 84.638 28.3769 84.638C30.6805 84.638 32.2008 82.9928 32.942 81.1467C29.9674 80.2428 26.8025 80.1644 23.8379 80.9198Z"
                fill="#EE7F93" stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M28.3645 77.3068C25.47 77.3309 23.4609 79.7073 23.4609 79.7073C23.4609 79.7073 23.5851 80.2075 23.8395 80.9206C26.8041 80.1653 29.967 80.2437 32.9436 81.1476C33.2982 80.2658 33.4764 79.6129 33.4764 79.6129C33.4764 79.6129 31.261 77.2827 28.3645 77.3068Z"
                fill="white" stroke="#601841" stroke-width="1.05488" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="text">
          <h4>Лікування грипу</h4>
          <p>Дорослі та діти віком від 1 року* з симптомами грипу під час циркуляції вірусу грипу</p>
        </div>
      </div>

      <div class="module-theory__block">
        <div class="icon">
          <svg width="102" height="101" viewBox="0 0 102 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M89.9218 63.1901C104.298 48.7345 104.298 25.2973 89.9218 10.8417C75.5459 -3.6139 52.2379 -3.6139 37.862 10.8417C23.4861 25.2973 23.4861 48.7345 37.862 63.1901C52.2379 77.6457 75.5459 77.6457 89.9218 63.1901Z"
                fill="#E6F2C4"/>
            <path
                d="M80.7048 85.633C87.3392 83.6151 91.0906 76.5711 89.0838 69.8999C87.0769 63.2287 80.0718 59.4566 73.4374 61.4745C66.803 63.4925 63.0516 70.5364 65.0584 77.2076C67.0653 83.8788 74.0704 87.651 80.7048 85.633Z"
                fill="#E6F2C4"/>
            <path
                d="M52.8934 58.5559C53.9419 58.1944 55.252 59.313 55.6435 60.4858C56.0349 61.6586 54.6249 65.5906 54.3034 66.7915C53.6304 69.3118 52.488 70.4786 51.9268 70.818C51.3656 71.1574 50.1893 71.559 49.6641 71.1574"
                fill="white"/>
            <path
                d="M52.8934 58.5559C53.9419 58.1944 55.252 59.313 55.6435 60.4858C56.0349 61.6586 54.6249 65.5906 54.3034 66.7915C53.6304 69.3118 52.488 70.4786 51.9268 70.818C51.3656 71.1574 50.1893 71.559 49.6641 71.1574"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M3.75689 58.5559C2.73236 58.1944 1.4502 59.313 1.06675 60.4858C0.683301 61.6586 2.06332 65.5906 2.37687 66.7915C3.03593 69.3118 4.15232 70.4786 4.70154 70.818C5.25075 71.1574 6.4011 71.559 6.91436 71.1574"
                fill="white"/>
            <path
                d="M3.75689 58.5559C2.73236 58.1944 1.4502 59.313 1.06675 60.4858C0.683301 61.6586 2.06332 65.5906 2.37687 66.7915C3.03593 69.3118 4.15232 70.4786 4.70154 70.818C5.25075 71.1574 6.4011 71.559 6.91436 71.1574"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.1666 82.4624C13.1666 82.4624 14.5626 92.0556 11.0117 100L13.1666 82.4624Z" fill="white"/>
            <path d="M13.1666 82.4624C13.1666 82.4624 14.5626 92.0556 11.0117 100" stroke="#601841"
                  stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M42.9912 83.1851C42.9912 83.1851 42.4 93.5153 45.6554 99.9978L42.9912 83.1851Z" fill="white"/>
            <path d="M42.9912 83.1851C42.9912 83.1851 42.4 93.5153 45.6554 99.9978" stroke="#601841"
                  stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M29.5174 66.67C43.4003 65.6963 53.9255 54.3957 53.0261 41.4294C52.1266 28.4631 40.1432 18.7412 26.2603 19.715C12.3774 20.6887 1.85218 31.9893 2.75161 44.9556C3.65104 57.9219 15.6345 67.6438 29.5174 66.67Z"
                fill="white"/>
            <path
                d="M52.7094 59.8978C52.7094 68.2159 42.6936 74.9596 30.3371 74.9596C17.9807 74.9596 7.96484 68.2159 7.96484 59.8978C7.96484 51.5796 28.5217 36.7407 40.8762 36.7407C53.2307 36.7407 52.7094 51.5796 52.7094 59.8978Z"
                fill="white"/>
            <path
                d="M35.5125 29.235C39.973 24.7498 39.973 17.4778 35.5125 12.9926C31.052 8.50733 23.8201 8.50733 19.3596 12.9926C14.8992 17.4778 14.8991 24.7498 19.3596 29.235C23.8201 33.7202 31.052 33.7202 35.5125 29.235Z"
                fill="white"/>
            <path
                d="M33.4375 54.9795C34.6637 54.6079 40.0021 53.9774 41.2323 54.4955C41.9693 54.8047 44.7373 56.9395 45.4063 57.3732"
                fill="white"/>
            <path
                d="M33.4375 54.9795C34.6637 54.6079 40.0021 53.9774 41.2323 54.4955C41.9693 54.8047 44.7373 56.9395 45.4063 57.3732"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M22.3702 60.8859C21.3976 59.7452 19.8338 59.1347 18.344 59.3135C16.7383 59.5062 14.0801 61.1249 14.0801 61.1249"
                fill="white"/>
            <path
                d="M22.3702 60.8859C21.3976 59.7452 19.8338 59.1347 18.344 59.3135C16.7383 59.5062 14.0801 61.1249 14.0801 61.1249"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M18.6152 63.5747C19.9851 63.5747 21.0956 62.467 21.0956 61.1006C21.0956 59.7342 19.9851 58.6265 18.6152 58.6265C17.2453 58.6265 16.1348 59.7342 16.1348 61.1006C16.1348 62.467 17.2453 63.5747 18.6152 63.5747Z"
                fill="#601841"/>
            <path
                d="M23.665 54.8632C22.4308 54.5158 17.0825 53.9876 15.8622 54.5298C15.1313 54.8531 12.4032 57.0421 11.7441 57.4879"
                fill="white"/>
            <path
                d="M23.665 54.8632C22.4308 54.5158 17.0825 53.9876 15.8622 54.5298C15.1313 54.8531 12.4032 57.0421 11.7441 57.4879"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M35.3952 21.0903C35.6388 20.2067 35.557 20.1685 35.557 19.2045C35.557 13.6136 32.8768 10.2217 27.4944 10.2217C22.112 10.2217 19.8991 13.5192 19.8991 19.1102C19.8991 20.0761 19.8173 20.1123 20.0609 20.9959C12.5635 24.6569 5.43164 33.937 5.43164 43.1106C5.43164 47.5027 6.62195 49.6816 8.68503 53.3828C15.5613 49.7981 33.4519 46.0346 36.8691 33.5333C38.4429 44.1509 44.2686 44.9884 48.0113 50.4909C49.0099 47.7617 49.5571 46.0909 49.5571 43.1086C49.5571 33.935 42.8946 24.7473 35.3972 21.0863L35.3952 21.0903Z"
                fill="#E4B6BC"/>
            <path
                d="M26.5308 34.1002C24.7054 38.685 21.7316 41.2535 18.3484 43.9948C14.9652 46.738 4.31025 48.6499 4.31025 58.191C4.31025 58.191 1.84375 51.369 1.84375 45.6656C1.84375 31.2485 13.0519 19.5625 27.5014 19.5625C41.9509 19.5625 53.159 31.2485 53.159 45.6656C53.159 48.4189 52.4121 56.5262 52.4121 56.5262"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.2943 22.0487C16.2544 21.6591 16.2324 21.3579 16.2324 20.9563C16.2324 14.7026 21.2733 9.63379 27.4925 9.63379C33.7117 9.63379 38.7525 14.7026 38.7525 20.9563C38.7525 21.2977 38.7365 21.5407 38.7086 21.874"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M6.41602 64.2089C6.87736 67.9965 6.78749 71.2498 8.07766 74.8285C10.2586 80.8773 15.4592 84.448 20.4401 88.5247C21.9061 89.7236 23.386 90.9326 25.0716 91.7961C27.3863 92.981 29.2996 92.981 31.6143 91.7961C33.2999 90.9326 34.7798 89.7236 36.2457 88.5247C41.2267 84.45 46.4273 80.8773 48.6082 74.8285C49.8824 71.294 50.164 66.2553 50.6933 62.2891"
                fill="white"/>
            <path
                d="M6.41602 64.2089C6.87736 67.9965 6.78749 71.2498 8.07766 74.8285C10.2586 80.8773 15.4592 84.448 20.4401 88.5247C21.9061 89.7236 23.386 90.9326 25.0716 91.7961C27.3863 92.981 29.2996 92.981 31.6143 91.7961C33.2999 90.9326 34.7798 89.7236 36.2457 88.5247C41.2267 84.45 46.4273 80.8773 48.6082 74.8285C49.8824 71.294 50.164 66.2553 50.6933 62.2891"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M36.5938 32.9033C36.6277 35.4558 37.7261 38.0404 38.547 39.6751C40.2146 43.0028 45.8886 48.0535 47.9057 50.0718C51.0732 53.2408 52.7009 56.452 52.8647 59.2976"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M36.5088 31.2866C37.0381 34.0259 35.9476 37.1266 34.26 39.4521C26.1535 50.6139 4.13867 50.5617 4.13867 60.2013"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M34.7441 60.8854C35.7167 59.7447 37.2805 59.1342 38.7704 59.313C40.3761 59.5058 43.0342 60.8412 43.0342 60.8412"
                fill="white"/>
            <path
                d="M34.7441 60.8854C35.7167 59.7447 37.2805 59.1342 38.7704 59.313C40.3761 59.5058 43.0342 60.8412 43.0342 60.8412"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M38.5 63.5747C39.8699 63.5747 40.9804 62.467 40.9804 61.1006C40.9804 59.7342 39.8699 58.6265 38.5 58.6265C37.1301 58.6265 36.0195 59.7342 36.0195 61.1006C36.0195 62.467 37.1301 63.5747 38.5 63.5747Z"
                fill="#601841"/>
            <path d="M30.7632 73.8604C29.545 75.0753 27.5698 75.0753 26.3516 73.8604H30.7632Z" fill="white"/>
            <path d="M30.7632 73.8604C29.545 75.0753 27.5698 75.0753 26.3516 73.8604" stroke="#601841"
                  stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M33.3841 81.0343C33.3841 81.0343 31.1733 80.6849 28.2874 80.7049C25.4016 80.725 23.3984 81.1186 23.3984 81.1186"
                fill="white"/>
            <path
                d="M33.3841 81.0343C33.3841 81.0343 31.1733 80.6849 28.2874 80.7049C25.4016 80.725 23.3984 81.1186 23.3984 81.1186"
                stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M57.2396 89.8851C60.1129 89.8851 62.4422 87.5429 62.4422 84.6537C62.4422 81.7645 60.1129 79.4224 57.2396 79.4224C54.3664 79.4224 52.0371 81.7645 52.0371 84.6537C52.0371 87.5429 54.3664 89.8851 57.2396 89.8851Z"
                fill="#E6F2C4"/>
            <path d="M77.832 10.6704V52.1707" stroke="#601841" stroke-width="0.974353" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M79.9037 11.1693C80.1787 10.0175 79.4731 8.85962 78.3277 8.5831C77.1822 8.30658 76.0307 9.01612 75.7557 10.1679C75.4807 11.3197 76.1864 12.4776 77.3318 12.7541C78.4773 13.0306 79.6288 12.3211 79.9037 11.1693Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M79.8887 53.2227C80.2047 52.0816 79.5409 50.8989 78.4061 50.5812C77.2713 50.2635 76.0952 50.9309 75.7792 52.072C75.4632 53.2131 76.127 54.3958 77.2618 54.7135C78.3966 55.0313 79.5727 54.3638 79.8887 53.2227Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M98.6621 31.6206H57.3926" stroke="#601841" stroke-width="0.974353" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M92.5596 16.8052L63.377 46.1516" stroke="#601841" stroke-width="0.974353" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M92.5596 46.4328L63.377 17.0884" stroke="#601841" stroke-width="0.974353" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M91.4219 37.7209L96.0214 39.0744L91.7814 36.2669C91.312 35.9556 91.0804 35.3853 91.2082 34.835C91.4499 33.8028 91.5757 32.7264 91.5757 31.6198C91.5757 30.3285 91.4019 29.0794 91.0784 27.8925C90.9126 27.286 91.1802 26.6434 91.7295 26.3422L95.5221 24.2576L91.1023 25.1734C90.5351 25.2898 89.96 25.0308 89.6664 24.5307C88.552 22.6169 86.9982 20.9902 85.1448 19.7933C84.6036 19.4439 84.3939 18.753 84.6335 18.1546L86.4509 13.6079L83.1336 17.7369C82.7901 18.1666 82.2209 18.3433 81.6957 18.1827C80.3995 17.783 79.0235 17.5682 77.5975 17.5682C76.3612 17.5682 75.1609 17.7308 74.0185 18.0341C73.5093 18.1686 72.966 17.9859 72.6345 17.5722L69.451 13.6079L71.1286 17.8031C71.3803 18.4317 71.1286 19.1426 70.5475 19.486C68.1928 20.8717 66.2775 22.9322 65.0612 25.4043L59.5391 24.2596L63.4475 26.4084C63.9948 26.7097 64.2624 27.3483 64.1006 27.9548C63.787 29.1236 63.6193 30.3546 63.6193 31.6238C63.6193 32.893 63.7771 34.0518 64.0726 35.1905C64.2184 35.7528 63.9928 36.3452 63.5094 36.6645L59.8606 39.0804L63.8689 37.8996C64.4701 37.7229 65.1132 37.986 65.4207 38.5342C66.639 40.7011 68.4125 42.5105 70.5495 43.7657C71.1306 44.1071 71.3803 44.82 71.1286 45.4466L69.5709 49.3426L72.6465 45.5128C72.9181 45.1755 73.3635 45.0329 73.7789 45.1514C74.9912 45.4968 76.2714 45.6815 77.5915 45.6815C79.1093 45.6815 80.5693 45.4385 81.9373 44.9887C82.3647 44.8481 82.8321 44.9847 83.1137 45.3361L86.411 49.441L84.6655 45.073C84.4238 44.4685 84.6475 43.7797 85.1907 43.4243C87.128 42.1591 88.7337 40.426 89.8501 38.3836C90.1557 37.8233 90.8048 37.5482 91.4139 37.7289L91.4219 37.7209Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M87.6636 32.2618C88.0173 26.672 83.7975 21.8524 78.2386 21.4968C72.6796 21.1412 67.8865 25.3843 67.5329 30.9741C67.1793 36.5638 71.399 41.3835 76.9579 41.7391C82.5169 42.0947 87.31 37.8515 87.6636 32.2618Z"
                fill="#EE7F93" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M77.804 35.6162C79.172 34.2406 79.172 32.0104 77.804 30.6348C76.436 29.2592 74.218 29.2592 72.85 30.6348C71.482 32.0104 71.482 34.2406 72.85 35.6162C74.218 36.9918 76.436 36.9918 77.804 35.6162Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M83.2104 31.7451C84.1584 30.0493 83.5597 27.9018 81.8732 26.9486C80.1867 25.9954 78.0511 26.5974 77.1031 28.2932C76.1552 29.989 76.7539 32.1365 78.4404 33.0897C80.1268 34.0429 82.2625 33.4409 83.2104 31.7451Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M76.1439 30.9644C77.3637 30.7611 78.1886 29.602 77.9865 28.3754C77.7843 27.1488 76.6316 26.3193 75.4118 26.5226C74.192 26.7258 73.367 27.885 73.5692 29.1115C73.7713 30.3381 74.9241 31.1676 76.1439 30.9644Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M79.2271 37.0166C80.4635 37.0166 81.4659 36.0087 81.4659 34.7654C81.4659 33.5221 80.4635 32.5142 79.2271 32.5142C77.9906 32.5142 76.9883 33.5221 76.9883 34.7654C76.9883 36.0087 77.9906 37.0166 79.2271 37.0166Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M65.3213 17.4068C65.602 16.2564 64.9022 15.095 63.7582 14.8126C62.6142 14.5303 61.4591 15.234 61.1783 16.3843C60.8975 17.5347 61.5973 18.6962 62.7414 18.9785C63.8854 19.2609 65.0405 18.5572 65.3213 17.4068Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M94.6806 17.4083C94.9614 16.2579 94.2616 15.0964 93.1176 14.8141C91.9736 14.5318 90.8185 15.2354 90.5377 16.3858C90.2569 17.5362 90.9567 18.6976 92.1008 18.98C93.2448 19.2623 94.3998 18.5587 94.6806 17.4083Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M100.465 32.2944C100.76 31.1477 100.074 29.9776 98.9339 29.681C97.7934 29.3844 96.6298 30.0736 96.3348 31.2204C96.0399 32.3672 96.7253 33.5373 97.8658 33.8339C99.0063 34.1305 100.17 33.4412 100.465 32.2944Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M59.123 32.2935C59.418 31.1467 58.7325 29.9766 57.5921 29.68C56.4516 29.3834 55.288 30.0727 54.993 31.2195C54.6981 32.3662 55.3835 33.5363 56.524 33.8329C57.6645 34.1295 58.8281 33.4403 59.123 32.2935Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M64.7037 46.9739C65.0132 45.831 64.3426 44.6522 63.206 44.3411C62.0694 44.0299 60.8971 44.7041 60.5877 45.8471C60.2782 46.99 60.9488 48.1688 62.0854 48.4799C63.222 48.7911 64.3943 48.1168 64.7037 46.9739Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M94.4615 46.9749C94.771 45.832 94.1004 44.6532 92.9638 44.342C91.8272 44.0309 90.6549 44.7051 90.3455 45.848C90.036 46.991 90.7066 48.1697 91.8432 48.4809C92.9798 48.7921 94.1521 48.1178 94.4615 46.9749Z"
                fill="white" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M82.5833 75.438L84.3188 75.9481L82.7191 74.8898C82.5414 74.7733 82.4555 74.5564 82.5034 74.3495C82.5933 73.96 82.6432 73.5543 82.6432 73.1366C82.6432 72.6506 82.5773 72.1787 82.4555 71.7308C82.3936 71.5019 82.4934 71.2589 82.7011 71.1465L84.1311 70.3592L82.4635 70.7047C82.2498 70.7488 82.0321 70.6504 81.9222 70.4617C81.5009 69.7387 80.9157 69.1262 80.2167 68.6744C80.013 68.5418 79.9331 68.2828 80.023 68.0558L80.708 66.3408L79.4578 67.8992C79.328 68.0619 79.1143 68.1281 78.9166 68.0679C78.4273 67.9173 77.908 67.8369 77.3708 67.8369C76.9034 67.8369 76.4521 67.8972 76.0207 68.0116C75.829 68.0619 75.6233 67.9936 75.4995 67.8369L74.2992 66.3408L74.9323 67.9233C75.0261 68.1603 74.9323 68.4294 74.7126 68.5579C73.8239 69.08 73.1009 69.8572 72.6436 70.789L70.5605 70.3572L72.0344 71.1685C72.2401 71.283 72.342 71.524 72.2801 71.7509C72.1623 72.1927 72.0983 72.6566 72.0983 73.1346C72.0983 73.6125 72.1583 74.0503 72.2701 74.4801C72.326 74.6909 72.2401 74.9159 72.0584 75.0364L70.6824 75.9481L72.1942 75.5023C72.4219 75.436 72.6635 75.5344 72.7794 75.7412C73.2387 76.5586 73.9077 77.2414 74.7126 77.7153C74.9323 77.8438 75.0261 78.1129 74.9303 78.3499L74.3431 79.8199L75.5035 78.376C75.6053 78.2495 75.7731 78.1953 75.9308 78.2394C76.3882 78.37 76.8715 78.4382 77.3688 78.4382C77.942 78.4382 78.4932 78.3459 79.0084 78.1772C79.1702 78.125 79.3459 78.1772 79.4518 78.3077L80.696 79.856L80.037 78.2073C79.9451 77.9784 80.031 77.7193 80.2347 77.5848C80.9656 77.1088 81.5708 76.4541 81.9921 75.683C82.108 75.4721 82.3516 75.3677 82.5813 75.436L82.5833 75.438Z"
                fill="#EE7F93" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M52.0936 10.035L53.5115 10.4527L52.2034 9.58719C52.0596 9.49079 51.9877 9.31608 52.0277 9.14538C52.1016 8.82608 52.1415 8.49472 52.1415 8.15333C52.1415 7.7557 52.0876 7.37013 51.9877 7.00464C51.9358 6.81787 52.0197 6.61906 52.1894 6.52668L53.3578 5.88406L51.9957 6.16721C51.822 6.20336 51.6442 6.12303 51.5523 5.9684C51.2088 5.37799 50.7295 4.87795 50.1583 4.50844C49.9926 4.39999 49.9267 4.18712 50.0006 4.00237L50.5598 2.60064L49.5372 3.87384C49.4314 4.00638 49.2556 4.06061 49.0939 4.0104C48.6944 3.8879 48.2711 3.82163 47.8297 3.82163C47.4482 3.82163 47.0788 3.87183 46.7273 3.96421C46.5695 4.00638 46.4037 3.95016 46.3019 3.82163L45.3193 2.59863L45.8365 3.89192C45.9144 4.0847 45.8365 4.30561 45.6568 4.41003C44.9298 4.83778 44.3407 5.47237 43.9652 6.23348L42.2637 5.88004L43.4679 6.54275C43.6377 6.63513 43.7196 6.83193 43.6697 7.01869C43.5738 7.38017 43.5219 7.75771 43.5219 8.14931C43.5219 8.54091 43.5698 8.89837 43.6617 9.2478C43.7076 9.42051 43.6377 9.60325 43.4879 9.70166L42.3635 10.4467L43.5998 10.0832C43.7855 10.029 43.9832 10.1093 44.0791 10.278C44.4545 10.9467 45.0017 11.503 45.6608 11.8906C45.8405 11.995 45.9164 12.2159 45.8385 12.4087L45.3592 13.6096L46.3079 12.4288C46.3917 12.3244 46.5296 12.2802 46.6574 12.3183C47.0308 12.4248 47.4263 12.481 47.8337 12.481C48.301 12.481 48.7524 12.4067 49.1738 12.2681C49.3056 12.224 49.4494 12.2681 49.5372 12.3746L50.5538 13.6397L50.0165 12.2922C49.9427 12.1055 50.0106 11.8926 50.1783 11.7842C50.7755 11.3946 51.2707 10.8604 51.6143 10.2298C51.7081 10.0571 51.9078 9.97276 52.0956 10.027L52.0936 10.035Z"
                fill="#EE7F93" stroke="#601841" stroke-width="0.974353" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="text">
          <h4>Профілактика грипу</h4>
          <p>Дорослі та діти віком від 1 року* після контакту з особою з клінічно діагностованим грипом під час
            циркуляції вірусу грипу</p>
        </div>
      </div>

      <div class="module-theory__warn">
        <div class="icon">
          <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.6261 22.3264L17.1436 4.90765C16.6247 4.04539 15.3736 4.04539 14.8547 4.90765L4.37218 22.3264C3.83708 23.2156 4.47814 24.3479 5.51668 24.3479H26.4816C27.5202 24.3479 28.1612 23.2156 27.6261 22.3264ZM19.2324 3.65311C17.7664 1.21719 14.2319 1.21719 12.7659 3.65312L2.28346 21.0719C0.771755 23.5839 2.5828 26.7827 5.51668 26.7827H26.4816C29.4155 26.7827 31.2265 23.5839 29.7148 21.0719L19.2324 3.65311ZM16.0918 7.7024C16.6638 7.7024 17.1276 8.16569 17.1276 8.73719V17.9743C17.1276 18.5458 16.6638 19.0091 16.0918 19.0091C15.5197 19.0091 15.056 18.5458 15.056 17.9743L15.056 8.73719C15.056 8.16569 15.5197 7.7024 16.0918 7.7024ZM16.0983 22.7781C16.6738 22.7781 17.1405 22.312 17.1405 21.7369C17.1405 21.1619 16.6738 20.6957 16.0983 20.6957C15.5227 20.6957 15.056 21.1619 15.056 21.7369C15.056 22.312 15.5227 22.7781 16.0983 22.7781Z"
                  fill="#00A03B"/>
          </svg>
        </div>
        <p>Застосування препарату Агріппа не замінює вакцинацію проти грипу<sup>8</sup></p>
      </div>

      <div class="module-theory__star">*Згідно з інструкцією для медичного застосування лікарського засобу Агріппа,
        препарат застосовують дітям віком від 1 року з масою тіла більше 40 кг, які здатні проковтнути капсулу.
      </div>

      <div class="module-theory__next next-button" @click="nextStep()">
        Далі
      </div>
    </div>

    <div class="module-theory__state" v-show="state === 2">

      <div class="module-theory__title">Дозування лікарського засобу Агріппа залежить від вікової категорії та маси тіла
        пацієнта<sup>8*</sup>
      </div>

      <div class="module-theory__table-title">
        Лікування грипу
      </div>

      <table>
        <thead>
        <tr>
          <td>Дорослі та діти віком від 1 року<br>
            <span>(m >40кг)</span>
          </td>
          <td>Дорослі та діти віком від 1 року<br>з ослабленим імунітетом<br><span>(m >40кг)</span></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="20.4316" y="4.82568" width="8.72727" height="21.6243" rx="4.36364"
                      transform="rotate(45 20.4316 4.82568)"
                      stroke="#751B20" stroke-width="0.818182"/>
                <path d="M18.5791 18.3124L13.1387 12.6895" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>



            <b>1 капсула</b> (75 мг)
              </span>
            </p>
          </td>
          <td>
            <p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="20.4316" y="4.82568" width="8.72727" height="21.6243" rx="4.36364"
                      transform="rotate(45 20.4316 4.82568)"
                      stroke="#751B20" stroke-width="0.818182"/>
                <path d="M18.5791 18.3124L13.1387 12.6895" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>



            <b>1 капсула</b> (75 мг)
            </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55664 16.8408L6.64459 15.1704L8.73254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.55664 16.8408L6.64459 15.1704L8.73254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M7.0625 13.1121C7.84251 10.7606 9.41614 8.76723 11.5034 7.48665C13.5907 6.20607 16.0563 5.72131 18.4615 6.11862C20.8667 6.51593 23.0556 7.76955 24.6386 9.65643C26.2217 11.5433 27.0963 13.9411 27.1068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M7.0625 13.1121C7.84251 10.7606 9.41614 8.76723 11.5034 7.48665C13.5907 6.20607 16.0563 5.72131 18.4615 6.11862C20.8667 6.51593 23.0556 7.76955 24.6386 9.65643C26.2217 11.5433 27.0963 13.9411 27.1068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.8789 6.39502V7.87269" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.8789 24.1094V25.6171" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M23.709 9.48486L23.326 9.86783" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M10.6152 22.6265L10.2875 22.9542" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M9.74805 9.2627L10.131 9.64566" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.8418 22.4043L23.1695 22.732" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M26.2713 19.3228C25.5075 21.5345 23.9667 23.4094 21.9228 24.6138C19.879 25.8183 17.4648 26.2743 15.1097 25.9006C12.7546 25.5269 10.6114 24.3478 9.06132 22.573C7.51124 20.7983 6.65486 18.543 6.64453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M26.2713 19.3228C25.5075 21.5345 23.9667 23.4094 21.9228 24.6138C19.879 25.8183 17.4648 26.2743 15.1097 25.9006C12.7546 25.5269 10.6114 24.3478 9.06132 22.573C7.51124 20.7983 6.65486 18.543 6.64453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M29.2415 15.0155L27.1618 16.8756L24.8906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29.2415 15.0155L27.1618 16.8756L24.8906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M12.3047 19.209C12.1343 19.209 12.0112 19.1726 11.9355 19.1C11.8597 19.0213 11.8219 18.9154 11.8219 18.7823C11.8219 18.6975 11.8439 18.6158 11.8881 18.5372C11.9323 18.4585 11.9954 18.3768 12.0775 18.292L14.094 16.2222C14.4032 15.9075 14.6241 15.62 14.7567 15.3598C14.8955 15.0935 14.965 14.8272 14.965 14.5609C14.965 14.2219 14.8577 13.9647 14.6431 13.7892C14.4285 13.6137 14.1129 13.5259 13.6964 13.5259C13.4439 13.5259 13.2009 13.5623 12.9674 13.6349C12.7339 13.7075 12.5003 13.8255 12.2668 13.9889C12.1721 14.0495 12.0838 14.0767 12.0017 14.0706C11.9197 14.0646 11.8471 14.0343 11.784 13.9799C11.7272 13.9254 11.6893 13.8588 11.6704 13.7801C11.6514 13.7015 11.6546 13.6198 11.6798 13.535C11.7051 13.4503 11.765 13.3777 11.8597 13.3171C12.1185 13.1295 12.4183 12.9843 12.7591 12.8814C13.0999 12.7785 13.4471 12.7271 13.8005 12.7271C14.2612 12.7271 14.6494 12.7967 14.965 12.9359C15.2868 13.069 15.5298 13.2657 15.6939 13.5259C15.858 13.7862 15.9401 14.1039 15.9401 14.4792C15.9401 14.7334 15.8959 14.9815 15.8075 15.2236C15.7255 15.4657 15.5961 15.7138 15.4194 15.968C15.249 16.2161 15.0249 16.4794 14.7472 16.7578L12.9691 18.5473C12.9203 18.5964 12.8348 18.5629 12.8348 18.4948C12.8348 18.453 12.8698 18.4191 12.9129 18.4191H15.8454C15.9843 18.4191 16.0916 18.4524 16.1673 18.519C16.243 18.5856 16.2809 18.6824 16.2809 18.8095C16.2809 18.9366 16.243 19.0365 16.1673 19.1091C16.0916 19.1757 15.9843 19.209 15.8454 19.209H12.3047Z"
                    fill="#751B20"/>
                <path
                    d="M20.6794 19.2725C20.528 19.2725 20.4081 19.2332 20.3197 19.1545C20.2313 19.0698 20.1872 18.9548 20.1872 18.8095V18.3685C20.1872 18.1157 19.9759 17.9108 19.7152 17.9108H17.6499C17.4922 17.9108 17.3659 17.8775 17.2713 17.8109C17.1766 17.7443 17.1292 17.6414 17.1292 17.5022C17.1292 17.4236 17.1482 17.3388 17.186 17.248C17.2302 17.1573 17.306 17.0392 17.4133 16.894L20.1682 13.0539C20.2376 12.951 20.3165 12.8753 20.4049 12.8269C20.4933 12.7724 20.5942 12.7452 20.7078 12.7452C20.8404 12.7452 20.9477 12.7845 21.0297 12.8632C21.1181 12.9359 21.1623 13.0508 21.1623 13.2082V16.6632C21.1623 16.916 21.3736 17.1209 21.6342 17.1209H21.7587C21.9102 17.1209 22.0206 17.1542 22.0901 17.2208C22.1658 17.2874 22.2037 17.3842 22.2037 17.5113C22.2037 17.6445 22.1658 17.7443 22.0901 17.8109C22.0206 17.8775 21.9102 17.9108 21.7587 17.9108H21.6342C21.3736 17.9108 21.1623 18.1157 21.1623 18.3685V18.8095C21.1623 18.9548 21.1181 19.0698 21.0297 19.1545C20.9477 19.2332 20.8309 19.2725 20.6794 19.2725ZM19.7152 17.1209C19.9759 17.1209 20.1872 16.916 20.1872 16.6632V14.0015C20.1872 13.9545 20.2265 13.9163 20.275 13.9163C20.3459 13.9163 20.3876 13.9935 20.3472 14.05L18.0855 17.216C18.0507 17.2647 17.9718 17.2408 17.9718 17.1815C17.9718 17.148 17.9998 17.1209 18.0342 17.1209H19.7152Z"
                    fill="#751B20"/>
              </svg>
              <span>



            <b>2</b> рази на добу
              </span>
            </p>
          </td>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55664 16.8408L6.64459 15.1704L8.73254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.55664 16.8408L6.64459 15.1704L8.73254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M7.0625 13.1121C7.84251 10.7606 9.41614 8.76723 11.5034 7.48665C13.5907 6.20607 16.0563 5.72131 18.4615 6.11862C20.8667 6.51593 23.0556 7.76955 24.6386 9.65643C26.2217 11.5433 27.0963 13.9411 27.1068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M7.0625 13.1121C7.84251 10.7606 9.41614 8.76723 11.5034 7.48665C13.5907 6.20607 16.0563 5.72131 18.4615 6.11862C20.8667 6.51593 23.0556 7.76955 24.6386 9.65643C26.2217 11.5433 27.0963 13.9411 27.1068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.8789 6.39502V7.87269" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.8789 24.1094V25.6171" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M23.709 9.48486L23.326 9.86783" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M10.6152 22.6265L10.2875 22.9542" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M9.74805 9.2627L10.131 9.64566" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.8418 22.4043L23.1695 22.732" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M26.2713 19.3228C25.5075 21.5345 23.9667 23.4094 21.9228 24.6138C19.879 25.8183 17.4648 26.2743 15.1097 25.9006C12.7546 25.5269 10.6114 24.3478 9.06132 22.573C7.51124 20.7983 6.65486 18.543 6.64453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M26.2713 19.3228C25.5075 21.5345 23.9667 23.4094 21.9228 24.6138C19.879 25.8183 17.4648 26.2743 15.1097 25.9006C12.7546 25.5269 10.6114 24.3478 9.06132 22.573C7.51124 20.7983 6.65486 18.543 6.64453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M29.2415 15.0155L27.1618 16.8756L24.8906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29.2415 15.0155L27.1618 16.8756L24.8906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M12.3047 19.209C12.1343 19.209 12.0112 19.1726 11.9355 19.1C11.8597 19.0213 11.8219 18.9154 11.8219 18.7823C11.8219 18.6975 11.8439 18.6158 11.8881 18.5372C11.9323 18.4585 11.9954 18.3768 12.0775 18.292L14.094 16.2222C14.4032 15.9075 14.6241 15.62 14.7567 15.3598C14.8955 15.0935 14.965 14.8272 14.965 14.5609C14.965 14.2219 14.8577 13.9647 14.6431 13.7892C14.4285 13.6137 14.1129 13.5259 13.6964 13.5259C13.4439 13.5259 13.2009 13.5623 12.9674 13.6349C12.7339 13.7075 12.5003 13.8255 12.2668 13.9889C12.1721 14.0495 12.0838 14.0767 12.0017 14.0706C11.9197 14.0646 11.8471 14.0343 11.784 13.9799C11.7272 13.9254 11.6893 13.8588 11.6704 13.7801C11.6514 13.7015 11.6546 13.6198 11.6798 13.535C11.7051 13.4503 11.765 13.3777 11.8597 13.3171C12.1185 13.1295 12.4183 12.9843 12.7591 12.8814C13.0999 12.7785 13.4471 12.7271 13.8005 12.7271C14.2612 12.7271 14.6494 12.7967 14.965 12.9359C15.2868 13.069 15.5298 13.2657 15.6939 13.5259C15.858 13.7862 15.9401 14.1039 15.9401 14.4792C15.9401 14.7334 15.8959 14.9815 15.8075 15.2236C15.7255 15.4657 15.5961 15.7138 15.4194 15.968C15.249 16.2161 15.0249 16.4794 14.7472 16.7578L12.9691 18.5473C12.9203 18.5964 12.8348 18.5629 12.8348 18.4948C12.8348 18.453 12.8698 18.4191 12.9129 18.4191H15.8454C15.9843 18.4191 16.0916 18.4524 16.1673 18.519C16.243 18.5856 16.2809 18.6824 16.2809 18.8095C16.2809 18.9366 16.243 19.0365 16.1673 19.1091C16.0916 19.1757 15.9843 19.209 15.8454 19.209H12.3047Z"
                    fill="#751B20"/>
                <path
                    d="M20.6794 19.2725C20.528 19.2725 20.4081 19.2332 20.3197 19.1545C20.2313 19.0698 20.1872 18.9548 20.1872 18.8095V18.3685C20.1872 18.1157 19.9759 17.9108 19.7152 17.9108H17.6499C17.4922 17.9108 17.3659 17.8775 17.2713 17.8109C17.1766 17.7443 17.1292 17.6414 17.1292 17.5022C17.1292 17.4236 17.1482 17.3388 17.186 17.248C17.2302 17.1573 17.306 17.0392 17.4133 16.894L20.1682 13.0539C20.2376 12.951 20.3165 12.8753 20.4049 12.8269C20.4933 12.7724 20.5942 12.7452 20.7078 12.7452C20.8404 12.7452 20.9477 12.7845 21.0297 12.8632C21.1181 12.9359 21.1623 13.0508 21.1623 13.2082V16.6632C21.1623 16.916 21.3736 17.1209 21.6342 17.1209H21.7587C21.9102 17.1209 22.0206 17.1542 22.0901 17.2208C22.1658 17.2874 22.2037 17.3842 22.2037 17.5113C22.2037 17.6445 22.1658 17.7443 22.0901 17.8109C22.0206 17.8775 21.9102 17.9108 21.7587 17.9108H21.6342C21.3736 17.9108 21.1623 18.1157 21.1623 18.3685V18.8095C21.1623 18.9548 21.1181 19.0698 21.0297 19.1545C20.9477 19.2332 20.8309 19.2725 20.6794 19.2725ZM19.7152 17.1209C19.9759 17.1209 20.1872 16.916 20.1872 16.6632V14.0015C20.1872 13.9545 20.2265 13.9163 20.275 13.9163C20.3459 13.9163 20.3876 13.9935 20.3472 14.05L18.0855 17.216C18.0507 17.2647 17.9718 17.2408 17.9718 17.1815C17.9718 17.148 17.9998 17.1209 18.0342 17.1209H19.7152Z"
                    fill="#751B20"/>
              </svg>
              <span>



            <b>2</b> рази на добу
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6.47745" y="7.86368" width="19.5455" height="16.6364" rx="1.22727" stroke="#751B20"
                      stroke-width="0.818182"/>
                <path d="M11.8867 7.45459L11.8867 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 20.5459L10.4315 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 20.5459L15.522 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 20.5459L19.8865 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M20.6133 7.45459L20.6133 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 16.9092L10.4315 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 16.9092L15.522 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 16.9092L19.8865 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.52344 13.2729H24.978" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>
            <b>5</b> днів
              </span>
            </p>
          </td>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6.47745" y="7.86368" width="19.5455" height="16.6364" rx="1.22727" stroke="#751B20"
                      stroke-width="0.818182"/>
                <path d="M11.8867 7.45459L11.8867 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 20.5459L10.4315 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 20.5459L15.522 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 20.5459L19.8865 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M20.6133 7.45459L20.6133 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 16.9092L10.4315 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 16.9092L15.522 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 16.9092L19.8865 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.52344 13.2729H24.978" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>
            <b>10</b> днів
              </span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="module-theory__table-title">
        Після контакту з хворим на грип<br>
        <span>(постконтактна профілактика)</span>
      </div>

      <table>
        <thead>
        <tr>
          <td>Дорослі та діти віком від 1 року<br>
            <span>(m >40кг)</span>
          </td>
          <td>Дорослі та діти віком від 1 року<br>з ослабленим імунітетом<br><span>(m >40кг)</span></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="2">
            Почати якомога швидше<br>протягом <b>48 годин</b> після контакту
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="20.9316" y="4.82568" width="8.72727" height="21.6243" rx="4.36364"
                      transform="rotate(45 20.9316 4.82568)"
                      stroke="#751B20" stroke-width="0.818182"/>
                <path d="M19.0791 18.3124L13.6387 12.6895" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>


            <b>1 капсула</b> (75 мг)
            </span>
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.80664 16.8408L5.89459 15.1704L7.98254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.80664 16.8408L5.89459 15.1704L7.98254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M6.3125 13.1121C7.09251 10.7606 8.66614 8.76723 10.7534 7.48665C12.8407 6.20607 15.3063 5.72131 17.7115 6.11862C20.1167 6.51593 22.3056 7.76955 23.8886 9.65643C25.4717 11.5433 26.3463 13.9411 26.3568 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M6.3125 13.1121C7.09251 10.7606 8.66614 8.76723 10.7534 7.48665C12.8407 6.20607 15.3063 5.72131 17.7115 6.11862C20.1167 6.51593 22.3056 7.76955 23.8886 9.65643C25.4717 11.5433 26.3463 13.9411 26.3568 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.1289 6.39502V7.87269" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.1289 24.1094V25.6171" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.959 9.48486L22.576 9.86783" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M9.86523 22.6265L9.53751 22.9542" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M8.99805 9.2627L9.38101 9.64566" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0918 22.4043L22.4195 22.732" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M25.5213 19.3228C24.7575 21.5345 23.2167 23.4094 21.1728 24.6138C19.129 25.8183 16.7148 26.2743 14.3597 25.9006C12.0046 25.5269 9.8614 24.3478 8.31132 22.573C6.76124 20.7983 5.90486 18.543 5.89453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M25.5213 19.3228C24.7575 21.5345 23.2167 23.4094 21.1728 24.6138C19.129 25.8183 16.7148 26.2743 14.3597 25.9006C12.0046 25.5269 9.8614 24.3478 8.31132 22.573C6.76124 20.7983 5.90486 18.543 5.89453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M28.4915 15.0155L26.4118 16.8756L24.1406 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.4915 15.0155L26.4118 16.8756L24.1406 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M11.5547 19.209C11.3843 19.209 11.2612 19.1726 11.1855 19.1C11.1097 19.0213 11.0719 18.9154 11.0719 18.7823C11.0719 18.6975 11.0939 18.6158 11.1381 18.5372C11.1823 18.4585 11.2454 18.3768 11.3275 18.292L13.344 16.2222C13.6532 15.9075 13.8741 15.62 14.0067 15.3598C14.1455 15.0935 14.215 14.8272 14.215 14.5609C14.215 14.2219 14.1077 13.9647 13.8931 13.7892C13.6785 13.6137 13.3629 13.5259 12.9464 13.5259C12.6939 13.5259 12.4509 13.5623 12.2174 13.6349C11.9839 13.7075 11.7503 13.8255 11.5168 13.9889C11.4221 14.0495 11.3338 14.0767 11.2517 14.0706C11.1697 14.0646 11.0971 14.0343 11.034 13.9799C10.9772 13.9254 10.9393 13.8588 10.9204 13.7801C10.9014 13.7015 10.9046 13.6198 10.9298 13.535C10.9551 13.4503 11.015 13.3777 11.1097 13.3171C11.3685 13.1295 11.6683 12.9843 12.0091 12.8814C12.3499 12.7785 12.6971 12.7271 13.0505 12.7271C13.5112 12.7271 13.8994 12.7967 14.215 12.9359C14.5368 13.069 14.7798 13.2657 14.9439 13.5259C15.108 13.7862 15.1901 14.1039 15.1901 14.4792C15.1901 14.7334 15.1459 14.9815 15.0575 15.2236C14.9755 15.4657 14.8461 15.7138 14.6694 15.968C14.499 16.2161 14.2749 16.4794 13.9972 16.7578L12.2191 18.5473C12.1703 18.5964 12.0848 18.5629 12.0848 18.4948C12.0848 18.453 12.1198 18.4191 12.1629 18.4191H15.0954C15.2343 18.4191 15.3416 18.4524 15.4173 18.519C15.493 18.5856 15.5309 18.6824 15.5309 18.8095C15.5309 18.9366 15.493 19.0365 15.4173 19.1091C15.3416 19.1757 15.2343 19.209 15.0954 19.209H11.5547Z"
                    fill="#751B20"/>
                <path
                    d="M19.9294 19.2725C19.778 19.2725 19.6581 19.2332 19.5697 19.1545C19.4813 19.0698 19.4372 18.9548 19.4372 18.8095V18.3685C19.4372 18.1157 19.2259 17.9108 18.9652 17.9108H16.8999C16.7422 17.9108 16.6159 17.8775 16.5213 17.8109C16.4266 17.7443 16.3792 17.6414 16.3792 17.5022C16.3792 17.4236 16.3982 17.3388 16.436 17.248C16.4802 17.1573 16.556 17.0392 16.6633 16.894L19.4182 13.0539C19.4876 12.951 19.5665 12.8753 19.6549 12.8269C19.7433 12.7724 19.8442 12.7452 19.9578 12.7452C20.0904 12.7452 20.1977 12.7845 20.2797 12.8632C20.3681 12.9359 20.4123 13.0508 20.4123 13.2082V16.6632C20.4123 16.916 20.6236 17.1209 20.8842 17.1209H21.0087C21.1602 17.1209 21.2706 17.1542 21.3401 17.2208C21.4158 17.2874 21.4537 17.3842 21.4537 17.5113C21.4537 17.6445 21.4158 17.7443 21.3401 17.8109C21.2706 17.8775 21.1602 17.9108 21.0087 17.9108H20.8842C20.6236 17.9108 20.4123 18.1157 20.4123 18.3685V18.8095C20.4123 18.9548 20.3681 19.0698 20.2797 19.1545C20.1977 19.2332 20.0809 19.2725 19.9294 19.2725ZM18.9652 17.1209C19.2259 17.1209 19.4372 16.916 19.4372 16.6632V14.0015C19.4372 13.9545 19.4765 13.9163 19.525 13.9163C19.5959 13.9163 19.6376 13.9935 19.5972 14.05L17.3355 17.216C17.3007 17.2647 17.2218 17.2408 17.2218 17.1815C17.2218 17.148 17.2498 17.1209 17.2842 17.1209H18.9652Z"
                    fill="#751B20"/>
              </svg>
              <span>


            <b>1</b> раз на добу
            </span>
            </p>
          </td>

        </tr>
        <tr>
          <td colspan="2">
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6.47745" y="7.86368" width="19.5455" height="16.6364" rx="1.22727" stroke="#751B20"
                      stroke-width="0.818182"/>
                <path d="M11.8867 7.45459L11.8867 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 20.5459L10.4315 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 20.5459L15.522 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 20.5459L19.8865 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M20.6133 7.45459L20.6133 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 16.9092L10.4315 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 16.9092L15.522 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 16.9092L19.8865 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.52344 13.2729H24.978" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>

            <b>10</b> днів
            </span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="module-theory__table-title">
        Сезонна профілактика грипу**
      </div>

      <table>
        <thead>
        <tr>
          <td>Дорослі та діти віком від 1 року<br>
            <span>(m >40кг)</span>
          </td>
          <td>Дорослі та діти віком від 1 року<br>з ослабленим імунітетом<br><span>(m >40кг)</span></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="20.4316" y="4.82568" width="8.72727" height="21.6243" rx="4.36364"
                      transform="rotate(45 20.4316 4.82568)"
                      stroke="#751B20" stroke-width="0.818182"/>
                <path d="M18.5791 18.3124L13.1387 12.6895" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>


            <b>1 капсула</b> (75 мг)
            </span>
            </p>
          </td>
          <td>
            <p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="20.4316" y="4.82568" width="8.72727" height="21.6243" rx="4.36364"
                      transform="rotate(45 20.4316 4.82568)"
                      stroke="#751B20" stroke-width="0.818182"/>
                <path d="M18.5791 18.3124L13.1387 12.6895" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>

            <b>1 капсула</b> (75 мг)
            </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.05664 16.8408L6.14459 15.1704L8.23254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.05664 16.8408L6.14459 15.1704L8.23254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M6.5625 13.1121C7.34251 10.7606 8.91614 8.76723 11.0034 7.48665C13.0907 6.20607 15.5563 5.72131 17.9615 6.11862C20.3667 6.51593 22.5556 7.76955 24.1386 9.65643C25.7217 11.5433 26.5963 13.9411 26.6068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M6.5625 13.1121C7.34251 10.7606 8.91614 8.76723 11.0034 7.48665C13.0907 6.20607 15.5563 5.72131 17.9615 6.11862C20.3667 6.51593 22.5556 7.76955 24.1386 9.65643C25.7217 11.5433 26.5963 13.9411 26.6068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.3789 6.39502V7.87269" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.3789 24.1094V25.6171" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M23.209 9.48486L22.826 9.86783" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M10.1152 22.6265L9.78751 22.9542" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M9.24805 9.2627L9.63101 9.64566" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.3418 22.4043L22.6695 22.732" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M25.7713 19.3228C25.0075 21.5345 23.4667 23.4094 21.4228 24.6138C19.379 25.8183 16.9648 26.2743 14.6097 25.9006C12.2546 25.5269 10.1114 24.3478 8.56132 22.573C7.01124 20.7983 6.15486 18.543 6.14453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M25.7713 19.3228C25.0075 21.5345 23.4667 23.4094 21.4228 24.6138C19.379 25.8183 16.9648 26.2743 14.6097 25.9006C12.2546 25.5269 10.1114 24.3478 8.56132 22.573C7.01124 20.7983 6.15486 18.543 6.14453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M28.7415 15.0155L26.6618 16.8756L24.3906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.7415 15.0155L26.6618 16.8756L24.3906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M11.8047 19.209C11.6343 19.209 11.5112 19.1726 11.4355 19.1C11.3597 19.0213 11.3219 18.9154 11.3219 18.7823C11.3219 18.6975 11.3439 18.6158 11.3881 18.5372C11.4323 18.4585 11.4954 18.3768 11.5775 18.292L13.594 16.2222C13.9032 15.9075 14.1241 15.62 14.2567 15.3598C14.3955 15.0935 14.465 14.8272 14.465 14.5609C14.465 14.2219 14.3577 13.9647 14.1431 13.7892C13.9285 13.6137 13.6129 13.5259 13.1964 13.5259C12.9439 13.5259 12.7009 13.5623 12.4674 13.6349C12.2339 13.7075 12.0003 13.8255 11.7668 13.9889C11.6721 14.0495 11.5838 14.0767 11.5017 14.0706C11.4197 14.0646 11.3471 14.0343 11.284 13.9799C11.2272 13.9254 11.1893 13.8588 11.1704 13.7801C11.1514 13.7015 11.1546 13.6198 11.1798 13.535C11.2051 13.4503 11.265 13.3777 11.3597 13.3171C11.6185 13.1295 11.9183 12.9843 12.2591 12.8814C12.5999 12.7785 12.9471 12.7271 13.3005 12.7271C13.7612 12.7271 14.1494 12.7967 14.465 12.9359C14.7868 13.069 15.0298 13.2657 15.1939 13.5259C15.358 13.7862 15.4401 14.1039 15.4401 14.4792C15.4401 14.7334 15.3959 14.9815 15.3075 15.2236C15.2255 15.4657 15.0961 15.7138 14.9194 15.968C14.749 16.2161 14.5249 16.4794 14.2472 16.7578L12.4691 18.5473C12.4203 18.5964 12.3348 18.5629 12.3348 18.4948C12.3348 18.453 12.3698 18.4191 12.4129 18.4191H15.3454C15.4843 18.4191 15.5916 18.4524 15.6673 18.519C15.743 18.5856 15.7809 18.6824 15.7809 18.8095C15.7809 18.9366 15.743 19.0365 15.6673 19.1091C15.5916 19.1757 15.4843 19.209 15.3454 19.209H11.8047Z"
                    fill="#751B20"/>
                <path
                    d="M20.1794 19.2725C20.028 19.2725 19.9081 19.2332 19.8197 19.1545C19.7313 19.0698 19.6872 18.9548 19.6872 18.8095V18.3685C19.6872 18.1157 19.4759 17.9108 19.2152 17.9108H17.1499C16.9922 17.9108 16.8659 17.8775 16.7713 17.8109C16.6766 17.7443 16.6292 17.6414 16.6292 17.5022C16.6292 17.4236 16.6482 17.3388 16.686 17.248C16.7302 17.1573 16.806 17.0392 16.9133 16.894L19.6682 13.0539C19.7376 12.951 19.8165 12.8753 19.9049 12.8269C19.9933 12.7724 20.0942 12.7452 20.2078 12.7452C20.3404 12.7452 20.4477 12.7845 20.5297 12.8632C20.6181 12.9359 20.6623 13.0508 20.6623 13.2082V16.6632C20.6623 16.916 20.8736 17.1209 21.1342 17.1209H21.2587C21.4102 17.1209 21.5206 17.1542 21.5901 17.2208C21.6658 17.2874 21.7037 17.3842 21.7037 17.5113C21.7037 17.6445 21.6658 17.7443 21.5901 17.8109C21.5206 17.8775 21.4102 17.9108 21.2587 17.9108H21.1342C20.8736 17.9108 20.6623 18.1157 20.6623 18.3685V18.8095C20.6623 18.9548 20.6181 19.0698 20.5297 19.1545C20.4477 19.2332 20.3309 19.2725 20.1794 19.2725ZM19.2152 17.1209C19.4759 17.1209 19.6872 16.916 19.6872 16.6632V14.0015C19.6872 13.9545 19.7265 13.9163 19.775 13.9163C19.8459 13.9163 19.8876 13.9935 19.8472 14.05L17.5855 17.216C17.5507 17.2647 17.4718 17.2408 17.4718 17.1815C17.4718 17.148 17.4998 17.1209 17.5342 17.1209H19.2152Z"
                    fill="#751B20"/>
              </svg>
              <span>


            <b>1</b> раз на добу
            </span>
            </p>
          </td>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.05664 16.8408L6.14459 15.1704L8.23254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.05664 16.8408L6.14459 15.1704L8.23254 16.8408" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M6.5625 13.1121C7.34251 10.7606 8.91614 8.76723 11.0034 7.48665C13.0907 6.20607 15.5563 5.72131 17.9615 6.11862C20.3667 6.51593 22.5556 7.76955 24.1386 9.65643C25.7217 11.5433 26.5963 13.9411 26.6068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M6.5625 13.1121C7.34251 10.7606 8.91614 8.76723 11.0034 7.48665C13.0907 6.20607 15.5563 5.72131 17.9615 6.11862C20.3667 6.51593 22.5556 7.76955 24.1386 9.65643C25.7217 11.5433 26.5963 13.9411 26.6068 16.4231"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.3789 6.39502V7.87269" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.3789 24.1094V25.6171" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M23.209 9.48486L22.826 9.86783" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M10.1152 22.6265L9.78751 22.9542" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M9.24805 9.2627L9.63101 9.64566" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.3418 22.4043L22.6695 22.732" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M25.7713 19.3228C25.0075 21.5345 23.4667 23.4094 21.4228 24.6138C19.379 25.8183 16.9648 26.2743 14.6097 25.9006C12.2546 25.5269 10.1114 24.3478 8.56132 22.573C7.01124 20.7983 6.15486 18.543 6.14453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path
                    d="M25.7713 19.3228C25.0075 21.5345 23.4667 23.4094 21.4228 24.6138C19.379 25.8183 16.9648 26.2743 14.6097 25.9006C12.2546 25.5269 10.1114 24.3478 8.56132 22.573C7.01124 20.7983 6.15486 18.543 6.14453 16.2085V15.1704"
                    stroke="#751B20" stroke-width="0.818182" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M28.7415 15.0155L26.6618 16.8756L24.3906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.7415 15.0155L26.6618 16.8756L24.3906 15.2549" stroke="#751B20" stroke-width="0.818182"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M11.8047 19.209C11.6343 19.209 11.5112 19.1726 11.4355 19.1C11.3597 19.0213 11.3219 18.9154 11.3219 18.7823C11.3219 18.6975 11.3439 18.6158 11.3881 18.5372C11.4323 18.4585 11.4954 18.3768 11.5775 18.292L13.594 16.2222C13.9032 15.9075 14.1241 15.62 14.2567 15.3598C14.3955 15.0935 14.465 14.8272 14.465 14.5609C14.465 14.2219 14.3577 13.9647 14.1431 13.7892C13.9285 13.6137 13.6129 13.5259 13.1964 13.5259C12.9439 13.5259 12.7009 13.5623 12.4674 13.6349C12.2339 13.7075 12.0003 13.8255 11.7668 13.9889C11.6721 14.0495 11.5838 14.0767 11.5017 14.0706C11.4197 14.0646 11.3471 14.0343 11.284 13.9799C11.2272 13.9254 11.1893 13.8588 11.1704 13.7801C11.1514 13.7015 11.1546 13.6198 11.1798 13.535C11.2051 13.4503 11.265 13.3777 11.3597 13.3171C11.6185 13.1295 11.9183 12.9843 12.2591 12.8814C12.5999 12.7785 12.9471 12.7271 13.3005 12.7271C13.7612 12.7271 14.1494 12.7967 14.465 12.9359C14.7868 13.069 15.0298 13.2657 15.1939 13.5259C15.358 13.7862 15.4401 14.1039 15.4401 14.4792C15.4401 14.7334 15.3959 14.9815 15.3075 15.2236C15.2255 15.4657 15.0961 15.7138 14.9194 15.968C14.749 16.2161 14.5249 16.4794 14.2472 16.7578L12.4691 18.5473C12.4203 18.5964 12.3348 18.5629 12.3348 18.4948C12.3348 18.453 12.3698 18.4191 12.4129 18.4191H15.3454C15.4843 18.4191 15.5916 18.4524 15.6673 18.519C15.743 18.5856 15.7809 18.6824 15.7809 18.8095C15.7809 18.9366 15.743 19.0365 15.6673 19.1091C15.5916 19.1757 15.4843 19.209 15.3454 19.209H11.8047Z"
                    fill="#751B20"/>
                <path
                    d="M20.1794 19.2725C20.028 19.2725 19.9081 19.2332 19.8197 19.1545C19.7313 19.0698 19.6872 18.9548 19.6872 18.8095V18.3685C19.6872 18.1157 19.4759 17.9108 19.2152 17.9108H17.1499C16.9922 17.9108 16.8659 17.8775 16.7713 17.8109C16.6766 17.7443 16.6292 17.6414 16.6292 17.5022C16.6292 17.4236 16.6482 17.3388 16.686 17.248C16.7302 17.1573 16.806 17.0392 16.9133 16.894L19.6682 13.0539C19.7376 12.951 19.8165 12.8753 19.9049 12.8269C19.9933 12.7724 20.0942 12.7452 20.2078 12.7452C20.3404 12.7452 20.4477 12.7845 20.5297 12.8632C20.6181 12.9359 20.6623 13.0508 20.6623 13.2082V16.6632C20.6623 16.916 20.8736 17.1209 21.1342 17.1209H21.2587C21.4102 17.1209 21.5206 17.1542 21.5901 17.2208C21.6658 17.2874 21.7037 17.3842 21.7037 17.5113C21.7037 17.6445 21.6658 17.7443 21.5901 17.8109C21.5206 17.8775 21.4102 17.9108 21.2587 17.9108H21.1342C20.8736 17.9108 20.6623 18.1157 20.6623 18.3685V18.8095C20.6623 18.9548 20.6181 19.0698 20.5297 19.1545C20.4477 19.2332 20.3309 19.2725 20.1794 19.2725ZM19.2152 17.1209C19.4759 17.1209 19.6872 16.916 19.6872 16.6632V14.0015C19.6872 13.9545 19.7265 13.9163 19.775 13.9163C19.8459 13.9163 19.8876 13.9935 19.8472 14.05L17.5855 17.216C17.5507 17.2647 17.4718 17.2408 17.4718 17.1815C17.4718 17.148 17.4998 17.1209 17.5342 17.1209H19.2152Z"
                    fill="#751B20"/>
              </svg>
              <span>


            <b>1</b> раз на добу
            </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6.47745" y="7.86368" width="19.5455" height="16.6364" rx="1.22727" stroke="#751B20"
                      stroke-width="0.818182"/>
                <path d="M11.8867 7.45459L11.8867 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 20.5459L10.4315 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 20.5459L15.522 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 20.5459L19.8865 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M20.6133 7.45459L20.6133 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 16.9092L10.4315 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 16.9092L15.522 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 16.9092L19.8865 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.52344 13.2729H24.978" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>

            <b>6</b> тижнів
            </span>
            </p>
          </td>
          <td>
            <p>
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6.47745" y="7.86368" width="19.5455" height="16.6364" rx="1.22727" stroke="#751B20"
                      stroke-width="0.818182"/>
                <path d="M11.8867 7.45459L11.8867 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 20.5459L10.4315 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 20.5459L15.522 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 20.5459L19.8865 20.5459" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M20.6133 7.45459L20.6133 6.00004" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M12.6133 16.9092L10.4315 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M16.9766 16.9092L15.522 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M22.0684 16.9092L19.8865 16.9092" stroke="#751B20" stroke-width="0.818182"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.52344 13.2729H24.978" stroke="#751B20" stroke-width="0.818182" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>
            до <b>12</b> тижнів
            </span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="module-theory__star">*Згідно з інструкцією для медичного застосування лікарського засобу Агріппа,
        препарат застосовують дітям віком від 1 року з масою тіла більше 40 кг, які здатні проковтнути капсулу.
      </div>
      <div class="module-theory__star m-t">**Профілактика під час сезонної епідемії грипу в дітей віком до 12 років не
        досліджувалася.
      </div>

      <div class="module-theory__next next-button" @click="nextState(6, 'next')">
        Далі
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory5",
  data() {
    return {
      state: 1,
    };
  },
  methods: {
    previousStep() {
      this.state -= 1;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    },
    nextStep() {
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.6  - Раздел "Показання та дозування Агріппи" - Э.${this.state}'`
      });
      this.state += 1;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    },
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.6  - Раздел "Показання та дозування Агріппи" '
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      }, 100)
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$fontSB: "Commissioner-SemiBold";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
  }

  .next-button {
    width: 262px;
    height: 64px;
    margin: 48px auto 0 auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-position: 110% center;
    }
  }

  &__back {
    margin-bottom: 16px;
    padding-left: 22px;
    background-image: url("~@/assets/img/training-module4/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #9f9f9f;
    cursor: pointer;
  }

  &__text {
    color: var(--Black, #545454);
    font-family: $fontSB;
    font-size: 16px;
    line-height: 120%; /* 19.2px */
    margin-bottom: 16px;
  }

  &__block {
    display: flex;
    align-items: center;
    padding: 40px;

    border-radius: 20px;
    background: #FAF5E9;

    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      max-width: 100px;
      width: 100%;
      height: auto;
      margin-right: 20px;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .text {
      max-width: 626px;
      width: 100%;

      h4 {
        color: var(--751-b-20, #751B20);
        font-family: $fontSB;
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        color: var(--Black, #545454);
        font-family: $fontR;
        font-size: 18px;
      }
    }
  }

  &__warn {
    padding: 16px;
    display: flex;
    align-items: center;
    border: 1px solid var(--Green, #00A03B);
    margin-top: 32px;

    p {
      color: var(--Green, #00A03B);
      font-family: $fontSB;
      font-size: 16px;
      line-height: 120%; /* 19.2px */
    }

    .icon {
      max-width: 32px;
      width: 100%;
      height: auto;
      margin-right: 12px;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  &__star {
    margin-top: 32px;

    color: var(--Black, #545454);

    /* H5 */
    font-family: $fontR;
    font-size: 14px;
    line-height: 120%; /* 16.8px */

    &.m-t {
      margin-top: 12px;
    }
  }

  &__table-title {
    color: var(--Black, #545454);
    font-family: $fontSB;
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 32px;
    line-height: .9em;

    span {
      color: var(--Black, #545454);
      font-family: $fontR;
      font-size: 16px;
      line-height: 120%; /* 19.2px */
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    thead {

      td {
        width: 50%;
        background: #F1D8D9;
        padding: 20px 10px;

        color: var(--751-b-20, #751B20);
        text-align: center;
        font-family: $fontSB;
        font-size: 16px;
        border-right: 1px solid white;

        span {
          color: var(--Black, #545454);
          text-align: center;
          font-family: $fontR;
          font-size: 16px;
        }
      }
    }

    tbody {
      tr {

        background: #FAF5E9;
        border: none;
      }

      td {
        text-align: center;
        padding: 4px 10px;
        border-right: 1px solid white;
        color: var(--Black, #545454);
        font-family: $fontR;
        font-size: 16px;

        b {
          color: var(--Black, #545454);
          font-family: $fontSB;
          font-size: 16px;
        }

        p {
          display: flex;
          align-items: center;
          justify-content: center;


          svg {
            width: 32px;
            margin-right: 4px;
            display: block;
            height: auto;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 767px) {

  .module-theory {

    &__back {
      font-size: 12px;
      line-height: 15px;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 32px auto 0 auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }

    &__text {
      font-size: 14px;
      margin-bottom: 9px;
    }

    &__block {
      padding: 16px;
      border-radius: 16px;

      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        max-width: 60px;
        margin-right: 16px;

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .text {
        max-width: 626px;
        width: 100%;

        h4 {
          font-size: 16px;
          margin-bottom: 2px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    &__warn {
      margin-top: 24px;

      p {
        font-size: 14px;
      }

      .icon {
        max-width: 32px;
        width: 100%;
        height: auto;
        margin-right: 12px;

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    &__star {
      margin-top: 24px;

      font-size: 12px;

      &.m-t {
        margin-top: 8px;
      }
    }

    &__table-title {
      font-size: 16px;

      margin-bottom: 12px;
      margin-top: 24px;

      span {
        font-size: 14px;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      thead {

        td {
          padding: 10px;

          font-size: 14px;

          span {
            font-size: 13px;
          }
        }
      }

      tbody {

        td {
          font-size: 13px;

          b {
            font-size: 13px;
          }

          p {
            svg {
              width: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
