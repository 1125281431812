<template>
  <div class="section-video-360">
    <div class="video-block">
      <iframe
          ref="video"
          width="560"
          height="315"
          :src="`${content.url}`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
      ></iframe>

      <img
          v-if="previewShow"
          class="preview"
          :src="`${$root.globalUrl}${content.preview}`"
          alt=""
      />

      <div v-if="previewShow" @click="triggerVideo()" class="play-btn">
        <svg

            viewBox="0 0 138 138"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="69" cy="69" r="69" fill="white" fill-opacity="0.4"/>
          <path
              d="M85 67.5358C87.6667 69.0754 87.6667 72.9244 85 74.464L61 88.3204C58.3333 89.86 55 87.9355 55 84.8563L55 57.1435C55 54.0643 58.3333 52.1398 61 53.6794L85 67.5358Z"
              fill="white"
          />
        </svg>
      </div>
    </div>

    <div class="link-mob" @click="openYouTube()"></div>
  </div>
</template>

<script>
export default {
  name: "section-video-360",

  props: {
    content: Object
  },

  data: () => ({
    nameSection: "video_360",
    previewShow: true
  }),
  methods: {
    triggerVideo() {
      this.previewShow = !this.previewShow;
      this.$refs.video.src += "?&autoplay=1";
    },
    openYouTube() {
      if (window.matchMedia("(max-width: 768px)").matches) {
        window.location = this.content.url;
      }
    }
  },
  mounted() {
    let url_string = window.location.href;

    let url = new URL(url_string);
    let c = url.searchParams.get("video");

    let myVideo = document.getElementsByClassName("section-video-360")[0];
    let elemV = myVideo.getBoundingClientRect().top;
    let header = document.querySelector('.header-block').offsetHeight;
    const offsetPos = elemV - header;

    const embedId = this.content.url.replace('https://www.youtube.com/embed/', '');
    if (c === embedId) {
      window.scrollBy({
        top: offsetPos,
        behavior: 'smooth'
      })
    }
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;
.section-video-360 {
  margin: 10px 0;
  width: 100%;
  position: relative;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 10px -15px;
    width: auto;
  }

  .video-block {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    .play-btn {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;

      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: flex;

      svg {
        width: 138px;
        height: auto;
        display: block;
        @media screen and (max-width: 670px) {
          width: 100px;
        }
      }
    }

    .preview {
      width: 100%;
      height: auto;
      display: block;

      position: absolute;
      left: 0;
      top: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .link-mob {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  iframe {
    width: 100%;
    display: block;
    min-height: 464px;
    @media screen and (max-width: 425px) {
      min-height: 180px;
    }
  }
}
</style>
