<template>
  <div class="module-theory">
    <div class="module-theory__title" v-if="state < 4">
      Біль - ключовий прояв остеоартриту колінного суглоба<sup>1</sup>
    </div>

    <template v-for="(question, questionkey) in questions">
      <div
        class="module-question"
        :key="questionkey"
        v-show="questionkey + 1 === state"
      >
        <div class="module-question__row">
          <div class="module-question__col-left">
            <div class="module-question__image">
              <img
                :src="
                  require(`@/assets/img/training-module2/theme-1/step-1/${question.image}`)
                "
                alt=""
              />
            </div>
          </div>
          <div class="module-question__col-right">
            <div class="module-question__detail">
              <div
                class="module-question__detail-title"
                v-html="question.title"
              ></div>
              <div class="module-question__detail-buttons">
                <div
                  class="module-question__detail-btn"
                  v-for="(answer, answerKey) in question.answers"
                  :key="answerKey"
                  @click="selectAnswer(questionkey, answerKey)"
                  :class="{
                    error: answer.isSelected && !answer.isCorrect,
                    success: answer.isSelected && answer.isCorrect
                  }"
                >
                  {{ answer.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="module-question" v-show="state === 3">
      <div class="module-question__row">
        <div class="module-question__col-left">
          <div class="module-question__image">
            <img
              src="@/assets/img/training-module2/theme-1/step-1/3.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="module-question__col-right">
          <div class="module-question__answer">
            <div class="module-question__answer-title">
              З віком поширеність остеоартриту збільшується і складає 85% у
              пацієнтів старше 75 років.<sup>1</sup>
            </div>
            <div
              class="module-question__answer-next next-button"
              @click="changeState()"
            >
              Далі
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="module-detail" v-show="state === 4">
      <div class="module-theory__title">
        Клієнт найчастіше скаржиться на:
      </div>
      <div class="module-theory__list">
        <ul>
          <li>Короткочасне обмеження руху суглобу зранку</li>
          <li>Набряк навколо суглобу</li>
          <li><b>Біль</b> у суглобі під час руху</li>
        </ul>
      </div>
      <div class="module-theory__image">
        <img
          class="module-theory__image-desktop"
          src="@/assets/img/training-module2/theme-1/step-1/4-desktop.svg"
          alt=""
        />
        <img
          class="module-theory__image-mobile"
          src="@/assets/img/training-module2/theme-1/step-1/4-mobile.svg"
          alt=""
        />
      </div>
      <div
        class="module-theory__next next-button"
        @click="nextState(2, 'next')"
      >
        Далі
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Theory1",
  data() {
    return {
      state: 1,
      questions: [
        {
          title:
            "Спробуйте свої сили. <br /> Відгадайте, який вік є типовим для <br /> клієнта з остеоартритом<sup>1</sup>:",
          image: "1.jpg",
          answers: [
            {
              title: "До 55",
              isCorrect: false,
              isSelected: false
            },
            {
              title: "Старше 55",
              isCorrect: true,
              isSelected: false
            }
          ]
        },
        {
          title:
            "Спробуйте свої сили. <br /> Відгадайте, чи є зайва вага типовою <br /> для клієнта з остеоартритом<sup>1</sup>:",
          image: "2.jpg",
          answers: [
            {
              title: "Так",
              isCorrect: true,
              isSelected: false
            },
            {
              title: "Ні",
              isCorrect: false,
              isSelected: false
            }
          ]
        }
      ]
    };
  },
  methods: {
    selectAnswer(questionkey, answerKey) {
      this.questions.forEach(question => {
        question.answers.forEach(answer => {
          answer.isSelected = false;
        });
      });
      this.questions[questionkey].answers[answerKey].isSelected = true;
      if (this.questions[questionkey].answers[answerKey].isCorrect) {
        setTimeout(() => {
          this.state += 1;
        }, 500);
      }

      gtag("event", "click", {
        event_category: `Выбор "${this.questions[questionkey].answers[answerKey].title}"`,
        event_label: `Т.1 - Раздел "Остеоартрит" - Э.${questionkey + 1}`
      });
    },
    changeState() {
      this.state += 1;
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.1 - Раздел "Остеоартрит" - Э.3'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.1 - Раздел "Остеоартрит" - Э.4'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__list {
    margin-bottom: 32px;
    ul {
      padding-left: 20px;
      margin: 0;
      li {
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
      }
    }
  }
  &__image {
    margin-bottom: 48px;
    img {
      max-width: 100%;
    }
    &-desktop {
      display: block;
    }
    &-mobile {
      display: none;
    }
  }
  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}
.module-question {
  padding-top: 32px;
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 45%;
      max-width: 45%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 55%;
      max-width: 55%;
      padding: 0 10px;
    }
  }
  &__image {
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__detail {
    max-width: 345px;
    text-align: center;
    &-title {
      margin-bottom: 21px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #00a03b;
      ::v-deep {
        sup {
          font-family: $fontDemi;
        }
      }
    }
    &-buttons {
      display: flex;
      justify-content: center;
    }
    &-btn {
      width: 124px;
      height: 44px;
      margin: 0 8px;
      background: rgba(228, 234, 237, 0.4);
      border: 1px solid #00a03b;
      border-radius: 80px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 43px;
      text-align: center;
      color: #005984;
      cursor: pointer;
      &.error {
        background: #fb3449;
        border-color: #fb3449;
        color: #fff;
      }
      &.success {
        background: #00a03b;
        color: #fff;
      }
    }
  }
  &__answer {
    text-align: center;
    &-title {
      margin-bottom: 32px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
      ::v-deep {
        sup {
          font-family: $fontDemi;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
    }
    &__list {
      ul {
        li {
          font-family: $fontR;
          font-size: 14px;
          line-height: 17px;
          b {
            font-family: $fontDemi;
          }
        }
      }
    }
    &__image {
      margin-bottom: 32px;
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
        width: 100%;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
  .module-question {
    padding-top: 0;
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__image {
      img {
        width: 100%;
      }
    }
    &__detail {
      max-width: 95%;
      margin: -50px auto 0;
      padding: 32px 12px;
      background: #fff;
    }
    &__answer {
      padding-top: 16px;
      &-title {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
</style>
