<template>
  <div class="section-image_left_text_with_background_right">
    <div
      class="item item-left-img"
      v-bind:style="{ background: content.color }"
    >
      <img :src="`${$root.globalUrl}${content.file}`" alt="" />

      <div class="text">
        <h3 v-html="content.title" v-if="content.title !== ''"></h3>
        <div class="text-wrapper" v-html="content.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-background-text-img",

  props: {
    content: Object
  },

  data: () => ({
    nameSection: "image_left_text_with_background_right"
  })
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-image_left_text_with_background_right {
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  .item {
    background: #00a03b;
    width: 100%;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 670px) {
    }

    @media screen and (max-width: 560px) {
      flex-direction: column;
    }

    @media screen and (max-width: 425px) {
      width: auto !important;
      margin: 15px -15px !important;
    }

    img {
      display: block;
      max-width: 400px;
      min-width: 280px;
      width: 100%;
      height: auto;
      @media screen and (max-width: 560px) {
        max-width: 560px;
      }
    }

    .text {
      max-width: 430px;
      min-width: 10px;
      margin: 25px 0 0 0;
      padding: 25px 25px 0 25px;
      position: relative;
      @media screen and (max-width: 1024px) {
        max-width: 550px;
      }

      &:before {
        content: "";
        width: 41px;
        height: 3px;
        background: white;
        position: absolute;
        left: 25px;
        top: 0;
      }

      h3 {
        color: white;
        font-family: $fontDemi;
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 15px;
        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }

      p {
        color: white;
        font-size: 16px;
        font-family: $fontR;
        margin-bottom: 15px;
        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
