<template>
  <div class="section-diagram-type1">
    <div class="img-diagram">
      <img :src="`${$root.globalUrl}${content.file}`" alt="" />
    </div>

    <div class="description">
      <h3 v-html="content.title"></h3>

      <div class="text-wrapper" v-html="content.description"></div>

      <div class="img-diagram img-diagram-mobile">
        <img :src="`${$root.globalUrl}${content.file}`" alt="" />
      </div>

      <div class="diagram-history">
        <div
          class="text-item"
          v-for="(key, value) in content.blocks"
          v-if="key.title !== ''"
        >
          <span :style="{ background: key.color }"></span>
          <p v-html="'— ' + key.title"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-diagram-type1",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "diagram_type_1"
  })
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;
.section-diagram-type1 {
  margin: 10px 0;
  display: flex;

  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  > div {
    max-width: 400px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    &.img-diagram {
      @media screen and (max-width: 425px) {
        display: none;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &.description {
      display: flex;
      flex-direction: column;

      .img-diagram-mobile {
        display: none;
        @media screen and (max-width: 425px) {
          margin: 0 -15px 15px -15px;
          display: block;
        }
      }

      h3 {
        color: #00a03b;
        font-family: $fontM;
        font-size: 20px;
        margin-bottom: 15px;
        @media screen and (max-width: 670px) {
          font-size: 16px;
        }
      }

      p {
        margin-bottom: 15px;
        color: #545454;
        font-family: $fontR;
        font-size: 16px;

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .diagram-history {
        margin: auto 0 0 0;

        .text-item {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          p {
            padding-left: 10px;
            color: #545454;
            font-family: $fontR;
            font-size: 16px;
            position: relative;
            @media screen and (max-width: 670px) {
              font-size: 13px;
            }
          }

          span {
            border-radius: 50%;

            display: flex;

            width: 16px;
            height: 16px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &:nth-child(1) {
      margin-right: 15px;

      @media screen and (max-width: 670px) {
        margin-right: 5px;
      }
      @media screen and (max-width: 425px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 425px) {
        margin-left: 0;
      }
    }
  }
}
</style>
