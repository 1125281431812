<template>
  <div class="section-double-row-text">
    <div class="col col-bold">
      <p v-html="content.title"></p>
    </div>

    <div class="col col-reg">
      <div class="text-wrapper" v-html="content.description"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-double-row-text",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "text_with_title_horizontal"
  })
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-double-row-text {
  margin: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
    flex-direction: column;
  }

  .col {
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 670px) {
      width: 100%;
    }

    &:nth-child(1) {
      margin-right: 12px;
      @media screen and (max-width: 670px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 12px;
      @media screen and (max-width: 670px) {
        margin-left: 0;
      }
    }

    &.col-bold {
      @media screen and (max-width: 670px) {
        margin-bottom: 15px;
      }

      p {
        color: #00a03b;
        font-family: $fontR;
        font-size: 30px;
        @media screen and (max-width: 670px) {
          font-size: 18px;
        }

        b,
        strong {
          font-family: $fontDemi;
          font-weight: normal;
        }
      }
    }

    &.col-reg {
      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
