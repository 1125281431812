var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-nav"},[_c('div',{staticClass:"training-nav__inner"},[_c('div',{staticClass:"training-nav__list"},[_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 1,
          current: _vm.currentState >= 1 && _vm.currentState < 3
        },on:{"click":function($event){return _vm.nextState(1, '')}}},[_vm._v(" Сумамед®: курс прийому ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 3,
          current: _vm.currentState >= 3 && _vm.currentState <= 7
        },on:{"click":function($event){return _vm.nextState(3, '')}}},[_vm._v(" Упаковка Сумамед® ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 8,
          current: _vm.currentState === 8
        },on:{"click":function($event){return _vm.nextState(8, '')}}},[_vm._v(" Фінальний тест ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }