<template>
  <!-- Begin accordion -->
  <div class="accordion">
    <div  class="accordion__item" v-for="(accordion, key) in blocks" :key="key">
      <div
        class="accordion__title"
        @click="toggleAccordion(key)"
        :class="{ active: !!state[key] }"
        v-html="accordion.title"
      ></div>

      <div :class="{ active: !!state[key] }"
        class="accordion__content"
        v-show="state[key]"
        v-html="accordion.description"
      ></div>
    </div>
  </div>
  <!-- End accordion -->
</template>

<script>
import { get } from "lodash";

export default {
  name: "Accordion",
  props: {
    content: Object,
  },

  data() {
    return {
      state: [],
    };
  },

  computed: {
    blocks() {
      return get(this.content, "blocks", []);
    },
  },
  methods: {
    toggleAccordion(key) {
      this.$set(this.state, key, !this.state[key]);
    },
  },
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

/* Begin accordion */
.accordion {
  &__item {
    margin-bottom: 18px;
  }
  &__title {
    position: relative;
    padding: 18px 105px 18px 42px;
    background: #204e77;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    cursor: pointer;
    &:after {
      content: "\25BE";
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
    }
    &.active {
      &:after {
        content: "\25B4";
      }
    }
  }
  &__content {
    padding: 29px 29px 23px 42px;
    background: #e4eaed;
    &.active{
      max-height: max-content;
    }
    &::v-deep {
      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #204e77;
        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .accordion {
    &__title {
      padding: 7px 24px 9px 14px;
      font-size: 14px;
      line-height: 17px;
      &:after {
        right: 8px;
      }
    }
    &__content {
      padding: 20px 10px 11px 14px;
      &::v-deep {
        p {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
}
/* // */
</style>
