<template>
  <div class="section-diagram-type3">
    <div class="description">
      <h3>Consetetur sadipscing elitr, sed diam nonumy</h3>

      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </p>

      <div class="img-diagram img-diagram-mobile">
        <img :src="require('../assets/img/diagram3.png')" alt="" />
      </div>

      <div class="diagram-history">
        <p class="color1">- Lorem ipsum dolor</p>
        <p class="color2">- Eirmod tempor invidunt</p>
        <p class="color3">- Eirmod tempor invidunt</p>
      </div>
    </div>

    <div class="img-diagram">
      <img :src="require('../assets/img/diagram3.png')" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "section-diagram-type3"
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;
.section-diagram-type3 {
  margin: 10px 0;
  display: flex;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  > div {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    &.img-diagram {
      @media screen and (max-width: 425px) {
        display: none;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &.description {
      display: flex;
      flex-direction: column;

      .img-diagram-mobile {
        display: none;
        @media screen and (max-width: 425px) {
          margin: 0 -15px 15px -15px;
          display: block;
        }
      }

      h3 {
        color: #00a03b;
        font-family: $fontM;
        font-size: 20px;
        margin-bottom: 15px;
        @media screen and (max-width: 670px) {
          font-size: 16px;
        }
      }

      p {
        margin-bottom: 15px;
        color: #545454;
        font-family: $fontR;
        font-size: 16px;

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .diagram-history {
        margin: auto 0 0 0;

        p {
          padding-left: 20px;
          margin-bottom: 10px;
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          position: relative;

          @media screen and (max-width: 670px) {
            font-size: 13px;
          }

          &.color1 {
            &:before {
              background: #c4c4c4;
            }
          }

          &.color2 {
            &:before {
              background: #00a03b;
            }
          }

          &.color3 {
            &:before {
              background: #b3d06b;
            }
          }

          &.color4 {
            &:before {
              background: #aa198b;
            }
          }

          &:before {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            left: 0;
            top: 4px;
            @media screen and (max-width: 425px) {
              top: 0;
            }

            border-radius: 50%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &:nth-child(1) {
      margin-right: 15px;

      @media screen and (max-width: 670px) {
        margin-right: 5px;
      }
      @media screen and (max-width: 425px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 425px) {
        margin-left: 0;
      }
    }
  }
}
</style>
