var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-nav"},[_c('div',{staticClass:"training-nav__inner"},[_c('div',{staticClass:"training-nav__list"},[_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 1,
          current: _vm.currentState === 1
        },on:{"click":function($event){return _vm.nextState(1, '')}}},[_vm._v(" Про Азитроміцин ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 2,
          current: _vm.currentState >= 2 && _vm.currentState <= 6
        },on:{"click":function($event){return _vm.nextState(2, '')}}},[_vm._v(" Сумамед®: форми випуску і дозування ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 7,
          current: _vm.currentState === 7
        },on:{"click":function($event){return _vm.nextState(7, '')}}},[_vm._v(" Фінальний тест ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }