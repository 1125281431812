<template>
  <div class="section-col-list-text">
    <div
      v-bind:style="{ borderColor: key.color }"
      :class="`item color${value + 1}`"
      v-for="(key, value) in content.blocks"
    >
      <span v-bind:style="{ color: key.color }">{{ value + 1 }}</span>
      <div class="list">
        <div class="text-wrapper" v-html="key.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-col-list-text",

  props: {
    content: Object
  },

  data: () => ({
    nameSection: "big_list"
  })
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-col-list-text {
  margin: 10px 0;
  width: 100%;
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 25px 0;
    border-top: 1px solid red;

    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.color1 {
      border-color: #00a03b;

      span {
        color: #00a03b;
      }
    }

    &.color2 {
      border-color: #b3d06b;

      span {
        color: #b3d06b;
      }
    }

    &.color3 {
      border-color: #005a85;

      span {
        color: #005a85;
      }
    }

    &.color4 {
      border-color: #317575;

      span {
        color: #317575;
      }
    }

    span {
      line-height: 0.9em;
      display: block;
      margin-right: 30px;
      color: #545454;
      font-size: 70px;
      font-family: $fontR;
      @media screen and (max-width: 670px) {
        margin-right: 15px;
      }
    }

    p {
      margin-bottom: 15px;
      color: #545454;
      font-family: $fontR;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 670px) {
        font-size: 14px;
      }
    }
  }
}
</style>
