<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Чи можна замінювати препарат і як це <br />
      зробити правильно?
    </div>
    <div class="module-theory__subtitle">
      Оберіть один з варіантів, який ви вважаєте правильним <br />
      щодо генеричної заміни НПЗП при болі в спині
    </div>
    <div class="questions">
      <div class="questions__steps">
        <b> Ситуація {{ question }}</b> / {{ questions.length }}
      </div>
      <template v-if="!currentQuestion.isHasAnswer">
        <div class="questions__title">
          {{ currentQuestion.title }}
        </div>
        <div class="questions__answers">
          <div
              class="questions__answers-item"
              v-for="(answer, key) in currentQuestion.answers"
              :key="key"
              @click="selectAnswer(currentQuestion, answer)"
          >
            {{ answer.title }}
          </div>
        </div>

        <div class="questions__info" v-show="!currentQuestion.isHasAnswer">
          Торгівельна назва: Олфен®-75 . Форма випуску: розчин для ін'єкцій, по
          2 мл в ампулі; по 5 ампул у коробці. Склад діючої речовини: 1 ампула
          (2 мл) містить: диклофенаку натрію 75 мг та лідокаїну гідрохлориду 20
          мг
        </div>
      </template>

      <template v-else>
        <div class="questions__message">
          <div
              class="questions__message-title"
              :class="{
              correct: currentQuestion.isHasCorrectAnswer === true,
              error: currentQuestion.isHasCorrectAnswer === false
            }"
          >
            {{
              currentQuestion.isHasCorrectAnswer === true
                  ? currentQuestion.message.correctTitle
                  : currentQuestion.message.errorTitle
            }}
          </div>
          <div
              class="questions__message-text"
              v-html="currentQuestion.message.text"
          ></div>
        </div>
        <div class="questions__result" v-show="question === questions.length">
          <p>
            <b>
              А тепер час перевірити ваші знання за підсумками цієї теми.
            </b>
          </p>
          <p>
            Натискайте на кнопку “Перейти до тестування”, щоб пройти тест
          </p>
        </div>
        <div
            class="questions__to-test next-button"
            v-show="currentQuestion.isHasAnswer && question === questions.length"
            @click="nextState(6, 'next')"
        >
          Перейти до тестування
        </div>
        <div
            class="questions__next next-button"
            v-show="currentQuestion.isHasAnswer && question !== questions.length"
            @click="nextQuestion()"
        >
          Далі
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory5",
  data() {
    return {
      state: 1,
      question: 1,
      questions: [
        {
          id: 1,
          isHasAnswer: false,
          isHasCorrectAnswer: null,
          title:
              "Чи можна рекомендувати Диклофенак натрію 75 мг, якщо клієнту призначили Диклофенак натрію 75 мг + Лідокаїну гідрохлорид 20 мг?",
          answers: [
            {
              id: 1,
              isSelected: false,
              isCorrect: false,
              title: "Так"
            },
            {
              id: 2,
              isSelected: false,
              isCorrect: true,
              title: "Ні"
            }
          ],
          message: {
            errorTitle: "Неправильно!",
            correctTitle: "Правильно!",
            text:
                "Рекомендований препарат повинен мати <b>таку саму діючу речовину / такі самі діючі речовини.</b>"
          }
        },
        {
          id: 2,
          isHasAnswer: false,
          isHasCorrectAnswer: null,
          title:
              "Клієнту призначили препарат у формі випуску ампули 2 мл. Чи можна йому рекомендувати інші лікарські форми?",
          answers: [
            {
              id: 1,
              isSelected: false,
              isCorrect: false,
              title: "Так"
            },
            {
              id: 2,
              isSelected: false,
              isCorrect: true,
              title: "Ні"
            }
          ],
          message: {
            errorTitle: "Неправильно!",
            correctTitle: "Правильно!",
            text:
                "Рекомендований препарат повинен мати <b>таку саму форму випуску.</b>"
          }
        },
        {
          id: 3,
          isHasAnswer: false,
          isHasCorrectAnswer: null,
          title:
              "Клієнту призначили 200 мг препарату? Чи можна рекомендувати той самий препарат, проте з іншим дозуванням?",
          answers: [
            {
              id: 1,
              isSelected: false,
              isCorrect: false,
              title: "Так"
            },
            {
              id: 2,
              isSelected: false,
              isCorrect: true,
              title: "Ні"
            }
          ],
          message: {
            errorTitle: "Неправильно!",
            correctTitle: "Правильно!",
            text:
                "Рекомендований препарат повинен відповідати призначеному та мати <b>таке саме дозування діючої речовини.</b>"
          }
        }
      ]
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.question - 1];
    }
  },
  methods: {
    selectAnswer(currentQuestion, answer) {
      currentQuestion.isHasAnswer = true;
      answer.isSelected = true;
      if (answer.isCorrect) {
        currentQuestion.isHasCorrectAnswer = true;
      } else {
        currentQuestion.isHasCorrectAnswer = false;
      }
    },
    nextQuestion() {
      this.question += 1;
    },
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 55px;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    p {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .questions {
    max-width: 490px;
    margin: auto;
    &__steps {
      width: 130px;
      margin: 0 auto 32px;
      background: #e4eaed;
      padding: 7px 19px 8px;
      border-radius: 80px;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #545454;
      b {
        font-family: $fontDemi;
      }
    }
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #00a03b;
    }
    &__answers {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 32px;
      &-item {
        width: 125px;
        margin-right: 18px;
        background: rgba(228, 234, 237, 0.4);
        border: 1px solid #00a03b;
        border-radius: 80px;
        padding: 12px 49px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #005984;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          background: #00a03b;
          color: #fff;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__message {
      &-title {
        margin-bottom: 16px;
        font-family: $fontDemi;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        &.correct {
          color: #005984;
        }
        &.error {
          color: #aa198b;
        }
      }
      &-text {
        max-width: 305px;
        margin: 0 auto 32px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #545454;
        b {
          font-family: $fontDemi;
        }
      }
    }
    &__info {
      max-width: 305px;
      margin: auto;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #545454;
    }
    &__result {
      max-width: 330px;
      margin: 0 auto 24px;
      p {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #545454;
        &:last-child {
          margin-bottom: 0;
        }
        b {
          font-family: $fontDemi;
          color: #00a03b;
        }
      }
    }
    &__to-test {
      &.next-button {
        width: 330px;
        padding: 0 22px;
        text-indent: -40px;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 22px;
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 17px;
      br {
        display: none;
      }
    }
    .questions {
      &__title {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
      }
      &__answers {
        margin-bottom: 16px;
        &-item {
          width: 158px;
          padding: 12px 65px;
          font-size: 14px;
        }
      }
      &__message {
        &-title {
          margin-bottom: 15px;
          font-size: 16px;
          line-height: 20px;
        }
        &-text {
          margin-bottom: 26px;
          font-size: 14px;
          line-height: 17px;
        }
      }
      &__result {
        p {
          font-size: 14px;
          line-height: 17px;
          b {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      &__to-test {
        &.next-button {
          width: 280px;
          padding: 0 30px;
        }
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: 26px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}

@media screen and (max-width: 359px) {
  .module-theory {
    .questions {
      &__answers {
        &-item {
          width: 128px;
          padding: 12px 55px;
        }
      }
    }
  }
}
</style>
