<template>
  <div class="game">
    <!-- Begin game -->
    <div class="game-view" v-if="!isGameOver">
      <div
        class="game-layout"
        :class="{
          'active-onboarding': isOnboarding,
          disabled:
            isGreenMessage ||
            isRedMessage ||
            isRedMessageOk ||
            isYellowMessage ||
            isBlueMessage
        }"
      >
        <div class="game-pills">
          <drag
            v-for="pill in pills"
            :key="pill.id"
            :data="pill"
            class="game-pills__item"
            @dragstart="onDragStart"
            @dragend="onDragEnd"
            :class="[
              `game-pills__item-${pill.id}`,
              { empty: !pill.isDragable }
            ]"
          ></drag>
        </div>
        <!-- Begin pill ampule zone -->
        <div class="game-ampule__zone">
          <Drop class="game-ampule" @drop="dropAmpule"></Drop>
          <div class="game-ampule__icon">
            <img
              :src="
                require(`@/assets/img/training-module1/game/ampoule-${ampuleProgress}.svg`)
              "
              alt=""
            />
          </div>
          <div class="game-ampule__name">Нейрорубін™</div>
        </div>
        <!-- // -->
        <!-- Begin pill drag zone -->
        <div class="game-pill__zone">
          <Drop class="game-pill" @drop="dropPill"></Drop>
          <div class="game-pill__icon">
            <img
              :src="
                require(`@/assets/img/training-module1/game/pill-${pillProgress}.svg`)
              "
              alt=""
            />
          </div>
          <div class="game-pill__name">
            Нейрорубін™- <br />
            Форте Лактаб
          </div>
        </div>
        <!-- // -->
      </div>
      <!-- Begin green message -->
      <div
        class="game-messages__item game-messages__item--green"
        :class="isGreenMessage ? 'active' : 'inactive'"
      >
        <div class="game-messages__info">
          Молодець! Правильно!
        </div>
        <div class="game-messages__image">
          <img
            src="~@/assets/img/training-module1/game/blob-green.svg"
            alt=""
          />
        </div>
      </div>
      <!-- // -->
      <!-- Begin red message -->
      <div
        class="game-messages__item game-messages__item--red"
        :class="isRedMessage ? 'active' : 'inactive'"
      >
        <div class="game-messages__image">
          <img src="~@/assets/img/training-module1/game/blob-red.svg" alt="" />
        </div>
        <div class="game-messages__info">
          Агов! Друже, зачекай, <br />
          у Нейрорубін™ дози вищі
        </div>
      </div>
      <!-- // -->
      <!-- Begin red ok message -->

      <div
        class="game-messages__item game-messages__item--red-ok"
        :class="isRedMessageOk ? 'active' : 'inactive'"
      >
        <div class="game-messages__image">
          <img
            src="~@/assets/img/training-module1/game/blob-red-ok.svg"
            alt=""
          />
        </div>
        <div class="game-messages__info">
          Супер!
        </div>
      </div>
      <!-- // -->
      <!-- Begin blue message -->
      <div
        class="game-messages__item game-messages__item--blue"
        :class="isBlueMessage ? 'active' : 'inactive'"
      >
        <div class="game-messages__info">
          Точно!
        </div>
        <div class="game-messages__image">
          <img src="~@/assets/img/training-module1/game/blob-blue.svg" alt="" />
        </div>
      </div>
      <!-- // -->
      <!-- Begin green message -->
      <div
        class="game-messages__item game-messages__item--yellow"
        :class="isYellowMessage ? 'active' : 'inactive'"
      >
        <div class="game-messages__image">
          <img
            src="~@/assets/img/training-module1/game/blob-yellow.svg"
            alt=""
          />
        </div>
        <div class="game-messages__info">
          Ой! Спробуй ще!
        </div>
      </div>
      <!-- // -->
    </div>
    <!-- // -->

    <!-- Begin game finish -->
    <div class="game-finish" v-if="isGameOver">
      <div class="game-finish__title">
        Вітаємо! Тепер ви знаєте, що Нейрорубін<sup>™</sup> — єдиний в Україні
        комплекс вітамінів B<sub>1</sub>, В<sub>6</sub> та В<sub>12</sub>, що
        містить 1000 мкг В<sub>12</sub> як в ампулах, так і в таблетках<sup
          >*4,5</sup
        >
      </div>
      <div class="game-finish__row">
        <div class="game-finish__col-left">
          <div class="game-finish__image">
            <img
              src="~@/assets/img/training-module1/game/game-finished.png"
              alt=""
            />
          </div>
        </div>
        <div class="game-finish__col-right">
          <div class="game-finish__detail">
            <p>
              <span>
                А тепер час перевірити ваші знання за підсумками цього модуля.
              </span>
            </p>
            <p>
              Натискайте на кнопку “Перейти до тестування”, щоб пройти фінальний
              тест
            </p>
            <div class="game-finish__detail-btn" @click="nextState(4, 'next')">
              Перейти до тестування
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // -->

    <!-- Begin onboarding -->
    <div class="game-onboarding" v-if="isOnboarding">
      <div class="game-onboarding__modal">
        <div class="game-onboarding__close" @click="closeOnboard()"></div>
        <div class="game-onboarding__main">
          <div class="game-onboarding__title">
            Давайте зіграємо в гру, що допоможе вам <br />
            запам’ятати дозування препарату Нейрорубін™
          </div>
          <div class="game-onboarding__steps visible-mobile">
            <div class="game-onboarding__step">
              <span>0{{ onboardingStage }}</span> / 03
            </div>
          </div>
          <div
            class="game-onboarding__text visible-mobile"
            v-if="onboardingStage === 1"
          >
            У вас є різні дозування вітамінів <br />
            <span>B<sub>1</sub>,</span> <span>B<sub>6</sub></span> та
            <span>B<sub>12</sub></span>
          </div>
          <div
            class="game-onboarding__text visible-mobile"
            v-if="onboardingStage === 2"
          >
            Виберіть дозування та перетягніть його до
            <span>ампули</span> чи <span>таблетки</span>. Якщо дозування вірне —
            ампула або таблетка починає заповнюватися кольором.
          </div>
          <div
            class="game-onboarding__text visible-mobile"
            v-if="onboardingStage === 3"
          >
            <p>
              Вам необхідно вірно розмістити всі дозування вітамінів
              <span>B<sub>1</sub>,</span> <span>B<sub>6</sub></span> та
              <span>B<sub>12</sub></span
              >, щоб ампула та таблетка заповнились кольором на 100%.
            </p>
          </div>
          <div class="game-onboarding__row">
            <div class="game-onboarding__col-left">
              <div class="game-onboarding__image">
                <img
                  class="game-onboarding__image-desktop"
                  :src="
                    require(`@/assets/img/training-module1/game/onboarding-${onboardingStage}.jpg`)
                  "
                  alt=""
                />
                <img
                  v-touch:swipe="onboardSwipe"
                  class="game-onboarding__image-mobile"
                  :src="
                    require(`@/assets/img/training-module1/game/onboarding-${onboardingStage}-mobile.jpg`)
                  "
                  alt=""
                />
                <div class="game-onboarding__dots">
                  <div
                    class="game-onboarding__dot"
                    :class="{ active: onboardingStage === 1 }"
                    @click="setOnboardingState(1)"
                  ></div>
                  <div
                    class="game-onboarding__dot"
                    :class="{ active: onboardingStage === 2 }"
                    @click="setOnboardingState(2)"
                  ></div>
                  <div
                    class="game-onboarding__dot"
                    :class="{ active: onboardingStage === 3 }"
                    @click="setOnboardingState(3)"
                  ></div>
                </div>
              </div>
            </div>
            <div class="game-onboarding__col-right">
              <div class="game-onboarding__detail">
                <div class="game-onboarding__steps visible-desktop">
                  <div class="game-onboarding__step">
                    <span>0{{ onboardingStage }}</span> / 03
                  </div>
                </div>
                <div
                  class="game-onboarding__text visible-desktop"
                  v-if="onboardingStage === 1"
                >
                  У вас є різні дозування вітамінів
                  <span>B<sub>1</sub>,</span> <span>B<sub>6</sub></span> та
                  <span>B<sub>12</sub></span>
                </div>
                <div
                  class="game-onboarding__text visible-desktop"
                  v-if="onboardingStage === 2"
                >
                  Виберіть дозування та перетягніть його до
                  <span>ампули</span> чи <span>таблетки</span>. Якщо дозування
                  вірне — ампула або таблетка починає заповнюватися кольором.
                </div>
                <div
                  class="game-onboarding__text visible-desktop"
                  v-if="onboardingStage === 3"
                >
                  <p>
                    Вам необхідно вірно розмістити всі дозування вітамінів
                    <span>B<sub>1</sub>,</span> <span>B<sub>6</sub></span> та
                    <span>B<sub>12</sub></span
                    >, щоб ампула та таблетка заповнились кольором на 100%.
                  </p>
                </div>
                <div
                  class="game-onboarding__btn"
                  :class="{ start: onboardingStage === 3 }"
                  @click="nextOnboard()"
                >
                  {{ onboardingStage === 3 ? "Розпочати гру" : "Далі" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // -->
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { Drag, Drop } from "vue-easy-dnd";

export default {
  name: "Game",
  data() {
    return {
      isOnboarding: true,
      onboardingStage: 1,
      isGameOver: false,
      ampulesContainer: [],
      ampuleProgress: 0,
      pillsContainer: [],
      pillProgress: 0,
      isGreenMessage: false,
      isRedMessage: false,
      isRedMessageOk: false,
      isYellowMessage: false,
      isBlueMessage: false,
      pills: [
        {
          id: 1,
          name: "B1_100",
          forGroup: ["ampule"],
          isDragable: true
        },
        {
          id: 2,
          name: "B1_200",
          forGroup: ["pill"],
          isDragable: true
        },
        {
          id: 3,
          name: "B6_50",
          forGroup: ["pill"],
          isDragable: true
        },
        {
          id: 4,
          name: "B6_100",
          forGroup: ["ampule"],
          isDragable: true
        },
        {
          id: 5,
          name: "B12_500",
          forGroup: [],
          isDragable: true
        },
        {
          id: 6,
          name: "B12_1000",
          forGroup: ["ampule", "pill"],
          isDragable: true
        },
        {
          id: 7,
          name: "B12_1000",
          forGroup: ["ampule", "pill"],
          isDragable: true
        }
      ]
    };
  },
  components: {
    Drag,
    Drop
  },
  watch: {
    ampulesContainer(array) {
      this.ampuleProgress = array.length;
    },
    pillsContainer(array) {
      this.pillProgress = array.length;
    }
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", { event_category: "sec_3_go_test" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    onboardSwipe(direction) {
      if (direction === "left") {
        if (this.onboardingStage < 3) {
          if (this.onboardingStage === 1) {
            gtag("event", "click", {
              event_category: "sec_3_onboarding_1_swipe_next"
            });
          }
          if (this.onboardingStage === 2) {
            gtag("event", "click", {
              event_category: "sec_3_onboarding_2_swipe_next"
            });
          }
          this.onboardingStage += 1;
        }
      }
      if (direction === "right") {
        if (this.onboardingStage > 1) {
          if (this.onboardingStage === 2) {
            gtag("event", "click", {
              event_category: "sec_3_onboarding_2_swipe_back"
            });
          }
          if (this.onboardingStage === 3) {
            gtag("event", "click", {
              event_category: "sec_3_onboarding_3_swipe_back"
            });
          }
          this.onboardingStage -= 1;
        }
      }
    },
    setOnboardingState(state) {
      if (state === 1) {
        gtag("event", "click", { event_category: "sec_3_onboarding_1_dot" });
      }
      if (state === 2) {
        gtag("event", "click", { event_category: "sec_3_onboarding_2_dot" });
      }
      if (state === 3) {
        gtag("event", "click", { event_category: "sec_3_onboarding_3_dot" });
      }
      this.onboardingStage = state;
    },
    nextOnboard() {
      if (this.onboardingStage < 3) {
        this.onboardingStage += 1;
        if (this.onboardingStage === 2) {
          console.log(2);
          gtag("event", "click", { event_category: "sec_3_onboarding_2" });
        }
        if (this.onboardingStage === 3) {
          console.log(3);
          gtag("event", "click", { event_category: "sec_3_onboarding_3" });
        }
      } else {
        this.isOnboarding = false;
        localStorage.setItem("isOnboarding", false);
        gtag("event", "onLoad", { event_category: "sec_3_game_start" });
      }
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    closeOnboard() {
      this.isOnboarding = false;
      localStorage.setItem("isOnboarding", false);
    },
    preventDefault(event) {
      event.preventDefault();
    },
    onDragStart() {
      var supportsPassive = false;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: () => {
              return (supportsPassive = true);
            }
          })
        );
      } catch (e) {
        console.log(e);
      }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent =
        "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

      window.addEventListener("DOMMouseScroll", this.preventDefault, false); // older FF
      window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
      window.addEventListener("touchmove", this.preventDefault, wheelOpt); // mobile
    },
    onDragEnd() {
      var supportsPassive = false;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: () => {
              return (supportsPassive = true);
            }
          })
        );
      } catch (e) {
        console.log(e);
      }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent =
        "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
      window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
      window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
      window.removeEventListener("touchmove", this.preventDefault, wheelOpt);
    },
    dropAmpule(event) {
      if (this.ampulesContainer.length === 0) {
        if (event.data.forGroup.includes("ampule")) {
          event.data.isDragable = false;
          this.ampulesContainer.push(event.data);
          if (event.data.name === "B1_100") {
            this.isGreenMessage = true;
            gtag("event", "click", { event_category: "b1_100_true" });
          }
          if (event.data.name === "B6_100") {
            this.isBlueMessage = true;
            gtag("event", "click", { event_category: "b6_100_true" });
          }
          if (event.data.name === "B12_1000") {
            this.isRedMessageOk = true;
            gtag("event", "click", { event_category: "b12_1_1000_true" });
          }
        } else {
          if (event.data.name === "B1_200") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b1_200_false" });
          }
          if (event.data.name === "B6_50") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b6_50_false" });
          }
          if (event.data.name === "B12_500") {
            this.isRedMessage = true;
            gtag("event", "click", { event_category: "b12_500_false" });
          }
        }
      } else {
        if (
          !this.ampulesContainer.some(
            ampule => ampule.name === event.data.name
          ) &&
          event.data.forGroup.includes("ampule")
        ) {
          event.data.isDragable = false;
          this.ampulesContainer.push(event.data);
          if (event.data.name === "B1_100") {
            this.isGreenMessage = true;
            gtag("event", "click", { event_category: "b1_100_true" });
          }
          if (event.data.name === "B6_100") {
            this.isBlueMessage = true;
            gtag("event", "click", { event_category: "b6_100_true" });
          }
          if (event.data.name === "B12_1000") {
            this.isRedMessageOk = true;
            gtag("event", "click", { event_category: "b12_1_1000_true" });
          }
        } else {
          if (event.data.name === "B1_200") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b1_200_false" });
          }
          if (event.data.name === "B6_50") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b6_50_false" });
          }
          if (event.data.name === "B12_500") {
            this.isRedMessage = true;
            gtag("event", "click", { event_category: "b12_500_false" });
          }
          if (event.data.name === "B12_1000") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b12_2_1000_false" });
          }
        }
      }
      setTimeout(
        () => {
          this.isGreenMessage = false;
          this.isRedMessage = false;
          this.isRedMessageOk = false;
          this.isBlueMessage = false;
          this.isYellowMessage = false;
          if (this.ampuleProgress === 3 && this.pillProgress === 3) {
            this.isGameOver = true;
            gtag("event", "onLoad", { event_category: "sec_3_game_finish" });
          }
        },
        event.data.name === "B12_500" ? 3000 : 2000
      );
    },
    dropPill(event) {
      if (this.pillsContainer.length === 0) {
        if (event.data.forGroup.includes("pill")) {
          event.data.isDragable = false;
          this.pillsContainer.push(event.data);
          if (event.data.name === "B1_200") {
            this.isGreenMessage = true;
            gtag("event", "click", { event_category: "b1_200_true" });
          }
          if (event.data.name === "B6_50") {
            this.isBlueMessage = true;
            gtag("event", "click", { event_category: "b6_50_true" });
          }
          if (event.data.name === "B12_1000") {
            this.isRedMessageOk = true;
            gtag("event", "click", { event_category: "b12_1_1000_true" });
          }
        } else {
          if (event.data.name === "B1_100") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b1_100_false" });
          }
          if (event.data.name === "B6_100") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b6_100_false" });
          }
          if (event.data.name === "B12_500") {
            this.isRedMessage = true;
            gtag("event", "click", { event_category: "b12_500_false" });
          }
        }
      } else {
        if (
          !this.pillsContainer.some(pill => pill.name === event.data.name) &&
          event.data.forGroup.includes("pill")
        ) {
          event.data.isDragable = false;
          this.pillsContainer.push(event.data);
          if (event.data.name === "B1_200") {
            this.isGreenMessage = true;
            gtag("event", "click", { event_category: "b1_200_true" });
          }
          if (event.data.name === "B6_50") {
            this.isBlueMessage = true;
            gtag("event", "click", { event_category: "b6_50_true" });
          }
          if (event.data.name === "B12_1000") {
            this.isRedMessageOk = true;
            gtag("event", "click", { event_category: "b12_2_1000_true" });
          }
        } else {
          if (event.data.name === "B1_100") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b1_100_false" });
          }
          if (event.data.name === "B6_100") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b6_100_false" });
          }
          if (event.data.name === "B12_500") {
            this.isRedMessage = true;
            gtag("event", "click", { event_category: "b12_500_false" });
          }
          if (event.data.name === "B12_1000") {
            this.isYellowMessage = true;
            gtag("event", "click", { event_category: "b12_2_1000_false" });
          }
        }
      }
      setTimeout(
        () => {
          this.isGreenMessage = false;
          this.isRedMessage = false;
          this.isRedMessageOk = false;
          this.isBlueMessage = false;
          this.isYellowMessage = false;
          if (this.ampuleProgress === 3 && this.pillProgress === 3) {
            this.isGameOver = true;
            gtag("event", "onLoad", { event_category: "sec_3_game_finish" });
          }
        },
        event.data.name === "B12_500" ? 3000 : 2000
      );
    }
  },
  mounted() {
    if (localStorage.getItem("isOnboarding") !== null) {
      this.isOnboarding = false;
    } else {
      gtag("event", "click", { event_category: "sec_3_onboarding_1" });
    }
  }
};
</script>
<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.game {
  position: relative;
  overflow: hidden;
  &-layout {
    position: relative;
    height: 475px;
    background: url("~@/assets/img/training-module1/game/game-bg.jpg") no-repeat;
    background-size: cover;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($color: #fff, $alpha: 0.5);
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s;
    }
    &.disabled {
      pointer-events: none;
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-pills {
    position: absolute;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 431px;
    &__item {
      position: absolute;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: -31px;
        left: -31px;
        right: -31px;
        bottom: -31px;
        background: url("~@/assets/img/training-module1/game/drag-shadow.svg")
          no-repeat center;
        background-size: contain;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s;
        z-index: -1;
      }
      &.drag-in {
        &:hover {
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &.drag-source,
      &.empty {
        background-image: url("~@/assets/img/training-module1/game/pill-empty-bg.svg");
        pointer-events: none;
        cursor: default;
      }
      &-1 {
        top: 210px;
        left: 115px;
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/img/training-module1/game/b1-100.svg");
      }
      &-2 {
        top: 0px;
        left: 135px;
        width: 110px;
        height: 110px;
        background-image: url("~@/assets/img/training-module1/game/b1-200.svg");
      }
      &-3 {
        top: 256px;
        left: 228px;
        width: 85px;
        height: 85px;
        background-image: url("~@/assets/img/training-module1/game/b6-50.svg");
      }
      &-4 {
        top: 117px;
        left: 169px;
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/img/training-module1/game/b6-100.svg");
      }
      &-5 {
        top: 25px;
        left: 268px;
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/img/training-module1/game/b12-500.svg");
      }
      &-6 {
        top: 136px;
        left: 286px;
        width: 120px;
        height: 120px;
        background-image: url("~@/assets/img/training-module1/game/b12-1000-1.svg");
      }
      &-7 {
        top: 78px;
        left: 22px;
        width: 120px;
        height: 120px;
        background-image: url("~@/assets/img/training-module1/game/b12-1000-2.svg");
      }
    }
  }
  &-ampule {
    position: absolute;
    left: 30px;
    bottom: 53px;
    width: 160px;
    height: 160px;
    background: url("~@/assets/img/training-module1/game/ampule-ellipse-bg.svg")
      no-repeat;
    background-size: contain;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("~@/assets/img/training-module1/game/drop-shadow.svg")
        no-repeat center;
      background-size: contain;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s;
    }
    &.drop-in {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
    &__zone {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    div {
      display: none;
    }
    &__icon {
      position: absolute;
      left: 75px;
      bottom: 41px;
      transform: rotate(45deg);
      pointer-events: none;
      img {
        width: 52px;
        filter: drop-shadow(0px 8px 8px rgba(190, 111, 103, 0.6));
      }
    }
    &__name {
      position: absolute;
      left: 0;
      bottom: 16px;
      width: 220px;
      height: 48px;
      padding: 14px 0 12px;
      background: linear-gradient(
        -90deg,
        #cb2e23 50.35%,
        rgba(239, 172, 136, 0.16) 123.05%
      );
      border-radius: 0 50px 50px 0;
      font-weight: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }
  }
  &-pill {
    position: absolute;
    right: 30px;
    bottom: 53px;
    width: 160px;
    height: 160px;
    background: url("~@/assets/img/training-module1/game/pill-ellipse-bg.svg")
      no-repeat;
    overflow: hidden;
    background-size: contain;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("~@/assets/img/training-module1/game/drop-shadow.svg")
        no-repeat center;
      background-size: contain;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s;
    }
    &.drop-in {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
    &__zone {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    div {
      display: none;
    }
    &__icon {
      position: absolute;
      right: 65px;
      bottom: 70px;
      pointer-events: none;
      img {
        width: 90px;
        filter: drop-shadow(0px 8px 8px rgba(190, 111, 103, 0.6));
      }
    }
    &__name {
      position: absolute;
      right: 0;
      bottom: 16px;
      width: 220px;
      height: 48px;
      padding: 4px 0 4px;
      background: linear-gradient(
        90deg,
        #cb2e23 50.35%,
        rgba(239, 172, 136, 0.16) 123.05%
      );
      border-radius: 50px 0 0 50px;
      font-weight: $fontDemi;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
  }
  &-messages {
    &__item {
      position: absolute;
      bottom: 0;
      z-index: 10;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      transition: all 1s;
      &:after {
        display: none;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 82px;
        background: linear-gradient(
          182.3deg,
          #ae1d28 30.77%,
          rgba(174, 29, 40, 0) 66.48%
        );
        opacity: 0.9;
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
      &.inactive {
        transform: translateY(200%);
        opacity: 0;
      }
      &.active {
        transform: translateY(0);
        opacity: 1;
      }
      &--green {
        .game {
          &-messages {
            &__info {
              background: rgba($color: #00a03b, $alpha: 0.8);
              &:after {
                border-width: 0 20px 20px 0;
                border-color: transparent rgba($color: #00a03b, $alpha: 0.8)
                  transparent transparent;
              }
            }
          }
        }
      }
      &--blue {
        .game {
          &-messages {
            &__info {
              background: rgba($color: #59abe3, $alpha: 0.8);
              &:after {
                border-width: 0 20px 20px 0;
                border-color: transparent rgba($color: #59abe3, $alpha: 0.8)
                  transparent transparent;
              }
            }
          }
        }
      }
      &--red,
      &--red-ok,
      &--yellow {
        .game {
          &-messages {
            &__info {
              left: 9px;
              &:after {
                right: unset;
                left: 15px;
                border-width: 20px 20px 0 0;
              }
            }
            &__image {
              margin-right: 0;
              margin-left: 40px;
            }
          }
        }
      }
      &--red {
        .game {
          &-messages {
            &__info {
              background: rgba($color: #fb3449, $alpha: 0.8);
              text-align: center;
              left: -20px;
              &:after {
                border-color: rgba($color: #fb3449, $alpha: 0.8) transparent
                  transparent transparent;
              }
            }
            &__image {
            }
          }
        }
      }
      &--red-ok {
        .game {
          &-messages {
            &__info {
              left: -5px;
              background: rgba($color: #00a03b, $alpha: 0.8);
              &:after {
                border-color: rgba($color: #00a03b, $alpha: 0.8) transparent
                  transparent transparent;
              }
            }
          }
        }
      }
      &--yellow {
        .game {
          &-messages {
            &__info {
              background: rgba($color: #f6f3b5, $alpha: 0.8);
              color: #cb2e23;
              &:after {
                border-color: rgba($color: #f6f3b5, $alpha: 0.8) transparent
                  transparent transparent;
              }
            }
          }
        }
      }
    }
    &__info {
      position: relative;
      width: 270px;
      left: -20px;
      align-self: flex-start;
      padding: 13px 20px;
      border-radius: 8px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      &:after {
        content: "";
        position: absolute;
        bottom: -20px;
        right: 15px;
        width: 0;
        height: 0;
        border-style: solid;
      }
    }
    &__image {
      margin-right: 32px;
      margin-bottom: 28px;
      margin-left: -15px;
      img {
        display: block;
      }
    }
  }
  &-finish {
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #cb2e23;
      sub,
      sup {
        font-family: $fontDemi;
      }
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &__col {
      &-left {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }
    }
    &__image {
      img {
        display: block;
        max-width: 100%;
      }
    }
    &__detail {
      padding-top: 40px;
      p {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 20px;
        color: #545454;

        span {
          font-family: $fontDemi;
          font-size: 18px;
          line-height: 22px;
          color: #00a03b;
        }
      }
      &-btn {
        width: 100%;
        max-width: 376px;
        height: 64px;
        padding: 0 43px;
        margin-top: 24px;
        background-color: #aa198b;
        background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-origin: content-box;
        border-radius: 90px;
        font-weight: normal;
        font-size: 22px;
        text-indent: -50px;
        text-align: center;
        line-height: 64px;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          background-position: 105% center;
        }
      }
    }
  }
  &-onboarding {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 90, 133, 0.3);
    backdrop-filter: blur(24px);
    z-index: 2;
    &__modal {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 678px;
      transform: translate(-50%, -50%);
      padding: 15px 37px 40px 20px;
      background: #ffffff;
    }
    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 12px;
      height: 12px;
      background: url("~@/assets/img/training-module1/game/close-icon.svg")
        no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #00a03b;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }
    &__col-left {
      flex-basis: 53%;
      max-width: 53%;
      padding: 0 15px;
    }
    &__col-right {
      flex-basis: 47%;
      max-width: 47%;
      padding: 0 15px;
    }
    &__image {
      position: relative;
      &-mobile {
        display: none;
      }
      &-desktop {
        display: block;
        max-width: 100%;
      }
    }
    &__dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 12px;
      display: flex;
      justify-content: center;
    }
    &__dot {
      width: 48px;
      height: 4px;
      background: #e4eaed;
      border-radius: 10px;
      margin-right: 12px;
      cursor: pointer;

      &.active {
        background: linear-gradient(71.92deg, #00a03b 0%, #b4d16c 100%);
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &__detail {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__steps {
      &.visible-mobile {
        display: none;
      }
    }
    &__step {
      font-family: $fontDemi;
      font-size: 12px;
      line-height: 15px;
      color: #317575;
      span {
        color: #7fc25d;
      }
    }
    &__text {
      font-size: 16px;
      line-height: 20px;
      color: #545454;
      span {
        font-family: $fontM;
        color: #00a03b;
      }
      &.visible-mobile {
        display: none;
      }
    }
    &__btn {
      width: 100%;
      height: 44px;
      padding: 0 95px;
      background-color: #aa198b;
      background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
      border-radius: 90px;
      font-weight: normal;
      font-size: 16px;
      text-indent: -50px;
      text-align: center;
      line-height: 44px;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s;
      &.start {
        padding: 0 55px;
      }
      &:hover {
        background-position: 105% center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .game {
    margin: -24px -15px 0;
    &-layout {
      height: 665px;
      background-position: top center;
    }
    &-ampule {
      left: 12px;
      bottom: 63px;
      &__icon {
        left: 58px;
        bottom: 52px;
      }
      &__name {
        width: 180px;
        height: 46px;
        bottom: 24px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &-pill {
      right: 12px;
      bottom: 63px;
      &__icon {
        right: 45px;
        bottom: 80px;
      }
      &__name {
        width: 180px;
        height: 46px;
        bottom: 24px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &-messages {
      &__item {
        display: block;
        &--red {
          .game {
            &-messages {
              &__info {
                &:after {
                  border-color: transparent rgba(251, 52, 73, 0.8) transparent
                    transparent;
                }
              }
            }
          }
        }
        &--red-ok {
          .game {
            &-messages {
              &__info {
                &:after {
                  border-color: transparent rgba(0, 160, 59, 0.8) transparent
                    transparent;
                }
              }
            }
          }
        }
        &--yellow {
          .game {
            &-messages {
              &__info {
                &:after {
                  border-color: transparent rgba(246, 243, 181, 0.8) transparent
                    transparent;
                }
              }
            }
          }
        }
        &--green,
        &--red,
        &--red-ok,
        &--blue,
        &--yellow {
          .game {
            &-messages {
              &__info {
                bottom: 180px;
                left: 50%;
                text-align: center;
                &:after {
                  right: unset;
                  left: 63px;
                  bottom: -15px;
                  border-width: 0 15px 15px 0;
                }
              }
              &__image {
                margin: 0;
              }
            }
          }
        }
      }
      &__info {
        position: absolute;
        width: 245px;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 17px;
      }
      &__image {
        img {
          margin: auto;
        }
      }
    }
    &-finish {
      padding-top: 24px;
      &__title {
        margin-bottom: 7px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__detail {
        padding: 0 24px;
        text-align: center;
        p {
          font-size: 14px;
          line-height: 17px;
          span {
            font-size: 16px;
            line-height: 20px;
          }
        }
        &-btn {
          width: 280px;
          height: 44px;
          margin: 24px auto 0;
          padding: 0 30px;
          background-size: 26px;
          font-size: 16px;
          text-indent: -40px;
          line-height: 43px;
        }
      }
    }
    &-onboarding {
      &__modal {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100%;
        transform: translate(0, 0);
        padding: 25px 8px 40px 8px;
      }
      &__close {
        top: 10px;
        right: 24px;
      }
      &__title {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.02em;
        br {
          display: none;
        }
      }
      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__image {
        margin-bottom: 66px;
        &-desktop {
          display: none;
        }
        &-mobile {
          display: block;
          max-width: 120px;
          margin: 0 auto 12px;
        }
      }
      &__dots {
        position: relative;
        bottom: 0;
      }
      &__dot {
        width: 24px;
      }
      &__steps {
        margin-bottom: 6px;
        text-align: center;
        &.visible-mobile {
          display: block;
        }
        &.visible-desktop {
          display: none;
        }
      }
      &__text {
        margin-bottom: 34px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        &.visible-mobile {
          display: block;
        }
        &.visible-desktop {
          display: none;
        }
      }
      &__btn {
        width: 100%;
        max-width: 266px;
        height: 44px;
        margin: auto;
        padding: 0 95px;
        background-size: 26px;
        font-size: 16px;
        line-height: 43px;
        &.start {
          padding: 0 64px;
        }
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .game {
    &-pills {
      max-width: 350px;
      &__item {
        &-1 {
          top: 245px;
          left: 83px;
          width: 90px;
          height: 90px;
        }
        &-2 {
          top: 38px;
          left: 91px;
          width: 100px;
          height: 100px;
        }
        &-3 {
          top: 297px;
          left: 183px;
          width: 75px;
          height: 75px;
        }
        &-4 {
          top: 151px;
          left: 140px;
          width: 90px;
          height: 90px;
        }
        &-5 {
          top: 77px;
          left: 230px;
          width: 90px;
          height: 90px;
        }
        &-6 {
          top: 204px;
          left: 240px;
          width: 113px;
          height: 113px;
        }
        &-7 {
          top: 127px;
          left: 0px;
          width: 113px;
          height: 113px;
        }
      }
    }
    &-messages {
      &__image {
        img {
          margin: auto;
          width: 88px;
          height: 88px;
          margin-bottom: 80px;
        }
      }
    }
  }
}
@media screen and (max-width: 374px) {
  .game {
    &-layout {
      height: 385px;
      &.active-onboarding {
        height: 665px;
      }
    }
    &-ampule {
      left: 12px;
      bottom: 45px;
      width: 110px;
      height: 110px;
      &__icon {
        left: 43px;
        bottom: 42px;
        img {
          width: 32px;
        }
      }
      &__name {
        width: 140px;
        height: 46px;
        bottom: 10px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &-pill {
      right: 13px;
      bottom: 45px;
      width: 110px;
      height: 110px;
      &__icon {
        right: 37px;
        bottom: 60px;
        img {
          width: 55px;
        }
      }
      &__name {
        width: 140px;
        height: 46px;
        bottom: 10px;
        padding: 8px 0 4px;
        font-size: 12px;
        line-height: 15px;
      }
    }
    &-pills {
      max-width: 265px;
      &__item {
        &-1 {
          top: 128px;
          left: 78px;
          width: 60px;
          height: 60px;
        }
        &-2 {
          top: -20px;
          left: 67px;
          width: 70px;
          height: 70px;
        }
        &-3 {
          top: 116px;
          left: 146px;
          width: 45px;
          height: 45px;
        }
        &-4 {
          top: 59px;
          left: 102px;
          width: 60px;
          height: 60px;
        }
        &-5 {
          top: 6px;
          left: 150px;
          width: 60px;
          height: 60px;
        }
        &-6 {
          top: 60px;
          left: 185px;
          width: 80px;
          height: 80px;
        }
        &-7 {
          top: 39px;
          left: 0;
          width: 80px;
          height: 80px;
        }
      }
    }
    &-messages {
      &__item {
        &--green,
        &--red,
        &--red-ok,
        &--blue,
        &--yellow {
          .game {
            &-messages {
              &__info {
                bottom: 150px;
              }
            }
          }
        }
      }
      &__image {
        img {
          margin-bottom: 50px;
          max-width: 100px;
        }
      }
    }
  }
}
</style>
