var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-game"},[_c('div',{staticClass:"module-game__title"},[_vm._v(" Гра: знайдіть підробку ")]),_c('div',{staticClass:"module-game__text"},[_vm._v(" Давайте перевіримо, чи добре ви запам’ятали захисні елементи на упаковках Сумамед®. ")]),_c('div',{staticClass:"module-game__dots"},_vm._l((_vm.questions),function(question,key){return _c('div',{key:key,staticClass:"module-game__dots-item",class:{
          current: _vm.currentQuestion === key + 1,
          correct: question.isCorrect === true,
          incorrect: question.isCorrect === false
        }},[_c('div',{staticClass:"module-game__dots-item-inner"})])}),0),_c('div',{staticClass:"module-game__count"},[_c('span',[_vm._v(" "+_vm._s(_vm.currentQuestion >= 10 ? "" : "0")+_vm._s(_vm.currentQuestion)+" ")]),_vm._v(" / "+_vm._s(_vm.questionsCount >= 10 ? "" : "0")+_vm._s(_vm.questionsCount)+" ")]),_vm._l((_vm.questions),function(question,questionKey){return [(questionKey + 1 === _vm.currentQuestion)?_c('div',{key:questionKey,staticClass:"module-game__item"},[_c('div',{staticClass:"module-game__pack",class:[{incorrect: question.isCorrect === false}, ("question-" + (questionKey + 1))]},[_c('img',{attrs:{"src":require(("@/assets/img/training-module5/theme-2/game/" + (question.id) + ".png")),"alt":""}})]),(_vm.isTipActive)?_c('div',{staticClass:"module-game__result",class:{success: _vm.selectedAnswer.isCorrect, error: !_vm.selectedAnswer.isCorrect}},[_vm._v(" "+_vm._s(_vm.selectedAnswer.isCorrect ? question.tips.correct : question.tips.incorrect)+" "),_c('div',{staticClass:"module-game__closer",on:{"click":function($event){_vm.isTipActive = false}}},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 9L9 1","stroke":"white","stroke-width":"1.5","stroke-linecap":"round"}}),_c('path',{attrs:{"d":"M9 9L1 1","stroke":"white","stroke-width":"1.5","stroke-linecap":"round"}})])])]):_vm._e(),(_vm.currentQuestion === 1 && !_vm.selectedAnswer.id)?_c('div',{staticClass:"module-game__hint"},[_vm._v(" Уважно роздивіться зображення"),_c('br'),_vm._v(" та оберіть — це оригінал, або підробка ")]):_vm._e(),_c('div',{staticClass:"module-game__answers"},_vm._l((question.answers),function(answer,answerKey){return _c('button',{key:answerKey,staticClass:"module-game__answers-item",class:[
              {
                correct: answer.isCorrect && _vm.selectedAnswer.id === answer.id,
                incorrect: !answer.isCorrect && _vm.selectedAnswer.id === answer.id,
              },
              ("question-" + (questionKey + 1))
            ],attrs:{"disabled":_vm.selectedAnswer.id !== answer.id && _vm.selectedAnswer.id},domProps:{"innerHTML":_vm._s(answer.title)},on:{"click":function($event){return _vm.selectAnswer(question.id, answer, questionKey, answerKey)}}})}),0)]):_vm._e()]}),_c('button',{staticClass:"module-game__next next-button",attrs:{"type":"button","disabled":!_vm.selectedAnswer.id},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" Далі ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }