<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Олфен®-75: лікування меншим об’ємом
    </div>
    <div class="info-block">
      <div class="info-block__row">
        <div class="info-block__col-left">
          <div class="info-block__text">
            <p>
              Розпізнати клієнта з вертебральним больовим синдромом не так і
              важко<sup>1,2</sup>:
            </p>
            <ul>
              <li>Напівзігнуте положення</li>
              <li>Рука на попереку</li>
              <li>Гримаса болю</li>
            </ul>
          </div>
        </div>
        <div class="info-block__col-right">
          <div class="info-block__image">
            <img
              src="@/assets/img/training-module3/theme-2/step-1/1.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="info-block__row flex-end">
        <div class="info-block__col-left">
          <div class="info-block__text">
            <p class="text-bordered">
              <b>
                Біль у попереку
              </b>
            </p>
            <p>
              Чи знали ви, що <b>23% пацієнтів</b> із болем у попереку мають
              біль високої інтенсивності?<sup>1</sup>
            </p>
            <p>Це призводить до<sup>1</sup>:</p>
            <ul>
              <li>Погіршення якості життя</li>
              <li>Обмеження у фізичній діяльності</li>
              <li>Обмеження у повсякденному житті.</li>
            </ul>
          </div>
        </div>
        <div class="info-block__col-right">
          <div class="info-block__title">
            Пацієнту потрібне <br />
            турботливе лікування!
          </div>
          <div
            class="module-theory__next next-button"
            @click="nextState(2, 'next')"
          >
            Далі
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory1",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  },
  mounted() {
    this.windowWidth = document.body.clientWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = document.body.clientWidth;
    });
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  .info-block {
    margin-bottom: 48px;
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      &.flex-end {
        align-items: flex-end;
      }
    }
    &__col {
      &-left {
        flex-basis: 44%;
        max-width: 44%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 56%;
        max-width: 56%;
        padding: 0 10px;
      }
    }
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #00a03b;
    }
    &__text {
      p {
        margin-bottom: 18px;
        font-family: $fontR;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        b {
          font-family: $fontDemi;
          font-weight: 700;
          sup {
            font-family: $fontDemi;
          }
        }
        &.text-bordered {
          position: relative;
          padding-top: 24px;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 41px;
            height: 3px;
            background: #00a03b;
          }
        }
      }
      ul {
        padding-left: 17px;
        li {
          font-family: $fontR;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #545454;
        }
      }
    }
    &__image {
      img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }
    .info-block {
      margin-bottom: 0;
      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__text {
        p {
          margin-bottom: 11px;
          font-size: 14px;
          line-height: 17px;
        }
        ul {
          margin-bottom: 20px;
          li {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      &__image {
        margin-bottom: 24px;
      }
      &__title {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
