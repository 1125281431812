<template>
  <section class="article-page sumamed">
    <div class="main-page">
      <div class="content">
        <div class="article-wrapper">
          <!-- Begin filter -->
          <div class="filter">
            <div class="filter__head">
              <div class="filter__col filter__col-left">
                <!-- Begin patients -->
                <div class="filter-patients">
                  <div class="filter-title">Хто Ваш паціент?</div>
                  <div class="filter-patients__list">
                    <div
                      v-for="(patient, key) in patients"
                      :key="key"
                      class="filter-patients__item"
                      :class="`filter-patients__item_${patient.slug}-icon`"
                    >
                      <input
                        @change="
                          checkPatient();
                          changeFilter();
                        "
                        type="checkbox"
                        :id="`${patient.slug}-checkbox`"
                        :name="`patient-${patient.slug}`"
                        v-model="data.patient"
                        :value="patient.slug"
                        :disabled="patient.disabled"
                        placeholder
                      />
                      <label :for="`${patient.slug}-checkbox`">
                        {{ patient.title }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- // -->
                <!-- Begin weight -->
                <div class="filter-weight" v-if="data.patient[0] === 'child'">
                  <div class="filter-title">Вага, кг</div>
                  <div class="filter-weight__list">
                    <div
                      class="filter-weight__item"
                      v-for="(weight, key) in weights"
                      :key="key"
                    >
                      <input
                        type="checkbox"
                        :id="`weight-checkbox-${key}`"
                        @change="changeFilter()"
                        :value="weight.value"
                        v-model="data.weight"
                        :disabled="weight.disabled"
                        :name="`weight-${key}`"
                        placeholder
                      />
                      <label :for="`weight-checkbox-${key}`">
                        {{ weight.title }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- // -->
              </div>
              <div class="filter__col filter__col-center">
                <!-- Begin form -->
                <div class="filter-check">
                  <div class="filter-title">Виберіть форму препарату</div>
                  <div class="filter-check__list" v-if="drugForm">
                    <div
                      class="filter-check__item"
                      v-for="(form, index) in drugForm.options"
                      :key="index"
                    >
                      <input
                        type="checkbox"
                        :id="`form-checkbox-${index}`"
                        @change="changeFilter()"
                        :disabled="form.disabled"
                        :value="form.id"
                        v-model="data.form"
                        :name="`drug-form${index}`"
                        placeholder
                      />
                      <label :for="`form-checkbox-${index}`">
                        <span
                          :style="{
                            'background-image':
                              'url(' + $root.globalUrl + form.icon + ')'
                          }"
                        ></span>
                        {{ form.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- // -->
              </div>
              <div class="filter__col filter__col-right">
                <!-- Begin taste -->
                <div class="filter-check">
                  <div class="filter-title">Виберіть смак препарату</div>
                  <div class="filter-check__list" v-if="drugTaste">
                    <div
                      class="filter-check__item"
                      v-for="(taste, index) in drugTaste.options"
                      :key="index"
                    >
                      <input
                        type="checkbox"
                        :id="`taste-checkbox-${index}`"
                        @change="changeFilter()"
                        :disabled="taste.disabled"
                        :value="taste.id"
                        v-model="data.taste"
                        :name="taste.slug"
                        placeholder
                      />
                      <label :for="`taste-checkbox-${index}`">
                        <span
                          :style="{
                            'background-image':
                              'url(' + $root.globalUrl + taste.icon + ')'
                          }"
                        ></span>
                        {{ taste.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- // -->
              </div>
            </div>
            <div class="filter__footer">
              <div class="filter-calc" @click="toggleChildCalc">
                Калькулятор для дітей
              </div>
              <div
                class="filter-reset"
                :class="isClearFilter ? 'disabled' : ''"
                @click="resetFilters"
              >
                Скинути фільтри
              </div>
            </div>
          </div>
          <!-- // -->
          <!-- Begin tablets  -->
          <div class="tablets">
            <div
              class="tablets-list"
              v-for="(row, index) in drugGroups"
              :key="index"
            >
              <div
                class="tablets-col"
                v-for="(drug, key) in Object.values(drugs).slice(
                  index * drugCols,
                  (index + 1) * drugCols
                )"
                :key="`tablets-col-${index}-${key}`"
                :data-id="`tablets-col-${index}-${key}`"
              >
                <div
                  class="tablets-item"
                  :class="
                    `${index}-${key}` === activeDrugDetails ? 'active' : ''
                  "
                  @click="toggleDrugDetails(`${index}-${key}`, $event)"
                >
                  <div class="tablets-item__image">
                    <img :src="$root.globalUrl + drug.main_image.path" alt />
                    <div class="tablets-item__options">
                      <div
                        class="tablets-item__options-item"
                        v-if="drug.attributes.drug_form.options !== undefined"
                      >
                        <img
                          :src="
                            drugFormIcon(drug.attributes.drug_form.options[0])
                          "
                          alt
                        />
                      </div>
                      <div
                        class="tablets-item__options-item"
                        v-if="drug.attributes.drug_taste.options !== undefined"
                      >
                        <img
                          :src="
                            drugTasteIcon(drug.attributes.drug_taste.options[0])
                          "
                          alt
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="tablets-item__detail"
                    :style="{ backgroundColor: drug.bg }"
                  >
                    <div class="tablets-item__title">{{ drug.title }}</div>
                    <div
                      class="tablets-item__subtitle"
                      v-if="
                        getDrugFormSlug(
                          drug.attributes.drug_form.options[0]
                        ) === 'poroshok-dlya-oralnoji-suspenziji'
                      "
                    >
                      Флакон
                      {{ parseText(drug.dosing_text) }}
                    </div>
                    <div class="tablets-item__footer">
                      <div class="tablets-item__text">
                        {{ parseText(drug.form_release) }}
                      </div>
                      <div
                        class="tablets-item__btn"
                        :class="
                          `${index}-${key}` === activeDrugDetails
                            ? 'active'
                            : ''
                        "
                      >
                        {{
                          `${index}-${key}` === activeDrugDetails
                            ? "⟵"
                            : "Детальніше"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tablets-details"
                v-for="(drug, key) in Object.values(drugs).slice(
                  index * drugCols,
                  (index + 1) * drugCols
                )"
                :key="`tablets-details-${index}-${key}`"
                :data-id="`tablets-details-${index}-${key}`"
                v-show="`${index}-${key}` === activeDrugDetails"
              >
                <div class="tablets-details__content">
                  <div class="tablets-details__close">
                    <div
                      class="tablets-details__close-btn"
                      @click="toggleDrugDetails(`${index}-${key}`)"
                    >
                      Закрити
                    </div>
                  </div>
                  <!-- Begin slider -->
                  <div class="tablets-slider">
                    <Slider
                      :drug="drug"
                      :id="`tablets-slider-${index}-${key}`"
                    />
                  </div>
                  <!-- // -->
                  <!-- Begin tablets description -->
                  <div class="tablets-description">
                    <div class="tablets-description__row">
                      <div class="tablets-description__col">
                        <div
                          class="mode-application"
                          :class="getPatientClass(drug)"
                          :style="{ backgroundColor: drug.bg }"
                        >
                          <div class="mode-application__title">
                            {{ parseText(drug.dosing_title) }}
                          </div>
                          <div
                            class="mode-application__text"
                            v-html="drug.dosing_description"
                          ></div>
                          <div class="mode-application__options">
                            <div
                              class="mode-application__option mode-application__option_weight"
                              v-if="drug.dosing_table_header.weight.is_active"
                              :class="
                                `form-slug-${getDrugFormSlug(
                                  drug.attributes.drug_form.options[0]
                                )}`
                              "
                            >
                              <div class="mode-application__option-title">
                                {{
                                  parseText(
                                    drug.dosing_table_header.weight.title
                                  )
                                }}
                              </div>
                              <div
                                class="mode-application__option-value"
                                v-for="(tableWeight, key) in drug.dosing_table"
                                :key="`weight-${key}`"
                              >
                                {{ tableWeight.weight.replace(/>=/, "≥") }}
                              </div>
                            </div>

                            <div
                              class="mode-application__option mode-application__option_dose"
                              v-if="drug.dosing_table_header.days.is_active"
                              :class="
                                `form-slug-${getDrugFormSlug(
                                  drug.attributes.drug_form.options[0]
                                )}`
                              "
                            >
                              <div class="mode-application__option-title">
                                {{
                                  parseText(drug.dosing_table_header.days.title)
                                }}
                              </div>

                              <div
                                class="mode-application__option-value"
                                v-for="(tableDays, key) in drug.dosing_table"
                                :key="`day-${key}`"
                              >
                                {{ tableDays.days.replace(/\./, ",") }}
                              </div>
                            </div>

                            <div
                              class="mode-application__option mode-application__option_weight"
                              v-if="
                                drug.dosing_table_header.reception_count
                                  .is_active
                              "
                              :class="
                                `form-slug-${getDrugFormSlug(
                                  drug.attributes.drug_form.options[0]
                                )}`
                              "
                            >
                              <div class="mode-application__option-title">
                                {{
                                  parseText(
                                    drug.dosing_table_header.reception_count
                                      .title
                                  )
                                }}
                              </div>
                              <div
                                class="mode-application__option-value"
                                v-for="(tableReceptionCount,
                                key) in drug.dosing_table"
                                :key="`reception-count-${key}`"
                              >
                                {{
                                  tableReceptionCount.reception_count.replace(
                                    />=/,
                                    "≥"
                                  )
                                }}
                              </div>
                            </div>

                            <div
                              class="mode-application__option mode-application__option_dose"
                              v-if="drug.dosing_table_header.dose.is_active"
                              :class="
                                `form-slug-${getDrugFormSlug(
                                  drug.attributes.drug_form.options[0]
                                )}`
                              "
                            >
                              <div class="mode-application__option-title">
                                {{
                                  parseText(drug.dosing_table_header.dose.title)
                                }}
                              </div>

                              <div
                                class="mode-application__option-value"
                                v-for="(tableDose, key) in drug.dosing_table"
                                :key="`dose-${key}`"
                              >
                                {{ tableDose.dose.replace(/\./, ",") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tablets-description__col">
                        <div class="tablets-detail">
                          <h3 class="tablets-detail__name">{{ drug.title }}</h3>
                          <div class="tablets-detail__item">
                            <div class="tablets-detail__item-title">
                              Форма випуску
                            </div>
                            <div class="tablets-detail__item-text">
                              {{ parseText(drug.form_release) }}
                            </div>
                          </div>
                          <div class="tablets-detail__item">
                            <div class="tablets-detail__item-title">
                              Доступні смаки
                            </div>
                            <div class="tablets-detail__item-text">
                              {{ parseText(drug.available_taste) }}
                            </div>
                          </div>
                          <div class="tablets-detail__item">
                            <div class="tablets-detail__item-title green">
                              Дозування
                            </div>
                            <div class="tablets-detail__item-text tablets-dose">
                              {{ parseText(drug.dosing_text) }}
                            </div>
                          </div>
                          <div class="tablets-detail__item">
                            <div class="tablets-detail__item-title">
                              Як приймати
                            </div>
                            <div
                              class="tablets-detail__item-text"
                              v-html="drug.use_instruction"
                            ></div>
                          </div>
                          <div class="tablets-detail__item">
                            <div class="tablets-detail__item-title">
                              Додаткові переваги:
                            </div>
                            <div
                              class="tablets-detail__item-text"
                              v-html="drug.additional_benefits"
                            ></div>
                          </div>
                          <a
                            :href="$root.globalUrl + drug.instruction_file.path"
                            download
                            target="_blank"
                            class="tablets-detail__btn"
                            >{{ drug.instruction_title }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- // -->
                </div>
              </div>
            </div>
          </div>
          <!-- // -->
        </div>
      </div>
      <!-- Begin child calc -->
      <div class="modal" v-if="isChildCalc">
        <div class="modal-main">
          <div class="modal-close" @click="toggleChildCalc">Закрити</div>
          <div class="modal-content">
            <div class="child-calc">
              <div class="child-calc__title">
                Розрахунок дози
                <br />для дитини
              </div>
              <div class="child-calc__weight">
                <span class="child-calc__weight-value">
                  {{ parseInt(data.weight) }}
                </span>
                <span class="child-calc__weight-comma">,</span>
                <span class="child-calc__weight-value">0</span>
                <span class="child-calc__weight-units">кг</span>
              </div>
              <div class="child-calc__range">
                <veeno
                  v-model="data.weight"
                  connect
                  :pipsy="{ mode: 'steps', density: 17 }"
                  :handles="[5, 5]"
                  :range="{ min: 5, max: 45 }"
                />
              </div>
              <a
                href="#"
                class="child-calc__submit"
                @click.prevent="
                  childCalc({ from: 5, to: parseInt(data.weight) })
                "
                >Розрахувати</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- // -->
      <!-- Begin child calc result -->
      <div class="modal" v-if="isChildCalcResults && childDrugs.length !== 0">
        <div class="modal-main">
          <div class="modal-close" @click="toggleChildCalcResults">Закрити</div>
          <div class="modal-content">
            <div class="calc-results">
              <div class="calc-results__list">
                <div
                  class="calc-results__item"
                  v-for="(drug, key) in Object.values(childDrugs).splice(0, 2)"
                  :key="key"
                >
                  <div class="calc-results__image">
                    <img :src="$root.globalUrl + drug.main_image.path" alt />
                  </div>
                  <div class="calc-results__options">
                    <div class="calc-results__options-item">
                      <img
                        :src="
                          drugFormIcon(drug.attributes.drug_form.options[0])
                        "
                        alt
                      />
                    </div>
                    <div class="calc-results__options-item">
                      <img
                        :src="
                          drugTasteIcon(drug.attributes.drug_taste.options[0])
                        "
                        alt
                      />
                    </div>
                  </div>
                  <div class="calc-results__taste">
                    {{ parseText(drug.available_taste) }}
                  </div>
                </div>
              </div>
              <div class="calc-results__detail">
                <div class="calc-results__row">
                  <div class="calc-results__col">
                    <div class="calc-results__drug">
                      <div class="calc-results__drug-title">Вам потрібен</div>
                      <div class="calc-results__drug-value">
                        {{ childDrugs[0].title.split("®")[0] }}®
                        {{
                          parseText(childDrugs[0].dosing_text).replace(
                            /\(\w.+\)/gm,
                            ""
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="calc-results__col">
                    <div class="calc-results__dose">
                      <div class="calc-results__dose-title">Добова доза</div>
                      <div class="calc-results__dose-value">
                        {{ getChildDrugDose }} мл 1 раз на добу
                      </div>
                    </div>
                  </div>
                </div>
                <div class="calc-results__text">
                  Щоб приготувати суспензію, додайте у флакон
                  <span>{{ childDrugs[0].volume_water }} мл</span> води
                </div>
              </div>
              <div class="calc-results__buttons">
                <a
                  href="#"
                  class="calc-results__btn"
                  @click.prevent="toggleChildCalcResults"
                  >Зрозуміло</a
                >
                <a
                  href="#"
                  @click.prevent="childCalcAgain"
                  class="calc-results__btn calc-results__btn_transparent"
                  >Розрахувати знову</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- // -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
import veeno from "veeno";
import Slider from "@/components/sumamed/Slider";

export default {
  name: "Sumamed",
  data() {
    return {
      patients: [
        {
          title: "Дитина",
          slug: "child",
          disabled: false
        },
        {
          title: "Дорослий",
          slug: "adult",
          disabled: false
        }
      ],
      weights: [
        {
          title: "5-14",
          disabled: false,
          value: { from: 5, to: 14 }
        },
        {
          title: "15-17",
          disabled: false,
          value: { from: 15, to: 17 }
        },
        {
          title: "18-24",
          disabled: false,
          value: { from: 18, to: 24 }
        },
        {
          title: "25-30",
          disabled: false,
          value: { from: 25, to: 30 }
        },
        {
          title: "31-44",
          disabled: false,
          value: { from: 31, to: 44 }
        },
        {
          title: "Вага 45+",
          disabled: false,
          value: { from: 45, to: 100 }
        }
      ],
      drugForm: null,
      drugTaste: null,
      drugs: [],
      drugRows: null,
      drugCols: null,
      childDrugs: [],
      data: {
        patient: [],
        weight: [],
        form: [],
        taste: []
      },
      isClearFilter: true,
      childDrugResults: [],
      isChildCalc: false,
      isChildCalcResults: false,
      activeDrugDetails: null
    };
  },
  components: {
    veeno,
    Slider
  },
  computed: {
    // get patient class
    drugGroups() {
      var drugGroup = null;
      if (this.drugs) {
        drugGroup = Array.from(
          Array(
            Math.ceil(Object.keys(this.drugs).length / this.drugCols)
          ).keys()
        );
      }
      return drugGroup;
    },
    getChildDrugDose() {
      var dose = null;
      if (this.childDrugs.length !== 0) {
        this.childDrugs[0].dosing_table.forEach(item => {
          if (item.weight !== null) {
            var currentWeight = parseInt(this.data.weight);
            var weightArray = item.weight.split("-");

            if (
              weightArray.length === 1 &&
              currentWeight === Number(weightArray[0])
            ) {
              dose = item.dose;
            } else if (weightArray.length === 1 && item.weight.match(/>=/)) {
              let weight = item.weight.replace(/>=/, "");
              if (currentWeight >= weight) {
                dose = item.dose;
              }
            } else if (
              weightArray.length === 2 &&
              currentWeight >= Number(weightArray[0]) &&
              currentWeight <= Number(weightArray[1])
            ) {
              dose = item.dose;
            }
          }
        });
      }
      return dose;
    }
  },
  methods: {
    // get all drugs forms and tastes
    async getDrugAttributes() {
      await axios
        .get(this.$api.host + "/api/v1/attributes/")
        .then(response => {
          var data = response.data;
          data.forEach(drug => {
            if (drug.slug === "drug_form") {
              drug.options.forEach(option => {
                option.disabled = false;
              });
              this.drugForm = drug;
            }
            if (drug.slug === "drug_taste") {
              drug.options.forEach(option => {
                option.disabled = false;
              });
              this.drugTaste = drug;
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    // Get all drugs
    async getAllDrugs() {
      await axios
        .post(this.$api.host + "/api/v1/pages/filter/sumamed", {
          slug: "p-oberit-svii-preparat-z-liniiki-sumamed-r-p",
          patients: [],
          weight: {},
          attributes: [
            {
              attribute_id: 1,
              options: []
            },
            {
              attribute_id: 2,
              options: []
            }
          ]
        })
        .then(response => {
          if (response.status === 200) {
            this.drugs = response.data;
            this.drugRows = Math.ceil(
              Object.keys(this.drugs).length / this.drugCols
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // change filter inputs
    async changeFilter() {
      // get drugs by filter
      await axios
        .post(this.$root.globalUrl + "/api/v1/pages/filter/sumamed", {
          slug: "p-oberit-svii-preparat-z-liniiki-sumamed-r-p",
          patients: this.data.patient,
          weight: this.data.weight.length === 0 ? {} : this.data.weight[0],
          attributes: [
            {
              attribute_id: 1,
              options: this.data.form
            },
            {
              attribute_id: 2,
              options: this.data.taste
            }
          ]
        })
        .then(response => {
          if (response.status === 200) {
            this.drugs = response.data;
            this.drugRows = Math.ceil(
              Object.keys(this.drugs).length / this.drugCols
            );
            this.checkAvaliableFilters();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // beby clalc by weight
    async childCalc() {
      var weightChild = {
        from: parseInt(this.data.weight),
        to: parseInt(this.data.weight)
      };
      await axios
        .post(this.$root.globalUrl + "/api/v1/pages/filter/sumamed", {
          slug: "p-oberit-svii-preparat-z-liniiki-sumamed-r-p",
          is_for_calculator: true,
          patients: ["child"],
          weight: weightChild
        })
        .then(response => {
          this.childDrugs = response.data;
          this.toggleChildCalc();
          this.toggleChildCalcResults();
        })
        .catch(error => {
          console.log(error);
        });
    },
    // get form slug
    getDrugFormSlug(formId) {
      if (this.drugForm) {
        var formSlug = null;
        this.drugForm.options.forEach(form => {
          if (form.id === formId) {
            formSlug = form.slug;
          }
        });
        return formSlug;
      }
    },
    // get patient class
    getPatientClass(drug) {
      var drugForm = drug.attributes.drug_form.options[0];
      if (
        this.getDrugFormSlug(drugForm) === "tabletki-shcho-disperguyutsya" &&
        drug.patients.length === 2
      ) {
        drug.patients.push("position-bottom");
        return drug.patients;
      }
      if (
        this.getDrugFormSlug(drugForm) ===
          "tabletki-vkriti-plivkovoyu-obolonkoyu" &&
        drug.patients.length === 2
      ) {
        drug.patients.push("position-patient");
        return drug.patients;
      } else {
        return drug.patients;
      }
    },
    // get drug form icon
    drugFormIcon(formId) {
      var formIcon = null;
      this.drugForm.options.forEach(form => {
        if (formId === form.id) {
          formIcon = this.$root.globalUrl + form.icon;
        }
      });
      return formIcon;
    },
    // get drug taste icon
    drugTasteIcon(tasteId) {
      var tasteIcon = null;
      this.drugTaste.options.forEach(taste => {
        if (tasteId === taste.id) {
          tasteIcon = this.$root.globalUrl + taste.icon;
        }
      });
      return tasteIcon;
    },
    // reset all filters and disable attributes
    resetFilters() {
      this.data.patient = [];
      this.patients.forEach(patient => {
        patient.disabled = false;
      });
      this.weights.forEach(weight => {
        weight.disabled = false;
      });
      this.data.weight = [];
      this.data.form = [];
      this.data.taste = [];
      this.isClearFilter = true;
      this.drugs = [];
      this.activeDrugDetails = null;
      this.drugForm.options.forEach(option => {
        option.disabled = false;
      });
      this.drugTaste.options.forEach(option => {
        option.disabled = false;
      });
      this.getAllDrugs();
    },
    // check patient
    checkPatient() {
      if (this.data.patient !== "child") {
        this.data.weight = [];
      }
    },
    // check is avaliable drug form and teasty
    checkAvaliableFilters() {
      var patientsArray = [];
      var weightsArray = [];
      var drugFormsArray = [];
      var drugTasteArray = [];

      if (
        this.data.patient.length === 0 &&
        this.data.weight.length === 0 &&
        this.data.form.length === 0 &&
        this.data.taste.length === 0
      ) {
        this.isClearFilter = true;
      } else {
        this.isClearFilter = false;
      }

      this.drugs.forEach(drug => {
        // check all drug weights
        let weightFrom = Number(drug.calulator_weight_from);
        let weightTo = Number(drug.calulator_weight_to);
        if (!weightsArray.includes(weightTo)) {
          weightsArray.push(weightTo);
        }
        if (!weightsArray.includes(weightFrom)) {
          weightsArray.push(weightFrom);
        }
        // check all drug patients
        drug.patients.forEach(patient => {
          if (!patientsArray.includes(patient)) {
            patientsArray.push(patient);
          }
        });
        // check all drug forms
        if (drug.attributes.drug_form.options !== undefined) {
          var drugFormId = drug.attributes.drug_form.options[0];
          if (!drugFormsArray.includes(drugFormId)) {
            drugFormsArray.push(drugFormId);
          }
        }
        // check all drug taste
        if (drug.attributes.drug_taste.options !== undefined) {
          var drugTasteId = drug.attributes.drug_taste.options[0];
          if (!drugTasteArray.includes(drugTasteId)) {
            drugTasteArray.push(drugTasteId);
          }
        }
      });

      // check is patient avaliable
      this.patients.forEach(patient => {
        if (patientsArray.includes(patient.slug)) {
          if (
            this.data.patient.length !== 0 &&
            patient.slug !== this.data.patient[0]
          ) {
            patient.disabled = true;
          } else {
            patient.disabled = false;
          }
        } else {
          patient.disabled = true;
        }
      });
      // check is weight avaliable
      this.drugs.forEach(drug => {
        if (drug.attributes.drug_taste.options[0] !== 7) {
          this.weights.forEach(weight => {
            // get min of array
            Array.prototype.min = function() {
              return Math.min.apply(null, this);
            };
            Array.prototype.max = function() {
              return Math.max.apply(null, this);
            };

            if (
              weight.value.from >= weightsArray.min() &&
              weight.value.to <= weightsArray.max() &&
              weight.value.to <= weightsArray.max()
            ) {
              if (this.data.weight[0] !== undefined) {
                if (
                  this.data.weight[0].from === weight.value.from &&
                  this.data.weight[0].to === weight.value.to
                ) {
                  weight.disabled = false;
                } else {
                  weight.disabled = true;
                }
              } else {
                weight.disabled = false;
              }
            } else {
              weight.disabled = true;
            }
          });
        }
      });

      // check is drug form avaliable
      if (this.drugForm) {
        this.drugForm.options.forEach(option => {
          if (drugFormsArray.includes(option.id)) {
            option.disabled = false;
          } else {
            option.disabled = true;
          }
        });
      }
      // check is drug taste avaliable
      if (this.drugTaste) {
        this.drugTaste.options.forEach(option => {
          if (drugTasteArray.includes(option.id)) {
            option.disabled = false;
          } else {
            option.disabled = true;
          }
        });
      }
    },
    // show hide child calc modal
    toggleChildCalc() {
      this.isChildCalc = !this.isChildCalc;
    },
    toggleChildCalcResults() {
      this.isChildCalcResults = !this.isChildCalcResults;
    },
    childCalcAgain() {
      this.isChildCalcResults = !this.isChildCalcResults;
      this.isChildCalc = !this.isChildCalc;
    },
    toggleDrugDetails(index) {
      if (this.activeDrugDetails === null) {
        this.activeDrugDetails = index;
      } else if (this.activeDrugDetails !== index) {
        this.activeDrugDetails = null;
        this.activeDrugDetails = index;
      } else {
        this.activeDrugDetails = null;
      }
      setTimeout(() => {
        document
          .querySelector(`[data-id=tablets-details-${index}]`)
          .scrollIntoView({ block: "start", behavior: "smooth" });
      }, 100);
    },
    parseText(html) {
      return document.createRange().createContextualFragment(html).textContent;
    },
    checkWindowWidth() {
      if (window.outerWidth >= 850) {
        this.drugCols = 3;
      }
      if (window.outerWidth <= 850) {
        this.drugCols = 2;
      }
      if (window.outerWidth <= 580) {
        this.drugCols = 1;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.checkWindowWidth();
    });
  },
  beforeMount() {
    this.checkWindowWidth();
    this.getDrugAttributes();
    this.getAllDrugs();
  }
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

@import "~tiny-slider/dist/tiny-slider.css";
// Begin main
.sumamed {
  .main-page {
    .content {
      margin-top: 0;
    }
  }
}
.main-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    position: relative;
    z-index: 2;
    max-width: $maxW;
    width: 100%;
    min-height: calc(100vh - 239px);
    height: 100%;
    margin: 95px auto 40px auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    .articles-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-top: 6px;

      @media screen and (max-width: 572px) {
        flex-flow: column nowrap;
        align-items: center;
      }
      .article-item {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 7px 14px 7px;
        max-height: 302px;

        &:hover {
          .more-btn {
            background: #00a03b !important;
            p {
              color: white !important;
            }
          }
        }

        @media screen and (max-width: 572px) {
          max-height: 1000px;
          height: auto;
          margin: 0 7px 15px 7px;
        }
      }
    }

    .more-article-btn {
      display: none;
      justify-content: center;
      align-items: center;
      max-width: 198px;
      width: 100%;
      height: 44px;
      margin: 0 auto 30px auto;
      background: #aa198b;
      border-radius: 60px;

      * {
        transition: all 0.5s ease;
      }

      &:active {
        svg {
          transform: rotate(360deg);
        }
      }

      p {
        color: #ffffff;
        font-size: 11px;
        font-family: $fontR;
        padding-left: 6px;
      }

      @media screen and (max-width: 572px) {
        display: flex;
      }
    }
  }
}
// Begin filter
.filter {
  padding-top: 25px;
  font-family: $fontDemi;
  &__head {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__col {
    padding: 0 12px;
    &-left {
      flex-basis: 35%;
      max-width: 35%;
    }
    &-center {
      flex-basis: 38%;
      max-width: 38%;
    }
    &-right {
      flex-basis: 27%;
      max-width: 27%;
    }
  }
  &-title {
    margin-bottom: 17px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    color: #005a85;
    &_disabled {
      color: #bdbdbd;
    }
  }
  &-patients {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 18px;
    }
    &__item {
      label {
        display: block;
        width: 124px;
        height: 124px;
        padding-top: 84px;
        background-color: #e4eaed;
        background-repeat: no-repeat;
        background-position: center 20px;
        border-radius: 33px;
        font-weight: 750;
        font-size: 15px;
        text-align: center;
        color: #00a03b;
        cursor: pointer;
      }
      input {
        display: none;
        &:checked + label {
          background: #00a03b;
          color: #fff;
        }
        &:disabled {
          + label {
            pointer-events: none;
            // user-select: none;
            color: #848484;
            opacity: 0.4;
            filter: grayscale(100%);
          }
        }
      }
      &_child-icon {
        label {
          background-image: url("~@/assets/img/sumamed/icon-child.png");
        }
        input {
          &:checked + label {
            background-image: url("~@/assets/img/sumamed/icon-child-white.png");
            background-position: center 20px;
            background-repeat: no-repeat;
          }
        }
      }
      &_adult-icon {
        label {
          background-image: url("~@/assets/img/sumamed/icon-adult.png");
        }
        input {
          &:checked + label {
            background-image: url("~@/assets/img/sumamed/icon-adult-white.png");
            background-position: center 20px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  &-weight {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item {
      flex-basis: 128px;
      max-width: 128px;
      margin-bottom: 10px;
      input {
        display: none;
        &:checked + label {
          background-color: #005a85;
          color: #fff;
        }
        &:disabled + label {
          pointer-events: none;
          // user-select: none;
          color: #bdbdbd;
        }
      }
      label {
        display: block;
        padding: 12px;
        background-color: #ffffff;
        box-shadow: 3px 3px 10px rgba(148, 156, 159, 0.4);
        border-radius: 19px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #005a85;
        cursor: pointer;
      }
    }
  }
  &-check {
    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 4px;
    }
    &__item {
      margin-bottom: 11px;
      &:last-child {
        margin-bottom: 0;
      }
      input {
        display: none;
        &:checked + label {
          background-color: #005a85;
          color: #fff;
        }
        &:disabled {
          + label {
            pointer-events: none;
            // user-select: none;
            color: #bdbdbd;
            span {
              filter: grayscale(100%);
              opacity: 0.3;
            }
          }
        }
      }
      label {
        position: relative;
        display: block;
        padding: 11px 15px 11px 45px;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-size: 32px;
        background-position: 4px center;
        box-shadow: 3px 3px 10px rgba(148, 156, 159, 0.4);
        border-radius: 20.7407px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #005a85;
        cursor: pointer;
        span {
          position: absolute;
          width: 32px;
          height: 32px;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          border-radius: 50%;
        }
      }
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 29px;
    margin-bottom: 45px;
  }
  &-calc {
    padding: 16px 24px 20px 60px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/sumamed/calc-icon.png");
    background-repeat: no-repeat;
    background-position: 21px center;
    border-radius: 150px;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #ffffff;
    cursor: pointer;
  }
  &-reset {
    padding-left: 40px;
    margin-right: 38px;
    margin-top: 7px;
    background: url("~@/assets/img/sumamed/reset-filter-icon.png") no-repeat
      left center;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #005a85;
    cursor: pointer;
    &.disabled {
      background: url("~@/assets/img/sumamed/reset-filter-white-icon.png")
        no-repeat left center;
      color: #bdbdbd;
    }
  }
}
// Begin tablets
.tablets {
  font-family: $fontDemi;
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -13.5px;
  }
  &-col {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
    padding: 0 13.5px;
  }
  &-item {
    position: relative;
    margin-bottom: 19px;
    &.active {
      &:before {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 13px 10px;
        border-color: transparent transparent #00a03b transparent;
      }
    }
    &__image {
      position: relative;
      border: 2px solid #d0e1ea;
      border-bottom: none;
      > img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 265px;
        object-fit: cover;
      }
    }
    &__options {
      position: absolute;
      top: 12px;
      left: 12px;
      &-item {
        width: 32px;
        height: 32px;
        margin-bottom: 9px;
        border-radius: 50%;
      }
    }
    &__detail {
      padding: 15px 20px;
      min-height: 145px;
      color: #fff;
    }
    &__title {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
    }
    &__subtitle {
      margin-bottom: 7px;
      font-weight: 450;
      font-size: 14px;
      line-height: 17px;
    }
    &__footer {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 15px;
      padding-left: 20px;
      min-height: 26px;
    }
    &__text {
      max-width: 150px;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
    &__btn {
      position: absolute;
      bottom: 5px;
      right: 0;
      background: #f5f5f5;
      border-top-left-radius: 62.5618px;
      border-bottom-left-radius: 62.5618px;
      padding: 6px 12px 9px 15px;
      font-weight: normal;
      font-size: 9px;
      line-height: 11px;
      color: #00a03b;
      cursor: pointer;
      transition: background-color 0.5s, color 0.5s;
      &.active {
        padding: 6px 10px 9px 15px;
        font-size: 16px;
      }
      &:hover {
        background-color: #379c78;
        color: #fff;
      }
    }
  }
  &-details {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 13.5px;
    &__content {
      position: relative;
      padding-top: 19px;
      border-top: 5px solid #00a03b;
    }
    &__close {
      margin-bottom: 19px;
      text-align: right;
      &-btn {
        display: inline-block;
        background-color: #e4eaed;
        background-image: url("~@/assets/img/sumamed/close-icon.png");
        background-repeat: no-repeat;
        background-position: 8px center;
        border-radius: 40px;
        padding: 7.5px 14px 7.5px 31px;
        font-size: 9px;
        line-height: 11px;
        color: #005a85;
        cursor: pointer;
      }
    }
  }
  &-slider {
    margin-bottom: 21px;
  }
  &-description {
    margin-bottom: 32px;
    &__row {
      display: flex;
      flex-wrap: wrap;
    }
    &__col {
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 9.5px;
    }
  }
  .mode-application {
    padding: 28px 51px 29px 96px;
    &.child {
      background-image: url("~@/assets/img/sumamed/icon-child-white.png");
      background-repeat: no-repeat;
      background-position: 27px 28px;
    }
    &.adult {
      background-image: url("~@/assets/img/sumamed/icon-adult-white.png");
      background-repeat: no-repeat;
      background-position: 27px 28px;
    }
    &.child.adult {
      background-image: url("~@/assets/img/sumamed/icon-adult-white.png"),
        url("~@/assets/img/sumamed/icon-child-white.png");
      background-repeat: no-repeat, no-repeat;
      background-position: 27px 28px, 27px 100px;
      &.position-patient {
        background-position: 27px 80px, 27px 140px;
        min-height: 230px;
      }
      &.position-bottom {
        background-position: 27px 170px, 27px 240px;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
    }
    &__text {
      margin-bottom: 21px;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      &::v-deep p {
        margin-bottom: 10px;
      }
    }
    &__options {
      display: flex;
      flex-wrap: wrap;
    }
    &__option {
      flex-basis: 82px;
      margin-right: 8px;
      padding: 20px 10px;
      color: #fff;
      text-align: center;
      &_weight {
        &.form-slug-poroshok-dlya-oralnoji-suspenziji {
          background: #09913b;
        }
        &.form-slug-tabletki-shcho-disperguyutsya {
          background: #245959;
        }
      }
      &_dose {
        &.form-slug-poroshok-dlya-oralnoji-suspenziji {
          background: rgba(255, 255, 255, 0.09);
        }
        &.form-slug-tabletki-shcho-disperguyutsya {
          background: rgba(255, 255, 255, 0.09);
        }
      }
      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 39px;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
      }
      &-value {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .tablets-detail {
    padding-left: 4px;
    &__name {
      margin-bottom: 23px;
      font-weight: 600;
      font-size: 36px;
      line-height: 34px;
      color: #00a03b;
    }
    &__item {
      margin-bottom: 20px;
      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #545454;
        &.green {
          color: #00a03b;
        }
      }
      &-text {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #545454;
        &.tablets-dose {
          font-weight: 600;
          font-size: 30px;
          line-height: 37px;
          color: #00a03b;
        }
        p {
          margin-bottom: 6px;
          &:first-child {
            margin-top: 10px;
          }
          &:last-of-type {
            margin-top: 28px;
            margin-bottom: 0;
          }
        }
      }
    }
    &__btn {
      display: inline-block;
      padding: 10px 21px 10px 37px;
      background-color: #aa198b;
      background-image: url("~@/assets/img/sumamed/download-icon.png");
      background-repeat: no-repeat;
      background-position: 16px center;
      border-radius: 20px;
      font-size: 14px;
      line-height: 11px;
      text-decoration: none;
      color: #ffffff;
    }
  }
}
//  Begin modal
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 120, 110, 0.7);
  overflow-y: auto;
  z-index: 999;
  &-main {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 785px;
    transform: translate(-50%, -50%);
    background: #fff;
  }
  &-close {
    position: absolute;
    top: 19px;
    right: 19px;
    background-color: #e4eaed;
    background-image: url("~@/assets/img/sumamed/close-icon.png");
    background-repeat: no-repeat;
    background-position: 8px center;
    border-radius: 40px;
    padding: 7.5px 14px 7.5px 31px;
    font-size: 9px;
    line-height: 11px;
    color: #005a85;
    cursor: pointer;
  }
}
.child-calc {
  position: relative;
  max-width: 360px;
  margin: 85px auto 85px;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 310px;
    background: url("~@/assets/img/sumamed/duck.png") no-repeat;
  }
  &__title {
    margin-bottom: 27px;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #317575;
  }
  &__weight {
    margin-bottom: 36px;
    font-weight: 750;
    font-size: 42.5676px;
    line-height: 52px;
    text-align: center;
    color: #00a03b;
    text-align: center;
    &-value {
      display: inline-block;
      padding: 4px 7px 4px 6px;
      background: #e4eaed;
      border-radius: 5px;
      text-align: center;
    }
    &-comma {
      display: inline-block;
      width: 12px;
      margin: 0 2px 0 3px;
      text-align: center;
    }
    &-units {
      position: relative;
      left: 8px;
      margin: 10px 6px;
      font-size: 18.2432px;
      line-height: 22px;
      text-align: center;
    }
  }
  &__range {
    margin-bottom: 61px;
  }
  &__submit {
    display: block;
    max-width: 158px;
    margin: auto;
    background: #317575;
    border-radius: 100px;
    padding: 11px 20px 13px 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    text-align: center;
    color: #ffffff;
  }
}
.calc-results {
  &__list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 9px;
  }
  &__item {
    position: relative;
    flex-basis: 210px;
    max-width: 210px;
  }
  &__image {
    position: relative;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 180px;
    }
  }
  &__options {
    position: absolute;
    top: 60px;
    left: 23px;
    &-item {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-bottom: 8px;
      overflow: hidden;
    }
  }
  &__taste {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: capitalize;
    color: #005a85;
  }
  &__detail {
    padding: 27px 50px 15px 50px;
    background: #efefef;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__col {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 15px;
  }
  &__drug {
    background: url("~@/assets/img/sumamed/drug-name-icon.png") no-repeat;
    background-position: 11px 6px;
    padding-left: 67px;
    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #00a03b;
    }
    &-value {
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }
  }
  &__dose {
    background: url("~@/assets/img/sumamed/drug-dose-icon.png") no-repeat;
    background-position: 10px 5px;
    padding-left: 58px;
    &-title {
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      color: #545454;
    }
    &-value {
      font-weight: 600;
      font-size: 25px;
      line-height: 31px;
      color: #005a85;
    }
  }
  &__text {
    padding-top: 15px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #545454;
    span {
      font-weight: 600;
      color: #005a85;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    padding: 35px 0 43px;
  }
  &__btn {
    display: block;
    width: 100%;
    max-width: 168px;
    margin: 0 7.5px;
    background: #317575;
    border-radius: 100px;
    padding: 11px 0 11px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    &_transparent {
      background: transparent;
      border: 2px solid #317575;
      color: #317575;
    }
  }
}
@media screen and (max-width: 920px) {
  .modal {
    &-main {
      max-width: 550px;
    }
  }
  .child-calc {
    max-width: 290px;
    margin: 85px auto 0;
    &:before {
      display: none;
    }
    &:after {
      content: "";
      display: block;
      width: 235px;
      height: 195px;
      margin: auto;
      background: url("~@/assets/img/sumamed/duck.png") no-repeat;
      background-size: contain;
    }
  }
  .calc-results {
    &__detail {
      padding: 27px 15px 35px 15px;
    }
    &__list {
      margin-bottom: 34px;
    }
    &__drug {
      padding-left: 60px;
      background-position: 7px 6px;
      &-title {
        font-size: 16px;
      }
      &-value {
        font-size: 20px;
        line-height: 32px;
      }
    }
    &__dose {
      padding-left: 60px;
      background-size: 25px;
      &-title {
        font-size: 18px;
      }
      &-value {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .filter {
    &__col {
      &-left {
        flex-basis: 100%;
        max-width: 100%;
      }
      &-center,
      &-right {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
    &-patients {
      &__list {
        justify-content: flex-start;
      }
      &__item {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-weight {
      margin-bottom: 30px;
      &__list {
        justify-content: flex-start;
        max-width: 430px;
      }
      &__item {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .tablets {
    &-col {
      flex-basis: 50%;
      max-width: 50%;
    }

    &-description {
      &__col {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
  .mode-application {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .filter {
    &__col {
      &-center,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &-check {
      margin-bottom: 30px;
    }
    &-patients {
      &__list {
        max-width: 290px;
        justify-content: space-between;
      }
    }
    &-weight {
      &__list {
        max-width: 290px;
        justify-content: space-between;
      }
      &__item {
        flex-basis: 132px;
        max-width: 132px;
        margin-right: 0;
      }
    }
    &-taste {
      &__list {
        max-width: 290px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
      &__item {
        flex-basis: 135px;
        &:last-child {
          margin-bottom: 11px;
        }
        label {
          padding: 11px 10px 11px 45px;
        }
      }
    }
    &__footer {
      flex-direction: column-reverse;
      margin-bottom: 50px;
    }
    &-calc {
      max-width: 270px;
    }
    &-reset {
      margin-bottom: 35px;
    }
  }
}
@media screen and (max-width: 580px) {
  .tablets {
    &-col {
      flex-basis: 100%;
      max-width: 100%;
    }
    .mode-application {
      padding: 28px 20px 29px 75px;
      background-size: 35px;
      &__title {
        font-size: 16px;
        line-height: 18px;
      }
      &__text {
        font-size: 12px;
      }
      &__option-title {
        font-size: 10px;
        line-height: 12px;
      }
      &.child {
        background-position: 20px 28px;
      }
      &.adult {
        background-position: 20px 28px;
      }
      &.child.adult {
        background-position: 20px 28px, 20px 80px;
        &.position-bottom {
          background-position: 27px 130px, 27px 180px;
        }
        &.position-patient {
          background-position: 27px 80px, 27px 130px;
        }
      }
    }
    .tablets-detail {
      &__btn {
        font-size: 12px;
      }
    }
  }
  .modal {
    &-main {
      top: 90px;
      left: 50%;
      max-width: 100%;
      transform: translate(-50%, 0);
    }
  }
  .child-calc {
    padding: 0 25px;
    &__title {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .calc-results {
    padding-top: 30px;
    &__col {
      flex-basis: 100%;
      max-width: 100%;
    }
    &__options {
      left: 10px;
    }
    &__drug {
      margin-bottom: 13px;
    }
    &__buttons {
      flex-wrap: wrap;
      padding: 25px 0 25px;
    }
    &__text {
      padding-top: 27px;
      padding-left: 8px;
    }
  }
}
@media screen and (max-width: 375px) {
  .tablets {
    .mode-application {
      &__option {
        flex-basis: 78px;
        max-width: 78px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .calc-results {
    &__buttons {
      display: block;
      text-align: center;
    }
    &__btn {
      margin: 0 auto 11px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.tns {
  &-outer {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  &-controls {
    &:focus {
      outline: none;
    }
  }
  &-item {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  &-nav {
    padding-top: 7px;
    text-align: center;
    button {
      display: inline-block;
      vertical-align: middle;
      width: 37px;
      height: 2px;
      background: #c8c8c8;
      border: none;
      outline: none;
      padding: 0;
      margin: 0 3px;
      border-radius: 15px;
      &.tns-nav-active {
        width: 78px;
        height: 4px;
        background: #00a03b;
      }
    }
  }
  &-controls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    width: 100%;
    bottom: 60px;
    z-index: 1;
    @media screen and (max-width: 580px) {
      bottom: 40px;
    }
    button {
      width: 60px;
      height: 27px;
      border: none;
      outline: none;
      text-indent: -999999px;
    }
    [data-controls="prev"] {
      left: 25px;
      background: url("~@/assets/img/sumamed/slider-prev-arrow.png") no-repeat;
      @media screen and (max-width: 580px) {
        width: 40px;
        background-size: contain;
      }
    }
    [data-controls="next"] {
      right: 25px;
      background: url("~@/assets/img/sumamed/slider-next-arrow.png") no-repeat;
      @media screen and (max-width: 580px) {
        width: 40px;
        background-size: contain;
      }
    }
  }
}
.child-calc {
  .noUi {
    &-base {
      z-index: unset;
      pointer-events: none;
    }
    &-pips {
      color: #9db0ac;
      &-horizontal {
        position: relative;
        height: auto;
        padding: 0;
      }
    }
    &-horizontal {
      height: 2px;
      padding: 0;
      z-index: 2;
      background: #c8c8c8;
      .noUi {
        &-marker {
          width: 8px;
          height: 8px;
          margin-top: -7px;
          background: #ffffff;
          border-radius: 50%;
          border: 1px solid #c8c8c8;
        }
        &-handle {
          top: -7px;
          right: -8px !important;
          width: 16px;
          height: 16px;
          background: #317575;
          border-radius: 50%;
          box-shadow: none;
          border: none;
          pointer-events: auto;
          &-lower {
            display: none;
          }
          &:focus {
            outline: none;
          }
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    &-connect {
      background: #00a03b;
      height: 4px;
    }
    &-target {
      border: none;
      background: #c8c8c8;
      box-shadow: none;
    }
    &-connects {
      height: 4px;
      top: -1px;
      z-index: 0;
    }
    &-marker {
      &-sub,
      &-large {
        width: 8px;
        height: 8px;
        margin-top: -5px;
        background: #ffffff;
        border-radius: 50%;
        border: 1px solid #00a03b;
      }
    }

    &-value {
      &-horizontal,
      &-sub {
        top: -4px;
        margin-left: -10px;
        font-weight: 750;
        font-size: 19.5569px;
        line-height: 24px;
        text-align: center;
        color: #545454;
        &:before {
          content: "кг";
          position: absolute;
          top: 0;
          right: -20px;
          display: inline-block;
          font-weight: normal;
          font-size: 18.2432px;
          line-height: 22px;
          text-align: center;
        }
        &:after {
          content: ",0";
          position: relative;
          display: inline-block;
        }
      }
    }
  }
}
.tablets-detail {
  &__item {
    &-text {
      ul {
        list-style: none;
      }
    }
  }
}
</style>
