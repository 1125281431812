<template>
  <div class="training-nav">
    <div class="training-nav__inner">
      <div class="training-nav__list">
        <div
          class="training-nav__item"
          :class="{
            success: state >= 1,
            current: currentState === 1
          }"
          @click="nextState(1, '')"
        >
          Вітаміни групи B
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 2,
            current: currentState === 2
          }"
          @click="nextState(2, '')"
        >
          Нейрорубін
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 3,
            current: currentState === 3
          }"
          @click="nextState(3, '')"
        >
          Інтерактивна гра
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 4,
            current: currentState === 4
          }"
          @click="nextState(4, '')"
        >
          Фінальний тест
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: ["state", "currentState"],
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.training-nav {
  position: relative;
  padding-top: 23px;
  margin-bottom: 24px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 4px;
    background: #dddddd;
    z-index: -1;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4px;
  }
  &__inner {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    position: relative;
    margin-right: 34px;
    padding-left: 20px;
    background-image: url("~@/assets/img/training-module1/navigation/locked-icon.svg");
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 16px;
    font-family: $fontR;
    font-weight: 400;
    line-height: 20px;
    color: #9f9f9f;

    &:last-child {
      margin-right: 0;
    }
    &.success {
      padding-left: 20px;
      background-image: url("~@/assets/img/training-module1/navigation/success-icon.svg");
      color: #005984;
      pointer-events: all;
      cursor: pointer;
    }
    &.current {
      padding-left: 0;
      background: none;
      font-family: $fontDemi;
      font-weight: 700;
      color: #005984;

      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        height: 4px;
        background: #7fc25d;
        border-radius: 5px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .training-nav {
    padding-top: 5px;
    &__item {
      margin-right: 20px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
@media screen and (max-width: 500px) {
  .training-nav {
    margin-left: -15px;
    margin-right: -15px;
    z-index: 1;
    &:after {
      bottom: 0;
    }
    &__inner {
      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    &__list {
      display: inline-block;
      padding: 0 15px 8px;
      white-space: nowrap;
    }
    &__item {
      display: inline-block;
    }
  }
}
</style>
