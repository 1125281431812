<template>
  <div class="section-table-drug-single-comparison-color">
    <div class="left-table">
      <table border="0" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <td colspan="1"></td>
            <td colspan="1">За повну вартість</td>
            <td colspan="1">За 0,10 грн</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Препарат</td>
            <td>Левофлоксацин-Тева</td>
            <td>Левофлоксацин-Тева</td>
          </tr>
          <tr>
            <td>Діюча речовина</td>
            <td style="background: rgba(0,160,59,0.2)">Левофлоксацин</td>
            <td style="background: rgba(170,25,139,0.2)">Левофлоксацин</td>
          </tr>
          <tr>
            <td>мг</td>
            <td>500</td>
            <td>500</td>
          </tr>
          <tr>
            <td>№</td>
            <td>10</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Термін придат.</td>
            <td>01.10.2021</td>
            <td>01.10.2021</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="header-wrapper">
      <div class="header-table header-table1">
        <p>За 0,10 грн</p>
      </div>
    </div>

    <div class="table-mob table-mob1">
      <table border="0" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td>Препарат</td>
            <td>Левофлоксацин-Тева</td>
          </tr>
          <tr>
            <td>Діюча речовина</td>
            <td style="background: rgba(0,160,59,0.2)">Левофлоксацин</td>
          </tr>
          <tr>
            <td>мг</td>
            <td>500</td>
          </tr>
          <tr>
            <td>№</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Термін придат.</td>
            <td>01.10.2021</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="header-wrapper">
      <div class="header-table header-table2">
        <p>За 0,10 грн</p>
      </div>
    </div>

    <div class="table-mob table-mob2">
      <table border="0" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td>Препарат</td>
            <td>Левофлоксацин-Тева</td>
          </tr>
          <tr>
            <td>Діюча речовина</td>
            <td style="background: rgba(170,25,139,0.2)">Левофлоксацин</td>
          </tr>
          <tr>
            <td>мг</td>
            <td>500</td>
          </tr>
          <tr>
            <td>№</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Термін придат.</td>
            <td>01.10.2021</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-table-drug-single-comparison-color"
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-table-drug-single-comparison-color {
  margin: 10px 0;
  display: flex;

  @media screen and (max-width: 861px) {
    flex-direction: column;
    margin: 5px 0 15px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 10px -15px 15px 0;
  }

  .left-table {
    max-width: 830px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 1024px;
    }

    @media screen and (max-width: 861px) {
      display: none;
    }

    table {
      max-width: 1024px;
      width: 100%;
    }

    thead {
      tr {
        td:nth-child(2) {
          padding: 12px 0;
          text-align: center;
          background: #00a03b;
          border-radius: 50px 0 0 50px;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
          border-right: 15px solid white;
        }

        td:nth-child(3) {
          padding: 12px 0;
          text-align: center;
          background: #aa198b;
          border-radius: 0 50px 50px 0;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n) {
          background: #ececec;
        }

        &:nth-child(2n + 1) {
          background: #f2f2f2;
        }

        td {
          border: none;
          color: #545454;
          font-family: $fontR;
          font-size: 13px;
          text-align: center;
          padding: 7px 10px;

          &:nth-child(2) {
            border-right: 15px solid white;
          }

          &:first-child {
            width: 153px;
            font-family: $fontM;
            text-align: right;
            padding: 7px;
            border-right: 7px solid white;
          }
        }

        &:first-child {
          td {
            font-family: $fontM;
          }
        }
      }
    }
  }

  .header-wrapper {
    overflow: hidden;

    .header-table {
      display: none;
      @media screen and (max-width: 861px) {
        display: flex;
        margin-bottom: 15px;
      }

      justify-content: center;
      align-items: center;
      width: auto;
      margin-left: 94px;
      border-radius: 50px 0 0 50px;
      padding: 12px 0;
      @media screen and (max-width: 425px) {
        margin-right: 15px;
      }

      p {
        color: white;
        font-size: 14px;
        font-family: $fontDemi;
      }

      &.header-table1 {
        background: #00a03b;
      }

      &.header-table2 {
        background: #aa198b;
      }
    }
  }

  .table-mob {
    display: none;
    @media screen and (max-width: 861px) {
      display: block;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 670px) {
      overflow-x: auto;
      width: 100%;
      position: relative;
    }

    &.table-mob1 {
      thead {
        tr {
          td:nth-child(2) {
            position: relative;
            padding: 12px 0;
            text-align: center;
            background: #00a03b;
            border-radius: 50px 0 0 50px;
            color: white;
            font-family: $fontDemi;
            font-size: 16px;
            border-bottom: 7px solid white;
          }
        }
      }
    }

    &.table-mob2 {
      thead {
        tr {
          td:nth-child(2) {
            position: relative;
            padding: 12px 0;
            text-align: center;
            background: #aa198b;
            border-radius: 50px 0 0 50px;
            color: white;
            font-family: $fontDemi;
            font-size: 16px;
            border-bottom: 7px solid white;
          }
        }
      }
    }

    table {
      width: 100%;
      @media screen and (max-width: 670px) {
        min-width: 425px;
      }
    }

    thead {
      tr {
        td:nth-child(2) {
          padding: 12px 0;
          text-align: center;
          background: #00a03b;
          border-radius: 50px 0 0 50px;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
        }

        td:nth-child(3) {
          padding: 12px 0;
          text-align: center;
          background: #aa198b;
          border-radius: 0 50px 50px 0;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n) {
          background: #ececec;
        }

        &:nth-child(2n + 1) {
          background: #f2f2f2;
        }

        td {
          border: none;
          color: #545454;
          font-family: $fontR;
          font-size: 13px;
          text-align: center;
          padding: 7px 10px;

          &:first-child {
            max-width: 85px;
            font-family: $fontM;
            text-align: right;
            padding: 7px;
            border-right: 7px solid white;
          }
        }

        &:first-child {
          td {
            font-family: $fontM;
          }
        }
      }
    }
  }
}
</style>
