<template>
  <div class="section-blockquote-list">
    <div v-if="content.type === 'quote_full_width'" class="blockquote">
      <blockquote>
        <div class="text-wrapper" v-html="content.quote"></div>
        <p><span v-html="content.author"></span></p>
      </blockquote>
    </div>

    <div v-if="content.type === 'list_with_points'" class="list">
      <div class="text-block" v-for="(value, key) in content.blocks" :key="key">
        <span :class="`${content.list_type}`"></span>
        <span v-html="value"></span>
      </div>
    </div>

    <div v-if="content.type === 'list_with_points_two'" class="double-list">
      <div v-for="(list, key) in items" :key="key" style="width: 50%">
        <div class="text-block" v-for="(item, i) in list" :key="i">
          <span :class="`${content.list_type}`"></span>
          <span v-html="item"></span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "section-blockquote-list",
  props: {
    content: Object
  },

  computed: {
    items() {
      if (this.content.type === 'list_with_points_two') {
        const list = this.content.blocks;
        const size = list.length;
        const mid = Math.ceil(size / 2);

        return [
          list.slice(0, mid + 1),
          list.slice(mid, size)
        ]
      }

      return this.content.blocks;
    }
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

.section-blockquote-list {
  margin: 10px 0;
  display: flex;

  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }

  > div {
    max-width: 830px;
    width: 100%;

    &.blockquote {
      blockquote {
        p {
          color: #00a03b;
          font-family: $fontM;
          font-size: 18px;
          margin-bottom: 15px;

          @media screen and (max-width: 670px) {
            font-size: 16px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-size: 13px;
            font-family: $fontR;
          }
        }
      }
    }

    &.double-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media screen and (max-width: 670px) {
        flex-direction: column;
      }

      .text-block {
        display: flex;

        &:first-child {
          // margin-right: 15px;
          @media screen and (max-width: 670px) {
            margin-right: 0;
          }
        }

        &:last-child {
          // margin-left: 15px;

          @media screen and (max-width: 670px) {
            margin-left: 0;
          }
        }

        .disc {
          min-width: 4px;
          min-height: 4px;

          height: 4px;
          width: 4px;
          border-radius: 50%;

          margin-top: 9px;
          background: #7fc25d;

          display: inline-block;
        }

        .circle {
          min-width: 4px;
          min-height: 4px;

          height: 4px;
          width: 4px;
          border-radius: 50%;

          margin-top: 9px;
          border: 1px solid #7fc25d;

          display: inline-block;
        }

        p {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          margin-bottom: 15px;
          padding-left: 15px;
          position: relative;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.list {
      .text-block {
        display: flex;

        .disc {
          min-width: 4px;
          min-height: 4px;

          height: 4px;
          width: 4px;
          border-radius: 50%;

          margin-top: 9px;
          background: #7fc25d;

          display: inline-block;
        }

        .circle {
          min-width: 4px;
          min-height: 4px;

          height: 4px;
          width: 4px;
          border-radius: 50%;

          margin-top: 9px;
          border: 1px solid #7fc25d;

          display: inline-block;
        }

        p {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          margin-bottom: 15px;
          padding-left: 15px;
          position: relative;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
