<template>
  <div class="module-theory">
    <template v-if="state === 1">
      <button
        class="module-theory__back-btn"
        @click="nextState(5, 'next')"
      >
        <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        Назад
      </button>
      <div class="module-theory__text">
        <strong>Спробуйте порахувати самостійно,</strong><br>
        скільки міліграм азитроміцину буде у готовій суспензії:
      </div>
      <img
        src="@/assets/img/training-module5/theme-1/step-6/pack.png"
        alt=""
        class="module-theory__pack-img--right"
      >
      <div class="module-theory__result" v-if="isResultActive" :class="{success: result === 'SUCCESS', error: result === 'ERROR'}">
        {{result === 'SUCCESS' ? 'Молодець! Правильно!' : 'Неправильно! Спробуйте ще'}}
        <div class="module-theory__closer" @click="isResultActive = false">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L9 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M9 9L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
      <div class="module-theory__green-text">
        Як ми бачимо згідно з маркуванням,<br>
        <strong>5 мл</strong> готової суспензії містить
      </div>
      <form
        class="module-theory__form"
        @submit.prevent="handleSubmit()"
        autocomplete="off"
      >
        <div class="module-theory__field">
          <the-mask
            mask="###"
            class="module-theory__input"
            type="text"
            id="answer"
            placeholder="Введіть відповідь"
            :value="answer"
            @input="event => handleAnswer(event)"
          >
          </the-mask>
          <label
            for="answer"
            class="module-theory__label"
          >
            <strong>мг азитроміцину</strong>
          </label>
        </div>
        <button 
          v-if="!result || result === 'ERROR'"
          class="module-theory__next check-button"
          type="submit"
          :disabled="result === 'ERROR' || !answer"
        >
          Перевірити
        </button>
        <button
          v-if="result === 'SUCCESS'"
          class="module-theory__next next-button"
          type="button"
          @click="changeState()"
        >
          Далі
        </button>
      </form>
    </template>

    <template v-if="state === 2">
      <button
        class="module-theory__back-btn"
        @click="changeState(-1)"
      >
        <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        Назад
      </button>
      <div class="module-theory__text">
        <strong>Спробуйте порахувати самостійно,</strong>
        скільки міліграм азитроміцину буде у готовій суспензії:
      </div>
      <img
        src="@/assets/img/training-module5/theme-1/step-6/pack-2.png"
        alt=""
        class="module-theory__pack-img--left"
      >
      <div class="module-theory__result second" v-if="isResultActive" :class="{success: result === 'SUCCESS', error: result === 'ERROR'}">
        {{result === 'SUCCESS' ? 'Молодець! Правильно!' : 'Неправильно! Спробуйте ще'}}
        <div class="module-theory__closer" @click="isResultActive = false">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L9 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M9 9L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
      <div class="module-theory__green-text">
        Це означає, що <strong>1 мл<br>
        буде містити 40 мг</strong>
      </div>
      <form
        class="module-theory__form"
        @submit.prevent="handleSubmit()"
        autocomplete="off"
      >
        <strong>Загальний об'єм готової суспензії складає</strong>
        <div class="module-theory__field">
          <the-mask
            mask="##"
            class="module-theory__input"
            type="text"
            id="answer"
            placeholder="Введіть відповідь"
            :value="answer"
            @input="event => handleAnswer(event)"
          >
          </the-mask>
          <label
            for="answer"
            class="module-theory__label"
          >
            <strong>мл</strong>
          </label>
        </div>
        <button 
          v-if="!result || result === 'ERROR'"
          class="module-theory__next check-button"
          type="submit"
          :disabled="result === 'ERROR' || !answer"
        >
          Перевірити
        </button>
        <button
          v-if="result === 'SUCCESS'"
          class="module-theory__next next-button"
          type="button"
          @click="changeState()"
        >
          Далі
        </button>
      </form>
    </template>

    <template v-if="state === 3">
      <button
        class="module-theory__back-btn"
        @click="changeState(-1)"
      >
        <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        Назад
      </button>
      <div class="module-theory__title">
        Результат
      </div>
      <img
        src="@/assets/img/training-module5/theme-1/step-6/pack-result.png"
        alt=""
        class="module-theory__pack-img"
      >
      <div class="module-theory__green-text">
        <b>Отже, дана форма випуску містить<br><br>
        <strong>40 x 30 = 1200 мг</strong><br>
        азитроміцину у готовій суспензії</b>
      </div>
      <div class="module-theory__small-text">
        Детальніше про дозування Сумамед® ви можете<br> дізнатися у 
        <a href="" class="module-theory__link">мобільному додатку</a>
      </div>
      <div class="module-theory__download">
        Доступно для завантаження
        <div class="module-theory__download-buttons">
          <a target="_blank" href="https://apps.apple.com/us/app/id1185814131" class="module-theory__download-button">
            <img src="@/assets/img/training-module5/theme-1/step-6/app-store.svg" alt="">
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=ua.teva.sumamedDosage" class="module-theory__download-button">
            <img src="@/assets/img/training-module5/theme-1/step-6/g-play.svg" alt="">
          </a>
        </div>
      </div>
      <div class="module-theory__next next-button test" @click="nextState(7, 'next')">
        Перейти до тестування
      </div>
    </template>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  name: "Theory6",
  components: {
    TheMask,
  },
  data() {
    return {
      state: 1,
      answer: null,
      isResultActive: false,
      result: null,
    };
  },
  methods: {
    changeState(direction) {
      this.isResultActive = false;
      this.result = null;
      this.answer = null;

      if(direction === -1) {
        this.state -= 1;
        gtag("event", "click", {
          event_category: 'Кнопка "Назад"',
          event_label: `'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування. Гра" - Э.${this.state}'`
        });
      } else {
        this.state += 1;
        gtag("event", "click", {
          event_category: 'Кнопка "Далі"',
          event_label: `'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування. Гра" - Э.${this.state}'`
        });
      }

      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Назад"',
        event_label: `'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.5'`
      });

      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    handleAnswer(event) {
      this.result = null;
      this.isResultActive = false;
      this.answer = event;
    },
    handleSubmit() {
      switch (this.state) {
        case 1:
          gtag("event", "click", {
            event_category: 'Відповідь на запитання №1',
            event_label: `'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування. Гра" - Э.${this.state}'`
          });
          if(this.answer === '200') {
            this.result = 'SUCCESS';
            this.isResultActive = true;
          } else {
            this.result = 'ERROR';
            this.isResultActive = true;
          }
        break;
        case 2:
          gtag("event", "click", {
            event_category: 'Відповідь на запитання №2',
            event_label: `'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування. Гра" - Э.${this.state}'`
          });
          if(this.answer === '30') {
            this.result = 'SUCCESS';
            this.isResultActive = true;
          } else {
            this.result = 'ERROR';
            this.isResultActive = true;
          }
      }

    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  text-align: center;
  position: relative;

  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__pack-img {
    margin: 32px -30px 12px 0;
  }

  &__pack-img--right {
    margin: 32px -90px 12px 0;
  }

  &__pack-img--left {
    margin: 32px 0 12px -90px;
  }

  &__green-text {
    margin-bottom: 43px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #00A03B;

    strong {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &__input {
    max-width: 140px;
    padding: 12px 0;
    border: none;
    outline: none;
    border-bottom: 2px solid #CCCCCC;
    margin-right: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #545454;
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */

    &::placeholder {
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      color: #9F9F9F;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  &__result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -60px);
    width: 330px;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    border-radius: 8px;
    font-family: $fontDemi;

    svg {
      cursor: pointer;
    }

    &.second {
      transform: translate(-50%, -50px);
    }

    &.success {
      background: rgba(0, 160, 59, 0.8);
      box-shadow: 0px 20px 40px rgba(0, 160, 59, 0.4);
    }

    &.error {
      background: rgba(251, 52, 73, 0.8);
      box-shadow: 0px 20px 40px rgba(251, 52, 73, 0.2);
    }
  }

  &__link {
    color: #005984;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  &__download {
    width: 100%;
    padding: 40px 105px 40px 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    background: #317575;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;

    &-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-button:first-of-type {
      margin-right: 10px;
    }
  }

  .check-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 262px;
    height: 64px;
    padding: 0 20px;
    margin: 64px auto 0;
    background-color: #aa198b;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:disabled {
      opacity: .3;
      cursor: not-allowed;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-position: 110% center;
    }

    &.test {
      width: 330px;
      padding: 0 22px;

      &:hover {
        background-position: 105% center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__pack-img--right {
      margin: 32px -90px 0 0;
    }

    &__pack-img--left {
      margin: 32px 0 0 -90px;
    }

    &__green-text {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 20px;

      strong {
        font-size: 20px;
        line-height: 22px;
      }
    }

    &__input {
      margin-right: 16px;
      font-size: 24px;
      line-height: 29px;

      &::placeholder {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        color: #9F9F9F;
      }
    }

    &__result,
    &__result.second {
      transform: translate(-50%, 150px);
      width: 100%;
    }

    &__download {
      flex-direction: column;
      width: 110%;
      margin-left: -5%;
      padding: 16px 37px 24px;
      margin-top: 24px;

      br {
        display: none;
      }

      &-buttons {
        margin-top: 16px;
      }
    }

    .check-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 266px;
      height: 44px;
      margin: 100px auto 0;
      padding: 0 95px;
      font-size: 16px;
      line-height: 43px;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 100px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;

      &.test {
        width: 280px;
      }
    }
  }
}
</style>
