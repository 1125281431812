<template>
  <div class="portret">
    <div class="portret__top">
      <swiper :options="swiperOption" style="height: auto" class="swiper">
        <swiper-slide>
          <div class="swiper__item">
            <div class="left">
              <div class="portret__text-wrapper first">
                <div class="portret__title">
                  Щось так<br />
                  плечі болять.<br />
                  <span>Що порадите?​</span>
                </div>
              </div>
              <img
                class="swiper__img img-first"
                src="~@/assets/img/training-module1/theme-3/item1.jpg"
                alt=""
              />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper__item">
            <div class="left">
              <img
                class="swiper__img img-second"
                src="~@/assets/img/training-module1/theme-3/item2.jpg"
                alt=""
              />
              <div class="portret__text-wrapper second">
                <div class="portret__title">
                  Спину прихопило<br />
                  боляче рухатись.<br />
                  <span>Як бути?​</span>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper__item">
            <div class="left">
              <div class="portret__text-wrapper third">
                <div class="portret__title">
                  Відчуваю біль<br />
                  між ребрами.<br />
                  <span>Що робити?​</span>
                </div>
              </div>
              <img
                class="swiper__img img-third"
                src="~@/assets/img/training-module1/theme-3/item3.jpg"
                alt=""
              />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev">
          <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2L3 9L10 16"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-next" slot="button-next">
          <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2L9 9L2 16"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </swiper>
      <div class="portret__detail">
        <div class="portret__detail-recom">
          Невралгія може мати різну локалізацію, проте основним симптомом, який
          потребує негайного лікування, є <span>біль.<sup>1</sup></span>
        </div>
        <ul class="portret__detail-list list">
          <span
            >Тривале відчуття болю негативно впливає на якість
            життя<sup>1</sup>:
          </span>
          <li class="list__item">Погіршує сон​</li>
          <li class="list__item">Спричинює депресію та тривожність​</li>
          <li class="list__item">
            Впливає на щоденну активність​ та працездатність
          </li>
        </ul>
      </div>
    </div>
    <div class="portret__bottom">
      <div class="portret__bottom--text">
        Комбінація високих доз вітамінів групи В та НПЗП
        <span>сприяє усуненню болю в спині.<sup>2</sup></span>
      </div>
      <div class="portret__next" @click="nextState(2, 'next')">Далі</div>
    </div>
  </div>
</template>

<script>
import sectionSliderWrapper from "../../section-slider-wrapper.vue";
export default {
  name: "Portret",
  components: {
    sectionSliderWrapper,
  },
  data: () => ({
    swiperOption: {
      autoHeight: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
  }),
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_1_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    },
  },
};
</script>

<style lang="scss">
// .swiper-pagination-bullet {
//   width: 24px !important;
//   height: 4px !important;
//   margin: 0 12px !important;
//   display: inline-block !important;
//   border-radius: 10px !important;
//   opacity: 1 !important;
//   background: #e4eaed !important;
// }
// .swiper-pagination-bullet-active {
//   color: #fff;
//   background: linear-gradient(71.92deg, #00a03b 0%, #b4d16c 100%) !important;
// }
// .swiper-pagination.swiper-pagination-custom span {
//   display: inline-block;
//   width: 24px;
//   height: 4px;
//   border-radius: 10px;
//   background: #e4eaed;
//   margin: 0 12px;
// }
// .swiper-button-prev {
//   background-image: none !important;
//   background-color: #7fc25d;
//   right: auto;
//   width: 48px !important;
//   height: 48px !important;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   bottom: 0;
//   top: auto !important;
//   left: 86px !important;
// }
// .swiper-button-next {
//   background-image: none !important;
//   background-color: #7fc25d;
//   right: auto;
//   width: 48px !important;
//   height: 48px !important;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   bottom: 0;
//   top: auto !important;
//   left: 310px !important;
// }

// .swiper-container-horizontal > .swiper-pagination-bullets {
//   bottom: 18px !important;
// }
</style>

<style lang="scss" scoped>
.portret {
  &__top {
    display: grid;
    grid-template-columns: 1.16fr 1fr;
  }
  &__bottom {
    background: rgba(0, 160, 59, 0.6);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 24px 48px 48px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    &--text {
      text-align: center;
      max-width: 55%;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      span {
        font-weight: 600;
      }
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: 600;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    margin-top: 32px;
    &:hover {
      background-position: 110% center;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-width: 266px;
      height: 44px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
  }
  &__detail {
    margin-top: 100px;
    margin-left: 20px;
    &-recom {
      color: #545454;
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 8px;
      span {
        font-weight: 600;
        color: #00a03b;
      }
      nobr {
      }
    }
    &-list {
      & > span {
        color: #00a03b;
        font-weight: 600;
      }
      &.list > .list__item {
        color: #545454;
        margin-left: 20px;
        font-size: 16px;
        line-height: 22px;
        padding-right: 2%;
      }
    }
    &-purpose {
      &.small {
        font-size: 12px;
        nobr {
          font-weight: 600;
        }
      }
    }
  }
  &__title {
    font-size: 26px;
    color: #ffffff;
    margin: 0 28px;
    line-height: 110%;
    letter-spacing: -0.01em;
    font-weight: 600;
    & span {
      font-size: 34px;
      line-height: 140%;
      margin-top: 10px;
      display: inline-block;
      @media (max-width: 430px) {
        font-size: 24px;
      }
    }
  }
  &__text-wrapper {
    background-image: url("~@/assets/img/training-module1/theme-2/Bubble-left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    max-width: 304px;
    height: 160px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    &.first {
      right: 0;
      margin-right: 0;
      margin-left: auto;
      max-width: 328px;
    }
    &.second {
      bottom: 30px;
      max-width: 308px;
    }
    &.third {
      max-width: 308px;
    }
  }
  .swiper {
    padding: 0px 0px;
    &-container {
      width: 419px;
    }
    &-wrapper {
      padding-left: 0;
    }
    &__item {
      height: 494px;
    }
    & .left {
      position: relative;
    }
    &__img {
      max-width: 81%;
      height: auto;
      object-fit: contain;
      &.img-first {
        transform: translateY(-10%);
        @media (max-width: 768px) {
          max-height: 260px;
        }
      }
      &.img-second {
        transform: translateX(23%);
      }
      &.img-third {
        transform: translate(23%, -16%);
      }
    }
  }
}
@media (max-width: 1100px) {
  .swiper-button-prev {
    left: 30px !important;
    right: auto !important;
  }
  .swiper-button-next {
    right: 30px !important;
    left: auto !important;
  }
}
@media only screen and (max-width: 768px) and (max-device-width: 768px) and (orientation: portrait) {
  .portret {
    &__top {
      grid-template-columns: 1fr !important;
    }
    &__detail {
      margin-top: 32px !important;
    }
    &__bottom {
      &--text {
        max-width: 234px !important;
      }
    }
    &__text-wrapper {
      height: 140px;
    }
  }
  .swiper {
    &__item {
      display: flex !important;

      justify-content: center !important;
      max-height: 380px;
    }
  }
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .swiper {
    &-container {
      width: 300px !important;
    }
  }
}

// @media only screen
// and (min-device-width : 375px)
// and (min-width: 375px)
// and (max-device-width : 667px)
// and (max-width: 667px)
// and (orientation : portrait) {
//   .portret {
//     &__top {
//       grid-template-columns: 1fr !important;
//     }
//     &__detail {
//       margin-top: 32px !important;
//     }
//     &__bottom{
//       &--text {
//         max-width: 234px !important;
//       }
//     }
//   }
//   .swiper {
//     &__item {
//       display: flex !important;
//       width: 100% !important;
//       justify-content: center !important;
//       max-height: 380px;
//     }
//   }
//   .swiper-button-prev {
//     display: none !important;
//   }
//   .swiper-button-next {
//     display: none !important;
//   }
// }

// @media only screen
// and (min-device-width : 414px)
// and (min-width: 414px)
// and (max-device-width : 736px)
// and (max-width: 736px)
// and (orientation : portrait)
// and (-webkit-min-device-pixel-ratio: 3) {
//   .portret {
//     &__top {
//       grid-template-columns: 1fr !important;
//     }
//     &__detail {
//       margin-top: 32px !important;
//     }
//     &__bottom{
//       &--text {
//         max-width: 234px !important;
//       }
//     }
//   }
//   .swiper {
//     &__item {
//       display: flex !important;
//       width: 100% !important;
//       justify-content: center !important;
//       max-height: 380px;
//     }
//   }
//   .swiper-button-prev {
//     display: none !important;
//   }
//   .swiper-button-next {
//     display: none !important;
//   }
// }

// @media only screen
// and (min-device-width : 320px)
// and (min-width: 320px)
// and (max-device-width : 568px)
// and (max-width: 568px)
// and (orientation : portrait)
// and (-webkit-min-device-pixel-ratio: 2) {
//   .portret {
//     &__top {
//       grid-template-columns: 1fr !important;
//     }
//     &__detail {
//       margin-top: 32px !important;
//     }
//     &__bottom{
//       &--text {
//         max-width: 234px !important;
//       }
//     }
//   }
//   .swiper {
//     &__item {
//       display: flex !important;
//       width: 100% !important;
//       justify-content: center !important;
//       max-height: 380px;
//     }
//   }
//   .swiper-button-prev {
//     display: none !important;
//   }
//   .swiper-button-next {
//     display: none !important;
//   }
// }

// @media only screen
//   and (min-device-width: 375px)
//   and (min-width: 375px)
//   and (max-device-width: 667px)
//   and (max-width: 667px)
//   and (orientation : portrait)
//   and (-webkit-min-device-pixel-ratio: 2) {
//   .portret {
//     &__top {
//       grid-template-columns: 1fr !important;
//     }
//     &__detail {
//       margin-top: 32px !important;
//     }
//     &__bottom{
//       &--text {
//         max-width: 234px !important;
//       }
//     }
//   }
//   .swiper {
//     &__item {
//       display: flex !important;
//       width: 100% !important;
//       justify-content: center !important;
//       max-height: 380px;
//     }
//   }
//   .swiper-button-prev {
//     display: none !important;
//   }
//   .swiper-button-next {
//     display: none !important;
//   }
// }
</style>
