<template>
  <div class="module-game">
    <div class="module-game__title">
      Гра: знайдіть підробку
    </div>
    <div class="module-game__text">
      Давайте перевіримо, чи добре ви запам’ятали захисні елементи на упаковках Сумамед®.
    </div>
      <div class="module-game__dots">
        <div
          v-for="(question, key) in questions"
          :key="key"
          class="module-game__dots-item"
          :class="{
            current: currentQuestion === key + 1,
            correct: question.isCorrect === true,
            incorrect: question.isCorrect === false
          }"
        >
          <div class="module-game__dots-item-inner"></div>
        </div>
      </div>
      <div class="module-game__count">
        <span>
          {{ currentQuestion >= 10 ? "" : "0" }}{{ currentQuestion }}
        </span>
        / {{ questionsCount >= 10 ? "" : "0" }}{{ questionsCount }}
      </div>
      <template v-for="(question, questionKey) in questions">
        <div
          class="module-game__item"
          :key="questionKey"
          v-if="questionKey + 1 === currentQuestion"
        >
          <div
            class="module-game__pack"
            :class="[{incorrect: question.isCorrect === false}, `question-${questionKey + 1}`]"
          >
            <img
              :src="require(`@/assets/img/training-module5/theme-2/game/${question.id}.png`)"
              alt=""
            >
          </div>
          <div
            class="module-game__result"
            v-if="isTipActive"
            :class="{success: selectedAnswer.isCorrect, error: !selectedAnswer.isCorrect}"
          >
            {{ selectedAnswer.isCorrect ? question.tips.correct : question.tips.incorrect }}
            <div class="module-game__closer" @click="isTipActive = false">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L9 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M9 9L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </div>
          </div>
          <div class="module-game__hint" v-if="currentQuestion === 1 && !selectedAnswer.id">
            Уважно роздивіться зображення<br>
            та оберіть — це оригінал, або підробка
          </div>
          <div class="module-game__answers">
            <button
              v-for="(answer, answerKey) in question.answers"
              :key="answerKey"
              v-html="answer.title"
              :disabled="selectedAnswer.id !== answer.id && selectedAnswer.id"
              @click="
                selectAnswer(question.id, answer, questionKey, answerKey)
              "
              class="module-game__answers-item"
              :class="[
                {
                  correct: answer.isCorrect && selectedAnswer.id === answer.id,
                  incorrect: !answer.isCorrect && selectedAnswer.id === answer.id,
                },
                `question-${questionKey + 1}`
              ]"
            ></button>
          </div>
        </div>
      </template>
      <button
        class="module-game__next next-button"
        type="button"
        @click="nextQuestion()"
        :disabled="!selectedAnswer.id"
      >
        Далі
      </button>
  </div>
</template>

<script>
export default {
  name: "Game",
  data() {
    return {
      questions: [
        {
          id: 1,
          tips: {
            correct: 'Так, це — підробка!',
            incorrect: 'Неправильно, це — підробка Зверніть увагу, що упаковка без прозорої наліпки та має інший колір',
          },
          answers: [
            {
              id: 2,
              title: 'Оригінал',
              isCorrect: false,
            },
            {
              id: 1,
              title: 'Підробка',
              isCorrect: true,
            },
          ],
        },
        {
          id: 2,
          tips: {
            correct: 'Так, це — оригінал! Присутні всі елементи',
            incorrect: 'Неправильно, це — оригінал!',
          },
          answers: [
            {
              id: 2,
              title: 'Оригінал',
              isCorrect: true,
            },
            {
              id: 1,
              title: 'Підробка',
              isCorrect: false,
            },
          ],
        },
        {
          id: 3,
          tips: {
            correct: 'Так, це — оригінал! Присутні всі елементи',
            incorrect: 'Неправильно, це — оригінал!',
          },
          answers: [
            {
              id: 2,
              title: 'Оригінал',
              isCorrect: true,
            },
            {
              id: 1,
              title: 'Підробка',
              isCorrect: false,
            },
          ],
        },
        
        {
          id: 4,
          tips: {
            correct: 'Так, це — підробка!',
            incorrect: 'Неправильно, це - підробка! Зверніть увагу, що упаковка без прозорої наліпки, а також без серій та номеру клапану зі сторони',
          },
          answers: [
            {
              id: 2,
              title: 'Оригінал',
              isCorrect: false,
            },
            {
              id: 1,
              title: 'Підробка',
              isCorrect: true,
            },
          ],
        },
        {
          id: 5,
          tips: {
            correct: 'Так, це — оригінал! Присутні всі елементи',
            incorrect: 'Неправильно, це — оригінал!',
          },
          answers: [
            {
              id: 2,
              title: 'Оригінал',
              isCorrect: true,
            },
            {
              id: 1,
              title: 'Підробка',
              isCorrect: false,
            },
          ],
        },
        {
          id: 6,
          tips: {
            correct: 'Так, це — підробка!',
            incorrect: 'Неправильно, це — підробка! Зверніть увагу на колір упаковки',
          },
          answers: [
            {
              id: 2,
              title: 'Оригінал',
              isCorrect: false,
            },
            {
              id: 1,
              title: 'Підробка',
              isCorrect: true,
            },
          ],
        },
      ],
      questionId: null,
      answerId: null,
      questionKey: null,
      answerKey: null,
      currentQuestion: 1,
      correctAnswers: 0,
      answers: [],
      selectedAnswer: {},
      isTipActive: false,
    };
  },
  computed: {
    questionsCount() {
      return this.questions.length;
    }
  },
  methods: {
    selectAnswer(questionId, answer, questionKey, answerKey) {
      this.questions = [...this.questions].map(
        item => (item.id === questionId
          ? {...item, isCorrect: answer.isCorrect}
          : item
        )
      )
      this.selectedAnswer = answer;
      this.questionId = questionId;
      this.answerId = answer.id;
      this.questionKey = questionKey + 1;
      this.answerKey = answerKey + 1;
      this.isTipActive = true;

      gtag("event", "click", {
        event_category: `Вибір "${
          this.questions[this.questionKey - 1].answers[this.answerKey - 1]
            .title
        }"`,
        event_label: `М.5 - Т.2 - Раздел "Упаковка Сумамед® Гра: Знайди пілробку" - Э.${this.currentQuestion}`
      });
    },
    nextQuestion() {
      if (this.currentQuestion < this.questions.length) {
        this.currentQuestion += 1;
        this.selectedAnswer = {};
        this.isTipActive = false;
        gtag("event", "click", {
          event_category: 'Кнопка "Далі"',
          event_label: `'М.5 - Т.2 - Раздел "Упаковка Сумамед® Гра: Знайди пілробку" - Э.${this.currentQuestion}'`
        });
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      } else {
        this.nextState(7, '')
      }
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.5 - Т.2 - Раздел "Упаковка Сумамед®" - Э.6'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-game {
  text-align: center;
  position: relative;

  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    padding-top: 16px;

    &-item {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 17px;
      background: #e4eaed;

      &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: -13px;
        width: 8px;
        height: 1px;
        background: #e4eaed;
      }

      &.correct {
        background: #00a03b;
      }

      &.incorrect {
        background: #fb3449;
      }
      
      &.current {
        background: #fff;
        border: 1px solid #005a85;

        .module-game {
          &__dots {
            &-item {
              &-inner {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background: #005a85;
              }
            }
          }
        }
      }

      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }

  &__item {
    position: relative;
  }

  &__hint {
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    padding: 6px 8px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #212661;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(180deg, #73A2A3 0%, #BCFCFA 100%);
      z-index: -1;
      border-radius: 8px;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }

    &::after {
      content: '';
      position: absolute;
      left: 4px;
      top: 4px;
      right: 4px;
      bottom: 4px;
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
      opacity: 0.4;
      backdrop-filter: blur(4.90903px);
      border-radius: 4px;
      z-index: -1;
    }
  }


  &__result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translate(-50%, 0);
    width: 330px;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: $fontDemi;
    line-height: 20px;
    color: #FFFFFF;
    border-radius: 8px;

    svg {
      cursor: pointer;
      margin-left: 10px;
    }

    &.success {
      background: rgba(0, 160, 59, 0.8);
      box-shadow: 0px 20px 40px rgba(0, 160, 59, 0.4);
    }

    &.error {
      background: rgba(251, 52, 73, 0.8);
      box-shadow: 0px 20px 40px rgba(251, 52, 73, 0.2);
    }
  }

  &__pack {
    position: relative;
    margin: 32px auto 92px;
    width: 60%;

    &.question-3,
    &.question-6 {
      width: 40%;
    }

    img {
      width: 100%;
      max-width: 100%;
      display: block;
    }

    &.incorrect {

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid rgb(129, 209, 205);
        box-shadow: 0px 2px 20px rgba(24, 48, 63, 0.2);
        border-radius: 50%;
      }

      &::before {
        width: 128px;
        height: 128px;
      }

      &::after {
        width: 78px;
        height: 78px;
      }

      &.question-1,
      &.question-5 {
        &::before {
          top: 11%;
          left: -8%;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/1.1.png");
        }

        &::after {
          top: 55%;
          left: -1%;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/1.2.png");
        }
      }

      &.question-2,
      &.question-4 {
        &::before {
          top: 40%;
          left: -3%;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/2.1.png");
        }

        &::after {
          top: 15%;
          left: 0;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/2.2.png");
        }
      }

      &.question-3{
        &::before {
          top: -3%;
          left: 40%;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/3.2.png");
        }

        &::after {
          top: 70%;
          left: 5%;
          width: 88px;
          height: 88px;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/3.1.png");
        }
      }

      &.question-6{
        &::before {
          top: -7%;
          right: 7%;
          background-image: url("~@/assets/img/training-module5/theme-2/game/incorrect-details/6.1.png");
        }

        &::after {
          display: none;
        }
      }
    }
  }

  &__count {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #317575;

    span {
      color: #7fc25d;
    }
  }

  &__answers {
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(228, 234, 237, 0.4);
      border: 1px solid #00a03b;
      outline: none;
      border-radius: 48px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #005a85;
      cursor: pointer;
      transition: all 0.5s;
      width: 126px;
      height: 64px;

      &:disabled {
        opacity: .3;
        &:hover {
          background: rgba(228, 234, 237, 0.4);
          border: 1px solid #00a03b;
          color: #005a85;
        }
      }
      &.correct,
      &:hover {
        background: #00a03b;
        color: #fff;
      }
      &.incorrect {
        border: 1px solid #fb3449;
        background: #fb3449;
        color: #fff;
      }

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 24px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-position: 110% center;
    }

    &:disabled {
      opacity: .3;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-game {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__result {
      bottom: 70px;
    }

    &__pack {
      margin: 32px auto 84px;
      width: 80%;

      &.question-3,
      &.question-6 {
        width: 60%;
      }

      &.incorrect {

        &::before {
          width: 64px;
          height: 64px;
        }

        &::after {
          width: 36px;
          height: 36px;
        }

        &.question-1,
        &.question-5 {
          &::before {
            top: 11%;
            left: -8%;
          }

          &::after {
            top: 55%;
            left: -1%;
          }
        }

        &.question-2,
        &.question-4 {
          &::before {
            top: 40%;
            left: -3%;
          }

          &::after {
            top: 15%;
            left: 0;
          }
        }

        &.question-3{
          margin-top: 10px;

          &::before {
            top: -5%;
            left: 40%;
          }

          &::after {
            top: 70%;
            left: 9%;
            width: 48px;
            height: 48px;
          }
        }

        &.question-6{
          &::before {
            top: -5%;
            right: 10%;
          }
        }
      }
    }

    &__answers {
      &-item {
        height: 44px;

        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 20px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;

      &.test {
        width: 280px;
      }
    }
  }
}
</style>
