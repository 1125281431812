<template>
  <div class="home">
    <home-paralax></home-paralax>

    <headed-block></headed-block>

    <preloader v-if="preload"></preloader>

    <div class="home-content">
      <div class="list">
        <div class="list-wrapper">
          <div class="list-subtitle">Оберіть напрямок:</div>

          <div
              v-for="(col, row) in navigation"
              :key="row"
              class="list-section"
              :class="`list-section-${row + 1}`"
          >
            <router-link
                v-for="(item, i) in col || []"
                :key="i"
                :to="{
                name: 'list-old',
                params: { specSlug: item.slug, category: 'all' },
              }"
                class="list-item"
            >
              <span
                  @mouseenter="hoverItem = item.slug"
                  @mouseleave="hoverItem = null"
                  v-html="`―&nbsp;${item.name_ua}`"
              ></span>
            </router-link>
          </div>

          <div class="list-section list-section-3">
            <div class="circle">
              <div
                  v-for="i in spec"
                  :key="i"
                  class="img"
                  :class="i"
                  :style="{ opacity: hoverItem === i ? 1 : 0 }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <subscribe-block style="margin: auto auto 0"></subscribe-block>
    </div>

    <footer-block></footer-block>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "home-page",

  data() {
    return {
      menuData: null,
      hoverItem: null,
      preload: true,

      spec: [
        "all",
        "infectious",
        "surgeon",
        "dermatologist",
        "endocrinologist",
        "gynecologist",
        "rheumatologist",
        "traumatologist",
        "cardiologist",
        "psychiatrist",
        "distributor",
        "pharmacist",
        "pulmonologist",
        "neurologist",
        "otolaryngologist",
        "therapist",
        "pediatrician",
      ],
    };
  },

  computed: {
    ...mapState("Spec", ["items"]),

    navigation() {
      const sortedList = this.sortMenuItems(this.items);
      return [sortedList.slice(0, 8), sortedList.slice(8, sortedList.lenght)];
    },
  },

  methods: {
    sortMenuItems(data) {
      if (!data.length) return [];
      const removeItems = ["distributor", "all"];
      const allItem = data.find((el) => el.slug === "all");
      const list = data.filter((el) => !removeItems.includes(el.slug));
      const sortedList = list.sort((a, b) =>
          a.name_ua.localeCompare(b.name_ua)
      );
      sortedList.push(allItem);

      return sortedList;
    },
  },

  mounted() {
    document.querySelector('meta[property="og:image"]').content =
        this.$root.globalUrl + "/assets/img/teva_logo_for_site.jpg";
    document.title = "Rx-портал Тева Україна";

    setTimeout(() => {
      this.preload = false;
    }, 500)
  },
};
</script>

<style lang="scss" scoped>
@import "../css/font-noto.css";
@import "../css/variables";

img,
svg {
  vertical-align: bottom;
}

.home {
  position: relative;
  font-family: $fontSecondary;
  // background: url("../assets/img/home/bg.png") no-repeat center 0px;
  background-image: url("../assets/line-decor-sm.svg");
  background-position: center 20px;
  background-repeat: no-repeat;

  @media (min-width: $md) {
    background-image: url("../assets/line-decor-md.svg");
    background-position-y: 40px;
  }
  @media (min-width: $lg) {
    background-image: url("../assets/line-decor.svg");
    background-position-y: 60px;
  }
  @media (min-width: 1680px) {
    background-size: 100%;
  }
}

.home-content {
  position: relative;
  z-index: 2;
  overflow: hidden;

  margin-top: 52px;
  margin-bottom: 0px;
  padding-bottom: 10px;

  @media (min-width: $md) {
    margin-top: 66px;
    min-height: calc(100vh - 66px - 104px);

    display: flex;
    flex-direction: column;
  }
}

.list {
  position: relative;
  z-index: 2;
  margin: 0 auto 32px;
  padding: 0 32px;
  width: 320px;

  @media (min-width: $md) {
    width: 850px;
  }
  @media (min-width: $lg) {
    width: 1080px;
    margin-bottom: 120px;
  }
}

.list-subtitle {
  position: absolute;
  top: 54px;
  left: 56px;
  width: 100px;

  font-family: $fontSecondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #00a03b;

  @media (min-width: $md) {
    top: 145px;
    left: 32px;
    width: 200px;

    font-size: 20px;
    line-height: 27px;
  }

  @media (min-width: $lg) {
    top: 54px;

    font-size: 16px;
    line-height: 22px;
  }
}

.list-item {
  display: block;
  text-decoration: none;
  color: inherit;

  span {
    cursor: pointer;

    font-family: $fontSecondary;
    font-weight: 300;
    font-size: 20px;
    line-height: 250%;
    color: #317575;

    @media (min-width: $md) {
      font-size: 30px;
      line-height: 230%;
    }

    @media (min-width: $lg) {
      font-size: 26px;
      line-height: 205%;
    }

    &:hover {
      font-weight: 600;
      color: #00a03b;
    }
  }
}

.list-wrapper {
  @media (min-width: $md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.list-section-1 {
  padding-top: 120px;

  @media (min-width: $md) {
    padding-top: 230px;
    width: 380px;
  }

  @media (min-width: $lg) {
    padding-top: 110px;
    width: 310px;
  }
}

.list-section-2 {
  @media (min-width: $md) {
    padding-top: 500px;
    width: 380px;
  }

  @media (min-width: $lg) {
    padding-top: 110px;
    width: 310px;
  }
}

.list-section-3 {
  position: absolute;
  top: 54px;
  right: -32px;

  .circle {
    width: 138px;
    height: 138px;

    background-image: url("../assets/img/home/circle.svg");
  }

  @media (min-width: $md) {
    top: 55px;
    left: 415px;
    right: auto;

    .circle {
      width: 390px;
      height: 390px;
    }
  }

  @media (min-width: $lg) {
    top: 90px;
    left: auto;
    right: 0;

    .circle {
      width: 457px;
      height: 457px;
    }
  }
}

.img {
  position: absolute;
  transition: all 0.2s ease;
  opacity: 0;

  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;

  &.neurologist {
    background: url("../assets/img/home/neur.png") no-repeat center;
    background-size: contain;
  }

  &.pediatrician {
    top: 20%;
    left: 25%;
    background: url("../assets/img/home/ped.png") no-repeat center;
    background-size: contain;
    width: 70%;
  }

  &.otolaryngologist {
    top: 20%;
    left: 23%;
    background: url("../assets/img/home/or.png") no-repeat center;
    background-size: contain;
    // width: 65%;
    // height: 60%;
  }

  &.therapist {
    top: 15%;
    left: 20%;
    background: url("../assets/img/home/ter.png") no-repeat center;
    background-size: contain;
  }

  &.pulmonologist {
    top: 15%;
    left: 27%;
    background: url("../assets/img/home/pull.png") no-repeat center;
    background-size: contain;
    width: 60%;
  }

  &.distributor {
    background: url("../assets/img/home/sell.png") no-repeat center;
    background-size: contain;
  }

  &.pharmacist {
    top: 15%;
    left: 25%;
    background: url("../assets/img/home/farm.png") no-repeat center;
    background-size: contain;
  }

  &.psychiatrist {
    top: 15%;
    left: 20%;
    background: url("../assets/img/home/brain.png") no-repeat center;
    background-size: contain;
  }

  &.cardiologist {
    top: 20%;
    left: 15%;
    background: url("../assets/img/home/heart.png") no-repeat center;
    background-size: contain;
    width: 90%;
  }

  &.endocrinologist {
    top: 20%;
    background: url("../assets/img/home/endo.png") no-repeat center;
    background-size: contain;
  }

  &.rheumatologist {
    background: url("../assets/img/home/reumo.png") no-repeat center;
    background-size: contain;
  }

  &.gynecologist {
    top: 15%;
    background: url("../assets/img/home/gyn.png") no-repeat center;
    background-size: contain;
  }

  &.dermatologist {
    top: 20%;
    background: url("../assets/img/home/der.png") no-repeat center;
    background-size: contain;
  }

  &.traumatologist {
    background: url("../assets/img/home/trauma.png") no-repeat center;
    background-size: contain;
  }

  &.surgeon {
    background: url("../assets/img/home/sye.png") no-repeat center;
    background-size: contain;
  }

  &.infectious {
    background: url("../assets/img/home/inf.png") no-repeat center;
    background-size: contain;
  }

  &.all {
    background: url("../assets/img/home/bag.png") no-repeat center;
    background-size: contain;
  }
}
</style>
