<template>
  <div class="module-theory">
    <template v-if="state === 1">
      <div class="module-theory__title">
        НПЗП полегшують біль та зменшують запалення у суглобах.<sup>2</sup> Чи
        впливають НПЗП на хрящ?
      </div>
      <div class="module-theory__subtitle">
        Залежно від впливу на біосинтез суглобового хряща НПЗП поділяють на 3
        групи:<sup>2,3</sup>
      </div>
      <div class="module-theory__image">
        <img
          class="module-theory__image-desktop"
          src="@/assets/img/training-module2/theme-1/step-2/1-desktop.svg"
          alt=""
        />
        <img
          class="module-theory__image-mobile"
          src="@/assets/img/training-module2/theme-1/step-2/1-mobile.svg"
          alt=""
        />
      </div>
      <div class="module-theory__next next-button" @click="changeState()">
        Далі
      </div>
    </template>
    <template v-if="state === 2">
      <div class="module-theory__back" @click="state = 1">
        Назад
      </div>
      <div class="module-theory__title">
        Який НПЗП має позитивний вплив на суглобовий хрящ?<sup>3</sup>
      </div>
      <div class="module-question">
        <div class="module-question__row">
          <div class="module-question__col">
            <div
              class="module-question__answers"
              :class="{
                error: isAnswerHasError === true,
                success: isAnswerHasError === false
              }"
            >
              <div
                class="module-question__answers-item"
                v-for="(answer, key) in answers"
                :key="key"
                @click="selectAnswer(key)"
                :class="{
                  error: answer.isSelected && !answer.isCorrect,
                  success: answer.isSelected && answer.isCorrect
                }"
              >
                {{ answer.title }}
              </div>
            </div>
          </div>
          <div class="module-question__col">
            <div class="module-question__detail">
              <div
                class="module-question__detail-title"
                :class="{ hidden: !isSelectedAnswer }"
              >
                Ацеклофенак має <br />
                хондропротекторні властивості та <br />
                стимулює синтез компонентів
                <br />
                матрикса хряща!<sup>4</sup>
              </div>
              <div
                class="module-question__detail-btn next-button"
                :class="{ disabled: isDisabledNextBtn }"
                @click="nextState(3, 'next')"
              >
                Далі
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Theory2",
  data() {
    return {
      state: 1,
      answers: [
        {
          title: "Індометацин",
          isSelected: false,
          isCorrect: false
        },
        {
          title: "Ібупрофен",
          isSelected: false,
          isCorrect: false
        },
        {
          title: "Ацеклофенак",
          isSelected: false,
          isCorrect: true
        },
        {
          title: "Німесулід",
          isSelected: false,
          isCorrect: false
        }
      ],
      isSelectedAnswer: false,
      isDisabledNextBtn: true,
      isAnswerHasError: null
    };
  },
  methods: {
    selectAnswer(key) {
      this.isSelectedAnswer = true;
      this.answers.forEach((answer, answerKey) => {
        if (key === answerKey) {
          answer.isSelected = true;
          if (answer.isCorrect) {
            this.isDisabledNextBtn = false;
            this.isAnswerHasError = false;
          } else {
            this.isAnswerHasError = true;
            this.isDisabledNextBtn = true;
          }
        } else {
          answer.isSelected = false;
        }
      });
      gtag("event", "click", {
        event_category: `Выбор "${this.answers[key].title}"`,
        event_label: 'М.2 - Т.1 - Раздел "Вибір НПЗП" - Э.2'
      });
    },
    changeState() {
      this.state += 1;
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.1 - Раздел "Вибір НПЗП" - Э.1'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.1 - Раздел "Вибір НПЗП" - Э.2'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    sup {
      font-family: $fontDemi;
    }
  }
  &__image {
    margin-bottom: 64px;
    img {
      max-width: 100%;
    }
    &-desktop {
      display: block;
    }
    &-mobile {
      display: none;
    }
  }
  &__back {
    margin-bottom: 16px;
    padding-left: 22px;
    background-image: url("~@/assets/img/training-module2/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #9f9f9f;
    cursor: pointer;
  }
  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
    &.disabled {
      opacity: 0.16;
      pointer-events: none;
    }
  }
}
.module-question {
  padding-top: 16px;
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -10px;
  }
  &__col {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 10px;
  }
  &__answers {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("~@/assets/img/training-module2/theme-1/step-2/2-default.jpg");
    &.error {
      background-image: url("~@/assets/img/training-module2/theme-1/step-2/2-error.jpg");
    }
    &.success {
      background-image: url("~@/assets/img/training-module2/theme-1/step-2/2-success.jpg");
    }
    &-item {
      width: 190px;
      height: 48px;
      margin-bottom: 12px;
      background: #f5f5f5;
      border: 1px solid #00a03b;
      border-radius: 90px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 48px;
      text-align: center;
      color: #005984;
      cursor: pointer;
      &.error {
        background: #fb3449;
        border-color: #fb3449;
        color: #fff;
      }
      &.success {
        background: #00a03b;
        color: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__detail {
    max-width: 345px;
    text-align: center;
    &-title {
      margin-bottom: 38px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #00a03b;
      transition: all 0.5s;
      &.hidden {
        display: none;
      }
      sup {
        font-family: $fontDemi;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .module-theory {
    &__back {
      font-size: 12px;
      line-height: 15px;
    }
    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
    }
    &__subtitle {
      font-family: $fontR;
      sup {
        font-family: $fontR;
      }
    }
    &__image {
      margin-bottom: 32px;
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
        width: 100%;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
  .module-question {
    &__col {
      flex-basis: 100%;
      max-width: 100%;
    }
    &__answers {
      margin-bottom: 48px;
    }
  }
}
</style>
