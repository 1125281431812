<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Сумамед® має зручні скорочені інструкції для пацієнтів
    </div>
    <div class="module-theory__accordions">
      <div
        class="module-theory__accordion"
        :class="{ opened: accordion.isOpened }"
        v-for="(accordion, key) in accordions"
        :key="key"
        @click="toggleAccordion(key)"
      >
        <div class="module-theory__accordion-title">
          {{ accordion.title }}
        </div>
        <div
          class="module-theory__accordion-text"
          v-show="accordion.isOpened"
        >
          <p
            class="module-theory__accordion-row"
            v-for="item of accordion.items"
            :key="item.number"
          >
            <span class="number">
              {{ item.number }}.
            </span>
            {{ item.text }}
          </p>
        </div>
      </div>
    </div>

    <div class="module-theory__qr">
      <div class="module-theory__qr-item">
        <img
          src="@/assets/img/training-module5/theme-2/step-6/qr-icon.svg"
          alt=""
          class="module-theory__qr-icon"
        >
        <img
          src="@/assets/img/training-module5/theme-2/step-6/pack-1.png"
          alt=""
          class="module-theory__qr-image"
        >
        <a target="_blank" href="https://qr.teva.ua/271395/" class="module-theory__link">
          Сумамед®Форте<br>Суспензія оральна
        </a>
      </div>
      <div class="module-theory__qr-item">
        <img
          src="@/assets/img/training-module5/theme-2/step-6/qr-icon.svg"
          alt=""
          class="module-theory__qr-icon"
        >
        <img
          src="@/assets/img/training-module5/theme-2/step-6/pack-2.png"
          alt=""
          class="module-theory__qr-image"
        >
        <a target="_blank" href="https://qr.teva.ua/271412/" class="module-theory__link">
          Сумамед® 500 мг, №3<br>Таблетки, що диспергуються
        </a>
      </div>
    </div>

    <p class="module-theory__green-text">
      А тепер час перевірити ваші знання<br> за підсумками цього модуля.
    </p>

    <p class="module-theory__text">
      Натискайте на кнопку “Перейти<br> до тестування”, щоб пройти тест
    </p>

    <div class="module-theory__next next-button" @click="nextState(8, 'next')">
      Перейти до тестування
    </div>
  </div>
</template>

<script>
export default {
  name: "Theory6",
  data() {
    return {
      accordions: [
        {
          id: 1,
          isOpened: false,
          title: "Як це працює:",
          items: [
            {
              number: 1,
              text: "Потрібно зісканувати QR-код на упаковці"
            },
            {
              number: 2,
              text: "Перейти за посиланням"
            },
            {
              number: 3,
              text: "Отримати інформацію про Сумамед® у формі доступних відповідей на часті запитання"
            },
          ]
        },
      ]
    };
  },
  methods: {
    toggleAccordion(key) {
      this.accordions[key].isOpened = !this.accordions[key].isOpened;
      gtag("event", "click", {
        event_category: `'Взаимодействие "Раскрывающийся список №${key + 1}"'`,
        event_label: 'М.5  - Раздел "Упаковка Сумамед®" - Э.7'
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до тестування"',
        event_label: `'М.5 - Т.2 - Раздел "Упаковка Сумамед®" - Э.7'`
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__accordions {
    margin: 32px 0 48px;
  }

  &__accordion {
    position: relative;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 160px 0 12px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(46, 113, 138, 0.16);
    border-radius: 2px;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      top: 24px;
      right: 24px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #005a85;
    }
    &.opened {
      min-height: 186px;
      padding: 0;
      padding: 12px;
      justify-content: unset;
      background: rgba($color: #e4eaed, $alpha: 0.5);
      box-shadow: none;

      .module-theory__accordion-title {
        margin-bottom: 12px;
      }

      &:after {
        transform: rotate(-180deg);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    &-icon {
      max-width: 32px;
      margin-right: 8px;
    }
    &-title {
      display: flex;
      align-items: center;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #005984;
    }
    &-row {
      display: flex;
      border-bottom: 1px solid rgba(33, 38, 97, .3);
      padding: 12px;

      &:last-of-type {
        border: none;
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        height: 24px;
        margin-right: 12px;
        background-color: #2DC7AC;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.1em;
        color: #212661;
        border-radius: 50%;
      }
    }
    &-text {
      position: relative;
      padding-top: 16px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: -12px;
        right: -12px;
        height: 1px;
        background-color: #212661;
      }
    }
  }

  &__container {

    &:first-of-type {
      width: 40%;

      .module-theory__row {
        justify-content: center;
      }

      .module-theory__col {
        align-items: center;
        text-align: center;

        &-img {
          margin-bottom: 8px;
        }
      }
    }

    &:last-of-type {
      width: 59%;

      .module-theory__col {
        p {
          display: flex;
          align-items: center;
          padding: 3px 0;
        }

        &-img {
          margin-right: 5px;
        }
      }
    }
  }

  &__qr {
    display: flex;

    &-item {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 0 19px 0 49px;

      &:first-of-type {
        border-right: 2px solid #212661;
      }
    }

    &-icon {
      position: absolute;
      top: 0;
      left: 49px;
    }

    &-image {
      width: 100%
    }
  }

  &__link {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-decoration-line: underline;
    color: #005984;
  }

  &__green-text {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #00A03B;
  }

  &__text {
    text-align: center;
  }

  .next-button {
    width: 330px;
    height: 64px;
    padding: 0 20px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 105% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__accordions {
      margin: 24px 0 32px;
    }

    &__accordion {
      min-height: 64px;
      padding: 12px;

      &.opened {
        min-height: 186px;
        padding: 12px;
      }

      &-icon {
        max-width: 32px;
        margin-right: 8px;
      }

      &-title {
        font-size: 16px;
        line-height: 20px;
      }

      &-text {
        font-size: 14px;
        line-height: 120%;
      }
    }

  &__qr {
      flex-direction: column;

      &-item {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 32px 0;

        &:first-of-type {
          border-right: none;
          border-bottom: 2px solid #212661;
        }
      }

      &-icon {
        position: absolute;
        top: 32px;
        left: 0;
      }

      &-image {
        width: 80%
      }
    }

    &__green-text {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
      margin-top: 0;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 20px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
