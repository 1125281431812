<template>
  <div class="section-row-img-text">
    <div class="line" v-for="(row, key) in dataContent" :key="key">
      <div class="item" v-for="(secondKey, i) in row" :key="i">
        <div class="img-wrapper">
          <img :src="`${$root.globalUrl}${secondKey.file}`" alt="" />
        </div>
        <div class="text-wrapper">
          <h3 v-html="secondKey.title"></h3>
          <p v-html="secondKey.description"></p>
        </div>
      </div>

      <div class="item item-hidden" v-if="key.length === 1">
        <div class="img-wrapper"></div>
        <div class="text-wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-img-text",
  props: {
    content: Object
  },
  data: () => ({
    dataContent: []
  }),
  beforeMount() {
    let array = this.content.blocks;
    let size = 2;
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      this.dataContent[i] = array.slice(i * size, i * size + size);
    }
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;

.section-row-img-text {
  margin: 10px 0;
  display: flex;
  flex-flow: column;

  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  .line {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    > div {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      min-width: 280px;
      width: 100%;

      &.item-hidden {
        visibility: hidden;
      }

      @media screen and (max-width: 1024px) {
        max-width: 600px;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:nth-child(1) {
        margin-right: 15px;
        @media screen and (max-width: 670px) {
          margin-right: 5px;
        }
        @media screen and (max-width: 600px) {
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        margin-left: 15px;
        @media screen and (max-width: 670px) {
          margin-left: 5px;
        }
        @media screen and (max-width: 600px) {
          margin-left: 0;
        }
      }

      img {
        display: block;
        max-width: 400px;
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        
        @media screen and (max-width: 1024px) {
          max-width: 600px;
        }
        @media screen and (max-width: 670px) {
          margin-bottom: 25px;
        }
        @media screen and (max-width: 600px) {
          margin-bottom: 15px;
        }
      }

      .img-wrapper {
        @media screen and (max-width: 425px) {
          margin: 0 -15px;
        }
      }

      .text-wrapper {
        padding-top: 16px;
        position: relative;

        @media screen and (max-width: 600px) {
          padding-top: 15px;
          margin: 0;
        }

        h3 {
          color: #545454;
          font-family: $fontDemi;
          font-size: 16px;
          margin-bottom: 15px;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }
        }

        p {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          @media screen and (max-width: 670px) {
            font-size: 14px;
          }
          @media screen and (max-width: 600px) {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:before {
          content: "";
          width: 41px;
          height: 3px;
          background: #00a03b;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
</style>
