<template>
  <div class="section-single-video" >
    <video :controls="isVideoPlayed" :poster="`${$root.globalUrl}${content.preview}`" ref="videoContent">
      <source :src="`${$root.globalUrl}${content.file}`" type="video/mp4" />
    </video>
    <div
      class="section-single-video__info-video-play desc"
      v-show="!isVideoPlayed"
      @click="playVideo()"
    ></div>
    <div
      class="section-single-video__info-video-play mob"
      v-show="!isVideoPlayed"
      @click="toggleFullScreen()"
    ></div>
  </div>
</template>

<script>
export default {
  name: "section-section-single-video-360",
  props: {
    content: Object
  },
  data() {
    return {
      isVideoPlayed: false,
    };
  },
  methods: {
    playVideo() {
      this.isVideoPlayed = true;
      this.$refs.videoContent.play();
    },
    toggleFullScreen() {
      this.playVideo();
      const videoElement = this.$refs.videoContent;
      if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
          screen.mozOrientation.lock('landscape');
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
          screen.mozOrientation.lock('landscape-primary');
        } else {
          videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
        }
      } else {
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else {
          document.webkitCancelFullScreen();
        }
      }
    },
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;
.section-single-video {
  margin: 10px 0;
  width: 100%;
  position: relative;
  
  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 15px -15px;
    width: auto;
  }

  video {
    width: 100%;
    display: block;
    min-height: 464px;
    @media screen and (max-width: 425px) {
      min-height: 180px;
    }
  }

  &__info {
    &-text {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
      b {
        font-family: $fontDemi;
      }
    }
    &-video {
      position: relative;
      video {
        display: block;
        max-width: 100%;
        &:focus {
          outline: none;
        }
      }
      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 89px;
        height: 89px;
        transform: translate(-50%, -50%);
        background: url("~@/assets/img/training-module3/theme-2/step-3/play-icon.svg")
          no-repeat;
        background-size: contain;
        cursor: pointer;
        z-index: 1;
        &.mob {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .section-single-video {
    &__info {
      &-video {
        &-play {
          &.mob {
            display: block;
          }
          &.desc {
            display: none;
          }
        }
      }
    }
  }
}
</style>
