<template>
  <div class="training-theory">
    <h3 class="training-theory__title">
      Вітаміни групи В:
    </h3>
    <div class="training-theory__list">
      <div class="training-theory__item">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 3.66667L4.71429 7L10.5 1" stroke="#7FC25D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="training-theory__text">
          <span>Незамінні поживні речовини,</span> які не можуть синтезуватися безпосередньо<br>
          в організмі<sup>3,4</sup>
        </div>
      </div>
      <div class="training-theory__item">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 3.66667L4.71429 7L10.5 1" stroke="#7FC25D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="training-theory__text">
          Забезпечують <span>оптимальний обмін речовин</span> у нервових клітинах<sup>3,4</sup>
        </div>
      </div>
      <div class="training-theory__item">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 3.66667L4.71429 7L10.5 1" stroke="#7FC25D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="training-theory__text">
          Сприяють <span>проведенню нервових імпульсів</span> в ЦНС і ВНС<sup>5</sup>
        </div>
      </div>
      <div class="training-theory__item">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 3.66667L4.71429 7L10.5 1" stroke="#7FC25D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="training-theory__text">
          Проявляють <span>знеболювальну дію у високих дозах<sup>3,4</sup></span> за рахунок зниження кількості 
          недоокиснених продуктів в аксонах периферичних  нервів<sup>5</sup>
        </div>
      </div>
    </div>
    <div class="training-theory__next" @click="nextState(3, 'next')">Далі</div>
  </div>
</template>

<script>
export default {
  name: "Theory",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_1_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontNB: "NotoSans-Bold";
$fontNSB: "NotoSans-SemiBold";
$fontNR: "NotoSans-Regular";
$fontNL: "NotoSans-Light";

.training-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontNSB;
    font-size: 32px;
    line-height: 44px;
    color: #00a03b;
    sub,
    sup {
      font-family: $fontNSB;
    }
  }
  &__list {
    margin-bottom: 64px;
  }
  &__item {
    display: flex;
    margin-bottom: 16px;
    svg {
      margin-right: 14px;
      margin-top: 4px;
    }
  }
  &__text {
    font-family: $fontNR;
    font-size: 16px;
    line-height: 22px;
    max-width: 660px;
    span {
      color: #00a03b;
      font-family: $fontNSB;
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontNR;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}
@media screen and (max-width: 767px) {
  .training-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
    &__list {
      margin-bottom: 24px;
    }
    &__item {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      svg {
        min-width: 12px;
        margin-top: 7px;
      }
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__detail {
      &-title {
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 22px;
      }
      &-text {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &__next {
      width: 100%;
      max-width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
  }
}
</style>
