<template>
  <div class="training-nav">
    <div
      class="training-nav__btn btn-left"
      v-show="isVisibleLeftArrow"
      @click="scrollMenu('left')"
    ></div>
    <div class="training-nav__inner">
      <div class="training-nav__list">
        <div
          class="training-nav__item"
          :class="{
            success: state >= 1,
            current: currentState === 1
          }"
          @click="nextState(1, '')"
        >
          Порушення циркадного ритму
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 2,
            current: currentState === 2
          }"
          @click="nextState(2, '')"
        >
          Нувіджил®
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 3,
            current: currentState === 3
          }"
          @click="nextState(3, '')"
        >
          Клінічна ефективність Нувіджил®
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 4,
            current: currentState === 4
          }"
          @click="nextState(4, '')"
        >
          Інтерактивний квест
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 5,
            current: currentState === 5
          }"
          @click="nextState(5, '')"
        >
          Випадок в аптеці
        </div>
        <div
          class="training-nav__item"
          :class="{
            success: state >= 6,
            current: currentState === 6
          }"
          @click="nextState(6, '')"
        >
          Перевірка знань
        </div>
      </div>
    </div>
    <div
      class="training-nav__btn btn-right"
      v-show="isVisibleRightArrow"
      @click="scrollMenu('right')"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: ["state", "currentState", "isVisibleLeftArrow", "isVisibleRightArrow"],
  data() {
    return {
      scrollLeft: 0
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
    },
    scrollMenu(direction) {
      this.$emit("scrollMenuTo", direction);
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.training-nav {
  position: relative;
  padding-top: 23px;
  margin-bottom: 29px;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: #dddddd;
    z-index: -1;
  }
  &__btn {
    position: absolute;
    bottom: 4px;
    width: 24px;
    height: 32px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    z-index: 1;
    &.btn-left {
      left: 0;
      background-image: url("~@/assets/img/training-module4/navigation/arrow-left-icon.svg");
    }
    &.btn-right {
      right: 0;
      background-image: url("~@/assets/img/training-module4/navigation/arrow-right-icon.svg");
    }
  }
  &__inner {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    display: inline-block;
    padding: 0 0 8px;
    white-space: nowrap;
  }
  &__item {
    position: relative;
    display: inline-block;
    margin-right: 16px;
    padding-left: 20px;
    background-image: url("~@/assets/img/training-module4/navigation/locked-icon.svg");
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 16px;
    font-family: $fontR;
    font-weight: 400;
    line-height: 20px;
    color: #9f9f9f;

    &:last-child {
      margin-right: 0;
    }
    
    &.success {
      padding-left: 20px;
      background-image: url("~@/assets/img/training-module4/navigation/success-icon.svg");
      color: #005984;
      pointer-events: all;
      cursor: pointer;
    }
    &.current {
      padding-left: 0;
      background: none;
      font-family: $fontDemi;
      font-weight: 700;
      color: #005984;

      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        height: 4px;
        background: #7fc25d;
        border-radius: 5px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .training-nav {
    padding-top: 5px;
    margin-left: -15px;
    margin-right: -15px;
    &__btn {
      display: none;
    }
    &__list {
      padding: 0 15px 8px;
    }
    &__item {
      margin-right: 20px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
