<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Профіль безпеки ацеклофенаку: селективність
    </div>
    <div class="module-theory__text">
      Хороший профіль безпеки ацеклофенаку пов’язаний насамперед з селективністю
      його дії.
    </div>
    <div class="module-theory__text">
      <b
        >Ацеклофенак селективно інгібує тільки циклооксигеназу 2
        (ЦОГ2)<sup>*</sup>.</b
      >
      <br />
      Для порівняння, той самий диклофенак інгібує ЦОГ1, і ЦОГ2.<sup>5 </sup>
    </div>
    <div class="module-theory__text">
      Проте яка різниця що інгібувати спитаєте ви? А різниця є!
    </div>
    <div class="module-theory__image">
      <img
        class="module-theory__image-desktop"
        src="@/assets/img/training-module2/theme-3/step-3/1-desktop.svg"
        alt=""
      />
      <img
        class="module-theory__image-mobile"
        src="@/assets/img/training-module2/theme-3/step-3/1-mobile.svg"
        alt=""
      />
      <div class="module-theory__image-text">
        Інгібуючи тільки ЦОГ2 ми зменшуємо<br />
        ризик ускладнень з боку ШКТ!<sup>7</sup>
      </div>
    </div>
    <div class="module-theory__next" @click="nextState(4, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Theory2",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.3 - Раздел "Селективність дії ацеклофенаку" '
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__text {
    margin-bottom: 19px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    sup {
      font-family: $fontDemi;
    }
  }
  &__image {
    &-desktop {
      display: block;
      max-width: 100%;
      margin: 0 auto 6px;
    }
    &-mobile {
      display: none;
      max-width: 100%;
      margin: auto;
    }
    &-text {
      margin-bottom: 64px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #00a03b;
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
      br {
        display: none;
      }
    }
    &__text {
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      sup {
        font-family: $fontR;
      }
      b {
        font-family: $fontDemi;
      }
    }
    &__item {
      margin-bottom: 32px;
      &:nth-child(2) {
        .module-theory {
          &__row {
            flex-wrap: wrap-reverse;
          }
        }
      }
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__image {
      margin-bottom: 24px;
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
      }
      &-text {
        margin-bottom: 24px;
        text-align: left;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }

    &__next {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
