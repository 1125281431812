<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Сумамед® — це оригінальний азитроміцин, найсучасніший антибіотик із класу макролідів. 
    </div>
    <div class="module-theory__text">
      Вперше молекулу азитроміцину було синтезовано на підприємстві PLIVA, в Хорватії. Там само й дотепер виробляється оригінальний препарат Сумамед®.
    </div>

    <ul class="module-theory__list">
      Азитроміцин це:
      <li class="module-theory__item">
        • Єдиний представник групи 15-членних макролідів<sup>1</sup>
      </li>
      <li class="module-theory__item">
        • №1 за об’ємами продажів в упаковках серед оральних антибіотиків*
      </li>
    </ul>
    
    <div class="module-theory__indications">
      <div class="module-theory__h2-title">
        Показання до застосування<sup>2</sup>:
      </div>
      <img
        class="desk"
        src="@/assets/img/training-module5/theme-1/step-1/indications.png"
        alt=""
      >
      <img
        class="mob"
        src="@/assets/img/training-module5/theme-1/step-1/indications-mob.png"
        alt=""
      >
      <div class="module-theory__exiters">
        <p class="module-theory__text">
          Найчастіше азитроміцин призначають 
          при бактеріальних інфекціях дихальних шляхів. Особливістю азитроміцину є те, 
          що він може діяти як на «типових» 
          так і на «атипових»** збудників.<sup>3,4</sup>
        </p>
        <div class="module-theory__scheme">
          <div class="module-theory__container">
            <div class="module-theory__block">
              <h3>
                «Типові» збудники
              </h3>
              Streptococcus pneumonia<br>
              Heamophilus influenzae
            </div>
            <div class="module-theory__block">
              <h3>
                «Атипові» збудники
              </h3>
              Mycoplasma pneumoniae<br>
              Chlamydophila pneumoniae
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="module-theory__next next-button" @click="nextState(2, 'next')">
      Далі
    </div>

  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory1",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.5 - Т.1 - Раздел "Сумамед®: курс прийому" - Э.1'`
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }

  &__list {
    margin: 32px 0 48px;
    font-weight: 600;
    font-family: $fontDemi;
    font-size: 22px;
    line-height: 27px;
    color: #212661;
    list-style: none;
  }

  &__item {
    margin-top: 12px;
    font-family: $fontDemi;
  }

  &__h2-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #00A03B;
    margin-bottom: 23px;
  }

  .mob {
    display: none;
  }

  .desk {
    max-width: 100%;
  }

  &__exiters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
  }

  &__scheme {
    position: relative;
    margin-left: 16px;
    padding: 12px 0;
    min-width: 50%;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      background: #E4EAED;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__block {
    width: 48%;
    padding: 12px 8px;
    font-style: italic;
    box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
    background-color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #212661;

    h3 {
      font-style: normal;
      font-size: 16px;
      margin-bottom: 6px;
      font-family: $fontDemi;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__text {
      font-size: 14px;
      line-height: 120%;
      color: #545454;
    }

    &__list {
      margin: 24px 0 32px;
      padding: 16px 24px;
      font-size: 16px;
      line-height: 20px;
    }

    &__h2-title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .mob {
      display: block;
      width: 100%;
    }

    .desk {
      display: none;
    }

    &__exiters {
      flex-direction: column;
      margin-bottom: 32px;
    }

    &__scheme {
      margin-left: 0;
      margin-top: 24px;
      min-width: 100%;
    }

    &__block {
      font-size: 11px;
      line-height: 13px;

      h3 {
        font-style: normal;
        font-size: 14px;
        margin-bottom: 6px;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
