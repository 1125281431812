<template>
  <div class="subscribe-block">
    <img
      class="left-img mob"
      :src="require('../assets/img/sub-img.jpg')"
      alt=""
    />
    <img
      class="left-img desktop"
      :src="require('../assets/img/sub-img-mob.jpg')"
      alt=""
    />

    <div class="left-side">
      <p class="reg-text">
        Підпишіться, щоб отримувати новини від <b>Тева Україна</b>
      </p>

      <a
        href="https://consent.teva.ua/"
        target="_blank"
        style="text-decoration: none"
      >
        <div class="sub-btn">
          <span class="sub-btn-text">Підписатися</span>
          <span class="sub-btn-icon"></span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "subscribe-block",
};
</script>

<style scoped lang="scss">
@import '../css/variables.scss';

$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

.subscribe-block {
  overflow: hidden;
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #005a85;
  min-height: 115px;
  max-width: 1128px;
  margin: auto 0 0 0;

  @media screen and (max-width: 670px) {
    flex-direction: column;
    max-width: 500px;
    margin: 10px auto;
  }

  .left-img {
    display: block;
    max-width: 264px;
    width: 100%;
    height: auto;

    &.desktop {
      @media screen and (max-width: 880px) {
        display: none;
      }
    }

    &.mob {
      display: none;
      @media screen and (max-width: 880px) {
        display: block;
      }
    }

    @media screen and (max-width: 670px) {
      max-width: 500px;
    }
  }

  .left-side {
    margin-left: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 880px) {
      margin-left: 0;
      padding: 25px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .reg-text {
    width: 100%;
    max-width: 340px;

    font-family: $fontSecondary;
    font-size: 17px;
    font-weight: 300;
    color: white;

    @media screen and (max-width: 670px) {
      max-width: 240px;
      font-size: 14px;
    }

    b,
    strong {
      font-family: inherit;
      font-weight: 600;
    }
  }

  .sub-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 170px;
    height: 42px;
    padding: 0 20px;
    background: #aa198b;

    margin: 0 30px 0 auto;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s ease;

    font-family: $fontR;
    font-size: 15px;
    color: white;

    @media screen and (max-width: 880px) {
      margin: 10px 30px 0 0;
    }

    * {
      transition: all 0.2s ease;
    }

    &:hover {
      .sub-btn-icon {
        transform: translateX(5px);
      }
    }

    .sub-btn-text {
      margin-bottom: 2px;
    }
    
    .sub-btn-icon {
      margin-top: 2px;
      width: 20px;
      height: 20px;

      background: url("../assets/icon/sub-btn-icon.svg") no-repeat center center;
    }
  }
}
</style>
