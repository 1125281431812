<template>
  <div class="section-ref">
    <h3 v-html="content.title"></h3>

    <div class="list-note">
      <p v-html="content.description"></p>
    </div>

    <div class="list-link">
      <div v-for="(item, i) in content.blocks" :key="i">
        <span class="list-link__number" v-html="item.number"></span>
        <div class="flex align-center" style="" v-html="item.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-ref",

  props: {
    content: Object,
  },

  data: () => ({
    nameSection: "reg_list",
  }),
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-ref {
  margin: 10px 0;
  padding: 30px 45px;
  background: #ededed;

  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  @media screen and (max-width: 425px) {
    padding: 20px 15px;
    margin: 5px -15px;
  }

  h3 {
    font-family: $fontM;
    font-size: 20px;
    color: #00a03b;
    font-weight: normal;
    margin-bottom: 25px;
  }

  .list-link {
    > div {
      margin-bottom: 20px;
      display: flex;

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 13px;
      }
      a {
        color: #00a03b;
        text-decoration: none;
        word-break: break-word;
      }
    }
    &__number {
      margin-right: 15px;
      min-width: 25px;
      min-height: 25px;
      max-height: 25px;
      padding: 0 5px;
      background: #00a03b;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 13px;
      font-family: $fontR;
    }
  }

  .list-note {
    p {
      color: #545454;
      font-family: $fontR;
      font-size: 13px;
      margin-bottom: 15px;
    }
  }
}
</style>
