<template>
  <div class="section-double-row-text-icon">
    <div class="col col-left">
      <h3 v-html="content.title"></h3>
      <div class="text-wrapper" v-html="content.description"></div>
    </div>

    <div class="col col-right">
      <div v-for="key in content.blocks" class="item-icon">
        <div>
          <img
            :src="`${$root.globalUrl}${key.file}`"
            alt=""
            width="45"
            height="45"
          />
        </div>
        <div class="text-wrapper" v-html="key.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-double-row-text-icon",
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;

.section-double-row-text-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;

  @media screen and (max-width: 670px) {
    margin: 10px 0;
    flex-direction: column;
  }

  .col {
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 670px) {
      max-width: 670px;
    }

    &:nth-child(1) {
      margin-right: 15px;
      position: relative;
      padding-top: 16px;

      @media screen and (max-width: 670px) {
        margin-right: 0;
        padding-top: 15px;
      }

      &:before {
        content: "";
        width: 41px;
        height: 3px;
        position: absolute;
        left: 0;
        top: 0;
        background: #00a03b;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 0;
      }
    }

    &.col-left {
      h3 {
        color: #545454;
        font-family: $fontDemi;
        font-size: 16px;
        margin-bottom: 15px;

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }
    }

    &.col-right {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      @media screen and (max-width: 670px) {
        justify-content: center;
      }

      .item-icon {
        display: flex;
        flex-direction: column;
        max-width: 150px;
        width: 100%;
        margin-bottom: 50px;
        @media screen and (max-width: 670px) {
          margin: 15px;
        }
        @media screen and (max-width: 425px) {
          max-width: 125px;
          margin: 10px;
        }

        img {
          display: block;
          margin-bottom: 20px;
          height: 45px;
          width: auto;
        }

        p {
          color: #00a03b;
          font-family: $fontM;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
