<template>
  <div class="module-theory">
    <div class="module-theory__back" v-if="state > 1" @click="previousStep()">
      Назад
    </div>
    <div class="module-theory__state" v-show="state === 1">
      <div class="module-theory__title">
        Вітаємо на четвертому модулі <br />
        «Порушення циркадного ритму, пов'язане з позмінною роботою»
      </div>
      <div class="module-theory__subtitle">
        Пропонуємо не гаяти час і відразу випробувати свої сили!
      </div>

      <div class="module-theory__item">
        <div class="module-theory__item-title">
          Уявімо пацієнта, який «засинає» під час нічних змін.
        </div>
        <div class="module-theory__item-row">
          <div class="module-theory__item-col-left">
            <div class="module-theory__item-img">
              <img src="@/assets/img/training-module4/theory-1/1.jpg" alt="" />
            </div>
          </div>
          <div class="module-theory__item-col-right">
            <div class="module-theory__item-detail">
              <div class="module-theory__item-subtitle">Основні скарги:</div>
              <div class="module-theory__item-list">
                <ul>
                  <li>Надмірна сонливість у нічну зміну</li>
                  <li>Безсоння</li>
                  <li>Виснаження</li>
                  <li>Дратівливість</li>
                  <li>Головний біль</li>
                </ul>
              </div>
              <div class="module-theory__item-subtitle subtitle-green">
                Хлопець працює у такому режимі вже більше півроку.
              </div>
              <div class="module-theory__item-next" @click="nextStep()">
                Далі
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-theory__state" v-show="state === 2">
      <div class="module-theory__title">
        Яку проблему може мати пацієнт?
      </div>
      <div class="module-theory__text">
        <p>
          Перш за все, потрібно звернути увагу, що пацієнт має
          <b>нестандартний графік роботи!</b>
        </p>
        <p>
          Можливі варіанти: <br />
          • Фіксована нічна зміна <br />
          • Післяобідня/вечірня зміна <br />
          • «Плаваючий» позмінний графік
        </p>
        <p class="info-modal-wrapper">
          На жаль, не всі можуть пристосуватися до такого ритму життя.
          <b>
            У 10-38% випадків робота позмінно <br />
            приводить до
            <span class="show-info-modal">
              порушення циркадного ритму.
              <div class="info-modal">
                <div class="info-modal__text">
                  Це стан, який виникає у разі невідповідності між природнім та
                  фактичним режимом «сон-бадьорість» при позмінній роботі.<sup
                    >11,12</sup
                  >
                </div>
              </div>
            </span>
            <sup>10</sup>
          </b>
        </p>
        <p>
          Розлад сну при позмінній роботі може розвинутися, якщо людина працює за нестандартним графіком більше 3 місяців.
        </p>
        <p>
          Це серйозна проблема, що несе ризики для здоров’я та знижує якість
          життя.<sup>11, 12</sup>
        </p>
        <p class="text-green">
          <b>
            Тому вона потребує уваги та консультації лікаря для вибору тактики
            лікування!
          </b>
        </p>
      </div>
      <div class="module-theory__next next-button" @click="nextStep()">
        Далі
      </div>
    </div>
    <div class="module-theory__state" v-show="state === 3">
      <div class="module-theory__title question-title">
        Які засоби збадьорення він може використовувати?
      </div>

      <div class="module-theory__answers">
        <div
          class="module-theory__answers-item"
          :class="{
            disabled: selectedAnswer && !answer.isSelected,
            selected: answer.isSelected
          }"
          v-for="(answer, key) in answers"
          :key="key"
          v-html="answer.title"
          @click="selectAnswer(key)"
        ></div>
      </div>

      <div
        class="module-theory__message"
        :class="{
          correct: selectedAnswer.isCorrect,
          error: !selectedAnswer.isCorrect
        }"
        v-if="selectedAnswer"
      >
        <div class="module-theory__message-row">
          <div class="module-theory__message-col-left">
            <div
              class="module-theory__message-title"
              v-html="selectedAnswer.title"
            ></div>
            <div
              class="module-theory__message-text"
              v-html="selectedAnswer.message"
            ></div>
          </div>
          <div class="module-theory__message-col-right">
            <div
              class="module-theory__message-reload"
              v-if="!selectedAnswer.isCorrect"
              @click="reloadAnswer()"
            >
              Спробувати інший варіант
            </div>
            <div
              class="module-theory__message-next next-button"
              v-if="selectedAnswer.isCorrect"
              @click="nextState(2, 'next')"
            >
              Далі
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory1",
  data() {
    return {
      state: 1,
      selectedAnswer: null,
      answers: [
        {
          id: 1,
          title: "Кофеїн у таблетках",
          isSelected: false,
          isCorrect: false,
          message:
            "Можливий варіант, але є сумніви. Період напіввиведення кофеїну — 3–7 годин, а робоча зміна — min 8 годин. Однієї таблетки може бути недостатньо для усунення проблеми."
        },
        {
          id: 2,
          title: "Жувальна гумка з ментолом",
          isSelected: false,
          isCorrect: false,
          message:
            "Звісно, пацієнт зможе себе відволікти на деякий час. Але сонливість під час нічних змін може мати серйозні наслідки: нещасні випадки на виробництві, ДТП.<sup>3</sup> Тому слід віддавати перевагу рішенням, які мають доведену ефективність."
        },
        {
          id: 3,
          title: "Пити каву протягом зміни",
          isSelected: false,
          isCorrect: false,
          message:
            "Без сумніву, поціновувачі кави використовують цей спосіб. Але ж кава має декілька складових. Кофеїн справді забезпечує стан бадьорості, а от теобромін, навпаки присипляє. Тому потрібний ефект має лише заварна цільнозернова кава, а не розчинна.<sup>4</sup>"
        },
        {
          id: 4,
          title: "Нувіджил®",
          isSelected: false,
          isCorrect: true,
          message:
            "Цей лікарський засіб відпускається за рецептом, тому необхідність його призначення визначається лікарем. Нувіджил® – засіб для підтримання бадьорості* протягом робочої зміни. Він бореться з надмірною сонливістю у пацієнтів з хронічним порушенням сну внаслідок позмінного графіку роботи.<sup>5,6</sup>"
        }
      ]
    };
  },
  methods: {
    selectAnswer(key) {
      this.answers[key].isSelected = true;
      this.selectedAnswer = this.answers[key];
      gtag("event", "click", {
        event_category: `'Выбор "${this.selectedAnswer.title}'`,
        event_label: `'М.4  - Раздел "Порушення циркадного ритму" - Э.${this.state}'`
      });
    },
    reloadAnswer() {
      this.answers.forEach(answer => {
        answer.isSelected = false;
      });
      this.selectedAnswer = null;
    },
    nextStep() {
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.4  - Раздел "Порушення циркадного ритму" - Э.${this.state}'`
      });
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    previousStep() {
      this.state -= 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.4  - Раздел "Порушення циркадного ритму" - Э.${this.state}'`
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
  }
  &__subtitle {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }
  &__item {
    &-title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #2e718a;
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }
    &-img {
      img {
        display: block;
        max-width: 100%;
      }
    }

    &-subtitle {
      margin-bottom: 16px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #2e718a;
      &.subtitle-green {
        margin-bottom: 32px;
        color: #00a03b;
      }
    }
    &-list {
      margin-bottom: 22px;
      ul {
        padding-left: 19px;
        li {
          font-family: $fontDemi;
          font-size: 18px;
          line-height: 22px;
          color: #2e718a;
        }
      }
    }
    &-next {
      width: 262px;
      height: 64px;
      padding: 0 83px;
      background-color: #aa198b;
      background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
      border-radius: 90px;
      font-family: $fontDemi;
      font-size: 22px;
      text-indent: -50px;
      text-align: center;
      line-height: 64px;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-position: 110% center;
      }
    }
  }
  &__text {
    max-width: 730px;
    margin-bottom: 64px;
    &.small-margin {
      margin-bottom: 32px;
    }
    p {
      margin-bottom: 15px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
      b {
        font-family: $fontDemi;
      }
      span {
        &.show-info-modal {
          position: relative;
          border-bottom: 1px solid #545454;
          cursor: pointer;
          &:hover {
            .info-modal {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
      &.text-green {
        color: #00a03b;
      }
      &.info-modal-wrapper {
        br {
          display: none;
        }
      }
      .info-modal {
        position: absolute;
        max-width: 420px;
        width: max-content;
        top: 32px;
        left: 74px;
        transform: translateX(-50%);
        padding: 8px;
        background: #ffffff;
        box-shadow: 0px 0px 16px rgba(0, 89, 132, 0.12);
        border-radius: 2px;
        font-family: $fontR;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        visibility: hidden;
        opacity: 0;
        cursor: default;
        transition: all 0.5s;
        &:before {
          content: "";
          position: absolute;
          top: -7.5px;
          left: 45%;
          width: 15px;
          height: 15px;
          background: #fff;
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }
  }
  &__back {
    margin-bottom: 16px;
    padding-left: 22px;
    background-image: url("~@/assets/img/training-module4/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #9f9f9f;
    cursor: pointer;
  }
  &__answers {
    max-width: 401px;
    margin: 0 auto 32px;
    padding-top: 32px;
    &-item {
      position: relative;
      margin-bottom: 24px;
      padding: 14px 42px 14px 42px;
      background: rgba(228, 234, 237, 0.4);
      border: 1px solid #00a03b;
      border-radius: 48px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #005a85;
      cursor: pointer;
      transition: all 0.5s;
      &:last-child {
        margin-bottom: 0;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      &.selected,
      &:hover {
        background: #00a03b;
        color: #fff;
        &:before {
          border: 3px solid #fff;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #00a03b;
      }
    }
  }
  &__message {
    padding: 24px 48px 32px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #fff;
    &.error {
      background: rgba($color: #fb3449, $alpha: 0.5);
    }
    &.correct {
      background: rgba($color: #00a03b, $alpha: 0.5);
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -20px;
    }
    &-col {
      &-left {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 20px;
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 20px;
      }
    }
    &-title {
      margin-bottom: 12px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
    }
    &-text {
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
    }
    &-reload {
      padding: 19px 46px 18px;
      background: #aa198b;
      border-radius: 90px;
      font-family: $fontDemi;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      cursor: pointer;
    }
    &-next {
      margin: 0;
    }
  }
  .next-button {
    width: 262px;
    height: 64px;
    margin: auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__back {
      font-size: 12px;
      line-height: 15px;
    }
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
      &.question-title {
        margin-bottom: 24px;
        text-align: center;
      }
    }
    &__subtitle {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
    }
    &__item {
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &-title {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 20px;
      }
      &-img {
        margin-bottom: 12px;
        img {
          width: 100%;
        }
      }
      &-subtitle {
        font-size: 18px;
        line-height: 22px;
        &.subtitle-green {
          margin-bottom: 32px;
          font-size: 18px;
          line-height: 22px;
        }
      }
      &-list {
        margin-bottom: 15px;
        ul {
          li {
            font-family: $fontR;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      &-next {
        width: 266px;
        height: 44px;
        margin: auto;
        padding: 0 95px;
        background-size: 24px;
        font-size: 16px;
        line-height: 43px;
        text-indent: -40px;
      }
    }
    &__text {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 17px;
      p {
        font-size: 14px;
        line-height: 17px;
        span {
          font-family: $fontDemi;
          font-weight: 700;
        }
        &.info-modal-wrapper {
          br {
            display: inline;
          }
        }
        .info-modal {
          max-width: 290px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    &__answers {
      padding-top: 0;
      &-item {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &__message {
      margin: 0 -20px;
      padding: 24px 24px 36px;
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &-title {
        display: none;
      }
      &-text {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
      &-reload {
        margin: auto;
        padding: 12px 30px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
