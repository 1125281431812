<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Ефективність НПЗП при остеоартриті <br />
      колінного суглобу
    </div>
    <div class="module-theory__text">
      Ми вже дізналися, що ацеклофенак має хондропротекторні властивості. <br />
      Давайте розглянемо його <b>ефективність порівняно з іншими НПЗП</b> <br />
      при лікуванні остеоартриту (ОА) колінного суглобу.
    </div>
    <div class="module-efficiency">
      <div class="module-efficiency__row">
        <div class="module-efficiency__col-left">
          <div class="module-efficiency__image image-desktop">
            <img
              src="~@/assets/img/training-module2/theme-2/step-1/1.svg"
              alt=""
            />
          </div>
        </div>
        <div class="module-efficiency__col-right">
          <div class="module-efficiency__detail">
            <div class="module-efficiency__title">Полегшення болю</div>
            <div class="module-efficiency__text">
              Результати мета-аналізу 44-х дослідженнь (Jung S.Y. et al. 2018)
              за участю 19045 пацієнтів з ОА колінних суглобів<sup>2</sup>
            </div>
            <div class="module-efficiency__image image-mobile">
              <img
                src="~@/assets/img/training-module2/theme-2/step-1/1-mobile.svg"
                alt=""
              />
            </div>
            <div class="module-efficiency__text">
              <b>Ацеклофенак увійшов у трійку</b> ефективних НП3П для пацієнтів
              з вираженим больовим синдромом<sup>2</sup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-efficiency">
      <div class="module-efficiency__row">
        <div class="module-efficiency__col-left">
          <div class="module-efficiency__image">
            <img
              src="~@/assets/img/training-module2/theme-2/step-1/2.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="module-efficiency__col-right">
          <div class="module-efficiency__detail">
            <div class="module-efficiency__title">
              Покращення функціонування суглобу
            </div>
            <div class="module-efficiency__text">
              Відповідно до результатів мета-аналізу 8 досліджень за участю 1709
              пацієнтів з ОA (Patel P.B. Et al. 2017)
              <b>ацеклофенак перевершував диклофенак та парацетамол</b> за
              показниками покращення фізичної функції суглобу.<sup>3</sup>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="module-theory__subtitle">
      Ацеклофенак у складі Олфен<sup>®</sup>-АФ не тільки має протизапальні та знеболювальні властивості, а й застосовується лише 1 раз на добу<sup>6</sup>.
    </div>

    <div class="module-theory__next" @click="nextState(2, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Theory1",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.2 - Раздел "Ефективність НПЗП" '
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__text {
    margin-bottom: 48px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 64px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}
.module-efficiency {
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 56%;
      max-width: 56%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 44%;
      max-width: 44%;
      padding: 0 10px;
    }
  }
  &__image {
    &.image-mobile {
      display: none;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__detail {
    max-width: 310px;
  }
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
  }
  &__text {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    &:last-child {
      margin-bottom: 0;
    }
    sup {
      font-family: $fontDemi;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
      br {
        display: none;
      }
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }
    &__text {
      margin-bottom: 20px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      &--green {
        margin-bottom: 12px;
        font-family: $fontDemi;
      }
      br {
        display: none;
      }
      b {
        font-family: $fontDemi;
      }
    }

    &__next {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
  .module-efficiency {
    margin-bottom: 8px;
    &__row {
      flex-wrap: wrap-reverse;
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__title {
      margin-bottom: 6px;
      font-size: 16px;
      line-height: 20px;
    }
    &__image {
      margin-bottom: 24px;
      &.image-desktop {
        display: none;
      }
      &.image-mobile {
        display: block;
      }
      img {
        width: 100%;
      }
    }
    &__detail {
      max-width: 100%;
      margin-bottom: 24px;
    }
    &__text {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      b {
        font-family: $fontDemi;
      }
    }
  }
}
</style>
