<template>
  <div class="section-double-list">
    <div class="line" v-for="(list, key) in dataContent" :key="key">
      <div class="text-wrapper" v-for="(secondKey, i) in list" :key='i'>
        <b v-html="secondKey.text.title"></b>
        <p v-html="secondKey.text.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-double-list-list",
  props: {
    content: Object
  },
  data: () => ({
    dataContent: []
  }),
  beforeMount() {
    let array = [];
    this.content.blocks.forEach((el, index) => {
      array.push({
        text: el,
        num: index + 1
      });
    });
    let size = Math.ceil(array.length / 2);
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      this.dataContent[i] = array.slice(i * size, i * size + size);
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;

.section-double-list {
  margin: 10px 0;
  display: flex;
  @media screen and (max-width: 600px) {
    margin: 5px 0;
    flex-direction: column;
  }

  .line {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 600px) {
      max-width: 600px;
    }

    &:first-child {
      margin-right: 15px;
      @media screen and (max-width: 670px) {
        margin-right: 5px;
      }
      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &:last-child {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 600px) {
        margin-left: 0;
      }
    }

    .text-wrapper {
      margin-bottom: 15px;
      max-width: 400px;
      width: 100%;
      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        font-size: 16px;
        color: #545454;
        font-family: $fontR;
        margin-bottom: 15px;

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        b,
        strong {
          display: block;
          font-weight: normal;
          color: #00a03b;
          font-family: $fontM;
          text-decoration: underline;
        }
      }

      b,
      strong {
        display: block;
        font-weight: normal;
        color: #00a03b;
        font-family: $fontM;
        text-decoration: underline;
      }
    }
  }
}
</style>
