<template>
  <div class="section-question">
    <div class="question-item-wrapper" v-for="(key, value) in question">
      <p v-if="thankBlock === false" class="section-h3-title">
        Пройдіть опитування
      </p>

      <div
        v-if="questionNum === secondValue + 1 && thankBlock === false"
        class="question-item"
        v-for="(secondKey, secondValue) in key.questions"
      >
        <div class="name" v-html="secondKey.title"></div>

        <div class="radio-btn-wrapper">
          <div
            @click="sendAnswer(key.id, secondKey.id, thirdKey.id, value)"
            :data-id="thirdKey.id"
            class="item"
            :class="lookDoWide"
            v-for="thirdKey in countLetters(secondKey.answers)"
            v-html="thirdKey.title"
          ></div>
        </div>
      </div>

      <div class="thank-block" v-if="thankBlock">
        Дякуємо за Вашу відповідь!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "section-question",
  props: {
    content: Object
  },

  data: () => ({
    question: "",
    questionNum: 1,
    thankBlock: false,
    category: [
      {
        name: "Педіатр",
        params: "pediatrician",
        id: "1",
        title: "Для педіатрів - Rx-портал Тева"
      },
      {
        name: "Терапевт",
        params: "therapist",
        id: "2",
        title: "Для терапевтів - Rx-портал Тева"
      },
      {
        name: "Отоларинголог",
        params: "otolaryngologist",
        id: "3",
        title: "Для отоларингологів - Rx-портал Тева"
      },
      {
        name: "Невропатолог",
        params: "neurologist",
        id: "4",
        title: "Для невропатологів - Rx-портал Тева"
      },
      {
        name: "Пульмонолог",
        params: "pulmonologist",
        id: "5",
        title: "Для пульмонологів - Rx-портал Тева"
      },
      {
        name: "Фармацевт",
        params: "pharmacist",
        id: "6",
        title: "Для фармацевтів - Rx-портал Тева"
      },
      {
        name: "Дистриб’ютор",
        params: "distributor",
        id: "7",
        title: ""
      },
      {
        name: "Психіатр",
        params: "psychiatrist",
        id: "8",
        title: "Для психіатрів - Rx-портал Тева"
      },
      {
        name: "Кардіолог",
        params: "cardiologist",
        id: "9",
        title: "Для кардіологів - Rx-портал Тева"
      },

      {
        name: "Травматолог",
        params: "traumatologist",
        id: "10",
        title: "Для травматологів - Rx-портал Тева"
      },

      {
        name: "Ревматолог",
        params: "rheumatologist",
        id: "11",
        title: "Для ревматологів - Rx-портал Тева"
      },
      {
        name: "Гінеколог",
        params: "gynecologist",
        id: "12",
        title: "Для гінекологів - Rx-портал Тева"
      },

      {
        name: "Ендокринолог",
        params: "endocrinologist",
        id: "13",
        title: "Для ендокринологів - Rx-портал Тева"
      },

      {
        name: "Дерматолог",
        params: "dermatologist",
        id: "14",
        title: "Для дерматологів - Rx-портал Тева"
      },

      {
        name: "Хірург",
        params: "surgeon",
        id: "15",
        title: "Для хірургів - Rx-портал Тева"
      },
      {
        name: "Інфекціоніст",
        params: "infectious",
        id: "16",
        title: "Для інфекціоністів - Rx-портал Тева"
      }
    ],
    forAxios: "",
    doWide: false
  }),
  computed: {
    lookDoWide() {
      return { wide: this.doWide };
    }
  },
  methods: {
    countLetters(answer) {
      answer.forEach(el => {
        if (el.title.length > 50) this.doWide = true;
      });

      return answer;
    },
    getQuestion() {
      axios
        .get(
          this.$root.globalUrl +
            "/api/v1/polls?ids=" +
            this.content.poll_ids.join(",")
        )
        .then(res => {
          this.question = res.data;
          this.category.forEach(el => {
            if (el.params === this.$route.params.category) {
              this.forAxios = el.id;
            }
          });
        })
        .catch(error => {});
    },

    sendAnswer(poll_id, question_id, answer_id, mainValue) {
      this.doWide = false;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      axios
        .post(this.$root.globalUrl + "/api/v1/polls/" + poll_id + "/vote", {
          specialty_id: parseInt(this.forAxios),
          votes: [
            {
              question_id: question_id,
              answers: [
                {
                  answer_id: answer_id
                }
              ]
            }
          ]
        })
        .then(res => {
          let questItems = document.querySelectorAll(".question-item .item");
          let thisAnswerNotCorrect = true;

          res.data.data.forEach(el => {
            questItems.forEach(function(item) {
              el.correct_answer_ids.forEach(function(correct_answer) {
                if (+item.getAttribute("data-id") === correct_answer) {
                  item.classList.add("flash-right");
                }
                if (correct_answer == answer_id) {
                  thisAnswerNotCorrect = false;
                }
              });
            });
            if (thisAnswerNotCorrect) {
              questItems.forEach(function(item) {
                if (+item.getAttribute("data-id") === answer_id) {
                  item.classList.add("flash-wrong");
                }
              });
            }
          });

          setTimeout(() => {
            if (this.questionNum >= this.question[mainValue].questions.length) {
              this.thankBlock = true;
            }
            this.questionNum++;
            this.doWide = false;
          }, 2500);
        })
        .catch(error => {});
    }
  },
  mounted() {
    this.getQuestion();
  }
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.section-question {
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 10px -15px;
  }

  .question-item-wrapper {
    padding: 38px 41px;
    background: #f5f5f5;

    @media screen and (max-width: 600px) {
      padding: 19px 15px;
    }

    .thank-block {
      text-align: center;
      color: #005a85;
      font-size: 18px;
    }

    .question-item {
      .name {
        font-size: 23px;
        font-family: $fontDemi;

        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
      }

      .radio-btn-wrapper {
        margin: 15px auto;
        // max-width: 573px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        @media screen and (max-width: 600px) {
          justify-content: center;
        }

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 12px;
          color: #ffffff;
          font-size: 13px;
          font-family: $fontM;

          background: #005a85;
          border-radius: 113.793px;

          max-width: calc(50% - 20px);
          width: 100%;

          margin: 10px 0;

          transition: all 0.2s ease;
          cursor: pointer;

          @media screen and (max-width: 600px) {
            max-width: 100%;
          }

          // &:hover {
          //   background: #00A03B;
          // }
          &.wide {
            max-width: none;
          }
          &.active {
          }
        }
      }
    }

    .section-h3-title {
      color: #005a85;
      font-size: 14px;
      font-family: $fontR;
      letter-spacing: 0.26em;

      text-transform: uppercase;

      @media screen and (max-width: 600px) {
        font-size: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.flash-right {
  background: #00a03b !important;
}

.flash-wrong {
  background: #ff4136 !important;
}
</style>
