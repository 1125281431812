<template>
  <div class="section-row-icon-text">
    <div
      class="item-icon"
      v-for="key in content.blocks"
      v-if="key.text !== '' && key.file !== undefined"
    >
      <div>
        <img
          :src="`${$root.globalUrl}${key.file}`"
          alt=""
          width="45"
          height="45"
        />
      </div>
      <div class="text-wrapper" v-html="key.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-icon-text",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-row-icon-text {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }

  @media screen and (max-width: 670px) {
    margin: 5px 0;
    flex-flow: row wrap;
    justify-content: center;
  }

  .item-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
    margin: 15px;
    width: 100%;

    @media screen and (max-width: 425px) {
      margin: 10px;
      max-width: 125px;
    }

    img {
      display: block;
      margin-bottom: 15px;
      height: 45px;
      width: auto;
    }

    p {
      text-align: center;
      font-family: $fontM;
      font-size: 13px;
      color: #00a03b;
    }
  }
}
</style>
