<template>
  <section class="article-page training-module-1">
    <div class="main-page">
      <div class="content">
        <div class="article-wrapper">
          <Navigation
            :state="state"
            :currentState="currentState"
            :isVisibleLeftArrow="isVisibleLeftArrow"
            :isVisibleRightArrow="isVisibleRightArrow"
            v-on:changeState="changeState($event)"
          />
          <Theory
            v-on:changeState="changeState($event)"
            v-if="currentState === 1"
          />
          <Product
            v-on:changeState="changeState($event)"
            v-if="currentState === 2"
          />
          <Game
            v-on:changeState="changeState($event)"
            v-if="currentState === 3"
          />
          <Test
            v-on:changeState="changeState($event)"
            v-if="currentState === 4"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navigation from "@/components/training-module1/theme-1/Navigation";
import Theory from "@/components/training-module1/theme-1/Theory";
import Product from "@/components/training-module1/theme-1/Product";
import Game from "@/components/training-module1/theme-1/Game";
import Test from "@/components/training-module1/theme-1/Test";

export default {
  name: "Module1Theme1",
  data() {
    return {
      state: 1,
      currentState: 1,
      isVisibleLeftArrow: false,
      isVisibleRightArrow: true
    };
  },
  components: {
    Navigation,
    Theory,
    Product,
    Game,
    Test
  },
  methods: {
    changeState(value) {
      this.currentState = value.state;
      localStorage.setItem("module1CurrentState", value.state);
      if (value.direction === "next" && this.state <= this.currentState) {
        this.state = value.state;
        localStorage.setItem("module1State", value.state);
      }
      this.scrollToActiveMenu();
    },
    scrollToActiveMenu() {
      var menu = document.querySelector(".training-nav__inner");
      var menuItems = document.querySelectorAll(".training-nav__item");
      menuItems.forEach((item, key) => {
        if (this.currentState === key + 1) {
          menu.scrollLeft = menuItems[key].offsetLeft;
          if (menu.scrollLeft === 0) {
            this.isVisibleLeftArrow = false;
            this.isVisibleRightArrow = true;
          } else {
            this.isVisibleLeftArrow = true;
            this.isVisibleRightArrow = false;
          }
        }
      });
    }
  },
  mounted() {
    if (localStorage.getItem("module1CurrentState") !== null) {
      this.currentState = Number(localStorage.getItem("module1CurrentState"));
    }
    if (localStorage.getItem("module1State") !== null) {
      this.state = Number(localStorage.getItem("module1State"));
    }
    this.scrollToActiveMenu();
  }
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

// Begin main
.training-module-1 {
  margin-bottom: 50px;
  .main-page {
    .content {
      margin-top: 0;
    }
  }
}
</style>
