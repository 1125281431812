var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"webinar_room"},[_c('div',{staticClass:"main-video-wrapper"},[_c('iframe',{ref:"video",attrs:{"width":"560","height":"315","src":("" + (_vm.content.blocks[_vm.currentVideo].link)),"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),(_vm.previewShow)?_c('img',{staticClass:"preview",attrs:{"src":("" + (_vm.$root.globalUrl) + (_vm.content.blocks[_vm.currentVideo].preview)),"alt":""},on:{"click":function($event){return _vm.triggerVideo()}}}):_vm._e(),_vm._l((_vm.content.blocks),function(key,value){return (_vm.currentVideo === value)?_c('countdown',{key:value.id,attrs:{"end-time":_vm.content.blocks[value].publishTime},scopedSlots:_vm._u([{key:"process",fn:function(ref){
var timeObj = ref.timeObj;
return _c('div',{staticClass:"timer-wrapper"},[_c('div',{staticClass:"time-title"},[_vm._v("Трансляція розпочнеться через")]),_c('span',{staticClass:"time-wrapper",domProps:{"innerHTML":_vm._s(
            ("<span class='day'>" + (timeObj.d) + " <span>" + (_vm.declOfNum(timeObj.d, [
              'День',
              'Дні',
              'Днів' ])) + "</span> </span> <span class='hour'>" + (timeObj.h) + "<span>" + (_vm.declOfNum(timeObj.h, [
              'Година',
              'Години',
              'Годин' ])) + "</span></span><span class='separator'>:</span><span class='minutes'>" + (timeObj.m) + " <span>" + (_vm.declOfNum(timeObj.m, [
              'Хвилина',
              'Хвилини',
              'Хвилин' ])) + "</span></span><span class='separator'>:</span><span class='minutes'>" + (timeObj.s) + " <span>" + (_vm.declOfNum(timeObj.s, [
              'Секунда',
              'Секунди',
              'Секунд' ])) + "</span></span>")
          )}}),_c('div',{staticClass:"time-thanks"},[_vm._v("Приємного перегляду")])])}}],null,true)},[_c('div',{staticClass:"finish",attrs:{"slot":"finish"},slot:"finish"})]):_vm._e()})],2),_c('div',{staticClass:"under-video"},[_c('div',{staticClass:"video-counter"},[_c('span',[_vm._v("Відео "+_vm._s(_vm.currentVideo + 1))]),_vm._v(" / "+_vm._s(_vm.content.blocks.length)+" ")]),(_vm.showInfo)?_c('div',{staticClass:"double-block"},[_c('div',{staticClass:"info"},[(_vm.content.blocks[_vm.currentVideo].videoName)?_c('div',{staticClass:"content-title",domProps:{"innerHTML":_vm._s(_vm.content.blocks[_vm.currentVideo].videoName || '-')}}):_vm._e(),(_vm.content.blocks[_vm.currentVideo].programTitle)?_c('div',{staticClass:"double-title",domProps:{"innerHTML":_vm._s(_vm.content.blocks[_vm.currentVideo].programTitle || '-')}}):_vm._e(),(_vm.content.blocks[_vm.currentVideo].programText)?_c('div',{staticClass:"text-wrapper",domProps:{"innerHTML":_vm._s(_vm.content.blocks[_vm.currentVideo].programText || '-')}}):_vm._e()]),(_vm.content.blocks.length > 1)?_c('div',{staticClass:"more-video video-list-wrapper"},_vm._l((_vm.content.blocks),function(key,value){return _c('div',{key:value,staticClass:"item",class:[{ active: _vm.currentVideo === value }],on:{"click":function($event){return _vm.switchVideo(value)}}},[_c('div',{staticClass:"video-img"},[_c('div',{staticClass:"click-item"}),(key.preview !== undefined)?_c('img',{staticClass:"preview",attrs:{"src":("" + (_vm.$root.globalUrl) + (key.preview)),"alt":""}}):_c('iframe',{attrs:{"width":"560","height":"315","src":("" + (key.link)),"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(key.videoName))])])}),0):_vm._e()]):_vm._e()]),_c('div',{staticClass:"promo",domProps:{"innerHTML":_vm._s(_vm.content.blocks[_vm.currentVideo].promonumber)}})])}
var staticRenderFns = []

export { render, staticRenderFns }