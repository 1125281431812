<template>
  <div class="training-product">
    <h3 class="training-product__title">
      Нейрорубін<sup>™</sup> — комплекс високих доз вітамінів
      B<sub>1</sub>, В<sub>6</sub> і <nobr>1000 мкг В<sub>12</sub>,</nobr> для лікування невритів та невралгій та скорочення прийому НПЗП.<sup>3,4</sup>
    </h3>
    <ul class="training-product__list">
      <li class="training-product__item">
        <img class="training-product__img" src="~@/assets/img/training-module1/theme-3/tabl.jpg" alt="" />
        <div class="training-product__detail">
          <div class="training-product__detail-recom">
            <span>Курс лікування:​<br></span>
            По 1–2 таблетки на добу протягом 
            <nobr>4 тижнів</nobr>​. Використовувати до або 
            під час прийому їжі.
          </div>
          <div class="training-product__detail-purpose">Таблетки можуть бути альтернативою ін’єкційній формі.</div>
        </div>
      </li>
      <li class="training-product__item">
        <img class="training-product__img" src="~@/assets/img/training-module1/theme-3/injec.jpg" alt="" />
        <div class="training-product__detail">
          <div class="training-product__detail-recom">
            <span>У тяжких (гострих) випадках:</span><br>1 ампула на добу до зменшення інтенсивності гострих симптомів​.
          </div>
          <div class="training-product__detail-recom">
            <span>​Після послаблення симптомів:</span><br>​1 ампула 1–3 рази на тиждень.
          </div>
          <div class="training-product__detail-purpose small">Для підтримання або подовження початкового терапевтичного курсу ін’єкцій та для профілактики рецидиву рекомендується застосовувати 
            препарат <nobr>Нейрорубін<sup>™</sup>- Форте Лактаб,</nobr> таблетки, вкриті плівковою оболонкою.<sup>4</sup></div>
        </div>
      </li>
      <li class="training-product__item training-product__item--last">
        <div class="training-product__detail-purpose">Високі дози вітамінів групи В присутні як в ін’єкціях, так і у таблетках<sup>3,4</sup></div>
        <img class="training-product__img" src="~@/assets/img/training-module1/theme-3/Table.jpg" alt="" />
      </li>
    </ul>
    <swiper :options="swiperOption" style="height: auto" class="swiper">
      <swiper-slide>
        <div class="swiper__item">
          <img class="swiper__img" src="~@/assets/img/training-module1/theme-3/tabl.jpg" alt="" />
          <div class="swiper__detail">
            <div class="swiper__detail-recom">
              <span>Курс лікування:​<br></span>
              По 1–2 таблетки на добу протягом 
              <nobr>4 тижнів</nobr>​. Використовувати до або 
              під час прийому їжі.
            </div>
            <div class="swiper__detail-purpose">Таблетки можуть бути альтернативою ін’єкційній формі.</div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="swiper__item">
          <img class="swiper__img" src="~@/assets/img/training-module1/theme-3/injec.jpg" alt="" />
          <div class="swiper__detail">
            <div class="swiper__detail-recom">
              <span>У тяжких (гострих) випадках:</span><br>1 ампула на добу до зменшення інтенсивності гострих симптомів​.
            </div>
            <div class="swiper__detail-recom">
              <span>​Після послаблення симптомів:</span><br>​1 ампула 1–3 рази на тиждень.
            </div>
            <div class="swiper__detail-purpose small">Для підтримання або подовження початкового терапевтичного курсу ін’єкцій та для профілактики рецидиву рекомендується застосовувати 
              препарат <nobr>Нейрорубін<sup>™</sup>- Форте Лактаб,</nobr> таблетки, вкриті плівковою оболонкою.<sup>5</sup></div>
            </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="swiper__item--last swiper__item">
          <div class="swiper__detail-purpose">Високі дози вітамінів групи В присутні як в ін’єкціях, так і у таблетках<sup>3,4</sup></div>
          <img class="swiper__img" src="~@/assets/img/training-module1/theme-3/Table-mob.jpg" alt="" />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="training-product__next" @click="nextState(5, 'next')">Далі</div>
  </div>
</template>

<script>
import sectionSliderWrapper from '../../section-slider-wrapper.vue';
export default {
  components: { sectionSliderWrapper },
  name: "Product",
  data: () => ({
    swiperOption: {
      autoHeight: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: "1.1",
    },
  }),
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_2_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

$fontNB: "NotoSans-Bold";
$fontNSB: "NotoSans-SemiBold";
$fontNR: "NotoSans-Regular";
$fontNL: "NotoSans-Light";

// .swiper-pagination-bullet {
//   width: 24px !important;
//   height: 4px !important;
//   margin: 0 12px !important;
//   display: inline-block !important;
//   border-radius: 10px !important;
//   opacity: 1 !important;
//   background: #E4EAED !important;
// }
// .swiper-pagination-bullet-active {
//   color:#fff;
//   background: linear-gradient(71.92deg, #00A03B 0%, #B4D16C 100%) !important; 
// }
// .swiper-pagination.swiper-pagination-custom span {
//   display: inline-block;
//   width: 24px;
//   height: 4px;
//   border-radius: 10px;
//   background: #E4EAED;
//   margin: 0 12px;
// }
// .swiper-container-horizontal > .swiper-pagination-bullets {
//   bottom: 10px !important;
// }

.training-product {
  .swiper {
    display: none;
    padding: 32px 20px;
    @media (max-width: 768px) {
      display: block;
    }
    &-wrapper {
      padding-left: 21px;
      @media (max-width: 768px) {
        padding-left: 0;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      width: 94% !important;
      padding: 8px;
      height: 494px;
      box-shadow: 0px 4px 20px rgba(46, 113, 138, 0.16);
      border-radius: 2px;
      background: #ffffff;
      &--last {
        flex-direction: column;
        align-items: center;
        padding-top: 32px;

        & .swiper__img {
          object-fit: contain;
          max-height: 390px;
        }

        & .swiper__detail-purpose {
          font-size: 16px;
          line-height: 22px;
          max-width: 74%;
          text-align: center;
          min-width: 240px;
          margin-bottom: 26px;
          @media (max-width: 540px) {
            max-width: 240px;
            min-width: unset;
          }
        }
      }
    }
    &__img {
      max-width: 100%;
    }
    &__detail {
      margin-top: 32px;
      &-recom {
        color: #545454;
        font-size: 14px;
        line-height: 120%;
        font-family: $fontNR;
        margin-bottom: 8px;
        span {
          font-family: $fontNSB;
        }
        nobr {
          font-family: $fontNR;
        }
      }
      &-purpose {
        color: #00A03B;
        font-family: $fontNSB;
        &.small {
          font-size: 12px;
          nobr {
            font-family: $fontNSB;
          }
        }
      }
    }
  }
  &__title {
    margin-bottom: 32px;
    font-family: $fontNSB; 
    font-size: 32px;
    line-height: 44px;
    color: #00a03b;
    sub,
    sup {
      font-family: $fontNSB;
    }
  }
  &__list {
    list-style-type: none;
    
  }
  &__item {
    box-shadow: 0px 4px 20px rgba(46, 113, 138, 0.16);
    border-radius: 2px;
    background: #FFFFFF;
    padding: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &--last {
      flex-direction: column;
      align-items: center;
      padding-top: 32px;
      min-height: 380px;
      .training-product__img {
        max-width: 722px;
        margin-top: 22px;
      }
    }
  }
  &__img {
    max-width: 434px;
    margin-right: 20px;
  }
  &__detail {
    max-width: 286px;
    &-recom {
      color: #545454;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
      font-family: $fontNR;
      span {
        font-family: $fontNSB;
      }
    }
    &-purpose {
      color: #00a03b;
      font-size: 16px;
      font-family: $fontNSB;
      line-height: 22px;
      &.small {
        margin-top: 12px;
        font-size: 14px;
        line-height: 120%;
        nobr {
          font-family: $fontNSB;
        }
      }
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontNR;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .training-product {
    &__title {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 24px;
    }
    &__image {
      margin-bottom: 32px;
    }
    &__list {
      display: none;
      margin-bottom: 20px;
      &-title {
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__col {
      flex-basis: 100%;
      max-width: 100%;
    }
    &__text {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 17px;
    }
    &__subtitle {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }
    &__next {
      width: 100%;
      max-width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
  }
}
</style>
