<template>
  <label class="wrapper" :class="{ disabled }">
    <input
      class="checkbox"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
      :disabled="disabled"
    />
    <span class="checkmark"></span>
    <div class="label">{{ label }}</div>
  </label>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },

  props: {
    value: { type: [String, Number] },
    modelValue: { default: "" },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    trueValue: { default: true },
    falseValue: { default: false },
  },

  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },
  },

  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../css/font-noto.css";
@import "../css/variables.scss";

.wrapper {
  display: flex;
  align-content: center;

  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 6px;
  cursor: pointer;
  // user-select: none;
}

.wrapper.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.label {
  height: 1.8rem;

  font-family: $fontSecondary;
  font-size: 14px;
  font-weight: 400;
  color: #317575;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0.25em;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid #317575;
}

@media (min-width: $lg) {
  .wrapper:hover input ~ .checkmark::after {
    display: block;
    border-color: #31757520;
  }
  // .wrapper input:focus ~ .checkmark::after {
  //   display: block;
  //   border-color: #ddd;
  // } 
  .wrapper:hover input:checked ~ .checkmark::after {
    display: block;
    border-color: #ffffff50;
  }

  .wrapper.disabled:hover input ~ .checkmark::after {
    display: none;
  }
  .wrapper.disabled:hover input:checked ~ .checkmark::after {
    display: block;
    border-color: #ffffff;
  }
}

.wrapper input:checked ~ .checkmark {
  background-color: #317575;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.wrapper input:checked ~ .checkmark::after {
  display: block;
}
.wrapper input:checked ~ .label {
  font-weight: 600;
}

.wrapper .checkmark::after {
  left: calc(50% - 3px);
  top: calc(50% - 5px);
  width: 4px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
