import axios from "axios";

const server = 'stage';
const serverList = {
  stage: "https://rxstage.inchkiev.ua",
  prod: "https://rx.teva.ua",
};

const { hostname, port, origin } = location;

export const isDev = hostname === "localhost" || port === "8080";
export const hostUrl = isDev ? serverList[server] : origin;
export const baseURL = `${hostUrl}/api/v1/`;
export const $axios = axios.create({ baseURL });

export default $axios;
