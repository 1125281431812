<template>
  <div class="section-double-col-text-icon">
    <div class="row row-top">
      <h3 v-html="content.title"></h3>
      <div class="text-wrapper" v-html="content.description"></div>
    </div>

    <div class="row row-bottom">
      <div
        v-for="key in content.blocks"
        class="item-icon"
        v-if="key.text !== '' && key.file !== undefined"
      >
        <div>
          <img
            :src="`${$root.globalUrl}${key.file}`"
            alt=""
            width="45"
            height="45"
          />
        </div>
        <div class="text-wrapper" v-html="key.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-double-col-text-icon",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-double-col-text-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  @media screen and (max-width: 670px) {
    margin: 10px 0;
  }

  .row {
    &:nth-child(1) {
      margin-right: 12px;
      position: relative;
      padding-top: 16px;
      margin-bottom: 10px;
      @media screen and (max-width: 670px) {
        margin-top: 0;
        margin-right: 0;
        padding-top: 15px;
        margin-bottom: 15px;
      }

      &:before {
        content: "";
        width: 41px;
        height: 3px;
        position: absolute;
        left: 0;
        top: 0;
        background: #00a03b;
      }
    }

    @media screen and (max-width: 670px) {
      margin: 10px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.row-top {
      h3 {
        color: #545454;
        font-family: $fontDemi;
        font-size: 16px;
        margin-bottom: 10px;
        @media screen and (max-width: 670px) {
          font-size: 14px;
        }
      }

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.row-bottom {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: flex-start;
      @media screen and (max-width: 1024px) {
        justify-content: center;
      }

      .item-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 150px;
        width: 100%;
        @media screen and (max-width: 1024px) {
          margin: 15px;
        }
        @media screen and (max-width: 425px) {
          max-width: 125px;
          margin: 10px;
        }

        img {
          display: block;
          margin-bottom: 20px;
          height: 43px;
          width: auto;
        }

        p {
          color: #3c7e79;
          font-family: $fontM;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
