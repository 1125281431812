<template>
  <div class="module-theory">
    <div class="module-theory__text">
      Всі ми знаємо, що НПЗП збільшують розвиток шлунково-кишкових кровотеч
      <br />
      (ШКК) та мають низку інших побічних явищ.
    </div>
    <div class="module-theory__text text-green">
      Проте чи всі вони однаково небезпечні?
    </div>
    <div class="module-theory__text">
      Мета-аналіз Castellsague J. 28-ми досліджень підтвердив, що у різних НПЗП
      — <br />
      різний ризик розвитку ШКК.<sup>1</sup>
    </div>

    <div class="module-theory__list">
      <div class="module-theory__item">
        <div class="module-theory__row">
          <div class="module-theory__col-left">
            <div class="module-theory__image">
              <img
                class="module-theory__image-desktop"
                src="@/assets/img/training-module2/theme-3/step-1/1.svg"
                alt=""
              />
              <img
                class="module-theory__image-mobile"
                src="@/assets/img/training-module2/theme-3/step-1/1-mobile.svg"
                alt=""
              />
            </div>
          </div>
          <div class="module-theory__col-right">
            <div class="module-theory__detail">
              <div class="module-theory__detail-title">
                Ацеклофенак має найнижчий ризик виникнення шлунково-кишкових
                кровотеч порівняно з іншими НПЗП<sup>1,2</sup>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module-theory__item">
        <div class="module-theory__row">
          <div class="module-theory__col-left">
            <div class="module-theory__image">
              <img
                class="module-theory__image-desktop"
                src="@/assets/img/training-module2/theme-3/step-1/2.svg"
                alt=""
              />
              <img
                class="module-theory__image-mobile"
                src="@/assets/img/training-module2/theme-3/step-1/2-mobile.svg"
                alt=""
              />
            </div>
          </div>
          <div class="module-theory__col-right">
            <div class="module-theory__detail">
              <div class="module-theory__detail-title">
                Частота виникнення серйозних побічних реакцій
              </div>
              <div class="module-theory__detail-text">
                П’ятирічний аналіз даних системи фармнагляду Франції
                Lapeyre-Mestre M. показав, що
                <b>ацеклофенак має найнижчу частоту</b> виникнення серйозних
                <b>побічних реакцій.<sup>3</sup></b>
              </div>
              <div class="module-theory__detail-text">
                Проаналізовано 38 506 повідомлень
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-theory__subtitle">
      Висока ефективність та переносимість ацеклофенаку обумовлює високий рівень
      задоволеності лікуванням та високу прихильність до терапії<sup>4</sup>
    </div>
    <div class="module-theory__next" @click="nextState(2, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory1",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.3 - Раздел "Безпека використання НПЗП" '
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__text {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    &.text-green {
      color: #00a03b;
    }
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 64px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
  &__item {
    margin-bottom: 52px;
    &:first-child {
      padding-top: 32px;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 56%;
      max-width: 56%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 44%;
      max-width: 44%;
      padding: 0 10px;
    }
  }
  &__image {
    img {
      max-width: 100%;
      width: 100%;
    }
    &-desktop {
      display: block;
    }
    &-mobile {
      display: none;
    }
  }
  &__detail {
    &-title {
      margin-bottom: 28px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      color: #00a03b;
      sup {
        font-family: $fontDemi;
      }
    }
    &-text {
      margin-bottom: 10px;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
      sup {
        font-family: $fontDemi;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__text {
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      &.text-green {
        font-family: $fontDemi;
      }
      sup {
        font-family: $fontR;
      }
      br {
        display: none;
      }
    }
    &__item {
      margin-bottom: 32px;
      &:nth-child(2) {
        .module-theory {
          &__row {
            flex-wrap: wrap-reverse;
          }
        }
      }
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__image {
      margin-bottom: 24px;
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
      }
    }
    &__detail {
      &-title {
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &-text {
        font-family: $fontR;
        font-size: 14px;
        line-height: 17px;
        b {
          font-family: $fontDemi;
        }
      }
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }

    &__next {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
