<template>
  <div class="training-product">
    <h3 class="training-product__title">
      Нейрорубін<sup>™</sup> — єдиний в Україні комплекс вітамінів
      B<sub>1</sub>, В<sub>6</sub> та В<sub>12</sub>, що містить 1000 мкг В<sub
        >12</sub
      >
      як в ампулах, так і в таблетках*<sup>4,5</sup>
    </h3>
    <div class="training-product__image">
      <img src="~@/assets/img/training-module1/product/1.jpg" alt="" />
    </div>
    <div class="training-product__list">
      <div class="training-product__list-title">Нейрорубін<sup>™5</sup></div>
      <div class="training-product__row">
        <div class="training-product__col">
          <div class="training-product__text">
            <p>
              <b>Кожна ампула містить: </b><br />
              • тіаміну гідрохлориду (вітамін В<sub>1</sub>) 100 мг <br />
              • піридоксину гідрохлориду (вітамін В<sub>6</sub>) 100 мг <br />
              • ціанокобаламіну (вітамін В<sub>12</sub>) 1 мг
            </p>
            <p>
              <b>У тяжких (гострих) випадках: </b> <br />
              1 ампула на добу до зменшення інтенсивності гострих симптомів
            </p>
          </div>
        </div>
        <div class="training-product__col">
          <div class="training-product__text">
            <p>
              <b>Після послаблення симптомів: </b> <br />
              1 ампула 1-3 рази на тиждень
            </p>
            <p>
              Для підтримання або подовження початкового терапевтичного курсу
              ін’єкцій та для профілактики рецидиву рекомендується застосовувати
              препарат Нейрорубін<sup>™</sup>-Форте Лактаб
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="training-product__list">
      <div class="training-product__list-title">
        Нейрорубін<sup>™</sup>-Форте Лактаб<sup>4</sup>
      </div>
      <div class="training-product__row">
        <div class="training-product__col">
          <div class="training-product__text">
            <p>
              <b>Кожна таблетка містить:</b><br />
              • тіаміну нітрату (вітамін В<sub>1</sub>) 200 мг <br />
              • піридоксину гідрохлориду (вітамін В<sub>6</sub>) 50 мг <br />
              • ціанокобаламіну (вітамін В<sub>12</sub>) 1 мг
            </p>
          </div>
        </div>
        <div class="training-product__col">
          <div class="training-product__text">
            <p>
              <b>Курс лікування:</b> <br />
              По 1-2 таблетки на добу протягом 4 тижнів
            </p>
            <p>
              <b>Таблетки можуть бути альтернативою ін’єкційній формі</b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="training-product__subtitle">
      З Нейрорубін<sup>™</sup> Ви можете обрати форму випуску в залежності від
      побажань пацієнта!<sup>4,5</sup>
    </div>
    <div class="training-product__subtitle">
      Нейрорубін<sup>™</sup> та Нейрорубін<sup>™</sup>-Форте Лактаб<sup>4</sup>
      — це комплекс з високими дозами вітамінів групи В<sup>4,5</sup>
    </div>
    <div class="training-product__next" @click="nextState(3, 'next')">Далі</div>
  </div>
</template>

<script>
export default {
  name: "Product",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_2_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.training-product {
  &__title {
    margin-bottom: 48px;
    font-family: $fontDemi;
    font-size: 40px;
    line-height: 49px;
    color: #00a03b;
    sub,
    sup {
      font-family: $fontDemi;
    }
  }
  &__image {
    margin-bottom: 64px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__list {
    margin-bottom: 48px;
    &-title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
      sub,
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 10px;
  }
  &__text {
    font-family: $fontM;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    p {
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    b {
      color: #00a03b;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 30px;
    line-height: 37px;
    color: #00a03b;
    sub,
    sup {
      font-family: $fontDemi;
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .training-product {
    &__title {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 24px;
    }
    &__image {
      margin-bottom: 32px;
    }
    &__list {
      margin-bottom: 20px;
      &-title {
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__col {
      flex-basis: 100%;
      max-width: 100%;
    }
    &__text {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 17px;
    }
    &__subtitle {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }
    &__next {
      width: 100%;
      max-width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
  }
}
</style>
