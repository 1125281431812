<template>
  <section class="article-page training-module-2">
    <div class="main-page">
      <div class="content">
        <div class="article-wrapper">
          <Navigation
            :state="state"
            :currentState="currentState"
            :isVisibleLeftArrow="isVisibleLeftArrow"
            :isVisibleRightArrow="isVisibleRightArrow"
            v-on:changeState="changeState($event)"
            v-on:scrollMenuTo="scrollMenuTo($event)"
          />
          <Theory1
            v-if="currentState === 1"
            v-on:changeState="changeState($event)"
          />
          <Game
            v-if="currentState === 2"
            v-on:changeState="changeState($event)"
          />
          <Theory2
            v-if="currentState === 3"
            v-on:changeState="changeState($event)"
          />
          <Theory3
            v-if="currentState === 4"
            v-on:changeState="changeState($event)"
          />
          <Test
            v-if="currentState === 5"
            v-on:changeState="changeState($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navigation from "@/components/training-module2/theme-3/Navigation";
import Theory1 from "@/components/training-module2/theme-3/Theory1";
import Theory2 from "@/components/training-module2/theme-3/Theory2";
import Theory3 from "@/components/training-module2/theme-3/Theory3";
import Game from "@/components/training-module2/theme-3/Game";
import Test from "@/components/training-module2/theme-3/Test";

export default {
  name: "Module2Theme3",
  data() {
    return {
      state: 1,
      currentState: 1,
      isVisibleLeftArrow: false,
      isVisibleRightArrow: true
    };
  },
  components: {
    Navigation,
    Theory1,
    Theory2,
    Theory3,
    Game,
    Test
  },
  methods: {
    changeState(value) {
      this.currentState = value.state;
      var themeData = JSON.parse(localStorage.getItem("module2_theme3"));
      themeData.currentStage = value.state;
      localStorage.setItem("module2_theme3", JSON.stringify(themeData));

      if (value.direction === "next" && this.state <= this.currentState) {
        this.state = value.state;
        themeData.state = value.state;
        localStorage.setItem("module2_theme3", JSON.stringify(themeData));
      }

      this.scrollToActiveMenu();
    },
    scrollToActiveMenu() {
      var menu = document.querySelector(".training-nav__inner");
      var menuItems = document.querySelectorAll(".training-nav__item");
      menuItems.forEach((item, key) => {
        if (this.currentState === key + 1) {
          menu.scrollLeft = menuItems[key].offsetLeft;
          if (menu.scrollLeft === 0) {
            this.isVisibleLeftArrow = false;
            this.isVisibleRightArrow = true;
          } else {
            this.isVisibleLeftArrow = true;
            this.isVisibleRightArrow = false;
          }
        }
      });
    },
    scrollMenuTo(direction) {
      var menu = document.querySelector(".training-nav__inner");

      if (direction === "left") {
        menu.scrollLeft = 0;
        this.isVisibleLeftArrow = false;
        this.isVisibleRightArrow = true;
      }
      if (direction === "right") {
        menu.scrollLeft = menu.scrollWidth;
        this.isVisibleLeftArrow = true;
        this.isVisibleRightArrow = false;
      }
    }
  },
  mounted() {
    if (localStorage.getItem("module2_theme3") === null) {
      localStorage.setItem(
        "module2_theme3",
        JSON.stringify({
          state: null,
          currentStage: null,
          game: {
            onboardSelect: null,
            onboardPaste: null,
            onboardCheck: null
          },
          test: {
            questions: null,
            answers: null,
            currentQuestion: null,
            correctAnswers: null,
            isActiveCode: null,
            isBedResult: null,
            isGoodResult: null,
            isGoodResultWitoutCode: null
          }
        })
      );
    }

    var themeData = JSON.parse(localStorage.getItem("module2_theme3"));

    if (themeData.currentStage !== null) {
      this.currentState = Number(themeData.currentStage);
    }
    if (themeData.stage !== null) {
      this.state = Number(themeData.state);
    }

    this.scrollToActiveMenu();
  }
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

// Begin main
.training-module-2 {
  margin-bottom: 50px;
  .main-page {
    .content {
      margin-top: 0;
    }
  }
}
</style>
