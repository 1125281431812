<template>
  <div class="section-video">
    <div class="video-file" v-if="content.type === 'slider_with_video'">
      <div class="video-wrapper">
        <video
            controls
            :poster="
            `${$root.globalUrl}${content.blocks[currentVideo - 1].preview}`
          "
        >
          <source
              :src="`${$root.globalUrl}${content.blocks[currentVideo - 1].video}`"
              type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div
            v-bind:class="[{ active: isPause }]"
            v-if="content.blocks.length !== currentVideo"
            class="next-video"
        >
          <div class="img-wrapper">
            <img
                :src="`${$root.globalUrl}${content.blocks[currentVideo].preview}`"
                alt=""
            />
          </div>

          <div class="text-wrapper">
            <p v-html="content.blocks[currentVideo].text"></p>
          </div>

          <div class="next-btn" @click="nextVideo(currentVideo + 1)">
            <p>Наступне</p>
          </div>
        </div>

        <div
            v-bind:class="[{ active: isPause }]"
            @click="playVideo()"
            class="play-btn desc"
        >
          <svg
              width="138"
              height="138"
              viewBox="0 0 138 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="69" cy="69" r="69" fill="white" fill-opacity="0.4"/>
            <path
                d="M85 67.5358C87.6667 69.0754 87.6667 72.9244 85 74.464L61 88.3204C58.3333 89.86 55 87.9355 55 84.8563L55 57.1435C55 54.0643 58.3333 52.1398 61 53.6794L85 67.5358Z"
                fill="white"
            />
          </svg>
        </div>
        <div
            v-bind:class="[{ active: isPause }]"
            @click="toggleFullScreen()"
            class="play-btn mob"
        >
          <svg
              width="138"
              height="138"
              viewBox="0 0 138 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="69" cy="69" r="69" fill="white" fill-opacity="0.4"/>
            <path
                d="M85 67.5358C87.6667 69.0754 87.6667 72.9244 85 74.464L61 88.3204C58.3333 89.86 55 87.9355 55 84.8563L55 57.1435C55 54.0643 58.3333 52.1398 61 53.6794L85 67.5358Z"
                fill="white"
            />
          </svg>
        </div>
      </div>

      <div class="video-counter">
        <p>
          <span>Відео {{ currentVideo }}</span> / {{ content.blocks.length }}
        </p>
      </div>

      <div class="more-info-wrapper">
        <div class="description-video-wrapper">
          <h3 v-html="content.blocks[currentVideo - 1].text"></h3>

          <div class="time">
            <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#z1)">
                <path
                    d="M0.737459 2.73921C1.2293 1.8966 1.89645 1.22945 2.73908 0.737634C3.58187 0.245845 4.50188 0 5.49984 0C6.49769 0 7.41798 0.245845 8.26062 0.737634C9.1033 1.22937 9.77045 1.89652 10.2623 2.73921C10.7542 3.58194 11 4.50224 11 5.50001C11 6.49784 10.7541 7.41798 10.2624 8.26077C9.77055 9.10338 9.1034 9.77053 8.26072 10.2624C7.41798 10.7542 6.49776 11 5.49994 11C4.50211 11 3.58179 10.7542 2.73911 10.2624C1.89647 9.7707 1.22935 9.10338 0.737559 8.26077C0.245845 7.41806 2.47955e-05 6.49779 2.47955e-05 5.50001C0 4.50214 0.245845 3.58182 0.737459 2.73921ZM2.12687 7.45506C2.47538 8.05182 2.948 8.52462 3.54477 8.87313C4.14153 9.22165 4.79321 9.39573 5.49991 9.39573C6.20644 9.39573 6.85817 9.22165 7.45501 8.87313C8.05177 8.5246 8.5244 8.05182 8.87291 7.45506C9.2215 6.85829 9.39573 6.20659 9.39573 5.50001C9.39573 4.79338 9.22142 4.14168 8.87291 3.54492C8.52447 2.94815 8.05172 2.47543 7.45501 2.12694C6.85824 1.7784 6.20654 1.6042 5.49991 1.6042C4.79323 1.6042 4.14153 1.7784 3.54477 2.12694C2.948 2.47545 2.47535 2.94815 2.12687 3.54492C1.77833 4.14168 1.60417 4.79338 1.60417 5.50001C1.60417 6.20659 1.77833 6.85829 2.12687 7.45506Z"
                    fill="#B3D06B"
                />
                <path
                    d="M4.81218 2.75H5.27056C5.3375 2.75 5.39229 2.77155 5.43536 2.81446C5.47815 2.85743 5.4997 2.91229 5.4997 2.97914V5.50004H7.10387C7.17077 5.50004 7.2256 5.52149 7.26859 5.56436C7.31154 5.6073 7.33301 5.66221 7.33301 5.72918V6.18749C7.33301 6.25436 7.31154 6.30929 7.26859 6.35221C7.22557 6.39513 7.17064 6.41657 7.10387 6.41657H4.81205C4.74528 6.41657 4.69032 6.39513 4.64748 6.35221C4.60456 6.30929 4.58294 6.25436 4.58294 6.18749V2.97914C4.58294 2.91234 4.60456 2.85751 4.64748 2.81446C4.69037 2.77155 4.7453 2.75 4.81218 2.75Z"
                    fill="#B3D06B"
                />
              </g>
              <defs>
                <clipPath id="z1">
                  <rect
                      width="11"
                      height="11"
                      fill="white"
                      transform="matrix(-1 0 0 1 11 0)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p>
              {{
                parseInt(content.blocks[currentVideo - 1].duration)
                    .toFixed(2)
                    .replace(".", ":")
              }}
            </p>
          </div>

          <div
              class="text-wrapper"
              v-html="content.blocks[currentVideo - 1].description"
          ></div>
        </div>

        <div class="video-list-wrapper">
          <div class="video-list">
            <div
                @click="nextVideo(value + 1)"
                v-bind:class="[{ active: currentVideo === value + 1 }]"
                class="item"
                v-for="(key, value) in content.blocks"
                :key="value + 'list-1'"
            >
              <div class="img-wrapper">
                <div class="play">
                  <svg
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                        cx="20.5"
                        cy="20"
                        rx="20.5"
                        ry="20"
                        fill="white"
                        fill-opacity="0.4"
                    />
                    <path
                        d="M25.4069 19.6238C26.0977 20.0037 26.0977 20.9963 25.4069 21.3762L18.4819 25.1849C17.8155 25.5515 17 25.0693 17 24.3087L17 16.6913C17 15.9307 17.8155 15.4485 18.4819 15.8151L25.4069 19.6238Z"
                        fill="white"
                    />
                  </svg>
                </div>
                <img :src="`${$root.globalUrl}${key.preview}`" alt=""/>
              </div>
              <div class="description">
                <h3 v-html="key.text"></h3>
                <div class="time">
                  <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#z1)">
                      <path
                          d="M0.737459 2.73921C1.2293 1.8966 1.89645 1.22945 2.73908 0.737634C3.58187 0.245845 4.50188 0 5.49984 0C6.49769 0 7.41798 0.245845 8.26062 0.737634C9.1033 1.22937 9.77045 1.89652 10.2623 2.73921C10.7542 3.58194 11 4.50224 11 5.50001C11 6.49784 10.7541 7.41798 10.2624 8.26077C9.77055 9.10338 9.1034 9.77053 8.26072 10.2624C7.41798 10.7542 6.49776 11 5.49994 11C4.50211 11 3.58179 10.7542 2.73911 10.2624C1.89647 9.7707 1.22935 9.10338 0.737559 8.26077C0.245845 7.41806 2.47955e-05 6.49779 2.47955e-05 5.50001C0 4.50214 0.245845 3.58182 0.737459 2.73921ZM2.12687 7.45506C2.47538 8.05182 2.948 8.52462 3.54477 8.87313C4.14153 9.22165 4.79321 9.39573 5.49991 9.39573C6.20644 9.39573 6.85817 9.22165 7.45501 8.87313C8.05177 8.5246 8.5244 8.05182 8.87291 7.45506C9.2215 6.85829 9.39573 6.20659 9.39573 5.50001C9.39573 4.79338 9.22142 4.14168 8.87291 3.54492C8.52447 2.94815 8.05172 2.47543 7.45501 2.12694C6.85824 1.7784 6.20654 1.6042 5.49991 1.6042C4.79323 1.6042 4.14153 1.7784 3.54477 2.12694C2.948 2.47545 2.47535 2.94815 2.12687 3.54492C1.77833 4.14168 1.60417 4.79338 1.60417 5.50001C1.60417 6.20659 1.77833 6.85829 2.12687 7.45506Z"
                          fill="#B3D06B"
                      />
                      <path
                          d="M4.81218 2.75H5.27056C5.3375 2.75 5.39229 2.77155 5.43536 2.81446C5.47815 2.85743 5.4997 2.91229 5.4997 2.97914V5.50004H7.10387C7.17077 5.50004 7.2256 5.52149 7.26859 5.56436C7.31154 5.6073 7.33301 5.66221 7.33301 5.72918V6.18749C7.33301 6.25436 7.31154 6.30929 7.26859 6.35221C7.22557 6.39513 7.17064 6.41657 7.10387 6.41657H4.81205C4.74528 6.41657 4.69032 6.39513 4.64748 6.35221C4.60456 6.30929 4.58294 6.25436 4.58294 6.18749V2.97914C4.58294 2.91234 4.60456 2.85751 4.64748 2.81446C4.69037 2.77155 4.7453 2.75 4.81218 2.75Z"
                          fill="#B3D06B"
                      />
                    </g>
                    <defs>
                      <clipPath id="z1">
                        <rect
                            width="11"
                            height="11"
                            fill="white"
                            transform="matrix(-1 0 0 1 11 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    {{
                      parseInt(key.duration)
                          .toFixed(2)
                          .replace(".", ":")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="youtube-video"
        v-if="content.type === 'slider_with_youtube_video'"
    >
      <div class="video-wrapper">
        <iframe
            ref="video"
            width="560"
            height="315"
            :src="`${content.blocks[currentVideo - 1].link}`"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>

        <div class="preview-img" v-if="previewShow">
          <img
              :src="
              `${$root.globalUrl}${content.blocks[currentVideo - 1].preview}`
            "
              alt=""
          />
        </div>

        <div
            v-bind:class="[{ active: isPause }]"
            v-if="content.blocks.length !== currentVideo"
            class="next-video desk"
        >
          <div class="img-wrapper">
            <img
                :src="`${$root.globalUrl}${content.blocks[currentVideo].preview}`"
                alt=""
            />
          </div>

          <div class="text-wrapper">
            <p v-html="content.blocks[currentVideo].title"></p>
          </div>

          <div class="next-btn" @click="switchVideo(currentVideo + 1)">
            <p>Наступне</p>
          </div>
        </div>

        <div
            v-bind:class="[{ active: isPause }]"
            @click="triggerVideo()"
            class="play-btn"
        >
          <svg
              width="138"
              height="138"
              viewBox="0 0 138 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="69" cy="69" r="69" fill="white" fill-opacity="0.4"/>
            <path
                d="M85 67.5358C87.6667 69.0754 87.6667 72.9244 85 74.464L61 88.3204C58.3333 89.86 55 87.9355 55 84.8563L55 57.1435C55 54.0643 58.3333 52.1398 61 53.6794L85 67.5358Z"
                fill="white"
            />
          </svg>
        </div>
      </div>

      <div
          v-bind:class="[{ active: isPause }]"
          v-if="content.blocks.length !== currentVideo"
          class="next-video mob"
      >
        <div class="img-wrapper">
          <img
              :src="`${$root.globalUrl}${content.blocks[currentVideo].preview}`"
              alt=""
          />
        </div>

        <div class="text-wrapper">
          <p v-html="content.blocks[currentVideo].title"></p>
        </div>

        <div class="next-btn" @click="switchVideo(currentVideo + 1)">
          <p>Наступне</p>
        </div>
      </div>

      <div class="video-counter">
        <p>
          <span>Відео {{ currentVideo }}</span> / {{ content.blocks.length }}
        </p>
      </div>

      <div class="more-info-wrapper">
        <div class="description-video-wrapper">
          <h3 v-html="content.blocks[currentVideo - 1].title"></h3>

          <div
              class="text-wrapper"
              v-html="content.blocks[currentVideo - 1].description"
          ></div>
        </div>

        <div v-if="content.blocks.length > 1" class="video-list-wrapper">
          <div class="video-list">
            <div
                v-for="(key, value) in content.blocks"
                :key="value + 'list-2'"
                class="item"
                v-bind:class="[{ active: currentVideo === value + 1 }]"
                @click="switchVideo(value + 1)"
            >
              <div class="img-wrapper">
                <div class="play">
                  <svg
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                        cx="20.5"
                        cy="20"
                        rx="20.5"
                        ry="20"
                        fill="white"
                        fill-opacity="0.4"
                    />
                    <path
                        d="M25.4069 19.6238C26.0977 20.0037 26.0977 20.9963 25.4069 21.3762L18.4819 25.1849C17.8155 25.5515 17 25.0693 17 24.3087L17 16.6913C17 15.9307 17.8155 15.4485 18.4819 15.8151L25.4069 19.6238Z"
                        fill="white"
                    />
                  </svg>
                </div>
                <img :src="`${$root.globalUrl}${key.preview}`" alt=""/>
              </div>
              <div class="description">
                <h3 v-html="key.title"></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-video",
  props: {
    content: Object
  },
  data: () => ({
    currentVideo: 1,
    isPause: true,
    previewShow: true
  }),

  mounted() {
    if (this.content.type === "slider_with_video") {
      let myVideo = document.getElementsByTagName("video")[0];

      myVideo.addEventListener("playing", () => {
        this.isPause = false;
      });

      myVideo.addEventListener("pause", () => {
        this.isPause = true;
      });
    }
    
    let url_string = window.location.href;
    
    let url = new URL(url_string);
    let c = url.searchParams.get("video");

    let myVideo = document.getElementsByClassName("youtube-video")[0];
    let elemV = myVideo.getBoundingClientRect().top;
    let header = document.querySelector('.header-block').offsetHeight;
    const offsetPos = elemV - header;

    this.content.blocks.forEach(element => {
      const embedId = element.link.replace('https://www.youtube.com/embed/', '');
      if (c === embedId) {
        window.scrollBy({
          top: offsetPos,
          behavior: 'smooth'
        })
      }
    });
    
    let linkVideo = []

    if (c !== null && c !== '') {
      this.content.blocks.filter((el, index) => {
        if (el.link.includes(c)) {
          linkVideo = index
        }
      })

      this.switchVideo(linkVideo + 1)
    }
  },

  methods: {
    triggerVideo() {
      this.previewShow = !this.previewShow;
      this.$refs.video.src += "?&autoplay=1";
      this.isPause = false;
    },

    playVideo() {
      let myVideo = document.getElementsByTagName("video")[0];
      myVideo.play();
    },

    toggleFullScreen() {
      this.playVideo();
      const videoElement = document.getElementsByTagName("video")[0];
      if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
          screen.mozOrientation.lock('landscape');
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
          screen.mozOrientation.lock('landscape-primary');
        } else {
          videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          screen.orientation.lock('landscape-primary');
          screen.orientation.lock('landscape');
        }
      } else {
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else {
          document.webkitCancelFullScreen();
        }
      }
    },

    switchVideo(id) {

      this.isPause = true;
      this.previewShow = true;
      this.currentVideo = id;

      let queryParams = new URLSearchParams(window.location.search);

      queryParams.set('video', this.content.blocks[id - 1].link.replace('https://www.youtube.com/embed/', ''));

      history.replaceState(null, null, "?" + queryParams.toString());

    },

    nextVideo(id) {
      this.isPause = true;
      this.currentVideo = id;
      let myVideo = document.getElementsByTagName("video")[0];
      myVideo.load();

    }
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;
.section-video {
  margin: 10px 0;
  width: 100%;
  min-height: 50px;

  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }

  .youtube-video {
    .video-wrapper {
      padding-top: 56.25%;
      @media screen and (max-width: 425px) {
        margin: 0 0 10px 0;
      }
    }

    .next-video {
      &.desk {
        @media screen and (max-width: 670px) {
          display: none !important;
        }
      }

      &.mob {
        display: none;
        @media screen and (max-width: 670px) {
          display: flex;
        }
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .preview-img {
    width: 100%;
    height: auto;
    display: block;

    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .video-wrapper {
    margin-bottom: 15px;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;

    &.mob {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }

    &.desc {
      @media (max-width: 767px) {
        display: none;
      }
    }
    

    .play-btn {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;

      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: none;

      &.active {
        display: flex;
      }
      &.mob {
        display: none;
        @media (max-width: 767px) {
          display: block;
        }
      }
      &.desc {
        @media (max-width: 767px) {
          display: none;
        }
      }

      
    }

    @media screen and (max-width: 425px) {
      margin: 0 -15px 15px -15px;
    }

    video {
      display: block;
      width: 100%;
      height: auto;
    }

    .next-video {
      min-height: 74px;
      max-width: 356px;
      width: 100%;
      background: #00a03b;
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      display: none;
      opacity: 0;

      align-items: center;
      transition: all 0.2s ease;

      &.active {
        opacity: 1;
        display: flex;
      }

      @media screen and (max-width: 670px) {
        position: relative;
        margin: 15px 0 15px 0;
        max-width: 670px;
        width: auto;
        display: flex !important;
        opacity: 1 !important;
      }

      .img-wrapper {
        max-width: 140px;
        width: 100%;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .text-wrapper {
        margin-left: 15px;
        margin-right: 15px;

        p {
          color: white;
          font-size: 11px;
          font-family: $fontR;
        }
      }

      .next-btn {
        cursor: pointer;
        padding: 10px;
        border-radius: 50px 0 0 50px;
        background: white;
        display: flex;
        margin: 0 0 0 auto;

        p {
          color: #00a03b;
          font-family: $fontR;
          font-size: 9px;
        }
      }
    }
  }

  .next-video {
    min-height: 74px;
    max-width: 356px;
    width: 100%;
    background: #00a03b;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    display: none;
    opacity: 0;

    align-items: center;
    transition: all 0.2s ease;

    &.active {
      opacity: 1;
      display: flex;
    }

    @media screen and (max-width: 670px) {
      position: relative;
      margin: 15px 0 15px 0;
      max-width: 670px;
      width: auto;
      display: flex !important;
      opacity: 1 !important;
    }

    .img-wrapper {
      max-width: 140px;
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .text-wrapper {
      margin-left: 15px;
      margin-right: 15px;

      p {
        color: white;
        font-size: 11px;
        font-family: $fontR;
      }
    }

    .next-btn {
      cursor: pointer;
      padding: 10px;
      border-radius: 50px 0 0 50px;
      background: white;
      display: flex;
      margin: 0 0 0 auto;

      p {
        color: #00a03b;
        font-family: $fontR;
        font-size: 9px;
      }
    }
  }

  .video-counter {
    margin-bottom: 15px;

    p {
      color: #878787;
      font-family: $fontR;
      font-size: 13px;

      span {
        color: #00a03b;
        font-family: $fontDemi;
      }
    }
  }

  .more-info-wrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 670px) {
      flex-direction: column;
    }

    > div {
      max-width: 400px;

      @media screen and (max-width: 1024px) {
        max-width: 500px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 670px) {
        max-width: 670px;
      }

      &:first-child {
        margin-right: 15px;
        @media screen and (max-width: 670px) {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-left: 15px;
        @media screen and (max-width: 670px) {
          margin-left: 0;
          margin-bottom: 0;
        }
      }

      &.description-video-wrapper {
        > h3 {
          font-weight: normal;
          color: #00a03b;
          font-size: 20px;
          font-family: $fontM;
          margin-bottom: 15px;
          @media screen and (max-width: 670px) {
            font-size: 16px;
          }
        }

        .time {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          svg {
            margin-right: 5px;
          }

          p {
            color: #545454;
            font-family: $fontR;
            font-size: 9px;
          }
        }

        .text-wrapper {
          > p {
            margin-bottom: 15px;
            color: #545454;
            font-family: $fontR;
            font-size: 16px;

            @media screen and (max-width: 670px) {
              font-size: 14px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &.video-list-wrapper::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 50px;
      }

      &.video-list-wrapper::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
      }

      &.video-list-wrapper::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #00a03b;
      }

      &.video-list-wrapper {
        max-height: 400px;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;

        .video-list {
          .item {
            cursor: pointer;
            width: 100%;
            display: flex;
            flex-direction: revert;
            margin-bottom: 10px;

            transition: all 0.2s ease;

            &.active,
            &:hover {
              background: #ededed;
            }

            .time {
              display: flex;
              align-items: center;

              svg {
                margin-right: 5px;
              }

              p {
                color: #545454;
                font-family: $fontR;
                font-size: 9px;
              }
            }

            .img-wrapper {
              position: relative;
              max-width: 131px;
              width: 100%;
              margin-right: 15px;

              .play {
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              img {
                width: 100%;
                height: auto;
                display: block;
              }
            }

            .description {
              padding: 5px 0;
              display: flex;
              flex-direction: column;
              justify-content: center;

              > h3 {
                margin-bottom: 15px;
                font-weight: normal;
                font-size: 11px;
                font-family: $fontR;
                color: #545454;
              }
            }
          }
        }
      }
    }
  }
}
</style>
