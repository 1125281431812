import { render, staticRenderFns } from "./Theory3.vue?vue&type=template&id=702e8e99&scoped=true&"
import script from "./Theory3.vue?vue&type=script&lang=js&"
export * from "./Theory3.vue?vue&type=script&lang=js&"
import style0 from "./Theory3.vue?vue&type=style&index=0&id=702e8e99&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702e8e99",
  null
  
)

export default component.exports