<template>
  <section class="article-page training-module-5">
    <div class="module-topics">
      <div class="module-topics__title">
        Все, що ви могли не знати про азитроміцин
      </div>
      <div class="module-topics__subtitle">
        Частини модулю:
      </div>
      <div class="module-topics__row">
        <div class="module-topics__col">
          <div class="module-topics__item">
            <div class="module-topics__image">
              <a href="/article/pharmacist/p-osvitnii-modul-5-tema-1-p">
                <img src="~@/assets/img/training-module5/topics/1.jpg" alt="" />
              </a>
            </div>
            <div class="module-topics__detail green-bg">
              <div class="module-topics__name">Тема 1</div>
              <div class="module-topics__text">
                Сумамед<sup>®</sup>: форми випуску і дозування
              </div>
              <a
                href="/article/pharmacist/p-osvitnii-modul-5-tema-1-p"
                class="module-topics__link"
              >
                Перейти
              </a>
            </div>
          </div>
        </div>
        <div class="module-topics__col">
          <div class="module-topics__item">
            <div class="module-topics__image">
              <a href="/article/pharmacist/p-osvitnii-modul-5-tema-2-p">
                <img src="~@/assets/img/training-module5/topics/2.jpg" alt="" />
              </a>
            </div>
            <div class="module-topics__detail blue-bg">
              <div class="module-topics__name">Тема 2</div>
              <div class="module-topics__text">
                Сумамед<sup>®</sup>: курс прийому та особливості пакування
              </div>
              <a
                href="/article/pharmacist/p-osvitnii-modul-5-tema-2-p"
                class="module-topics__link"
              >
                Перейти
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TrainingModule5"
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-topics {
  margin-bottom: 32px;
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    ::v-deep {
      sup {
        font-family: $fontDemi;
      }
    }
  }
  &__subtitle {
    margin-bottom: 48px;
    font-family: $fontDemi;
    font-size: 22px;
    line-height: 24px;
    color: #00a03b;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
    padding: 0 10px;
  }
  &__item {
    height: 100%;
  }
  &__image {
    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
  &__detail {
    position: relative;
    padding: 16px 24px 60px 24px;
    height: 50%;
    color: #fff;
    &.green-bg {
      background: #00a03b;
    }
    &.blue-bg {
      background: #005984;
    }
    &.teal-bg {
      background: #317575;
    }
  }
  &__name {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 17px;
  }
  &__text {
    font-family: $fontDemi;
    font-size: 14px;
    line-height: 17px;
  }
  &__link {
    position: absolute;
    display: block;
    width: 90px;
    height: 30px;
    right: 0;
    bottom: 15px;
    border-radius: 62px 0 0 62px;
    background: #ffffff;
    font-size: 10px;
    text-decoration: none;
    text-align: center;
    line-height: 30px;
    color: #00a03b;
  }
}
@media screen and (max-width: 767px) {
  .module-topics {
    padding-top: 24px;
    &__title {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
    &__subtitle {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
    }
    &__col {
      flex-basis: 100%;
      max-width: 100%;
    }
    &__item {
      height: auto;
      margin-bottom: 16px;
    }
    &__detail {
      height: auto;
      padding: 16px 95px 25px 24px;
    }
  }
}
</style>
