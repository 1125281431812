<template>
  <!-- Begin tab -->
  <div class="tab">
    <div class="tab__nav">
      <div
        class="tab__nav-col"
        :class="{
          'tab__nav-col-4': getTabs.length % 3 === 0,
          'tab__nav-col-6': getTabs.length % 2 === 0
        }"
        v-for="(tab, key) in getTabs"
        :key="key"
      >
        <div
          class="tab__nav-item"
          :class="{ active: tab.isActive }"
          @click="toggleTab(key)"
          v-html="tab.button_text"
        ></div>
      </div>
    </div>
    <div class="tab__content">
      <div
        class="tab__content-item"
        v-for="(tab, key) in getTabs"
        :class="{ active: tab.isActive }"
        :key="key"
      >
        <div class="tab__content-image">
          <img :src="$root.globalUrl + tab.image.path" alt />
        </div>
        <div
          class="tab__content-text"
          v-if="tab.description"
          v-html="tab.description"
        ></div>
      </div>
    </div>
  </div>
  <!-- End tab -->
</template>

<script>
export default {
  name: "Tab",
  props: {
    content: Object
  },
  computed: {
    getTabs() {
      return this.content.blocks.map((tab, index) => {
        if (index === 0) {
          this.$set(tab, "isActive", true);
        } else {
          this.$set(tab, "isActive", false);
        }
        return tab;
      });
    }
  },
  methods: {
    toggleTab(key) {
      this.content.blocks.map(tab => {
        tab.isActive = false;
        this.content.blocks[key].isActive = true;
      });
    }
  }
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

/* Begin tab */
.tab {
  padding: 15px 0;
  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 17px;
    margin: 0 -6px 34px -6px;
    &-col {
      margin-bottom: 17px;
      padding: 0 6px;

      &-4 {
        flex-basis: 33.3333333%;
        max-width: 33.3333333%;
      }
      &-6 {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
    &-item {
      height: 100%;
      padding: 15px 6px;
      background: #c4c4c4;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &.active {
        background: #aa198b;
      }
      &::v-deep {
        h4 {
          margin-bottom: 5px;
          font-family: $fontB;
          font-size: 21px;
          line-height: 26px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.02em;
          strong {
            font-family: $fontM;
          }
        }
      }
    }
  }
  &__content {
    &-item {
      display: none;
      &.active {
        display: block;
      }
    }
    &-image {
      margin-bottom: 34px;
      img {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }
    &-text {
      padding-bottom: 34px;
      &::v-deep {
        p {
          margin-bottom: 10px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #545454;
          &:last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .tab {
    &__nav {
      &-col {
        &-4 {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .tab {
    &__nav {
      &-col {
        &-4,
        &-6 {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
/* // */
</style>
