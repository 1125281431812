<template>
  <div class="module-theory">
    <div class="module-theory__state" v-show="state === 1">
      <div class="module-theory__title">
        Диклофенак при болю в спині
      </div>
      <div class="info-block">
        <div class="info-block__row">
          <div class="info-block__col-left">
            <div class="info-block__text">
              <p>
                Розпізнати клієнта з вертебральним больовим синдромом не так і
                важко<sup>1,2</sup>:
              </p>
              <ul>
                <li>Напівзігнуте положення</li>
                <li>Рука на попереку</li>
                <li>Гримаса болю</li>
              </ul>
              <div class="info-block__image-mobile">
                <img
                    src="@/assets/img/training-module3/theme-3/step-1/1-mob.png"
                    alt=""
                />
              </div>
              <p class="text-bordered">
                <b>
                  Диклофенак при болю в спині: переваги використання
                </b>
              </p>
              <p>
                Диклофенак - це більше 40 років клінічного застосування<sup>3</sup>
              </p>
            </div>
          </div>
          <div class="info-block__col-right">
            <div class="info-block__image-desktop">
              <img
                  src="@/assets/img/training-module3/theme-3/step-1/1.png"
                  alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="efficiency">
        <div class="efficiency__title">
          Ефективність
        </div>
        <div class="efficiency__info">
          Метааналіз van Walsem A. et al., 2015 р. <br/>
          146 524 пацієнтів, 146 досліджень<sup>4</sup>
        </div>
        <div class="efficiency__text">
          Диклофенак (150 мг/добу) був
          <b>ефективнішим для полегшення болю</b>, ніж целекоксиб (200
          мг/добу), напроксен (1000 мг/добу) та ібупрофен (2400 мг/добу).<sup
        >4</sup
        >
        </div>
      </div>
      <div class="security">
        <div class="security__title">Безпека</div>
        <div class="security__text">
          <p>
            Диклофенак має помірну селективність до ЦОГ2, що зменшує ризики
            деяких побічних реакцій.<sup>5</sup>
          </p>
        </div>
        <div class="security__image">
          <img
              src="@/assets/img/training-module3/theme-3/step-1/2.jpg"
              alt=""
          />
        </div>
        <div class="security__text">
          <p>
            Диклофенак має хороший профіль безпеки та
            ефективності<sup>4,5</sup>.
            <br/>
            Проте <b>як щодо швидкості дії?</b>
          </p>
          <p>
            Для клієнта швидкість знеболюючого ефекту може мати вирішальне
            значення.
          </p>
        </div>
      </div>
      <div class="module-theory__next next-button" @click="changeState()">
        Далі
      </div>
    </div>

    <div class="module-theory__state" v-show="state === 2">
      <div class="module-theory__title">
        На вашу думку, який час може бути оптимальним для знеболення після застосування ін'єкційного НПЗЗ пацієнтом із
        гострим болем?
      </div>
      <div class="game">
        <div class="game__men">
          <img
              v-show="getMenPosition === 1"
              src="@/assets/img/training-module3/theme-3/step-1/men-position-1.svg"
              alt=""
          />
          <img
              v-show="getMenPosition === 2"
              src="@/assets/img/training-module3/theme-3/step-1/men-position-2.svg"
              alt=""
          />
          <img
              v-show="getMenPosition === 3"
              src="@/assets/img/training-module3/theme-3/step-1/men-position-3.svg"
              alt=""
          />
          <img
              v-show="getMenPosition === 4"
              src="@/assets/img/training-module3/theme-3/step-1/men-position-4.svg"
              alt=""
          />
        </div>
        <div class="game__range">
          <div class="game__range-slider">
            <veeno
                id="slider"
                :step="1"
                :handles="1"
                :getset="changeSliderHandler"
                :connect="[true, false]"
                :pipsy="{ mode: 'steps', density: 30 }"
                :range="{ min: 1, max: 4 }"
                v-model="slider"
                @change="changeSliderHandler"
                @slide="changeSliderHandler"
            />
          </div>
          <div class="game__range-labels">
            <div class="game__range-label">1-2 год</div>
            <div class="game__range-label">30 хв - 1 год</div>
            <div class="game__range-label">&#60; 30 хв</div>
          </div>
        </div>
        <div class="game__message" :class="{ active: getMenPosition === 4 }">
          <div class="game__message-title">Ого, як швидко</div>
          <div class="game__message-text">
            Проте ми знаємо, як задовільнити потреби найвимогливіших покупців!
          </div>
        </div>
      </div>
      <div
          class="module-theory__next next-button"
          :class="{ disabled: getMenPosition === 1 }"
          @click="nextState(2, 'next')"
      >
        Далі
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import veeno from "veeno";

export default {
  name: "Theory1",
  data() {
    return {
      state: 1,
      slider: 1
    };
  },
  components: {
    veeno
  },
  computed: {
    getMenPosition() {
      return Math.floor(this.slider);
    }
  },
  methods: {
    changeSliderHandler() {
      var slider = document.getElementById("slider");
      var markers = slider.querySelectorAll(".noUi-marker");
      markers.forEach((marker, key) => {
        if (this.getMenPosition > key + 1) {
          marker.classList.add("active");
        } else {
          marker.classList.remove("active");
        }
      });
      if (this.getMenPosition === 4) {
        slider.setAttribute("disabled", true);
      }
    },
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;

    sup {
      font-family: $fontDemi;
    }
  }

  .info-block {
    margin-bottom: 48px;

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &__col {
      &-left {
        flex-basis: 44%;
        max-width: 44%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 56%;
        max-width: 56%;
        padding: 0 10px;
      }
    }

    &__text {
      p {
        margin-bottom: 18px;
        font-family: $fontR;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #545454;

        b {
          font-family: $fontDemi;
          font-weight: 700;

          sup {
            font-family: $fontDemi;
          }
        }

        &.text-bordered {
          position: relative;
          padding-top: 24px;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 41px;
            height: 3px;
            background: #00a03b;
          }
        }
      }

      ul {
        margin-bottom: 51px;
        padding-left: 17px;

        li {
          font-family: $fontR;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #545454;
        }
      }
    }

    &__image {
      &-desktop {
        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }
      }

      &-mobile {
        display: none;

        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .efficiency {
    margin-bottom: 48px;

    &__title {
      margin-bottom: 8px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }

    &__info {
      margin-bottom: 24px;
      font-size: 12px;
      line-height: 15px;
      color: #545454;
    }

    &__text {
      max-width: 545px;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      b {
        font-family: $fontDemi;
      }
    }
  }

  .security {
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }

    &__text {
      p {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 20px;
        color: #545454;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__image {
      padding-top: 32px;
      margin-bottom: 24px;

      img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .game {
    position: relative;
    width: 100%;
    max-width: 640px;
    height: 315px;
    margin: 48px auto 0;
    background: url("~@/assets/img/training-module3/theme-3/step-1/game-bg.svg") no-repeat;

    &__men {
      max-width: 173px;
      margin: auto;
    }

    &__range {
      width: 332px;
      margin: auto;

      &.disabled {
        pointer-events: none;
      }

      &-slider {
        margin-bottom: 6px;

        ::v-deep {
          .noUi {
            &-horizontal {
              .noUi {
                &-handle {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            &-target {
              position: relative;
              height: 14px;
              padding: 0 25px 0 10px;
              background: linear-gradient(
                      180deg,
                      #c7c7c7 0%,
                      #ededed 51.56%,
                      #c5c5c5 100%
              );
              border-radius: 45.2933px;
              border: none;
              box-shadow: 0px 7px 8px 0px rgba(78, 112, 131, 0.5);
            }

            &-connects {
              width: auto;
              margin: 0 -10px 0 -10px;
              height: 14px;
              border-radius: 45.2933px;
              overflow: hidden;
            }

            &-connect {
              background: linear-gradient(
                      180deg,
                      #e84080 0%,
                      #f972a5 51.56%,
                      #e84080 100%
              );
              border: none;
              box-shadow: none;
            }

            &-value {
              display: none;
            }

            &-handle {
              top: -10px;
              background: transparent;
              box-shadow: none;
              border: none;
              cursor: pointer;
              z-index: 2;

              &:focus {
                outline: none;
              }

              &:before {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                right: 0;
                bottom: 0;
                width: 89px;
                height: 89px;
                background: url("~@/assets/img/training-module3/theme-3/step-1/range-slider-circle.svg") no-repeat;
                background-size: contain;
                transform: translate(-50%, -50%);
              }

              &:after {
                display: none;
              }
            }

            &-pips-horizontal {
              top: 0px;
              left: 11px;
              width: 89%;
              height: 14px;
              padding: 0;
            }

            &-marker-horizontal {
              &.noUi {
                &-marker {
                  &-large,
                  &-sub {
                    top: 5px;
                    left: 7px;
                    width: 4px;
                    height: 4px;
                    background: #507285;
                    border-radius: 50%;

                    &.active {
                      background: #fff;
                      z-index: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &-labels {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
      }

      &-label {
        margin-right: 38px;
        background: #ffffff;
        padding: 2px 6px;
        box-shadow: 1.13233px 3.397px 4.52933px rgba(108, 135, 149, 0.5);
        border-radius: 56.6167px;
        font-family: $fontDemi;
        font-size: 10.191px;
        line-height: 12px;
        color: #507285;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__message {
      position: absolute;
      left: 50%;
      bottom: 16px;
      width: 100%;
      max-width: 330px;
      transform: translateX(-50%);
      background: #445094;
      padding: 9px 15px 12px;
      box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
      opacity: 0;
      visibility: hidden;
      transition: 2s all 0.5s;
      z-index: 10;

      &.active {
        opacity: 1;
        visibility: visible;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        width: 20px;
        border: 1px solid #ffffff;
      }

      &:before {
        left: -1px;
        border-right: none;
      }

      &:after {
        right: -1px;
        border-left: none;
      }

      &-title {
        margin-bottom: 10px;
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }

      &-text {
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 48px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &.disabled {
      opacity: 0.16;
    }

    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;

      br {
        display: none;
      }
    }

    .info-block {
      margin-bottom: 20px;

      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &__text {
        p {
          margin-bottom: 11px;
          font-size: 14px;
          line-height: 17px;
        }

        ul {
          margin-bottom: 25px;

          &:last-of-type {
            //margin-bottom: 0;
          }

          li {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      &__image {
        &-desktop {
          display: none;
        }

        &-mobile {
          display: block;
          margin-bottom: 24px;
        }
      }
    }

    .efficiency {
      margin-bottom: 26px;

      &__title {
        margin-bottom: 9px;
        font-size: 18px;
        line-height: 22px;
      }

      &__info {
        margin-bottom: 16px;
        font-size: 11px;
        line-height: 13px;
      }

      &__text {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .security {
      &__title {
        margin-bottom: 9px;
        font-size: 18px;
        line-height: 22px;
      }

      &__image {
        padding-top: 18px;
        margin-bottom: 18px;
      }

      &__text {
        p {
          margin-bottom: 15px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .game {
      width: auto;
      max-width: unset;
      margin: 25px -15px 0;

      &__range {
        max-width: 320px;
      }

      &__message {
        left: 15px;
        right: 15px;
        width: auto;
        max-width: unset;
        transform: unset;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}

@media screen and (max-width: 359px) {
  .module-theory {
    .game {
      &__range {
        max-width: 290px;

        &-label {
          margin-right: 25px;
        }
      }
    }
  }
}
</style>
