<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Дані щодо клінічної ефективності Нувіджил®
    </div>
    <div class="module-theory__subtitle">
      79% пацієнтів відчули покращення завдяки Нувіджил®<sup>8</sup>
    </div>
    <div class="module-theory__item">
      <div class="module-theory__item-row">
        <div class="module-theory__item-col-left">
          <div class="module-theory__item-img">
            <img src="@/assets/img/training-module4/theory-3/1.svg" alt="" />
          </div>
          <div class="module-theory__item-img-description">
            За даними 12-тижневого багатоцентрового плацебо-контрольованого
            подвійного сліпого клінічного дослідження Нувіджил®
          </div>
        </div>
        <div class="module-theory__item-col-right">
          <div class="module-theory__item-text">
            <p>
              У пацієнтів з надмірною сонливістю, асоційованою з хронічним
              порушенням сну при позмінній роботі, Нувіджил®:
            </p>
            <p>
              <b>
                • Покращував загальний клінічний стан, пов’язаний із сонливістю
                під час нічних змін, включно з поїздками на роботу та з
                роботи.<sup>8</sup>
              </b>
            </p>
            <p>
              <b>• Не впливав на якість денного сну<sup>***5,8</sup> </b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="module-theory__info">
      <div class="module-theory__info-row">
        <div class="module-theory__info-col-left">
          <div class="module-theory__info-title">
            Побічні реакції, про які <br />
            повідомлялося найчастіше (≥5%)
          </div>
          <div class="module-theory__info-list">
            • Головний біль<br />
            • Нудота<br />
            • Запаморочення<br />
            • Безсоння<br />
          </div>
        </div>
        <div class="module-theory__info-col-right">
          <div class="module-theory__info-text">
            Повний перелік побічних реакцій наведено в інструкції для медичного
            застосування лікарського засобу Нувіджил®<sup>5</sup>
          </div>
          <div
            class="module-theory__info-next next-button"
            @click="nextState(4, 'next')"
          >
            Далі
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory3",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.4  - Раздел "Клінічна ефективність Нувіджил" - Э.1'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
  }
  &__subtitle {
    margin-bottom: 32px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }
  .next-button {
    width: 262px;
    height: 64px;
    margin: auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
  &__item {
    margin-bottom: 32px;
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }
    &-img {
      img {
        display: block;
        max-width: 100%;
      }
      &-description {
        padding-top: 8px;
        padding-left: 15px;
        font-family: $fontR;
        font-size: 12px;
        line-height: 15px;
        color: #545454;
      }
    }
    &-text {
      p {
        margin-bottom: 15px;
        font-family: $fontR;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        strong {
          font-family: $fontDemi;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__info {
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }
      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }
    &-title {
      margin-bottom: 16px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;
    }
    &-list {
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
    &-text {
      max-width: 312px;
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 12px;
      line-height: 15px;
      color: #545454;
    }
    &-next {
      &.next-button {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }
    &__subtitle {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 17px;
    }
    &__item {
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &-img {
        img {
          width: 100%;
        }
        &-description {
          margin-bottom: 24px;
        }
      }
      &-text {
        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    &__info {
      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
      }
      &-list {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
      }
      &-text {
        margin-bottom: 32px;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
