<template>
  <div class="game">
    <div class="game__title">
      Спробуйте розставити НПЗП в залежності від ризику розвитку
      шлунково-кишкових кровотеч
      <span>
        (згідно даних метааналізу 28 клінічних досліджень):<sup>1</sup>
      </span>
    </div>
    <div class="game__zone">
      <div class="game__layout">
        <div class="game__layout-row">
          <div class="game__layout-col">
            <div class="game__layout-answers">
              <div
                v-for="(answer, key) in answers"
                :key="key"
                class="game__layout-answer"
                :class="
                  answer !== null
                    ? {
                        disabled:
                          (isSelectedDrug && answer.isHasAnswer) ||
                          onboardCheck,
                        active: isSelectedDrug && !answer.isHasAnswer,
                        'has-answer': answer.isHasAnswer,
                        correct: answer.isCorrect,
                      }
                    : {
                        active: isSelectedDrug,
                      }
                "
                @click="pasteDrug($event, key)"
              >
                {{ answer !== null ? answer.title : "" }}
              </div>
            </div>
          </div>
          <div class="game__layout-col">
            <div class="game__layout-drugs">
              <div
                v-for="(drug, key) in drugs"
                :key="key"
                class="game__layout-drug-back"
                :class="{
                  disabled:
                    (isSelectedDrug && !drug.isSelected) ||
                    drug.isMoved ||
                    onboardCheck,
                }"
                @click.self="selectDrug($event, key)"
              >
                <div
                  class="game__layout-drug-front"
                  :class="{
                    selected: drug.isSelected,
                  }"
                  @click="selectDrug($event, key)"
                >
                  {{ drug.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="game__onboarding">
        <div class="onboarding-select" v-show="onboardSelect">
          <div class="onboarding-select__dot"></div>
          <div class="onboarding-select__detail">
            Натисніть на назву препарату
          </div>
        </div>
        <div class="onboarding-paste" v-show="onboardPaste">
          <div class="onboarding-paste__dot"></div>
          <div class="onboarding-paste__detail">
            Розмістіть його у відповідній клітинці, в залежності від ризику
            розвитку ШКК
          </div>
        </div>
        <div class="onboarding-check" v-show="onboardCheck">
          <div class="onboarding-check__detail">
            <div class="onboarding-check__detail-text">
              Коли розташуєте усі препарати, натискайте на кнопку “Перевірити”
            </div>
            <div
              class="onboarding-check__detail-btn"
              @click="disableOnboarding()"
            >
              ОК
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="game__messages">
      <div class="game__messages-error" v-show="isErrorMessage">
        <div class="game__messages-error-text">
          Неправильно! Спробуйте ще раз
        </div>
        <div
          class="game__messages-error-btn"
          :class="{ disabled: isDisabledCheck }"
          @click="checkAnswers()"
        >
          Перевірити
        </div>
      </div>
      <div class="game__messages-success" v-show="isSuccessMessage">
        <div class="game__messages-success-detail">
          <div class="game__messages-success-title">
            Дуже добре!
          </div>
          <div class="game__messages-success-text">
            Ацеклофенак має найнижчий ризик виникнення шлунково-кишкових
            кровотеч порівняно з іншими НПЗП<sup>1,2</sup>
          </div>
        </div>
        <div
          class="game__messages-success-btn"
          :class="{ disabled: isDisabledCheck }"
          @click="nextState(3, 'next')"
        >
          Далі
        </div>
      </div>
    </div>
    <div
      class="game__check"
      :class="{ disabled: isDisabledCheck }"
      v-if="!isErrorMessage && !isSuccessMessage"
      @click="checkAnswers()"
    >
      Перевірити
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { TimelineMax } from "gsap";
export default {
  name: "Game",
  data() {
    return {
      onboardSelect: false,
      onboardPaste: false,
      onboardCheck: false,
      isErrorMessage: false,
      isSuccessMessage: false,
      isDisabledCheck: true,
      isSelectedDrug: false,
      selectedDrugKey: null,
      selectedAnswerKey: null,
      selectedDrugElement: null,
      selectedAnswerElement: null,
      answerTimeline: new TimelineMax(),
      drugs: [
        {
          id: 0,
          title: "Ацеклофенак",
          isCorrect: false,
          isSelected: false,
          isMoved: false,
        },
        {
          id: 1,
          title: "Мелоксикам",
          isCorrect: false,
          isSelected: false,
          isMoved: false,
        },
        {
          id: 2,
          title: "Німесулід",
          isCorrect: false,
          isSelected: false,
          isMoved: false,
        },
        {
          id: 3,
          title: "Кеторолак",
          isCorrect: false,
          isSelected: false,
          isMoved: false,
        },
      ],
      answers: [null, null, null, null],
    };
  },
  watch: {
    answers(answersArray) {
      if (answersArray.indexOf(null) === -1) {
        this.isDisabledCheck = false;
      } else {
        this.isDisabledCheck = true;
      }
    },
  },
  methods: {
    selectDrug(event, key) {
      if (!this.drugs[key].isMoved) {
        //  onboarding  manipulations
        var themeData = JSON.parse(localStorage.getItem("module2_theme3"));
        this.onboardSelect = false;
        themeData.game.onboardSelect = false;
        localStorage.setItem("module2_theme3", JSON.stringify(themeData));

        if (themeData.game.onboardPaste === null) {
          this.onboardPaste = true;
          gtag("event", "onLoad", {
            event_category: "Экран №2",
            event_label: 'М.2 - Т.3 - Раздел "Інтерактивна гра" - OnBoarding',
          });
        }
        // current selected  drug
        this.isSelectedDrug = !this.isSelectedDrug;
        this.drugs[key].isSelected = !this.drugs[key].isSelected;
        this.selectedDrugKey = key;
        this.selectedDrugElement = event.target;
      }
    },
    pasteDrug(event, key) {
      this.onboardPaste = false;
      this.selectedAnswerElement = event.target;
      this.selectedAnswerKey = key;

      if (this.answers[key] === null) {
        this.drugs.forEach((drug) => {
          drug.isSelected = false;
        });
        let selectedDrugElement = this.selectedDrugElement.getBoundingClientRect();
        let selectedAnswerElement = this.selectedAnswerElement.getBoundingClientRect();

        this.answerTimeline
          .to(this.selectedDrugElement, 0.5, {
            x: selectedAnswerElement.x - selectedDrugElement.x - 3,
            y: selectedAnswerElement.y - selectedDrugElement.y - 11,
            opacity: 0,
          })
          .add(() => {
            this.drugs[this.selectedDrugKey].isMoved = true;
            this.answers.splice(key, 1, this.drugs[this.selectedDrugKey]);
            this.answers[key].isHasAnswer = true;
            this.selectedDrugKey = null;
            this.selectedAnswerKey = null;
            this.selectedDrugElement = null;
            this.selectedAnswerElement = null;

            // onboarding paste drug logic
            var themeData = JSON.parse(localStorage.getItem("module2_theme3"));
            this.onboardPaste = false;
            themeData.game.onboardPaste = false;
            localStorage.setItem("module2_theme3", JSON.stringify(themeData));
            if (themeData.game.onboardCheck === null) {
              this.onboardCheck = true;
            }
            this.isSelectedDrug = false;
          });
      } else {
        this.selectedDrugElement = document.querySelectorAll(
          ".game__layout-drug-front"
        )[this.answers[key].id];
        this.answerTimeline
          .to(this.selectedDrugElement, 0.5, {
            x: 0,
            y: 0,
            opacity: 1,
          })
          .add(() => {
            this.drugs.forEach((drug) => {
              if (drug.id === this.answers[key].id) {
                drug.isMoved = false;
                drug.isHasAnswer = false;
              }
            });
            this.answers[key].isHasAnswer = false;
            this.answers.splice(key, 1, null);
          });
      }
    },
    checkAnswers() {
      // check if answers correct
      this.answers.forEach((answer, key) => {
        // if answer not correct, move to back
        if (
          (key === 0 && answer.id !== 3) ||
          (key === 1 && answer.id !== 2) ||
          (key === 2 && answer.id !== 1) ||
          (key === 3 && answer.id !== 0)
        ) {
          this.selectedDrugElement = document.querySelectorAll(
            ".game__layout-drug-front"
          )[answer.id];
          // move to default position
          this.answerTimeline
            .to(this.selectedDrugElement, 0.5, {
              x: 0,
              y: 0,
              opacity: 1,
            })
            .add(() => {
              // return to default values
              this.drugs.forEach((drug) => {
                if (drug.id === answer.id) {
                  drug.isMoved = false;
                  drug.isHasAnswer = false;
                }
              });
              // delete answer from answers array
              this.answers.splice(key, 1, null);
            });
        }
        // if answer correct, set to correct
        else {
          answer.isCorrect = true;
        }
      });
      // await animation end and show message
      setTimeout(() => {
        if (this.answers.indexOf(null) !== -1) {
          this.isErrorMessage = true;
        }
        if (this.answers.indexOf(null) === -1) {
          this.isErrorMessage = false;
          this.isSuccessMessage = true;
        }
      }, 700);

      this.answers.forEach((answer, key) => {
        gtag("event", "click", {
          event_category: `Выбор "${answer.title}"`,
          event_label: `Т.3 - Раздел "Інтерактивна гра" - Колонка ${key + 1}`,
        });
      });
      gtag("event", "click", {
        event_category: 'Кнопка "Перевірити"',
        event_label: 'М.2 - Т.3 - Раздел "Інтерактивна гра" ',
      });
    },
    disableOnboarding() {
      var themeData = JSON.parse(localStorage.getItem("module2_theme3"));
      this.onboardCheck = false;
      themeData.game.onboardCheck = false;
      localStorage.setItem("module2_theme3", JSON.stringify(themeData));
      gtag("event", "click", {
        event_category: 'Кнопка "Ок"',
        event_label: 'М.2 - Т.3 - Раздел "Інтерактивна гра" - OnBoarding',
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: 'М.2 - Т.3 - Раздел "Інтерактивна гра" ',
      });
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    },
  },
  mounted() {
    var themeData = JSON.parse(localStorage.getItem("module2_theme3"));
    if (themeData.game.onboardSelect === null) {
      this.onboardSelect = true;
      gtag("event", "onLoad", {
        event_category: "Экран №1",
        event_label: 'М.2 - Т.3 - Раздел "Інтерактивна гра" - OnBoarding',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.game {
  &__title {
    margin-bottom: 42px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    span {
      display: block;
      font-family: $fontDemi;
      font-size: 18px;
    }
    sup {
      font-family: $fontDemi;
    }
  }
  &__zone {
    position: relative;
    max-width: 640px;
    margin: auto;
  }
  &__layout {
    padding-bottom: 28px;
    border-bottom: 1px solid #e4eaed;
    &-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -15px;
    }
    &-col {
      flex-basis: 50%;
      max-width: 50%;
    }
    &-answers {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      background: url("~@/assets/img/training-module2/theme-3/step-2/1.svg")
        no-repeat;
      background-position: 15px 0px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &-answer {
      position: relative;
      width: 120px;
      height: 32px;
      margin-bottom: 8px;
      border-radius: 6px;
      pointer-events: none;
      border: 1px dashed #c6c6c6;
      font-family: $fontM;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      color: #545454;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        background: #f5f5f5;
        border: 1px dashed #445094;
        pointer-events: all;
        cursor: pointer;
      }
      &.has-answer {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(68, 80, 148, 0.2);
        border-radius: 6px;
        border: none;
        pointer-events: all;
        cursor: pointer;
      }
      &.correct {
        pointer-events: none;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 13px;
          height: 13px;
          transform: translate(-50%, -50%);
          background: url("~@/assets/img/training-module2/theme-3/step-2/correct-icon.svg")
            no-repeat;
          background-size: contain;
        }
      }
      &.disabled {
        pointer-events: none;
      }
    }
    &-drugs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 315px;
      padding-top: 17px;
      padding-left: 58px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &-drug {
      &-back {
        position: relative;
        width: 120px;
        height: 32px;
        margin-bottom: 16px;
        border-radius: 6px;
        background: rgba($color: #445094, $alpha: 0.2);
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
      &-front {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(68, 80, 148, 0.2);
        border-radius: 6px;
        font-family: $fontM;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #545454;
        cursor: pointer;
        &.selected {
          transform: rotate(-5deg) !important;
          transform-origin: left bottom !important;
        }
      }
    }
  }
  &__messages {
    &-error {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 48px 94px 48px 98px;
      background: rgba($color: #fb3449, $alpha: 0.6);
      &-text {
        margin-right: 30px;
        font-family: $fontDemi;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #ffffff;
      }
      &-btn {
        width: 262px;
        height: 64px;
        background-color: #aa198b;
        border-radius: 90px;
        font-weight: normal;
        font-size: 22px;
        text-align: center;
        line-height: 64px;
        color: #ffffff;
        cursor: pointer;
        &.disabled {
          opacity: 0.16;
          pointer-events: none;
        }
      }
    }
    &-success {
      display: flex;
      justify-content: space-between;
      padding: 24px 94px 32px;
      background: rgba($color: #00a03b, $alpha: 0.6);
      &-title {
        margin-bottom: 12px;
        font-family: $fontDemi;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
      }
      &-text {
        max-width: 315px;
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        sup {
          font-family: $fontDemi;
        }
      }
      &-btn {
        width: 262px;
        height: 64px;
        padding: 0 83px;
        margin-top: 8px;
        background-color: #aa198b;
        background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-origin: content-box;
        border-radius: 90px;
        font-weight: normal;
        font-size: 22px;
        text-indent: -50px;
        text-align: center;
        line-height: 64px;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          background-position: 110% center;
        }
      }
    }
  }
  &__onboarding {
    .onboarding-select {
      position: absolute;
      bottom: -15px;
      right: -50px;
      &__dot {
        position: absolute;
        width: 48px;
        height: 48px;
        bottom: 130px;
        left: -67px;
        background: url("~@/assets/img/training-module2/theme-3/step-2/onboard-dot-icon.svg")
          no-repeat;
        &:before {
          content: "";
          position: absolute;
          top: 23px;
          left: 40px;
          width: 1px;
          height: 130px;
          transform: rotate(-15deg);
          background: #ed1556;
        }
        &:after {
          content: "";
          position: absolute;
          top: 150px;
          left: 57px;
          width: 10px;
          height: 1px;
          background: #ed1556;
        }
      }
      &__detail {
        position: relative;
        width: 150px;
        padding: 6px 8px 10px;
        background: #445094;
        box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        &:before {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          bottom: -1px;
          width: 9px;
          border: 1px solid #ed1556;
          border-right: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          width: 9px;
          border: 1px solid #ed1556;
          border-left: none;
        }
      }
    }
    .onboarding-paste {
      position: absolute;
      top: -38px;
      right: 80px;
      &__dot {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 114px;
        left: -107px;
        background: url("~@/assets/img/training-module2/theme-3/step-2/onboard-dot-icon.svg")
          no-repeat;
        &:before {
          content: "";
          position: absolute;
          bottom: 25px;
          left: 24px;
          width: 1px;
          height: 100px;
          background: #ed1556;
        }
        &:after {
          content: "";
          position: absolute;
          top: -77px;
          left: 25px;
          width: 82px;
          height: 1px;
          background: #ed1556;
        }
      }
      &__detail {
        position: relative;
        width: 240px;
        padding: 6px 8px 10px;
        background: #445094;
        box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        &:before {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          bottom: -1px;
          width: 9px;
          border: 1px solid #ed1556;
          border-right: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          width: 9px;
          border: 1px solid #ed1556;
          border-left: none;
        }
      }
    }
    .onboarding-check {
      position: absolute;
      bottom: 23px;
      right: 0;
      &__detail {
        position: relative;
        width: 312px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 18px 10px 8px;
        background: #445094;
        box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
        &:before {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          bottom: -1px;
          width: 9px;
          border: 1px solid #ed1556;
          border-right: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          width: 9px;
          border: 1px solid #ed1556;
          border-left: none;
        }
        &-text {
          max-width: 186px;
          font-family: $fontDemi;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
        }
        &-btn {
          width: 82px;
          height: 44px;
          background: #ffffff;
          border-radius: 90px;
          font-family: $fontDemi;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          color: #445094;
          cursor: pointer;
        }
      }
    }
  }
  &__check {
    width: 262px;
    height: 64px;
    margin: 64px auto 0;
    background-color: #aa198b;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    &.disabled {
      opacity: 0.16;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .game {
    &__title {
      margin-bottom: 19px;
      font-size: 16px;
      line-height: 20px;
      span {
        display: inline;
        font-family: $fontR;
        font-size: 14px;
        sup {
          font-family: $fontR;
        }
      }
    }
    &__layout {
      margin-bottom: 8px;
      padding-bottom: 0;
      border-bottom: none;
      &-col {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0 15px;
      }
      &-drugs {
        padding-top: 18px;
        padding-left: 0;
        margin: auto;
      }
      &-drug {
        &-back {
          width: auto;
          height: auto;
          margin-bottom: 8px;
        }
        &-front {
          position: relative;
          padding: 8px;
          line-height: 17px;
        }
      }
      &-answers {
        max-width: 315px;
        padding-right: 20px;
        margin: auto;
        background-position: 0px 0px;
      }
    }
    &__messages {
      margin: 0 -15px;
      &-error {
        align-items: center;
        flex-direction: column;
        padding: 24px 47px 36px;
        &-text {
          margin-bottom: 16px;
          margin-right: 0;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
        &-btn {
          width: 266px;
          height: 44px;
          font-size: 16px;
          line-height: 43px;
        }
      }
      &-success {
        align-items: center;
        flex-direction: column;
        padding: 24px 47px 36px;
        &-title {
          margin-bottom: 12px;
          margin-right: 0;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
        &-text {
          margin-bottom: 18px;
          font-family: $fontR;
          font-size: 14px;
          line-height: 17px;
        }
        &-btn {
          width: 266px;
          height: 44px;
          padding: 0 95px;
          background-size: 24px;
          font-size: 16px;
          line-height: 43px;
          text-indent: -40px;
        }
      }
    }
    &__onboarding {
      .onboarding-select {
        bottom: -30px;
        right: 10px;
        &__dot {
          bottom: 55px;
          left: -88px;
          &:before {
            top: 12px;
            left: 47px;
            height: 75px;
            transform: rotate(-43deg);
          }
          &:after {
            width: 15px;
            top: 76px;
            left: 73px;
          }
        }
      }
      .onboarding-paste {
        top: -62px;
        left: -8px;
        right: unset;
        &__dot {
          top: 138px;
          left: 227px;
          &:before {
            height: 121px;
          }
          &:after {
            top: -99px;
            left: 14px;
            width: 11px;
          }
        }
      }
      .onboarding-check {
        bottom: -15px;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__check {
      width: 266px;
      height: 44px;
      margin: 22px auto 0;
      font-size: 16px;
      line-height: 43px;
    }
  }
}
@media screen and (max-width: 359px) {
  .game {
    &__layout {
      &-drug {
        &-front {
          padding: 8px 5px;
        }
      }
    }
  }
}
</style>
