<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Олфен<sup>®</sup>-АФ
    </div>
    <div class="module-theory__subtitle">
      Нестероїдний засіб для усунення болю при остеоартриті з
      хондропротекторними<br />
      властивостями<sup>3,4</sup>
    </div>
    <div class="module-theory__subtitle module-theory__subtitle--green">
      Олфен<sup>®</sup>-АФ має у своєму складі ацеклофенак 200мг, що діє до 24 годин та <br />
      приймається 1 раз на добу!<sup>5</sup>
    </div>
    <div class="module-theory__product">
      <div class="module-theory__product-row">
        <div class="module-theory__product-col-left">
          <div class="module-theory__product-list">
            <ul>
              <li>Стимулює синтез суглобового хряща<sup>3</sup></li>
              <li>Знімає запалення та біль<sup>4</sup></li>
            </ul>
          </div>
          <div class="module-theory__product-img">
            <img
              src="~@/assets/img/training-module2/theme-1/step-3/1.png"
              alt=""
            />
          </div>
        </div>
        <div class="module-theory__product-col-right">
          <div class="module-theory__product-detail">
            <div class="module-theory__product-info">
              Однієї упаковки вистачить на 10 днів терапії!
            </div>
            <div class="module-theory__product-text text-green">
              А тепер час перевірити ваші знання за підсумками цієї теми.
            </div>
            <div class="module-theory__product-text">
              Натискайте на кнопку “Перейти до тестування”, щоб пройти фінальний
              тест
            </div>
            <div
              class="module-theory__product-next"
              @click="nextState(4, 'next')"
            >
              Перейти до тестування
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Theory3",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до тестування"',
        event_label: 'М.2 - Т.1 - Раздел "Олфен-АФ" - Э.1'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 13px;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    &--green {
      color: #00a03b;
    }
    sup {
      font-family: $fontDemi;
    }
  }

  &__product {
    &-row {
      display: flex;
      flex-wrap: wrap;
    }
    &-col {
      &-left {
        flex-basis: 45%;
        max-width: 45%;
      }
      &-right {
        flex-basis: 55%;
        max-width: 55%;
      }
    }
    &-detail {
      max-width: 380px;
      padding-left: 5px;
    }
    &-list {
      margin-bottom: 16px;
      ul {
        padding-left: 18px;
        margin: 0;
        li {
          margin-bottom: 7px;
          font-family: $fontDemi;
          font-size: 16px;
          line-height: 20px;
          color: #545454;
          sup {
            font-family: $fontDemi;
          }
        }
      }
    }
    &-info {
      margin-bottom: 38px;
      padding: 16px 12px;
      background: linear-gradient(94.73deg, #273887 0%, #ed1556 100%);
      border-radius: 2px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
    &-text {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
      &.text-green {
        font-size: 18px;
        line-height: 22px;
        color: #00a03b;
      }
    }
    &-next {
      width: 375px;
      height: 64px;
      padding: 0 40px;
      margin: 23px 0 0;
      background-color: #aa198b;
      background-image: url("~@/assets/img/training-module2/arrow-icon.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-origin: content-box;
      border-radius: 90px;
      font-weight: normal;
      font-size: 22px;
      text-indent: -50px;
      text-align: center;
      line-height: 64px;
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-position: 105% center;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .module-theory {
    &__back {
      font-size: 12px;
      line-height: 15px;
    }
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }
    &__subtitle {
      margin-bottom: 20px;
      font-family: $fontR;
      font-size: 14px;
      line-height: 17px;
      &--green {
        margin-bottom: 12px;
        font-family: $fontDemi;
      }
      br {
        display: none;
      }
    }

    &__product {
      &-col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &-list {
        ul {
          li {
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      &-img {
        margin-bottom: 12px;
        img {
          width: 100%;
          max-width: 100%;
        }
      }
      &-info {
        margin-bottom: 32px;
        padding: 16px 24px;
      }
      &-text {
        font-family: $fontR;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        &.text-green {
          font-family: $fontDemi;
          font-size: 16px;
          line-height: 20px;
        }
      }
      &-next {
        width: 280px;
        height: 44px;
        margin: 24px auto 0;
        font-size: 16px;
        line-height: 44px;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
