import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export default (container) => {
  const canvas = createCanvas(document, container);
  const path = '/media/pack-3d.glb';

  const canvasDimensions = {
    width: canvas.clientWidth,
    height: canvas.clientHeight
  };

  const renderer = buildRenderer(canvasDimensions);
  const camera = buildCamera(canvasDimensions);
  const scene = buildScene();
  const loader = new GLTFLoader();

  // Light

  let light = new THREE.AmbientLight(0xffffff);
  light.intensity = 3.1;
  scene.add(light);

  // Loader

  loader.load( path, function ( gltf ) {
    let model = gltf.scene;

    model.position.set(0, -2 ,0);
    scene.add( model );
    renderer.render(scene, camera);
  }, undefined, function ( error ) {
  
    console.error( error );
  } )

  // Orbital controls

  const controls = new OrbitControls(camera, canvas);
  controls.enableDamping = true;
  controls.enableZoom = false;
  controls.minPolarAngle =  Math.PI / 2;
  controls.maxPolarAngle = Math.PI / 2;

  bindEventListeners();
  update();

  function createCanvas(document, container) {
    const canvas = document.createElement("canvas");
    container.appendChild(canvas);
    return canvas;
  }

  function bindEventListeners() {
    window.onresize = resizeCanvas;
    resizeCanvas();
  }

  function buildRenderer({ width, height }) {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    });
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 2;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);
    return renderer;
  }

  function buildCamera({ width, height }) {
    const aspectRatio = width / height;
    const fieldOfView = 45;
    const nearPlane = 1;
    const farPlane = 1000;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );
    camera.position.z = 9;
    return camera;
  }

  function buildScene() {
    const scene = new THREE.Scene();
    return scene;
  }

  function resizeCanvas() {
    // Update sizes
    canvasDimensions.width = canvas.clientWidth
    canvasDimensions.height = canvas.clientHeight

    // Update camera
    camera.aspect = canvasDimensions.width / canvasDimensions.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(canvasDimensions.width, canvasDimensions.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  function update() {
    // Update orbital controls
    controls.update();

    scene.rotation.y = 2;

    // Render
    renderer.render(scene, camera);

    // Call update again on the next frame
    requestAnimationFrame(update);
  }

  return {
    // expose functions to Vue here
    update,
    scene,
    controls
  };
};
