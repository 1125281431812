<template>
  <div class="comp-header">
    <div class="header-block" :class="{ shadow: !isOpenMenu }">
      <div class="header-content">
        <div class="logo mx-5">
          <router-link to="/">
            <img
              src="@/assets/img/logo.svg"
              width="90"
              height="30"
              alt="Teva logo image"
            />
          </router-link>
        </div>

        <div class="navigation-md" v-if="$route.name !== 'home'">
          <router-link
            v-for="(el, i) in categoryList"
            :key="i"
            :to="el.to"
            exact
            active-class="active"
            v-text="el.label"
            class="nav-item mx-6"
            :class="{
              active: $route.params.category === el.to.params.category,
            }"
          ></router-link>

          <div class="nav-item open-menu pa-8" @click="openMenu">
            <span>Обрано напрямок:</span>
            <span class="spec ml-2">{{ specName }}</span>

            <span
              class="icon expand ml-4"
              :style="{ transform: isOpenMenu ? 'rotate(180deg)' : '' }"
            >
            </span>
          </div>
        </div>

        <div
          v-if="isOpenFilter === false && $route.name !== 'home'"
          class="navigation-sm mr-8"
        >
          <div
            :class="[{ active: isOpenMenu }]"
            class="btn-menu"
            @click="openMenu"
            v-text="'Меню'"
          ></div>
        </div>
      </div>

      <div v-if="$route.name === 'list'" class="menu-sm">
        <router-link
          v-for="(el, i) in categoryList"
          :key="i"
          :to="el.to"
          exact
          active-class="active"
          v-text="el.label"
          class="item"
        ></router-link>
      </div>
    </div>

    <!-- drop menu -->
    <template v-if="$route.name !== 'home'">
      <div
        class="drop-menu"
        @mouseleave="openMenu"
        :class="[{ 'active shadow': isOpenMenu }]"
      >
        <div class="drop-menu-content">
          <div class="list-menu">
            <router-link
              v-for="(item, index) in specList"
              :key="index"
              :to="getNavLink(item)"
              @click="isOpenMenu = !isOpenMenu"
              class="item"
              :class="{ active: isActive(item) }"
            >
              <span class="minus" v-if="index">―</span>
              <span
                class="link"
                :data-title="`${item.name_ua}`"
                v-text="`${item.name_ua}`"
              ></span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="drop-menu-sm" :class="[{ active: isOpenMenu, selectSpec }]">
        <div class="dm-header pa-12 pr-4">
          <span v-if="!selectSpec">Меню</span>

          <button v-else class="dbtn ml-n6" @click="openSelectSpec">
            <span class="icon arrow-left"></span>
          </button>

          <button class="dbtn ml-auto mr-0" @click="openMenu">
            <span class="icon close"></span>
          </button>
        </div>

        <div class="dm-content">
          <div class="spec">
            <div v-if="!selectSpec" class="info py-6" @click="openSelectSpec">
              <div class="label">Обрано напрямок:</div>
              <div class="name">
                <span>{{ specName }}</span>

                <div class="ml-auto">
                  <span class="change">змінити</span>
                  <span class="icon arrow-right ml-4"></span>
                </div>
              </div>
            </div>

            <div v-else style="width: 100%">
              <div class="label pb-6">Напрямки:</div>

              <div class="spec-list">
                <div
                  v-for="(item, index) in specList"
                  :key="index"
                  @click="openMenu(), $router.push(getNavLink(item))"
                  class="item"
                  :class="{ active: isActive(item) }"
                  v-text="item.name_ua"
                ></div>
              </div>
            </div>
          </div>

          <div v-if="!selectSpec" class="list" @click="openMenu">
            <router-link
              v-for="(el, i) in categoryList"
              :key="i"
              :to="el.to"
              exact
              active-class="active"
              class="item"
            >
              <span class="item-label">{{ el.label }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { get } from "lodash";

export default {
  name: "header-block",

  data() {
    return {
      isOpenMenu: false,
      isOpenFilter: false,
      selectSpec: true,

      all: "Всі",
      test: [],
      // specList: [],
    };
  },

  computed: {
    ...mapState("Article", ["filterMap"]),
    ...mapState("Spec", { specMap: "items" }),
    ...mapGetters("Spec", ["currentSpec", "specById", "specBySlug"]),

    specId() {
      return this.currentSpec.id || 0;
    },

    specSlug() {
      return this.currentSpec.slug || 0;
    },

    specName() {
      return this.currentSpec.name_ua;
    },

    specList() {
      return this.sortSpecList(this.specMap);
    },

    categoryList() {
      const list = get(this.filterMap, "categories", []);
      const item = list.find(({ id }) => id === 9);
      const isShow = !get(item, "disabled", true);

      const getNavItem = (label, category) => ({
        label,
        to: {
          name: "list-old",
          params: {
            specSlug: this.specSlug,
            ...this.$route.params,
            category,
          },
        },
      });

      return [
        getNavItem("Всі матеріали", "all"),
        getNavItem("Заходи", "4"),
        getNavItem("Статті", "2,3"),
        getNavItem("Інструменти", "1"),

        ...(isShow ? [getNavItem("Освітні модулі", "9")] : []),
      ];
    },
  },

  methods: {
    log(...v) {
      console.log(...JSON.parse(JSON.stringify(v)));
    },

    isActive(item) {
      // this.log(item, this.$route.params);
      const { specSlug, spec } = this.$route.params;

      return item.slug === specSlug || item.id == spec;
    },

    getNavLink(item) {
      return {
        name: "list-old",
        params: {
          ...this.$route.params,
          specSlug: item.slug,
          category: "all",
        },
      };
    },

    sortSpecList(data) {
      if (!data.length) return [];
      const removeItems = ["distributor", "all"];
      const allItem = data.find((el) => el.slug === "all");
      const list = data.filter((el) => !removeItems.includes(el.slug));
      const sortedList = list.sort((a, b) =>
        a.name_ua.localeCompare(b.name_ua)
      );
      sortedList.push(allItem);

      return sortedList;
    },

    backBtn() {
      this.$router.push({
        name: "list-old",
        params: {
          specSlug: this.specSlug,
          category: "all",
          ...this.$route.params,
        },
      });
    },

    openSelectSpec() {
      this.selectSpec = !this.selectSpec;
    },

    openMenu() {
      this.selectSpec = false;

      if (window.innerWidth <= 768) {
        document.querySelector("body").style.overflow = "hidden";
      }

      if (this.isOpenMenu) {
        document.querySelector("body").style.overflow = "auto";
      }

      this.isOpenMenu = !this.isOpenMenu;
    },

    openFilter() {
      this.$store.commit("Article/setShowFilter", true);
    },
  },
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;

@import "../css/font-noto.css";
@import "../css/variables";

.shadow {
  box-shadow: 0 4px 49px rgba(0, 0, 0, 0.09);
}

.dbtn {
  padding: 8px 12px;
  border: none;
  background: none;
}

.icon {
  display: inline-block;
  // margin-right: 8px;
  width: 19px;
  height: 18px;

  background-repeat: no-repeat;
  background-position: center;

  &.menu {
    background-image: url("../assets/icon/old_menu.svg");
  }
  &.filter {
    background-image: url("../assets/icon/old_filter.svg");
  }
  &.expand {
    background-image: url("../assets/icon/expand.svg");
  }
  &.close {
    width: 15px;
    height: 15px;
    background-image: url("../assets/icon/close2.svg");
  }
  &.arrow-right {
    width: 15px;
    height: 8px;
    background-image: url("../assets/icon/arrow-right.svg");
  }
  &.arrow-left {
    width: 15px;
    height: 15px;
    background-image: url("../assets/icon/arrow-right.svg");
    transform: rotate(180deg);
  }
}

.drop-menu-sm {
  display: none;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff url("../assets/bg.svg") center 60px no-repeat;
  background-size: auto 100%;

  font-family: $fontSecondary;

  * {
    font-family: inherit;
  }

  &.active {
    @media (max-width: 768px) {
      display: block;
    }
  }

  &.selectSpec {
    background: #f2faf5;
  }

  .dm-header {
    display: flex;
    align-items: center;
    // padding: 24px 8px 24px 24px;
    height: 60px;

    font-size: 16px;
    font-weight: 500;
    color: #317575;
  }

  .dm-content {
    height: 100%;
    max-height: calc(var(--vh, 1vh) * 100 - 60px);
    overflow: auto;
  }

  .spec {
    display: flex;
    align-items: center;
    width: 100%;

    padding: 0 16px 0 24px;
    background-color: #f2faf5;

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 100%;
      height: 60px;
    }

    .label {
      font-size: 11px;
      font-weight: 300;
      color: #317575;
    }

    .name {
      display: flex;
      align-items: center;
      padding-top: 4px;

      font-size: 16px;
      font-weight: 600;
      color: #317575;
    }

    .change {
      font-size: 11px;
      font-weight: 300;
      color: #00a03b;
    }
  }

  .spec-list {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    max-height: calc(var(--vh, 1vh) * 100 - 60px - 27px - 20px);
    overflow: auto;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #317575;
    }

    &::-webkit-scrollbar-corner {
      background: none;
    }

    .item {
      position: relative;
      margin-top: 12px;
      padding: 8px 0 8px 32px;
      text-decoration: none;

      font-size: 16px;
      font-weight: 300;
      color: #317575;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #317575;
        border-radius: 50%;
        box-sizing: border-box;
      }

      &.active {
        font-weight: 600;
        color: #00a03b;

        &::before {
          border-width: 2px;
          border-color: #00a03b;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 16px;
          left: 7px;
          width: 6px;
          height: 6px;
          background-color: #00a03b;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;

    .item {
      margin-top: 16px;
      padding: 8px 24px;
      text-decoration: none;

      font-family: $fontSecondary;
      font-size: 20px;
      font-weight: 300;
      color: #204e77;

      .item-label {
        position: relative;
        padding: 8px 0px;
      }

      &.active {
        position: relative;
        font-weight: 600;

        .item-label::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: 2px solid;
        }
      }
    }
  }
}

.navigation-md {
  display: none;
  align-items: center;
  height: 100%;

  font-family: $fontSecondary;

  @media (min-width: $md + 1) {
    display: flex;
  }

  .nav-item {
    position: relative;

    display: flex;
    align-items: center;
    height: 100%;

    text-decoration: none;

    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #204e77;

    &.active {
      font-weight: 600;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: #204e77;
      }
    }

    &.open-menu {
      background-color: #f2faf5;
      color: #317575;

      .spec {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.navigation-sm {
  display: flex;
  z-index: 15;

  @media (min-width: 769px) {
    display: none;
  }

  .btn-menu {
    padding: 2px 0 4px;

    font-family: $fontSecondary;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #317575;

    border-top: 2px solid #317575;
    border-bottom: 2px solid #317575;
  }
}

.menu-sm {
  display: flex;
  width: 100%;
  overflow: auto;

  border-top: 1px solid #eff4f7;
  background: #ffffff;

  @media (min-width: 769px) {
    display: none;
  }

  .item {
    position: relative;
    margin-left: 12px;
    padding: 10px 0px;
    flex-shrink: 0;
    text-decoration: none;
    color: #204e77;

    font-family: $fontSecondary;
    font-size: 11px;
    font-weight: 400;

    &.active {
      font-weight: 600;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 2px solid;
      }
    }
  }
}

.comp-header {
  * {
    // user-select: none;
  }
}

.header-block {
  z-index: 15;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #ffffff;
  // height: 65px;

  @media screen and (max-width: 425px) {
    height: 50px;
  }

  .logo {
    svg {
      max-width: 90px;
      width: 100%;
      @media screen and (max-width: 425px) {
        max-width: 60px;
      }
    }
  }
}

.header-content {
  margin: 0 auto;
  max-width: $maxW;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 425px) {
    height: 50px;
  }

  .open-menu {
    cursor: pointer;
    display: flex;
    align-items: center;

    // border-top: 2px solid #317575;
    // border-bottom: 2px solid #317575;

    @media screen and (max-width: 425px) {
      display: none;
    }

    p {
      color: #317575;
      font-family: $fontR;
      font-weight: bold;
      font-size: 16px;

      text-transform: uppercase;
    }

    svg {
      margin-right: 8px;

      &.active {
        .line {
          opacity: 0;
        }

        .arrow {
          transform: translateX(-23px) rotate(180deg);
        }
      }
    }

    .arrow,
    .line {
      transition: all 0.2s ease;
      transform-origin: 80% 50%;
    }
  }
}

.drop-menu {
  z-index: 11;
  position: fixed;
  left: 0;
  top: 65px;
  width: 100%;
  background: #f2faf5;
  box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.15);

  backdrop-filter: blur(5px);
  transform: translateY(-150%);
  transition: all 0.5s ease;

  @media screen and (max-width: 768px) {
    display: none;
    top: 86px;
    height: calc(100vh - 86px);
  }

  .menu-title {
    padding: 0 10px;

    p {
      color: #545454;
      font-family: $fontDemi;
      font-size: 28px;

      @media screen and(max-width: 670px) {
        font-size: 20px;
      }
    }

    margin-bottom: 12px;

    @media screen and(max-width: 670px) {
      margin-bottom: 15px;
    }
  }

  &.active {
    transform: translateY(0);
  }

  .drop-menu-content {
    margin: 0 auto;
    padding: 20px 0;
    max-width: $maxW;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;

    @media screen and (max-width: 425px) {
      padding: 15px 0;
    }
  }

  .list-menu {
    display: flex;
    flex-flow: row wrap;
    padding: 0 10px;
    justify-content: center;

    @media screen and (max-width: 425px) {
      flex-flow: column nowrap;
    }
  }

  .item {
    cursor: pointer;
    position: relative;

    font-family: $fontSecondary;
    font-size: 20px;
    font-weight: 300;
    line-height: 180%;
    color: #317575;

    text-decoration: none;
    transition: all 0.2s ease;

    .minus {
      padding: 0 5px;
    }

    .link {
      display: inline-block;
      text-align: center;
    }

    .link::before {
      display: block;
      content: attr(data-title);
      height: 1px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }

    span {
      font-family: inherit;
    }

    .link::before,
    &:hover,
    &.active {
      color: #00a03b;
      font-weight: 700;
    }
    &:hover .link,
    &.active .link {
      text-decoration: underline;
    }

    @media screen and (max-width: 670px) {
      font-size: 18px;
    }

    @media screen and (max-width: 425px) {
      padding-left: 0;
      width: max-content;
    }
  }
}
</style>
