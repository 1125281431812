<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Ін’єкції Олфен® — лікування <br />
      болю зі знеболенням<sup>7-10</sup>
    </div>
    <div class="module-theory__subtitle">
      У складі Олфен®-75 є лідокаїну гідрохлорид 20 мг, що проявляє <br />
      знеболювальний ефект у місці ін’єкції<sup>7</sup>
    </div>
    <div class="module-theory__image">
      <img
        class="module-theory__image-desktop"
        src="@/assets/img/training-module3/theme-1/step-4/1-desktop.svg"
        alt=""
      />
      <img
        class="module-theory__image-mobile"
        src="@/assets/img/training-module3/theme-1/step-4/1-mobile.svg"
        alt=""
      />
    </div>
    <div class="module-theory__row">
      <div class="module-theory__col-left">
        <div class="module-theory__list">
          <ul>
            <li>
              Виражений знеболювальний ефект через 15-30 хвилин після введення*
            </li>
            <li>
              Звичайна разова доза: 1 ампула внутрішньом’язово 1 раз/добу<sup
                >7</sup
              >
            </li>
            <li>Вироблено у Німеччині<sup>3</sup></li>
          </ul>
        </div>
      </div>
      <div class="module-theory__col-right">
        <div class="module-theory__detail">
          <p>
            <b>А тепер час перевірити ваші знання за підсумками цієї теми.</b>
          </p>
          <p>Натискайте на кнопку “Перейти до тестування”, щоб пройти тест</p>
        </div>
        <div class="module-theory__next" @click="nextState(5, 'next')">
          Перейти до тестування
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory4",
  data() {
    return {
      state: 1
    };
  },
  methods: {
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
      gtag("event", "click", {
        event_category: 'Кнопка "Перейти до тестування"',
        event_label: 'М.3 - Т.1  - Раздел "Олфен-75"'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }
  &__image {
    margin-bottom: 25px;
    &-desktop {
      display: block;
      max-width: 100%;
      width: 100%;
    }
    &-mobile {
      display: none;
      max-width: 100%;
      width: 100%;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 57%;
      max-width: 57%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 43%;
      max-width: 43%;
      padding: 0 10px;
    }
  }
  &__list {
    max-width: 370px;
    ul {
      padding-left: 0;
      margin: 0;
      list-style-position: inside;
      li {
        margin-bottom: 18px;
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        sup {
          font-family: $fontDemi;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__detail {
    p {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      b {
        font-family: $fontDemi;
        color: #00a03b;
      }
    }
  }
  &__next {
    width: 310px;
    height: 64px;
    padding: 0 22px;
    margin-top: 24px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 20px;
    text-indent: -40px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 102% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
      br {
        display: none;
      }
    }
    &__subtitle {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
      br {
        display: none;
      }
    }
    &__image {
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
      }
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__list {
      margin-bottom: 36px;
      ul {
        li {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    &__detail {
      text-align: center;
      p {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
        b {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    &__next {
      width: 280px;
      height: 44px;
      margin: 24px auto 0;
      padding: 0 30px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
