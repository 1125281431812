<template>
  <div class="tablets-slider__list" :id="id">
    <div v-for="(drugSlide, key) in drug.image_slider" :key="key">
      <div class="tablets-slider__item">
        <img :src="$root.globalUrl + drugSlide.path" alt />
      </div>
    </div>
  </div>
</template>

<script>
import "nouislider/distribute/nouislider.min.css";
import { tns } from "tiny-slider/src/tiny-slider";

export default {
  name: "Slider",
  props: ["drug", "id"],
  mounted() {
    if (this.id.length) {
      tns({
        container: `#${this.id}`,
        items: 1,
        controls: true,
        autoplay: false
      });
    }
  }
};
</script>
