<template>
  <div class="module-theory">
    <button
      class="module-theory__back-btn"
      @click="nextState(3, '')"
    >
      <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      Назад
    </button>
    <div class="module-theory__title">
      Як правильно тлумачити дозування на упаковці суспензій?
    </div>
    <div class="module-theory__container">
      <div class="module-theory__blue-text">
        Натисніть на дозування на упаковці —<br>
        дізнайтеся що вони означають
      </div>
      <div class="module-theory__pack">
        <Model :rotate="rotate" />
        <div class="module-theory__button one">
          <div
            class="module-theory__button-inner"
            :class="{'active': activeTips.includes(1)}"
            @click="setActiveTips(1)"
          >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="7" y1="1" x2="7" y2="13" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <line x1="1" y1="7" x2="13" y2="7" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
          <div class="module-theory__tip one" :class="{active: activeTips.includes(1)}">
            <nobr>Дана форма випуску</nobr><br><nobr>буде містити 200 мг</nobr><br><nobr>азитроміцину у 5 мл</nobr><br><nobr>готової суспензії</nobr> (після розведення)
          </div>
        </div>
        <div class="module-theory__button two">
          <div
            class="module-theory__button-inner"
            :class="{'active': activeTips.includes(2)}"
            @click="setActiveTips(2)"
          >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="7" y1="1" x2="7" y2="13" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <line x1="1" y1="7" x2="13" y2="7" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
          <div class="module-theory__tip two" :class="{active: activeTips.includes(2)}">
            <nobr>30 мл -</nobr><br><nobr>загальний об’єм</nobr><br><nobr>готової суспензії</nobr>
          </div>
        </div>
        <div class="module-theory__background"></div>
        <div class="module-theory__platform"></div>
      </div>
    </div>
    <div class="module-theory__next next-button" @click="nextState(5, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
import Model from './Model';

export default {
  name: "Theory1",
  components: {
    Model,
  },
  data: () => ({
    activeTips: [],
    rotate: false,
  }),
  methods: {
    setActiveTips(tip) {
      if (this.activeTips.includes(tip)) {
        this.activeTips.splice(this.activeTips.indexOf(tip), 1);
        this.rotate = false;
        gtag("event", "click", {
          event_category: 'Кнопка "Відкрити додаткову інформацію про упаковку"',
          event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.3'
        });
      } else {
        this.activeTips.push(tip);
        this.rotate = true;
        gtag("event", "click", {
          event_category: 'Кнопка "Закрити додаткову інформацію про упаковку"',
          event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.3'
        });
      }
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: state < 4 ? 'Кнопка "Назад"' : 'Кнопка "Далі"',
        event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.3'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__blue-text {
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #212661;
  }

  &__pack {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }

  &__button {
    position: absolute;
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 50%;
    background: rgba(45, 199, 172, 0.25);
    border: 1px solid #2DC7AC;
    cursor: pointer;

    &.one {
      left: 65%;
      top: 40%;
    }

    &.two {
      right: 65%;
      bottom: 30%;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 3px;
      border-radius: 50%;
      background: #AA198B;
      box-shadow: 0px 0px 12.2726px rgba(45, 51, 120, 0.25);
      transition: .6s;
      
      &.active {
        background: #2DC7AC;
        transform: rotate(45deg);
      }
    }
  }

  &__tip {
    position: absolute;
    z-index: -1;
    padding: 8px;
    width: fit-content;
    opacity: 0;
    transition: .6s;
    backdrop-filter: blur(4.90903px);
    border-radius: 10px;
    border: 1px solid #fff;
    background-color: rgba(255, 255, 255, .5);
    font-size: 14px;
    line-height: 120%;
    color: #212661;

    &.one {
      transform: translate(-107%, -50%);
      top: 50%;
    }

    &.two {
      transform: translate(0, -50%);
      top: 50%;
      left: 120%;
    }

    @media (max-width: 450px) {
      padding-right: 15px;
      min-width: 165px;
    }

    &.active {
      z-index: 1;
      opacity: 1;
    }
  }

  &__background {
    height: 70%;
    z-index: -1;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, #73A2A3 0%, #BCFCFA 100%);
    border-radius: 12px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &__platform {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/img/training-module5/theme-1/step-4/platform.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0;
    z-index: -1;

    &::before {
      content: '';
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      width: 12%;
      height: 7%;
      background: linear-gradient(312.49deg, #317575 12.83%, rgba(49, 117, 117, 0) 119.9%);
      opacity: 0.3;
      filter: blur(15px);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 1%;
      left: 50%;
      transform: translateX(-50%);
      width: 68px;
      height: 68px;
      background-image: url("~@/assets/img/training-module5/theme-1/step-4/rotate-icon.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__platform {
      background-image: url("~@/assets/img/training-module5/theme-1/step-4/platform-mob.png");

      &::before {
        height: 10%;
      }
    }

    &__button {

      &.one {
        left: 80%;
        top: 40%;
      }

      &.two {
        right: 80%;
        bottom: 30%;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
