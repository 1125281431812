<template>
  <div class="module-theory">
    <div class="module-theory__title">
      д.м.н, професор Наталія Бездітко про використання менших за об'ємом
      ін’єкційних препаратів
    </div>
    <div class="module-theory__row">
      <div class="module-theory__col-left">
        <div class="module-theory__info">
          <div class="module-theory__info-text">
            Ін’єкційний диклофенак у дозі 75 мг зазвичай<br/>
            має об’єм 3 мл. Проте таку ж дозу можна <br/>
            знайти і у
            <b>меншому об’ємі.<sup>6</sup></b>
          </div>
          <div class="module-theory__info-video">

            <div class="module-theory__video-frame">
              <iframe
                  ref="video"
                  width="100%"
                  height="100%"
                  :src="
              `https://www.youtube.com/embed/xUrdXyCubhc?rel=0?&autoplay=${
                !isVideoPlayed ? '0' : '1'
              }`
            "
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
              ></iframe>
            </div>
            <!--            <div-->
            <!--                class="module-theory__info-video-play desc"-->
            <!--                v-show="!isVideoPlayed"-->
            <!--                @click="playVideo()"-->
            <!--            ></div>-->
            <!--            <div-->
            <!--                class="module-theory__info-video-play mob"-->
            <!--                v-show="!isVideoPlayed"-->
            <!--                @click="toggleFullScreen()"-->
            <!--            ></div>-->
          </div>
        </div>
      </div>
      <div class="module-theory__col-right">
        <div class="module-theory__question">
          <div class="module-theory__question-title">
            Яке лікування ви оберете?
          </div>
          <div class="module-theory__answers">
            <div
                class="module-theory__answers-item"
                :class="{ selected: ampule.isSelected }"
                v-for="(ampule, key) in ampules"
                :key="key"
                @click="selectAnswer(key)"
            >
              <div class="module-theory__answers-title">{{ ampule.value }}</div>
            </div>
          </div>
          <div
              class="module-theory__next next-button"
              :class="{ disabled: isDisabledNext }"
              @click="nextState(4, 'next')"
          >
            Далі
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory3",
  data() {
    return {
      state: 1,
      isVideoPlayed: false,
      isDisabledNext: true,
      ampules: [
        {
          id: 1,
          isSelected: false,
          value: "3 мл"
        },
        {
          id: 2,
          isSelected: false,
          value: "2 мл"
        }
      ]
    };
  },
  methods: {
    playVideo() {
      this.isVideoPlayed = true;
      this.$refs.video1.play();
    },
    toggleFullScreen() {
      this.playVideo();
      const videoElement = this.$refs.video1;
      if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
          screen.mozOrientation.lock('landscape');
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
          screen.mozOrientation.lock('landscape-primary');
        } else {
          videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          videoElement.webkitEnterFullScreen();
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
        }

      } else {
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else {
          document.webkitCancelFullScreen();
        }
      }
    },
    selectAnswer(key) {
      this.ampules.forEach(ampule => {
        ampule.isSelected = false;
      });
      this.ampules[key].isSelected = true;
      this.isDisabledNext = false;
    },
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 23px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;

    sup {
      font-family: $fontDemi;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  &__col {
    &-left {
      padding: 0 10px;
      flex-basis: 55%;
      max-width: 55%;
    }

    &-right {
      padding: 0 10px;
      flex-basis: 45%;
      max-width: 45%;
    }
  }

  &__info {
    &-text {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      b {
        font-family: $fontDemi;
      }
    }

    &-video {
      position: relative;
      // &.rotate {
      //   transform: rotate(-90deg);
      //   transform-origin: left top;
      //   width: 100vh;
      //   overflow-x: hidden;
      //   position: absolute;
      //   top: 100%;
      //   left: 0;
      // }
      video {
        display: block;
        max-width: 100%;

        &:focus {
          outline: none;
        }
      }

      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 89px;
        height: 89px;
        transform: translate(-50%, -50%);
        background: url("~@/assets/img/training-module3/theme-2/step-3/play-icon.svg") no-repeat;
        background-size: contain;
        cursor: pointer;
        z-index: 1;

        &.mob {
          display: none;
        }
      }
    }
  }

  &__question {
    padding-left: 5px;
    padding-right: 24px;

    &-title {
      margin-bottom: 11px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }
  }

  &__answers {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    @media screen and (max-width: 330px) {
      flex-direction: column;
      align-items: center;
    }

    &-item {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 150px;
      height: 168px;
      padding: 16px;
      margin-right: 10px;
      background-color: rgba(228, 234, 237, 0.4);
      background-image: url("~@/assets/img/training-module3/theme-2/step-3/ampule.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid #00a03b;
      border-radius: 21px;
      font-family: $fontDemi;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #005984;
      cursor: pointer;
      @media screen and (max-width: 330px) {
        margin-right: 0 !important;
        &:first-child {
          margin-bottom: 10px;
        }

      }

      &.selected {
        background-color: #00a03b;
        color: #fff;
      }

      &:nth-child(1) {
        background-position: center 20px;
      }

      &:nth-child(2) {
        background-position: center 33px;
        background-size: 35px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;

    &.disabled {
      opacity: 0.16;
    }

    &:hover {
      background-position: 110% center;
    }
  }

  &__video-frame {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
    }

    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &__info {
      display: flex;
      flex-wrap: wrap-reverse;

      &-video {
        margin-bottom: 17px;
        width: 100%;

        &-play {
          &.desc {
            display: none;
          }

          &.mob {
            display: block;
          }
        }
      }

      &-text {
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__question {
      padding-right: 0;
      padding-left: 0;

      &-title {
        margin-bottom: 21px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__answers {
      justify-content: center;
      margin-bottom: 0;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 38px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}

@media screen and (max-width: 359px) {
  .module-theory {
    &__answers {
      &-item {
        width: 140px;
        margin-right: 10px;
      }
    }
  }
}
</style>
