<template>
  <div class="module-theory">
    <div class="module-theory__state" v-show="state === 1">
      <div class="module-theory__title">
        Ін’єкції Олфен® — <br />
        лікування меншим об’ємом
      </div>
      <div class="module-theory__subtitle">
        <p>
          Олфен®-75 — в
          <b class="bold-on-mobile">ін’єкції об’ємом лише 2 мл</b> вдалося
          поєднати <br />
          Диклофенаку натрію 75 мг та Лідокаїну гідрохлориду 20 мг<sup>3</sup>
        </p>
      </div>
      <div class="module-theory__image">
        <img
            class="module-theory__image-desktop"
            src="@/assets/img/training-module3/theme-2/step-4/1-desktop.svg"
            alt=""
        />
        <img
            class="module-theory__image-mobile"
            src="@/assets/img/training-module3/theme-2/step-4/1-mobile.svg"
            alt=""
        />
      </div>
      <div class="module-theory__row">
        <div class="module-theory__col-left">
          <div class="module-theory__list">
            <ul>
              <li>Виражений знеболювальний ефект при помірних та тяжких болях неревматичного походження через 15-30 хвилин після введення<sup>3,4</sup></li>
              <li>
                Звичайна разова доза: 1 ампула внутрішньом’язово 1 раз/добу<sup
              >3</sup
              >
              </li>
              <li>Вироблено у Німеччині<sup>3</sup></li>
            </ul>
          </div>
        </div>
        <div class="module-theory__col-right">
          <div class="module-theory__next next-button" @click="changeState()">
            Далі
          </div>
        </div>
      </div>
    </div>
    <div class="module-theory__state" v-show="state === 2">
      <div class="module-theory__title">
        Генерична заміна
      </div>
      <div class="module-theory__subtitle">
        <p>
          <b
          >Медичні, фармацевтичні працівники під час здійснення ними
            професійної діяльності не мають права:
          </b>
          на вимогу споживача під час реалізації (відпуску) ЛЗ не надавати або
          надавати недостовірну інформацію про наявність в данному аптечному
          закладі ЛЗ з такою самою
          <b>діючою речовиною, формою випуска та дозуванням</b>
        </p>
        <p>
          Стаття 78. Пункт 4. основи Законодавства України про захист здоров’я.
        </p>
      </div>
      <div class="video-info">
        <div class="video-info__title">
          д.м.н, професор Наталія Бездітко про генеричну заміну
        </div>
        <div class="video-info__row">
          <div class="video-info__col-left">
            <div class="video-info__video">
              <div class="module-theory__video-frame">
                <iframe
                    ref="video"
                    width="100%"
                    height="100%"
                    :src="
              `https://www.youtube.com/embed/zNz7JsINumI?rel=0?&autoplay=${
                !isVideoPlayed ? '0' : '1'
              }`
            "
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
              </div>
              <!--              <div-->
              <!--                class="video-info__video-play desc"-->
              <!--                v-show="!isVideoPlayed"-->
              <!--                @click="playVideo()"-->
              <!--              ></div>-->
              <!--              <div-->
              <!--                class="video-info__video-play mob"-->
              <!--                v-show="!isVideoPlayed"-->
              <!--                @click="toggleFullScreen()"-->
              <!--              ></div>-->
            </div>
          </div>
          <div class="video-info__col-right">
            <div class="video-info__subtitle">
              Ключові повідомлення з відео:
            </div>
            <div class="video-info__list">
              <ul>
                <li>
                  Можливо рекомендувати препарат, який має таку саму лікарську
                  форму, діючу речовину та дозування
                </li>
                <li>
                  Якщо є ефект від лікування та відсутні побічні явища, немає
                  необхідності переходити на інший препарат
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="video-info__next next-button" @click="nextState(5, 'next')">
          Далі
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory4",
  data() {
    return {
      state: 1,
      isVideoPlayed: false
    };
  },
  methods: {
    playVideo() {
      this.isVideoPlayed = true;
      this.$refs.video1.play();
    },
    toggleFullScreen() {
      this.playVideo();
      const videoElement = this.$refs.video1;
      if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
          screen.mozOrientation.lock('landscape');
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
          screen.mozOrientation.lock('landscape-primary');
        } else {
          videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          screen.orientation.lock('landscape');
          screen.orientation.lock('landscape-primary');
        }
      } else {
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else {
          document.webkitCancelFullScreen();
        }
      }
    },
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {

  &__video-frame {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    max-width: 545px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
    p {
      margin-bottom: 15px;
      b {
        font-family: $fontDemi;
        font-weight: 7;
        &.bold-on-mobile {
          font-family: $fontR;
          font-weight: 400;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__image {
    margin-bottom: 25px;
    &-desktop {
      display: block;
      max-width: 100%;
      width: 100%;
    }
    &-mobile {
      display: none;
      max-width: 100%;
      width: 100%;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    &-left {
      flex-basis: 57%;
      max-width: 57%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 43%;
      max-width: 43%;
      padding: 0 10px;
    }
  }
  &__list {
    max-width: 370px;
    ul {
      padding-left: 0;
      margin: 0;
      list-style-position: inside;
      li {
        margin-bottom: 18px;
        font-family: $fontDemi;
        font-size: 16px;
        line-height: 20px;
        color: #545454;
        sup {
          font-family: $fontDemi;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__detail {
    p {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      b {
        font-family: $fontDemi;
        color: #00a03b;
      }
    }
  }
  &__next {
    &.next-button {
      margin: 24px auto 0;
    }
  }
  .video-info {
    padding-top: 48px;
    &__title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #00a03b;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    &__col {
      &-left {
        padding: 0 10px;
        flex-basis: 55%;
        max-width: 55%;
      }
      &-right {
        padding: 0 10px;
        flex-basis: 45%;
        max-width: 45%;
      }
    }
    &__video {
      position: relative;
      video {
        display: block;
        max-width: 100%;
        &:focus {
          outline: none;
        }
      }
      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 89px;
        height: 89px;
        transform: translate(-50%, -50%);
        background: url("~@/assets/img/training-module3/theme-2/step-4/play-icon.svg")
        no-repeat;
        background-size: contain;
        cursor: pointer;
        z-index: 1;
        &.mob {
          display: none;
        }
      }
    }
    &__subtitle {
      margin-bottom: 16px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;
    }
    &__list {
      ul {
        padding-left: 0;
        margin: 0;
        list-style-position: inside;
        li {
          margin-bottom: 18px;
          font-family: $fontDemi;
          font-size: 16px;
          line-height: 20px;
          color: #545454;
          sup {
            font-family: $fontDemi;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &__next {
      &.next-button {
        margin: 64px auto 0;
      }
    }
  }
  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: background-position 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
      br {
        display: none;
      }
    }
    &__subtitle {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
      br {
        display: none;
      }
      p {
        b {
          &.bold-on-mobile {
            font-family: $fontDemi;
            font-weight: 700;
          }
        }
      }
    }
    &__image {
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
      }
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &__list {
      margin-bottom: 33px;
      ul {
        li {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    &__detail {
      text-align: center;
      p {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
        b {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .video-info {
      padding-top: 0;
      &__col {
        &-left,
        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &__title {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 22px;
      }
      &__video {
        margin-bottom: 33px;
        &-play {
          &.desc {
            display: none;
          }
          &.mob {
            display: block;
          }
        }
      }
      &__subtitle {
        font-size: 16px;
        line-height: 20px;
      }
      &__list {
        margin-bottom: 33px;
        ul {
          li {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      &__next {
        &.next-button {
          margin: auto;
        }
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
