<template>
  <div class="module-theory">
    <div class="module-theory__back" v-if="state > 1" @click="previousStep()">
      Назад
    </div>
    <div class="module-theory__state" v-show="state === 1">
      <div class="module-theory__title">
        Дізнаємося більше про Нувіджил®
      </div>
      <div class="module-theory__text">
        <p>
          Діюча речовина Нувіджил® — <b>армодафініл.<sup>5</sup></b>
        </p>
      </div>
      <div class="module-theory__image">
        <img src="@/assets/img/training-module4/theory-2/1.jpg" alt=""/>
      </div>
      <div class="module-theory__mechanism">
        <div class="module-theory__mechanism-row">
          <div class="module-theory__mechanism-col-left">
            <div class="module-theory__mechanism-img">
              <img src="@/assets/img/training-module4/theory-2/2.jpg" alt=""/>
            </div>
            <div class="module-theory__mechanism-text text-mobile">
              <b>
                Армодафініл пригнічує зворотнє захоплення дофаміну з синаптичної
                щілини<sup>5</sup>
              </b>
            </div>
          </div>
          <div class="module-theory__mechanism-col-right">
            <div class="module-theory__mechanism-title">
              Механізм дії
            </div>
            <div class="module-theory__mechanism-text">
              Точний механізм дії невідомий. Дія армодафінілу, що зумовлює
              підтримання стану бадьорості, подібна до дії симпатоміметиків
              (засоби, що стимулюють нервову систему).<sup>5</sup>
            </div>
            <div class="module-theory__mechanism-text text-desktop">
              <b>
                Армодафініл пригнічує зворотнє захоплення дофаміну з синаптичної
                щілини<sup>5</sup>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div class="module-theory__next next-button" @click="nextStep()">
        Далі
      </div>
    </div>
    <div class="module-theory__state" v-show="state === 2">
      <div class="module-theory__title">
        У яких випадках застосовують Нувіджил®?
      </div>
      <div class="module-theory__text small-margin">
        Показання до застосування:
      </div>
      <div class="module-theory__accordions">
        <div
            class="module-theory__accordion"
            :class="{ opened: accordion.isOpened }"
            v-for="(accordion, key) in accordions"
            :key="key"
            @click="toggleAccordion(key)"
        >
          <div class="module-theory__accordion-number">{{ accordion.id }}.</div>
          <div class="module-theory__accordion-title">
            {{ accordion.title }}
          </div>
          <div
              class="module-theory__accordion-text"
              v-html="accordion.text"
              v-show="accordion.isOpened"
          ></div>
        </div>
      </div>
      <div class="module-theory__product">
        <div class="module-theory__product-row">
          <div class="module-theory__product-col-left">
            <div class="module-theory__product-img product-img-desktop">
              <img src="@/assets/img/training-module4/theory-2/3.png" alt=""/>
            </div>
          </div>
          <div class="module-theory__product-col-right">
            <div class="module-theory__product-title">
              Нувіджил® має зручний спосіб застосування
            </div>
            <div class="module-theory__product-text">
              1 таблетка на добу приблизно за 1 годину до початку робочої
              зміни<sup>**5</sup> Нувіджил® можна приймати незалежно від прийому
              їжі.<sup>5</sup>
            </div>
            <div class="module-theory__product-img product-img-mobile">
              <img src="@/assets/img/training-module4/theory-2/3.png" alt=""/>
            </div>
            <div
                class="module-theory__product-next next-button"
                @click="nextStep()"
            >
              Далі
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-theory__state" v-show="state === 3">
      <div class="module-theory__title">
        Як зберігати та відпускати Нувіджил®?
      </div>
      <div class="module-theory__text">
        <p>Звернемося до чинного законодавства України:</p>
        <p>
          Відповідно до Постанови кабінету міністрів України № 770 «Про
          затвердження переліку наркотичних засобів, психотропних речовин і
          прекурсорів»:
        </p>
        <p>
          <b>• армодафініл не включений до жодного списку даної Постанови.</b>
          <br/>
          <b> • його обіг не передбачає заходів контролю.<sup>6</sup> </b>
        </p>
        <p>
          <b>
            Армодафініл не є наркотичною речовиною, психотропним засобом або
            прекурсором.
            <sup>6</sup>
          </b>
        </p>
        <p>
          Тому: <br/>
          • Зберігання Нувіджил® — на полиці. <br/>
          • Відпуск Нувіджил® не потребує спеціальних рецептурних бланків № 3
          (ф-3) для виписування наркотичних та психотропних лікарських
          засобів.<sup>7</sup>
        </p>
      </div>
      <div class="module-theory__product">
        <div class="module-theory__product-row">
          <div class="module-theory__product-col-left">
            <div class="module-theory__product-img product-img-desktop">
              <img src="@/assets/img/training-module4/theory-2/4.png" alt=""/>
            </div>
          </div>
          <div class="module-theory__product-col-right end">
            <!--            <div class="module-theory__product-title">-->
            <!--&lt;!&ndash;              Нувіджил® не має аналогів&ndash;&gt;-->
            <!--            </div>-->
            <!--            <div class="module-theory__product-title product-title-mobile">-->
            <!--&lt;!&ndash;              Єдиний в Україні препарат з діючою речовиною армодафініл станом на&ndash;&gt;-->
            <!--&lt;!&ndash;              сьогодні.<sup>5</sup>&ndash;&gt;-->
            <!--            </div>-->
            <div class="module-theory__product-img product-img-mobile">
              <img src="@/assets/img/training-module4/theory-2/4.png" alt=""/>
            </div>
            <div
                class="module-theory__product-next next-button"
                @click="nextState(3, 'next')"
            >
              Далі
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "Theory2",
  data() {
    return {
      state: 1,
      accordions: [
        {
          id: 1,
          isOpened: false,
          title: "Надмірна сонливість при позмінній роботі",
          text:
              "Для лікування надмірної сонливості, пов’язаної з помірним та тяжким хронічним порушенням сну (внаслідок розладу циклу «сон — бадьорість») при позмінній роботі, коли нефармакологічні втручання є неуспішними або недоцільними.<sup>5</sup>"
        },
        {
          id: 2,
          isOpened: false,
          title:
              "Надмірна сонливість при синдромі обструктивного апное сну / гіпопное сну",
          text:
              "Для підтримання стану бадьорості у пацієнтів з надмірною сонливістю при синдромі обструктивного апное сну / гіпопное сну (як доповнення до терапії методом постійного позитивного тиску в дихальних шляхах (CPAP-терапії)).<sup>5</sup>"
        },
        {
          id: 3,
          isOpened: false,
          title: "Підтримання стану бадьорості у пацієнтів з нарколепсією",
          text:
              "Для підтримання стану бадьорості  у пацієнтів з надмірною денною сонливістю, пов’язаною з нарколепсією.<sup>5</sup>"
        }
      ]
    };
  },
  methods: {
    toggleAccordion(key) {
      this.accordions[key].isOpened = !this.accordions[key].isOpened;
      gtag("event", "click", {
        event_category: `'Взаимодействие "Раскрывающийся список №${key + 1}"'`,
        event_label: 'М.4  - Раздел "Нувіджил" - Э.4'
      });
    },
    previousStep() {
      this.state -= 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextStep() {
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.4  - Раздел "Нувіджил" - Э.${this.state}'`
      });
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", {state, direction});
      gtag("event", "click", {
        event_category: 'Кнопка "Далі"',
        event_label: `'М.4  - Раздел "Нувіджил" - Э.${this.state}'`
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
  }

  &__text {
    max-width: 730px;
    margin-bottom: 64px;

    &.small-margin {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 15px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      b {
        font-family: $fontDemi;
      }

      &.text-green {
        color: #00a03b;
      }
    }
  }

  &__back {
    margin-bottom: 16px;
    padding-left: 22px;
    background-image: url("~@/assets/img/training-module4/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #9f9f9f;
    cursor: pointer;
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    margin-bottom: 48px;
    padding-right: 50px;
  }

  &__mechanism {
    margin-bottom: 64px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;
      }
    }

    &-img {
      img {
        display: block;
        max-width: 100%;
      }
    }

    &-title {
      margin-bottom: 24px;
      font-family: $fontDemi;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
    }

    &-text {
      margin-bottom: 24px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      &.text-mobile {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      b {
        font-family: $fontDemi;
      }
    }
  }

  &__accordions {
    margin-bottom: 64px;
  }

  &__accordion {
    position: relative;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 160px 0 48px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(46, 113, 138, 0.16);
    border-radius: 2px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 24px;
      right: 24px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #005a85;
    }

    &.opened {
      min-height: 186px;
      padding: 32px 160px 32px 48px;
      justify-content: unset;
      background: rgba($color: #e4eaed, $alpha: 0.5);
      box-shadow: none;

      &:after {
        transform: rotate(-180deg);
      }

      &:nth-child(2) {
        padding: 18px 160px 32px 48px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-number {
      position: absolute;
      top: 31px;
      left: 12px;
      width: 24px;
      height: 24px;
      background: #f3d074;
      border-radius: 50%;
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.05em;
      text-align: center;
      color: #ed804a;
    }

    &-title {
      font-family: $fontDemi;
      font-size: 22px;
      line-height: 27px;
      color: #005984;
    }

    &-text {
      padding-top: 16px;
      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }
  }

  &__product {
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    &-col {
      &-left {
        flex-basis: 55%;
        max-width: 55%;
        padding: 0 10px;
      }

      &-right {
        flex-basis: 45%;
        max-width: 45%;
        padding: 0 10px;

        &.end {
          display: flex;
          align-items: flex-end;
        }
      }
    }

    &-img {
      &.product-img-mobile {
        display: none;
      }

      img {
        display: block;
        max-width: 100%;
      }
    }

    &-title {
      margin-bottom: 32px;
      font-family: $fontDemi;
      font-size: 18px;
      line-height: 22px;
      color: #00a03b;

      &:last-child {
        margin-bottom: 0;
      }

      sup {
        font-family: $fontDemi;
      }
    }

    &-text {
      margin-bottom: 32px;

      font-family: $fontR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;
    }

    .module-theory & {
      &-next {
        margin-top: 32px;
        margin-left: 0;
      }
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    margin: 0 auto;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module4/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontDemi;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;


    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__back {
      font-size: 12px;
      line-height: 15px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }

    &__text {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 17px;

      p {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__image {
      padding-right: 0;

      img {
        width: 100%;
      }
    }

    &__mechanism {
      margin-bottom: 32px;

      &-row {
        flex-wrap: wrap-reverse;
      }

      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
      }

      &-text {
        font-size: 14px;
        line-height: 17px;

        &.text-desktop {
          display: none;
        }

        &.text-mobile {
          display: block;
        }

        b {
          font-family: $fontR;
          font-weight: 400;
        }

        &:last-child {
          margin-bottom: 24px;
        }
      }

      &-img {
        margin-bottom: 16px;

        img {
          width: 100%;
        }
      }
    }

    &__accordions {
      margin-bottom: 32px;
    }

    &__accordion {
      display: block;
      min-height: auto;
      padding: 16px 36px 24px 48px;

      &.opened {
        min-height: auto;

        &:nth-child(1n) {
          padding: 16px 36px 24px 48px;
        }
      }

      &-number {
        top: 16px;
        left: 12px;
      }

      &-title {
        font-size: 16px;
        line-height: 20px;
      }

      &-text {
        margin: 8px -36px 0 -48px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__product {
      &-row {
        flex-wrap: wrap-reverse;
      }

      &-col {
        &-left {
          flex-basis: 100%;
          max-width: 100%;
        }

        &-right {
          flex-basis: 100%;
          max-width: 100%;

          &.end {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }

      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;

        &.product-title-mobile {
          margin-bottom: 24px;
        }
      }

      &-text {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 17px;
      }

      &-img {
        margin-bottom: 32px;

        &.product-img-desktop {
          display: none;
        }

        &.product-img-mobile {
          display: block;
        }

        img {
          width: 100%;
        }
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: auto;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
