<template>
  <div class="comp-footer">
    <div class="footer-block">
      <div class="footer-content">
        <div class="col1">
          <p>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ТЕВА УКРАЇНА»</p>
          <p>Ідентифікаційний код юридичної особи: 34770471</p>
          <a
            href="https://www.teva.ua/general-pages/Terms-of-Use/"
            target="_blank"
            >Політика конфіденційності</a
          ><br>
          <a href="https://www.tevapharm.com/data_privacy/" target="_blank">
            Глобальна політика конфіденційності
          </a>
        </div>
        <div class="col2">
          <p>
            Україна, 02152, місто Київ, проспект Павла Тичини, 1-В, поверх 9, БЦ
            «Silver Breeze»
          </p>
          <p>
            Електронна адреса:
            <a href="mailto:Consent@teva.ua">Consent@teva.ua</a>
          </p>
          <p>Телефон: <a href="tel:+380445947080">+380 44 594 70 80</a></p>
          <p>
            Центр підтримки клієнтів
            <a href="tel:0800502284">0800502284</a> (безкоштовно з усіх мереж
            зв'язку України)
          </p>
        </div>
        <div class="col3">
          <a href="#">
            <svg
              width="90"
              height="29"
              viewBox="0 0 90 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M83.6532 6.5182V7.69619C81.714 6.2826 79.334 5.57581 76.7777 5.57581C70.1665 5.57581 64.9658 10.5234 64.9658 16.8845C64.9658 19.7117 66.0235 22.3818 68.051 24.4236C70.2547 26.7011 73.6043 28.1147 77.1303 28.1147C79.5984 28.1147 81.8021 27.4079 83.7414 25.9943V27.1723C83.7414 27.4079 84.0058 27.6435 84.2703 27.6435H89.471C89.7355 27.6435 89.9999 27.4079 89.9999 27.1723V6.5182C89.9999 6.2826 89.7355 6.047 89.471 6.047H84.2703C83.9177 6.047 83.6532 6.20407 83.6532 6.5182ZM84.1821 16.8845C84.1821 19.7902 81.714 22.6959 77.8354 22.6959C74.1332 22.6959 71.4888 20.1829 71.4888 16.8845C71.4888 13.1935 74.4858 11.0731 77.7473 11.0731C81.714 10.9946 84.1821 13.9003 84.1821 16.8845Z"
                fill="#005A85"
              />
              <path
                d="M14.8972 22.853C13.3986 22.853 12.429 22.4603 11.7238 21.675C11.1949 21.1253 10.9305 20.2614 10.9305 19.0049V10.9946H17.6298C17.8942 10.9946 18.1587 10.759 18.1587 10.5234V6.43968C18.1587 6.20408 17.8942 5.96848 17.6298 5.96848H10.9305V0.471196C10.9305 0.235598 10.666 0 10.4016 0H4.93634C4.58374 0 4.40744 0.235598 4.40744 0.471196V5.96848H0.528893C0.264447 5.96848 0 6.20408 0 6.43968V10.5234C0 10.759 0.264447 10.9946 0.528893 10.9946H4.40744V18.4552C4.40744 21.5179 4.93634 23.4027 6.17042 24.8948C7.84525 26.9367 10.7542 28.0361 14.4564 28.0361C17.3653 28.0361 19.2165 27.4079 20.1861 26.7011C20.4505 26.4655 20.5387 26.0728 20.3624 25.8372C20.1861 25.6802 17.9824 22.4603 17.9824 22.4603C17.8061 22.1462 17.3653 22.0677 17.0127 22.3033C16.4838 22.5389 15.6023 22.853 14.8972 22.853Z"
                fill="#005A85"
              />
              <path
                d="M30.6758 5.57581C27.3262 5.57581 24.3291 6.7538 22.1254 8.95271C20.098 10.9946 18.952 13.8217 18.952 16.806C18.952 23.4027 24.1528 28.0361 31.4692 28.0361C34.7307 28.0361 37.904 26.9367 40.1959 25.209C40.2841 25.1304 40.3722 25.0519 40.5485 24.8948C40.7248 24.7378 40.7248 24.4236 40.6367 24.2666C40.1078 23.5598 38.7855 21.9891 38.0803 21.2038C37.904 20.9682 37.5514 20.9682 37.287 21.1253C37.1107 21.2823 36.9344 21.3609 36.7581 21.5179C35.7003 22.3033 33.9373 23.2457 31.6455 23.2457C28.2077 23.2457 25.6513 21.2038 25.2987 18.2196H41.1656C41.5182 18.2196 41.8707 17.984 41.9589 17.5913C41.9589 17.2772 42.047 16.963 42.047 16.4918C42.047 13.5076 40.9011 10.759 38.8737 8.71711C36.7581 6.67526 33.8492 5.57581 30.6758 5.57581ZM30.6758 10.2878C32.7033 10.2878 35.0833 11.7014 35.3477 13.9788H25.6513C26.0039 12.1725 27.9432 10.2878 30.6758 10.2878Z"
                fill="#005A85"
              />
              <path
                d="M53.859 22.5388V19.4761C53.859 18.2195 54.1235 16.806 54.6523 15.3924C55.2694 13.7432 56.1509 12.4081 57.1205 11.3872L58.619 9.65948V16.8845L53.859 22.5388Z"
                fill="#00A070"
              />
              <path
                d="M58.7072 9.58097L61.8806 5.88994C62.145 5.57581 62.5858 5.49728 62.9384 5.57581C63.2909 5.73288 63.5554 6.04701 63.5554 6.43967V10.9946L58.7072 16.7274V9.58097Z"
                fill="#B5D16C"
              />
              <path
                d="M63.5554 11.0731V13.6647C63.6436 14.9998 63.3791 16.5704 62.7621 18.1411C62.2332 19.5546 61.528 20.8112 60.6465 21.8321L58.7072 24.1095V16.8845L63.5554 11.0731Z"
                fill="#317575"
              />
              <path
                d="M58.7072 16.806V24.031L55.5338 27.722C55.2694 28.0361 54.8286 28.1147 54.4761 27.9576C54.1235 27.8005 53.859 27.4864 53.859 27.0937V22.4603L58.7072 16.806Z"
                fill="#005A85"
              />
              <path
                d="M53.2418 22.5388V19.4761C53.2418 18.2195 52.9774 16.806 52.4485 15.3924C51.8315 13.7432 50.95 12.4081 49.9803 11.3872L48.4818 9.65948V16.8845L53.2418 22.5388Z"
                fill="#00A070"
              />
              <path
                d="M48.3936 9.58097L45.2202 5.88994C44.9558 5.57581 44.515 5.49728 44.1625 5.57581C43.8099 5.73288 43.5454 6.04701 43.5454 6.43967V10.9946L48.3936 16.7274V9.58097Z"
                fill="#B5D16C"
              />
              <path
                d="M43.5456 11.0731V13.6647C43.4574 14.9998 43.7219 16.5704 44.3389 18.1411C44.8678 19.5546 45.573 20.8112 46.4545 21.8321L48.3938 24.1095V16.8845L43.5456 11.0731Z"
                fill="#317575"
              />
              <path
                d="M48.3939 16.806V24.031L51.5672 27.722C51.8317 28.0361 52.2724 28.1147 52.625 27.9576C52.9776 27.8005 53.242 27.4864 53.242 27.0937V22.4603L48.3939 16.806Z"
                fill="#005A85"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-block",
  data: () => ({}),
  methods: {}
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1108px;

.comp-footer {
  margin: auto 0 0 0;

  width: 100%;
  min-height: 94px;

  * {
    // user-select: none;
  }
}

.footer-block {
  margin: 10px 0 0 0;
  position: relative;
  z-index: 5;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 49px rgba(0, 0, 0, 0.09);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .footer-content {
    max-width: $maxW;
    width: 100%;
    margin: 0 auto;
    min-height: 94px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1128px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 768px) {
      padding: 30px 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .col1,
    .col2 {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        order: 2;
        max-width: 480px;
        width: 100%;
      }

      &.col2 {
        margin: auto 0;

        p {
          line-height: 1.6em;
        }
      }

      p {
        font-family: $fontL;
        font-size: 10px;
        color: black;
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }

      a {
        color: #005a85;
        font-family: $fontDemi;
        font-size: 10px;
        @media screen and (max-width: 768px) {
          text-align: center;
          margin: 0 auto;
          display: block;
        }
      }
    }

    .col3 {
      @media screen and (max-width: 768px) {
        display: flex;
        order: 1;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
