<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Близько 60% призначень нестероїдних протизапальних препаратів (НПЗП) при
      болю у спині (дорсопатії) — це в/м ін’єкції<sup>4</sup>
    </div>
    <div class="module-theory__subtitle">
      Клієнт хоче швидке та ефективне лікування, проте має один страх. Хочете
      дізнатися який?
    </div>
    <div class="game" :class="{ disabled: isGameOver }">
      <div class="game__message" v-show="!isActiveGame">
        Запропонуйте пацієнту ін’єкційний засіб
      </div>
      <div class="game__men">
        <Men />
      </div>
      <div
        class="game__syringe"
        :class="{ active: isActiveGame }"
        @mousedown="mouseDownHandler($event)"
        @mouseup="mouseUpHandler($event)"
        @mousemove.prevent="mouseMoveHandler($event)"
      >
        <img
          src="@/assets/img/training-module3/theme-1/step-2/syringe.svg"
          alt=""
        />
      </div>
      <div class="game__timer" :class="{ active: isActiveGame }">
        <Timer ref="timer" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Men from "@/assets/img/training-module3/theme-1/step-2/game-men.svg?inline";
import Timer from "@/assets/img/training-module3/theme-1/step-2/timer.svg?inline";
export default {
  name: "Theory2",
  data() {
    return {
      state: 1,
      isActiveGame: false,
      isGameOver: false,
      timerElement: null,
      timerValue: 5,
      syringe: {
        element: null,
        isDown: false,
        offset: [0, 0],
        mousePosition: {}
      },
      menElement: null
    };
  },
  components: {
    Men,
    Timer
  },
  watch: {
    isActiveGame() {
      this.countDown();
    }
  },
  methods: {
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    countDown() {
      var timerInterval = setInterval(() => {
        this.timerValue -= 1;
        this.timerElement.querySelector(
          ".timer-value-1"
        ).textContent = this.timerValue;
        this.timerElement.querySelector(
          ".timer-value-2"
        ).textContent = this.timerValue;
      }, 1000);
      setTimeout(() => {
        clearInterval(timerInterval);
        this.isGameOver = true;
        this.nextState(3, "next");
      }, 5000);
    },
    mouseDownHandler(event) {
      this.isActiveGame = true;
      this.syringe.isDown = true;

      var coordX = event.touches ? event.touches[0].pageX : event.pageX;
      var coordY = event.touches ? event.touches[0].pageY : event.pageY;
      this.syringe.offset = [
        this.syringe.element.offsetLeft - coordX,
        this.syringe.element.offsetTop - coordY
      ];
      gtag("event", "click", {
        event_category: "Взаимодействие пользователя со шприцом",
        event_label: 'М.3 - Т.1  - Раздел "НПЗП для лікування болю у спині" '
      });
    },

    mouseUpHandler() {
      this.syringe.isDown = false;
      this.syringe.element.removeAttribute("style");
      this.menElement.classList.remove("active");
    },
    mouseMoveHandler(event) {
      var coordX = event.touches ? event.touches[0].pageX : event.pageX;
      var coordY = event.touches ? event.touches[0].pageY : event.pageY;
      if (this.syringe.isDown) {
        this.syringe.mousePosition = {
          x: coordX,
          y: coordY
        };
        this.syringe.element.style.left =
          this.syringe.mousePosition.x + this.syringe.offset[0] + "px";
        this.syringe.element.style.top =
          this.syringe.mousePosition.y + this.syringe.offset[1] + "px";
        if (
          this.syringe.element.getBoundingClientRect().left <=
          this.menElement.getBoundingClientRect().right - 5
        ) {
          this.menElement.classList.add("active");
          this.syringe.isDown = false;
        } else {
          this.menElement.classList.remove("active");
        }
      }
    },
    documentMouseUpHandler(event) {
      this.mouseUpHandler(event);
    },
    touchStartHandler(event) {
      this.mouseDownHandler(event);
    },
    touchEndHandler(event) {
      this.mouseUpHandler(event);
    },
    touchMoveHandler(event) {
      this.mouseMoveHandler(event);
    }
  },
  mounted() {
    this.timerElement = this.$refs.timer;
    this.syringe.element = document.querySelector(".game__syringe");
    this.menElement = document.querySelector(".game__men .men-border");

    // Handle mouse up event on document and return game to default
    window.addEventListener("mouseup", this.documentMouseUpHandler);

    // Handle events on mobile devices
    this.syringe.element.addEventListener("touchstart", this.touchStartHandler);
    this.syringe.element.addEventListener("touchend", this.touchEndHandler);
    this.syringe.element.addEventListener("touchmove", this.touchMoveHandler);
  },
  destroyed() {
    window.removeEventListener("mouseup", this.documentMouseUpHandler);
    this.syringe.element.removeEventListener(
      "touchstart",
      this.touchStartHandler
    );
    this.syringe.element.removeEventListener("touchend", this.touchEndHandler);
    this.syringe.element.removeEventListener(
      "touchmove",
      this.touchMoveHandler
    );
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

@keyframes circletimer {
  0% {
    stroke-dashoffset: 270.176968209;
    stroke-dasharray: 270.176968209;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 270.176968209;
  }
}

.module-theory {
  &__title {
    margin-bottom: 24px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }
  &__subtitle {
    margin-bottom: 48px;
    font-family: $fontR;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
  }

  .game {
    position: relative;
    height: 386px;
    max-width: 638px;
    margin: auto;
    background-image: url("~@/assets/img/training-module3/theme-1/step-2/game-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    touch-action: pan-x;
    overflow: hidden;
    &.disabled {
      pointer-events: none;
    }
    &__message {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 330px;
      padding: 10px 8px 14px;
      background: #445094;
      box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      z-index: 1;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        width: 20px;
        border: 1px solid #ffffff;
      }
      &:before {
        left: -1px;
        border-right: none;
      }
      &:after {
        right: -1px;
        border-left: none;
      }
    }
    &__men {
      position: absolute;
      bottom: -53px;
      left: 85px;
      svg {
        display: block;
        width: 251px;
        height: 421px;
      }
      .men-border {
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
    }
    &__syringe {
      position: absolute;
      top: 72px;
      right: 45px;
      z-index: 1;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 95px;
        width: 119px;
        height: 123px;
        background-image: url("~@/assets/img/training-module3/theme-1/step-2/cursor.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
      &.active {
        &:before {
          display: none;
        }
      }
      img {
        pointer-events: none;
      }
    }
    &__timer {
      position: absolute;
      bottom: 17px;
      right: 18px;
      svg {
        display: block;
        width: 90px;
        height: 90px;
      }
      .timer-circle {
        transform: rotate(-90deg);
        transform-origin: center;
      }
      &.active {
        .timer-circle {
          animation: 5s circletimer linear;
        }
      }
      .timer-value {
        font-family: $fontB;
        font-size: 46px;
        white-space: pre;
        letter-spacing: -0.11em;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 22px;
    }
    &__subtitle {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: 17px;
    }
    .game {
      background-image: url("~@/assets/img/training-module3/theme-1/step-2/game-bg-mobile.svg");

      &__men {
        bottom: -50px;
        left: 12px;
        svg {
          width: 200px;
          height: 380px;
        }
      }
      &__syringe {
        top: 95px;
        right: 15px;
        width: 180px;
        &:before {
          bottom: -30px;
          left: 65px;
        }
        img {
          display: block;
          max-width: 100%;
        }
      }
      &__timer {
        bottom: 14px;
        right: 14px;
        svg {
          width: 86px;
          height: 86px;
        }
      }
    }
  }
}
@media screen and (max-width: 638px) {
  .module-theory {
    .game {
      margin: 0 -15px;
    }
  }
}
</style>
