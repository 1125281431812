import { render, staticRenderFns } from "./section-reg-text.vue?vue&type=template&id=79e19f81&"
import script from "./section-reg-text.vue?vue&type=script&lang=js&"
export * from "./section-reg-text.vue?vue&type=script&lang=js&"
import style0 from "./section-reg-text.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports