<template>
  <div class="module-theory">
    <button
      class="module-theory__back-btn"
      @click="nextState(3, '')"
    >
      <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      Назад
    </button>
    <div class="module-theory__title">
      Сумамед® — це оригінальний препарат азитроміцину, який має захист від підробок.
    </div>
    <div class="module-theory__green-text">
      <strong>Таблетки, що диспергуються</strong>
    </div>
    <div class="module-theory__text">
      <strong>Сумамед® 500 №3<br>
      Таблетки, що диспергуються</strong>
    </div>

    <div class="module-theory__image-block">
      <img
        src="@/assets/img/training-module5/theme-2/step-4/pack.png"
        alt=""
        class="module-theory__img"
      >
      <p class="module-theory__image-text">
        Дві прозорі <strong>наліпки що захищають <br>клапани</strong> від несанкціонованого відкриття
      </p>
      <p class="module-theory__image-text">
        <strong>Металізований колір</strong>, формула<br> якого тримається в секреті
      </p>
      <p class="module-theory__image-text">
        <strong>Серія та номер</strong> партії нанесені на білому<br> бічному клапані
      </p>
    </div>

    <div class="module-theory__next next-button" @click="nextState(5, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
export default {
  name: "Theory4",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: state < 4 ? 'Кнопка "Назад"' : 'Кнопка "Далі"',
        event_label: `'М.5 - Т.2 - Раздел "Упаковка Сумамед®" - Э.2'`
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__green-text {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #00A03B;
  }

  &__image-block {
    position: relative;
    margin: 24px auto 0;
    padding: 40px 0 0;
    max-width: 490px;
  }

  &__img {
    width: 100%;
    max-width: 100%;
  }

  &__image-text {
    position: absolute;
    font-size: 14px;
    line-height: 17px;
    color: #212661;

    &:nth-of-type(1) {
      top: 0;
      left: 0;
    }

    &:nth-of-type(2) {
      bottom: 40px;
      right: 0;
    }
    
    &:nth-of-type(3) {
      bottom: 0;
      left: 0;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

   &__image-block {
     margin-top: 16px;
      padding: 40px 0 20px;
      max-width: 490px;
    }

    &__image-text {
      font-size: 12px;
      line-height: 15px;
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>