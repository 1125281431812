<template>
  <div class="module-theory">
    <div class="module-theory__title">
      Як, на Вашу думку, об’єм ін’єкційного препарату впливає на рівень стресу у
      клієнта?
    </div>

    <p class="module-theory__text">
      Перемістіть повзунок вгору, щоб обрати рівень стресу
    </p>

    <div class="interactive-ampules">
      <div class="interactive-ampules__list">
        <div class="interactive-ampules__item">
          <div class="interactive-ampules__value">3 мл</div>
          <div class="interactive-ampules__elements">
            <div class="interactive-ampules__pips">
              <div class="interactive-ampules__pips-item max">max</div>
              <div class="interactive-ampules__pips-item min">min</div>
            </div>
            <div class="interactive-ampules__range">
              <veeno
                rtl
                vertical
                :handles="1"
                :step="1"
                :pipsy="{
                  mode: 'steps',
                  density: 20
                }"
                :range="{
                  min: 1,
                  max: 5
                }"
                v-model="ampule1"
              />
            </div>
            <div
              class="interactive-ampules__image interactive-ampules__image--3ml"
            >
              <img
                :src="
                  require(`@/assets/img/training-module3/theme-2/step-2/ampule-${getAmpule1}.svg`)
                "
                alt=""
              />
            </div>
          </div>
          <div class="interactive-ampules__name">Стрес</div>
        </div>
        <div class="interactive-ampules__item">
          <div class="interactive-ampules__value">2 мл</div>
          <div class="interactive-ampules__elements">
            <div class="interactive-ampules__pips">
              <div class="interactive-ampules__pips-item max">max</div>
              <div class="interactive-ampules__pips-item min">min</div>
            </div>
            <div class="interactive-ampules__range">
              <veeno
                rtl
                vertical
                :handles="1"
                :step="1"
                :pipsy="{
                  mode: 'steps',
                  density: 20
                }"
                :range="{
                  min: 1,
                  max: 5
                }"
                v-model="ampule2"
              />
            </div>
            <div
              class="interactive-ampules__image interactive-ampules__image--2ml"
            >
              <img
                :src="
                  require(`@/assets/img/training-module3/theme-2/step-2/ampule-${getAmpule2}.svg`)
                "
                alt=""
              />
            </div>
          </div>
          <div class="interactive-ampules__name">Стрес</div>
        </div>
      </div>
      <div class="interactive-ampules__message" v-show="isAmpuseSelected">
        Ого, скільки стресу! <br />
        Проте ми знаємо рішення
      </div>
    </div>
    <div
      class="module-theory__next next-button"
      :class="{ disabled: !isAmpuseSelected }"
      @click="nextState(3, 'next')"
    >
      Далі
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import veeno from "veeno";

export default {
  name: "Theory2",
  data() {
    return {
      state: 1,
      isAmpuseSelected: false,
      ampule1: 1,
      ampule2: 1
    };
  },
  watch: {
    ampule1(value) {
      if (value > 1) {
        this.isAmpuseSelected = true;
      } else {
        this.isAmpuseSelected = false;
      }
    },
    ampule2(value) {
      if (value > 1) {
        this.isAmpuseSelected = true;
      } else {
        this.isAmpuseSelected = false;
      }
    }
  },
  components: {
    veeno
  },
  computed: {
    getAmpule1() {
      return Math.floor(this.ampule1);
    },
    getAmpule2() {
      return Math.floor(this.ampule2);
    }
  },
  methods: {
    changeState() {
      this.state += 1;
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 29px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__text {
    margin-bottom: 18px;
    font-family: $fontR;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    color: #545454;
  }

  .interactive-ampules {
    position: relative;
    width: 100%;
    max-width: 638px;
    height: 388px;
    margin: auto;
    padding-top: 24px;
    background: url("~@/assets/img/training-module3/theme-2/step-2/game-bg.svg")
      no-repeat;
    background-size: cover;
    &__list {
      display: flex;
      flex-wrap: wrap;
      max-width: 340px;
      height: 100%;
      margin: auto;
      padding-left: 20px;
    }
    &__item {
      position: relative;
      margin-right: 60px;
      height: 290px;
      width: 130px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__value {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 6px;
      font-family: $fontDemi;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #005984;
    }
    &__elements {
      display: flex;
      flex-wrap: wrap;
      margin-top: -8px;
    }
    &__pips {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 5px;
      padding-top: 7px;
      &-item {
        width: 32px;
        height: 15px;
        background: #ffffff;
        box-shadow: 1px 3px 4px rgba(108, 135, 149, 0.5);
        border-radius: 57px;
        font-family: $fontDemi;
        font-size: 10.191px;
        line-height: 15px;
        text-align: center;
        color: #507285;
      }
    }
    &__range {
      height: 233px;
      ::v-deep {
        .noUi {
          &-value {
            display: none;
          }
          &-handle {
            left: -6px;
            top: -20px;
            position: relative;
            background: transparent;
            box-shadow: none;
            border: none;
            cursor: pointer;
            &:focus {
              outline: none;
            }
            &:before {
              display: block;
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              right: 0;
              bottom: 0;
              width: 89px;
              height: 89px;
              background: url("~@/assets/img/training-module3/theme-2/step-2/range-slider-circle.svg")
                no-repeat;
              background-size: contain;
              transform: translate(-50%, -50%);
            }
            &:after {
              display: none;
            }
          }
          &-vertical {
            width: 30px;
            padding: 20px 0 5px;
            .noUi {
              &-handle {
                width: 32px;
                height: 32px;
              }
            }
          }
          &-target {
            position: relative;
            background: url("~@/assets/img/training-module3/theme-2/step-2/range-slider-bg.svg")
              no-repeat;
            border: none;
            box-shadow: none;
          }

          &-pips-vertical {
            top: 18px;
            left: 0px;
            width: 16px;
            height: 89%;
            padding: 0;
          }
          &-marker-vertical {
            &.noUi {
              &-marker {
                &-large,
                &-sub {
                  left: 7px;
                  width: 4px;
                  height: 4px;
                  background: #507285;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
    &__image {
      position: absolute;
      &--3ml {
        left: 45px;
        bottom: -8px;
        img {
          height: 258px;
        }
      }
      &--2ml {
        left: 55px;
        bottom: -3px;
        img {
          height: 220px;
        }
      }
    }
    &__name {
      padding-left: 25px;
      padding-top: 9px;
      font-family: $fontDemi;
      font-size: 12px;
      line-height: 15px;
      color: #e84080;
    }
    &__message {
      position: absolute;
      left: 50%;
      bottom: 12px;
      width: 100%;
      max-width: 330px;
      transform: translateX(-50%);
      background: #445094;
      padding: 6px 15px 10px;
      box-shadow: 0px 4px 20px rgba(68, 80, 148, 0.3);
      font-family: $fontDemi;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        bottom: -1px;
        width: 20px;
        border: 1px solid #ffffff;
      }
      &:before {
        left: -1px;
        border-right: none;
      }
      &:after {
        right: -1px;
        border-left: none;
      }
    }
  }
  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module3/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
    &.disabled {
      opacity: 0.16;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
    }
    &__ text {
      margin-bottom: 11px;
      font-size: 14px;
      line-height: 17px;
    }
    .interactive-ampules {
      width: auto;
      max-width: unset;
      margin: 0 -15px;
      padding-top: 15px;
      &__list {
        max-width: 320px;
        padding-left: 0;
      }
      &__item {
        margin-right: 55px;
      }
      &__message {
        left: 15px;
        right: 15px;
        width: auto;
        max-width: unset;
        transform: unset;
      }
    }
    .next-button {
      width: 266px;
      height: 44px;
      margin: 35px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
@media screen and (max-width: 359px) {
  .module-theory {
    .interactive-ampules {
      &__list {
        max-width: 270px;
      }
      &__item {
        margin-right: 10px;
      }
    }
  }
}
</style>
