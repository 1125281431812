<template>
  <div class="portret">
    <div class="portret__left">
      <p class="portret__text">З початком карантину багато людей почали працювати віддалено. Робітники стали більше часу проводити на одному місці, <span>годинами не змінюючи положення тіла.<sup>1</sup></span></p>
      <div class="portret__text-wrapper">
        <div class="portret__title">
          Щось шия затерпла<br> та плечі болять.<br>
          <span>Що порадите?​</span>
        </div>
      </div>
      <img class="portret__img neck" src="@/assets/img/training-module1/theme-2/portret-img.png" alt="">
    </div>
    <div class="portret__right">
      <p class="portret__text">З початком карантину багато людей почали працювати віддалено. Робітники стали більше часу проводити на одному місці, <span>годинами не змінюючи положення тіла.<sup>1</sup></span></p>
      <p class="portret__text">Саме тому у цей період варто очікувати <span>збільшення кількості клієнтів з невралгією</span> шийного-плечового відділу.<sup>1</sup></p>
      <div class="portret__link" @click="nextState(2, 'next')">Далі</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portret",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_1_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.portret {
  display: grid;
  grid-template-columns: 1.16fr 1fr;
  max-height: 380px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    max-height: unset;
  }
  &__left {
    display: flex;
    flex-direction: column;
    position: relative;
    & > .portret__text {
      display: none;
      @media(max-width: 768px) {
        display: block;
      }
    }
  }
  &__text-wrapper {
    background-image: url('~@/assets/img/training-module1/theme-2/Bubble-left.svg');
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 328px;
    height: 160px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      height: 200px;
      max-width: unset;
      margin-top: 28px;
    }
    @media (max-width: 580px) {
      height: 160px;
      max-width: unset;
      margin-top: 28px;
    }
    @media (max-width: 430px) {
      max-width: 234px;
      height: 113px;
    }
  }
  &__right {
    padding-left: 32px;
    padding-top: 28px;
    & .portret__text {
      &:nth-child(1) {
        @media(max-width: 768px) {
          display: none;
        }
      }
    }
    @media (max-width: 580px) {
      padding-left: 0px;
      padding-top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__img {
    position: relative;
    bottom: 9%;
    &.neck {
      align-self: end;
      @media(max-width: 768px) {
        width: 80%;
      }
      @media (max-width: 580px) {
        min-width: 260px;
      }
    }
  }
  &__title {
    font-weight: 600;
    font-size: 26px;
    color: #FFFFFF;
    margin: 0 28px;
    line-height: 110%;
    letter-spacing: -0.01em;
    font-weight: 600;
    & span {
      font-size: 34px;
      line-height: 140%;
      font-weight: 600;
      @media (max-width: 430px) {
        font-size: 24px;
      }
    }
    @media (max-width: 430px) {
      margin: 0 19px;
      font-size: 18px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 22px;
    color: #545454;
    span {
      color: #00A03B;
      font-weight: 600;
    }
    
    &:nth-child(2) {
      margin-top: 16px;
      margin-bottom: 26px;
      @media (max-width: 430px) {
        margin-top: 0px;
        margin-bottom: 32px;
      }
    }
    @media (max-width: 430px) {
      font-size: 14px;
    }
  }
  &__link {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: 600;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    margin-top: 32px;
    &:hover {
      background-position: 110% center;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
      margin-top: 0;
    }
  }
}
</style>
