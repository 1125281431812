import Vue from "vue";
import Vuex from "vuex";

import Article from "./article";
import Spec from "./spec";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    scroll: {
      behavior: null,
      value: 0,
    },
    pagePopupId: null,
  },
  getters: {
    getScrollBehavior(state) {
      return state.scroll.behavior;
    },
    getScrollValue(state) {
      return state.scroll.value;
    },
    getPagePopup(state) {
      return state.popupId;
    },
  },
  mutations: {
    setScrollBehavior(state, val) {
      state.scroll.behavior = val;
    },
    setScrollValue(state, val) {
      state.scroll.value = val;
    },
    setPagePopup(state, val) {
      state.popupId = val;
    },
  },
  actions: {
    setScrollBehavior(context, val) {
      context.commit("setScrollBehavior", val);
    },
    setScrollValue(context, val) {
      context.commit("setScrollValue", val);
    },
    setPagePopup(context, val) {
      context.commit("setPagePopup", val);
    },
  },
  modules: {
    Article,
    Spec
  },
});
