<template>
  <div class="module-theory">
    <button
      class="module-theory__back-btn"
      @click="nextState(2, '')"
    >
      <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      Назад
    </button>
    <div class="module-theory__title">
      «Дитячі» форми випуску:
    </div>
    <div class="module-theory__text">
      <strong>Порошок для приготування суспензій Сумамед® та Сумамед® Форте</strong>
    </div>
    <div class="module-theory__text">
      Існує 4 дозування, в залежності від маси тіла дитини, та вибір з 2-х можливих смаків 
      для кожного дозування<sup>5</sup>
    </div>
    <div class="module-theory__tables">
      <div class="module-theory__container">
        <img
          src="@/assets/img/training-module5/theme-1/step-3/packs-1.png"
          alt=""
          class="module-theory__packs-img desk"
        >
        <img
          src="@/assets/img/training-module5/theme-1/step-3/packs-1-mob.png"
          alt=""
          class="module-theory__packs-img mob"
        >
        <table class="module-theory__table">
          <thead class="module-theory__head">
            <th>
              <td>Сумамед®</td>
            </th>
          </thead>
          <tbody class="module-theory__body">
            <tr class="module-theory__row row-top">
              <td class="module-theory__col">
                100 мг/5мл, 20мл
              </td>
            </tr>
            <tr class="module-theory__row row-bottom">
              <td class="module-theory__col">
                <img
                  src="@/assets/img/training-module5/theme-1/step-3/taste-icon-cherry.svg"
                  alt=""
                  class="module-theory__col-img"
                >
                Вишня-банан
              </td>
              <td class="module-theory__col">
                <img
                  src="@/assets/img/training-module5/theme-1/step-3/taste-icon-strawberry.svg"
                  alt=""
                  class="module-theory__col-img"
                >
                Полуниця
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="module-theory__container">
        <img
          src="@/assets/img/training-module5/theme-1/step-3/packs-2.png"
          alt=""
          class="module-theory__packs-img desk"
        >
        <img
          src="@/assets/img/training-module5/theme-1/step-3/packs-2-mob.png"
          alt=""
          class="module-theory__packs-img mob"
        >
        <table class="module-theory__table">
          <thead class="module-theory__head">
            <th>
              <td>
                Сумамед® Форте
              </td>
            </th>
          </thead>
          <tbody class="module-theory__body">
            <tr class="module-theory__row row-top">
              <td class="module-theory__col">
                200 мг/5 мл, 15 мл 
              </td>
              <td class="module-theory__col">
                200 мг/5 мл, 30 мл 
              </td>
              <td class="module-theory__col">
                200 мг/5 мл, 37,5 мл 
              </td>
            </tr>
            <tr class="module-theory__row row-bottom">
              <td class="module-theory__col">
                <p>
                  <img
                    src="@/assets/img/training-module5/theme-1/step-3/taste-icon-cherry.svg"
                    alt=""
                    class="module-theory__col-img"
                  >
                  Вишня-банан
                </p>
                <p>
                  <img
                    src="@/assets/img/training-module5/theme-1/step-3/taste-icon-banana.svg"
                    alt=""
                    class="module-theory__col-img"
                  >
                  Банан
                </p>
              </td>
              <td class="module-theory__col">
                <p>
                  <img
                    src="@/assets/img/training-module5/theme-1/step-3/taste-icon-cherry.svg"
                    alt=""
                    class="module-theory__col-img"
                  >
                  Вишня-банан
                </p>
                <p>
                  <img
                    src="@/assets/img/training-module5/theme-1/step-3/taste-icon-strawberry.svg"
                    alt=""
                    class="module-theory__col-img"
                  >
                  Полуниця
                </p>
              </td>
              <td class="module-theory__col">
                <p>
                  <img
                    src="@/assets/img/training-module5/theme-1/step-3/taste-icon-cherry.svg"
                    alt=""
                    class="module-theory__col-img"
                  >
                  Вишня-банан
                </p>
                <p>
                  <img
                    src="@/assets/img/training-module5/theme-1/step-3/taste-icon-raspberry.svg"
                    alt=""
                    class="module-theory__col-img"
                  >
                  Малина
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="module-theory__next next-button" @click="nextState(4, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
export default {
  name: "Theory3",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: state < 3 ? 'Кнопка "Назад"' : 'Кнопка "Далі"',
        event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.2'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__text {
    margin-bottom: 8px;
  }

  &__tables {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-top: 35px;
  }

  &__container {

    &:first-of-type {
      width: 40%;

      .module-theory__row {
        justify-content: center;
      }

      .module-theory__col {
        align-items: center;
        text-align: center;

        &-img {
          margin-bottom: 8px;
        }
      }
    }

    &:last-of-type {
      width: 59%;

      .module-theory__col {
        p {
          display: flex;
          align-items: center;
          padding: 3px 0;
        }

        &-img {
          margin-right: 5px;
        }
      }
    }
  }

  &__packs-img {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    &.mob {
      display: none;
    }
  }

  &__table {
    width: 100%;
    background: #F5F5F5;
    box-shadow: 0px 4px 10px rgba(68, 80, 148, 0.2);
    border-radius: 0 0 12px 12px;
    border-collapse: collapse;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    background: #212661;
    border-radius: 12px 12px 0px 0px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
    color: #212661;
    border-top: 1px solid #212661;

    &.row-top {
      height: 48px
    }

    &.row-bottom {
      height: 88px
    }

    .module-theory__col:last-of-type {
      border: none;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 15px;
    border-right: 1px solid #212661;

    &-img {
      max-width: 32px;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__tables {
      flex-direction: column;
      align-items: stretch;
      margin-top: 24px;
    }

    &__container {

      &:first-of-type {
        width: 100%;
        margin-bottom: 24px;
      }

      &:last-of-type {
        width: 100%;
      }
    }

    &__packs-img {
      min-width: 100%;

      &.desk {
        display: none;
      }

      &.mob {
        display: block;
      }
    }

    &__col {
      padding: 0 5px;

      &-img {
        max-width: 24px;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
