<template>
  <div class="game">
    <div class="game__text text">
      Біль у шийному відділі хребта є поширеним явищем серед населення. Кожна п’ята людина у світі відчуває його прямо у цей момент.<sup>2</sup>
      Як ви вважаєте, у якого відсотка пацієнтів <span>гострий біль у шиї переходить у хронічний?</span>
    </div>
    <div class="game__range">
      <div class="game__range-slider">
        <veeno
          id="slider"
          :step="1"
          tooltips
          :handles="1"
          :getset="changeSliderHandler"
          :connect="[true, false]"
          :pipsy="{ mode: 'steps', density: 3 }"
          :range="{ min: 1, max: 5 }"
          v-model="slider"
          @change="changeSliderHandler"
          @slide="changeSliderHandler"
        />
      </div>
      <div v-if="slider === '1.00'" class="game__start">Потрібно рухати, щоб вибрати %</div>
      <div class="game__range-labels">
        <div class="game__range-label">0<span>%</span></div>
        <div class="game__range-label">10<span>%</span></div>
        <div class="game__range-label">20<span>%</span></div>
        <div class="game__range-label">30<span>%</span></div>
        <div class="game__range-label">40<span>%</span></div>
      </div>
    </div>
    <div class="game__text text">
      <span>Нестероїдні протизапальні препарати (НПЗП)</span> показані для лікування гострих та хронічних невралгій. Рекомендовано використовувати <span>НПЗП</span> максимально короткими курсами.<sup>4,5</sup>
    </div>
    <div class="game__next" @click="nextState(3, 'next')" :class="{disabled: slider <= 1}">Далі</div>
  </div>
</template>

<script>
import veeno from "veeno";

export default {
  name: "Game",
  data() {
    return {
      slider: 1,
    };
  },
  components: {
    veeno,
  },
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", { event_category: "sec_3_go_test" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
    preventDefault(event) {
      event.preventDefault();
    },
    onDragStart() {
      var supportsPassive = false;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: () => {
              return (supportsPassive = true);
            }
          })
        );
      } catch (e) {
        console.log(e);
      }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent =
        "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

      window.addEventListener("DOMMouseScroll", this.preventDefault, false); // older FF
      window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
      window.addEventListener("touchmove", this.preventDefault, wheelOpt); // mobile
    },
    onDragEnd() {
      var supportsPassive = false;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: () => {
              return (supportsPassive = true);
            }
          })
        );
      } catch (e) {
        console.log(e);
      }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent =
        "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
      window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
      window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
      window.removeEventListener("touchmove", this.preventDefault, wheelOpt);
    },
    changeSliderHandler() {
      const tooltip = document.getElementsByClassName('noUi-tooltip');
      const triangle = document.getElementsByClassName('triangle');
      const noUiConnect = document.getElementsByClassName('noUi-connect');
      
      tooltip[0].classList.remove('hidden');
      tooltip[0].classList.remove('changeXPos');
      tooltip[0].classList.remove('percent-30');
      tooltip[0].classList.remove('percent-20');
      tooltip[0].classList.remove('percent-10');
      noUiConnect[0].classList.remove('opacity-1');
      noUiConnect[0].classList.remove('opacity-075');
      noUiConnect[0].classList.remove('opacity-05');
      noUiConnect[0].classList.remove('opacity-025');

      if (this.slider === '5.00') {
        tooltip[0].classList.add('changeXPos');
        noUiConnect[0].classList.add('opacity-1');
      } else if (this.slider === '4.00') {
        tooltip[0].classList.add('percent-30');
        noUiConnect[0].classList.add('opacity-075');
      } else if (this.slider === '3.00') {
        tooltip[0].classList.add('percent-20');
        noUiConnect[0].classList.add('opacity-05');
      } else if (this.slider === '2.00') {
        tooltip[0].classList.add('percent-10');
        noUiConnect[0].classList.add('opacity-025');
      }
      
      if (tooltip[0].innerHTML !== '1.00') {
        let newTooltip = 'Гострий біль у шийному відділ хребта<br> буде прогресувати до хронічного<br> приблизно <span>у 30% пацієнтів!<sup>2</sup></span>';
        tooltip[0].innerHTML = newTooltip;
        triangle[0].classList.remove('hidden');
      } else {
        tooltip[0].classList.add('hidden');
        triangle[0].classList.add('hidden');
      }

    },
  },
  mounted() {
    const noUiHandle = document.getElementsByClassName('noUi-handle');
    const newEle = document.createElement('div');
    
    newEle.classList.add('triangle');
    newEle.classList.add('hidden');
    noUiHandle[0].insertBefore(newEle, null);
    const tooltip = document.getElementsByClassName('noUi-tooltip');
    if (tooltip[0].innerHTML === '1.00') {
      tooltip[0].classList.add('hidden');
    }

    if (localStorage.getItem("isOnboarding") !== null) {
      this.isOnboarding = false;
    } else {
      gtag("event", "click", { event_category: "sec_3_onboarding_1" });
    }
  },
};
</script>
<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

$fontNB: "NotoSans-Bold";
$fontNSB: "NotoSans-SemiBold";
$fontNR: "NotoSans-Regular";
$fontNL: "NotoSans-Light";

.game {
  max-width: 732px;
  &__next {
    margin: 0 auto;
    margin-top: -86px;
    transform: translateX(58px);
    width: 262px;
    height: 64px;
    padding: 0 83px;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-family: $fontNR;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
    &.disabled {
      background-color: rgba(170, 25, 139, 0.16);
      pointer-events: none;
    }
  }
  &__text {
    font-family: $fontNR;
    font-size: 16px;
    line-height: 22px;
    color: #545454;
    margin-bottom: 151px;
    span {
      font-family: $fontNSB;
    }
  }
  &__range {
    width: 86%;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    margin-bottom: 48px;
    &.disabled {
      pointer-events: none;
    }
    .game__start {
      position: absolute;
      font-size: 16px;
      line-height: 22px;
      font-family: $fontNR;
      color: #317575;
      top: 1px;
      display: flex;
      align-items: center;
      height: 30px;
      left: 4px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 1px;
      border-top-right-radius: 1px;
      padding-left: 24px;
      background-color: #DADADA;
      &:after {
        position: absolute;
        right: -23px;
        top: 7.5px;
        content: '';
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 15px solid #DADADA;
        border-radius: 1px;
        transform: rotate(90deg);
      }
    }
    &-slider {
      margin-bottom: 6px;
      ::v-deep {
        .noUi {
          &-horizontal {
            .noUi {
              &-handle {
                width: 32px !important;
                height: 32px !important;
                right: -15px;
              }
            }
          }
          &-tooltip {
            font-family: $fontNR;
            font-size: 16px;
            line-height: 22px;
            max-width: 330px;
            min-width: 330px;
            bottom: 17px;
            text-align: start;
            color: #fff;
            padding: 16px 16px 10px;
            background: rgba(203, 46, 35, 1);
            box-shadow: 0px 20px 40px rgba(203, 46, 35, 0.1);
            backdrop-filter: blur(2px);
            border-radius: 8px;
            position: relative;
            bottom: 109px;
            left: 48%;
            &.hidden {
              visibility: hidden;
            }
            &.changeXPos {
              left: auto;
              right: 125px;
              &.noUi-tooltip::after {
                right: 9px;
              }
            }
            span {
              font-family: $fontNSB;
            }
          }

          &-target {
            position: relative;
            height: 32px;
            padding: 8px;
            background: #E4EAED;
            border-radius: 26px;
            border: none;
          }
          &-connects {
            width: auto;
            margin: 0;
            border-radius: 45.2933px;
            overflow: hidden;
            position: relative;
            &::after {
              background-image: url('~@/assets/img/training-module1/theme-2/Lines.svg');
              opacity: 0.5;
              background-repeat: space;
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              z-index: 1;
            }
          }
          &-connect {
            background: linear-gradient(90.15deg, #ED6056 0%, #9E0A00 61.11%);
            //opacity: 0.5;
            border: none;
            box-shadow: none;
            &.opacity-1 {
              opacity: 1;
            }
            &.opacity-075 {
              opacity: 0.75;
            }
            &.opacity-05 {
              opacity: 0.5;
            }
            &.opacity-025 {
              opacity: 0.25;
            }
            .img-wrapper {
              min-width: 534px;
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 5;
            }
            img {
              width: 534px;
              position: absolute;
              height: 100%;
              z-index: 5;
            }
          }
          &-value {
            display: none;
          }
          &-handle {
            top: -8px;
            background: transparent;
            box-shadow: none;
            border: none;
            cursor: pointer;
            z-index: 2;
            &:focus {
              outline: none;
            }
            &:before {
              display: block;
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              right: 0;
              bottom: 0;
              width: 30px;
              height: 30px;
              background: rgba(203, 46, 35, 0.2);
              border: 1px solid rgba(203, 46, 35, 0.5);
              border-radius: 50%;
              background-size: contain;
              transform: translate(-50%, -50%);
            }
            &:after {
              width: 16px;
              height: 16px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              background: #CB2E23;
            }
            .triangle {
              position: absolute;
              right: calc(50% - 24px / 2);
              top: -22px;
              border-radius: 2px;
              content: '';
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-bottom: 15px solid rgba(203, 46, 35, 1);
              border-radius: 1px;
              transform: rotate(180deg);
              &::after {
                height: 18px;
                width: 1px;
                position: absolute;
                top: -16px;
                left: -0.5px;
                background: rgba(203, 46, 35, 1);
                content: '';
              }
              &.hidden {
                visibility: hidden;
              }
            }
          }
          &-pips-horizontal {
            top: 36px;
            left: 11px;
            width: 96%;
            height: 14px;
            padding: 0;
          }
          &-marker-horizontal {
            &.noUi {
              &-marker {
                &-large,
                &-sub {
                  top: 0px;
                  left: 7px;
                  width: 2px;
                  height: 16px;
                  background: #E4EAED;
                  &.active {
                    background: #fff;
                    z-index: 1;
                  }
                }
                &-normal {
                  background:#E4EAED;
                  margin-top: 4px;
                  height: 8px;
                }
              }
            }
          }
        }
      }
    }
    &-labels {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: space-between;
    }
    &-label {
      margin-right: 0px;
      margin-top: 15px;
      background: #ffffff;
      padding: 2px 6px;
      font-family: $fontNSB;
      font-size: 16px;
      line-height: 22px;
      color: #9F9F9F;;
      &:last-child {
        margin-right: 0;
      }
      span {
        font-size: 80%;
        font-family: $fontNSB;
      }
    }
  }
}

.slider__track {
  width: 100%;
  position: absolute;
  z-index: 0;
  height: 32px;
  background: #E4EAED;
  opacity: 0.4;
  border-radius: 26px;
  &-top {
    background: transparent;
    border-color: transparent;
  }
  &-bottom {
    background: transparent;
    border-color: transparent;
  }
}
.slider__label {
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  padding: 16px 16px 10px 16px;
  border-radius: 8px;
  background: rgba(203, 46, 35, .8);
  box-shadow: 0px 20px 40px rgba(203, 46, 35, 0.1);
  backdrop-filter: blur(2px);
  max-width: 46%;
  margin-left: 0;
  top: -105px;
  font-family: "NotoSans-Regular";
  font-weight: 400;
}

@media (max-width: 768px) {
  .game {
    &__range {
      width: 100%;
      margin-left: 0;
    }
    &__text {
      margin-bottom: 131px;
    }
    &__next {
      max-width: 266px;
    height: 44px;
    padding: 0 95px;
    background-size: 26px;
    font-size: 16px;
    line-height: 44px;
      transform: translateX(0);
    }
  }
}
@media (max-width: 411px) {
  .game {
    .training-product__next {
      transform: translateX(0)
    }
    &__range {
      .game__start {
        top: -64px;
        padding-left: 8px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      &-label {
        &:nth-of-type(2) {
          margin-left: 9px;
        }
        &:nth-of-type(3) {
          margin-left: 8px;
        }
        &:nth-of-type(4) {
          margin-left: 7px;
        }
        &:nth-of-type(5) {
          margin-right: -14px;
        }
      }
      &-slider {
        margin-bottom: 6px;
        ::v-deep {
          .noUi {
            &-pips-horizontal {
              width: 95%;
            }
            &-tooltip {
              &.percent-10 {
                left: 270%;
              }
              &.percent-20 {
                left: 62%;
              }
              &.percent-30 {
                left: auto;
                right: 160%;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 340px) {
  .game__range {
    &-slider {
      ::v-deep {
        .noUi {
          &-tooltip {
            min-width: 300px;
            max-width: 300px;
            padding: 8px 8px 5px;
          }
        }
      }
    }
  }
}
</style>
