<template>
  <div class="section-text-big-list">
    <div class="text">
      <h3 v-html="content.title"></h3>
      <div class="text-wrapper" v-html="content.description"></div>

      <div class="img-wrapper" v-if="!!content.image">
        <img :src="`${$root.globalUrl}${content.image}`" alt="" />
      </div>
    </div>

    <div class="list">
      <div
        :class="`item color${value + 1} `"
        v-for="(key, value) in content.blocks"
        :key="value"
      >
        <span>{{ value + 1 }}</span>
        <div class="list">
          <div class="text-wrapper" v-html="key"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-text-big-list",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";
$maxW: 1128px;

.section-text-big-list {
  margin: 10px 0;
  display: flex;

  .img-wrapper {
    margin-top: 15px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  @media screen and (max-width: 670px) {
    margin: 5px 0;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }

  > div {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 550px;
    }
    @media screen and (max-width: 550px) {
      margin-bottom: 15px;
    }

    &:nth-child(1) {
      margin-right: 15px;

      @media screen and (max-width: 666px) {
        margin-right: 5px;
      }
      @media screen and (max-width: 550px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 666px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 550px) {
        margin-left: 0;
        margin-bottom: 0;
      }
    }

    &.text {
      padding-top: 25px;
      position: relative;

      @media screen and (max-width: 670px) {
        padding-top: 15px;
      }

      h3 {
        margin-bottom: 15px;
        color: #545454;
        font-family: $fontDemi;
        font-size: 16px;
        font-weight: normal;
      }

      p {
        margin-bottom: 15px;
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        @media screen and (max-width: 670px) {
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 41px;
        height: 3px;
        background: #00a03b;
      }
    }

    &.list {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 25px 0;
        border-top: 1px solid white;

        &.color1 {
          border-color: #00a03b;

          span {
            color: #00a03b;
          }
        }

        &.color2 {
          border-color: #b3d06b;

          span {
            color: #b3d06b;
          }
        }

        &.color3 {
          border-color: #005a85;

          span {
            color: #005a85;
          }
        }

        &.color4 {
          border-color: #317575;

          span {
            color: #317575;
          }
        }

        span {
          line-height: 0.9em;
          display: block;
          margin-right: 30px;
          color: #545454;
          font-size: 70px;
          font-family: $fontR;
          @media screen and (max-width: 670px) {
            margin-right: 15px;
          }
        }

        p {
          margin-bottom: 15px;
          color: #545454;
          font-family: $fontR;
          font-size: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          @media screen and (max-width: 670px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
