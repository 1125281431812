<template>
  <div class="training-theory">
    <h3 class="training-theory__title">
      Дослідження Mibielli et al. (2009)
    </h3>
    <div class="training-theory__descrip">Ефективність диклофенаку у комбінації з вітамінами групи В у порівнянні з монотерапією диклофенаком при болі у попереку.<sup>3</sup></div>
    <div class="training-theory__subtitle">Кількість пацієнтів: 372</div>
    <div class="training-theory__list">
      <div class="training-theory__item">
        <div class="training-theory__col-left">
          <div class="training-theory__image">
            <img src="~@/assets/img/training-module1/theme-2/Graph-1.png" alt="" />
          </div>
        </div>
        <div class="training-theory__col-right">
          <div class="training-theory__detail">
            <div class="training-theory__detail-text">
              <span>46,5% пацієнтів,</span> які отримували вітаміни групи В, припинили лікування у зв’язку з покращенням симптомів.<sup>3</sup>
            </div>
            <ul class="training-theory__detail-text list">
              <span>Комбінація НПЗП та високих доз вітамінів групи В може<sup>3</sup>: </span>
              <li>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 3.66667L4.71429 7L10.5 1" stroke="#7FC25D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="training-theory__detail-text">скоротити тривалість прийому НПЗП</p>
              </li>
              <li>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 3.66667L4.71429 7L10.5 1" stroke="#7FC25D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="training-theory__detail-text">зменшити ймовірність розвитку серйозних побічних  реакцій </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="training-theory__next" @click="nextState(4, 'next')">Далі</div>
  </div>
</template>

<script>
export default {
  name: "Theory",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "sec_1_btn_next" });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    },
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

$fontNB: "NotoSans-Bold";
$fontNSB: "NotoSans-SemiBold";
$fontNR: "NotoSans-Regular";
$fontNL: "NotoSans-Light";

.training-theory {
  &__title {
    margin-bottom: 11px;
    font-family: $fontNSB;
    font-size: 32px;
    line-height: 44px;
    color: #00a03b;
    sub,
    sup {
      font-family: $fontNSB;
    }
  }
  &__descrip {
    font-size: 16px;
    font-family: $fontNR;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 22px;
    color: #545454;
    font-family: $fontNSB;
    margin-top: 4px;
    margin-bottom: 24px;
  }
  &__list {
    margin-bottom: 64px;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 46px;
  }
  &__col {
    &-left {
      flex-basis: 56%;
      max-width: 56%;
      padding: 0 10px;
    }
    &-right {
      flex-basis: 44%;
      max-width: 44%;
      padding: 0 10px;
    }
  }
  &__image {
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__detail {
    &-title {
      margin-bottom: 24px;
      font-family: $fontNSB;
      font-size: 30px;
      line-height: 37px;
      color: #00a03b;
      sub,
      sup {
        font-family: $fontNSB;
      }
    }
    &-text {
      font-family: $fontNR;
      font-size: 16px;
      line-height: 20px;
      color: #545454;

      span {
        color: #00a03b;
        font-family: $fontNSB;
      }
      &.list {
        list-style-type: none;
        margin-top: 24px;
        span {
          max-width: 250px;
          display: inline-block;
        }

        li {
          display: flex;
          margin-top: 6px;
          svg {
            min-width: 12px;
            width: 12px;
            min-height: 12px;
            margin: 4px 10px 0 8px;
          }
        }
      }
    }
  }
  &__next {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: auto;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module1/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    font-family: $fontNR;
    &:hover {
      background-position: 110% center;
    }
  }
}
@media screen and (max-width: 767px) {
  .training-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
    &__list {
      margin-bottom: 24px;
    }
    &__item {
      display: flex;
      margin-bottom: 32px;
    }
    &__descrip {
      font-size: 14px;
    }
    &__subtitle {
      font-size: 14px;
    }
    &__col {
      &-left,
      &-right {
        flex-basis: 100%;
        max-width: 100%;
      }
      &-right {
        margin-top: 24px;
      }
    }
    &__detail {
      &-title {
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 22px;
      }
      &-text {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 17px;
        &.list {
          margin-top: 32px;
          margin-bottom: 0;
          .training-theory__detail-text {
            margin-bottom: 0;
          }
        }
      }
    }
    &__next {
      width: 100%;
      max-width: 266px;
      height: 44px;
      padding: 0 95px;
      background-size: 26px;
      font-size: 16px;
      line-height: 44px;
    }
  }
}
</style>
