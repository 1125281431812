var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-nav"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisibleLeftArrow),expression:"isVisibleLeftArrow"}],staticClass:"training-nav__btn btn-left",on:{"click":function($event){return _vm.scrollMenu('left')}}}),_c('div',{staticClass:"training-nav__inner"},[_c('div',{staticClass:"training-nav__list"},[_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 1,
          current: _vm.currentState === 1
        },on:{"click":function($event){return _vm.nextState(1, '')}}},[_vm._v(" Біль у попереку ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 2,
          current: _vm.currentState === 2
        },on:{"click":function($event){return _vm.nextState(2, '')}}},[_vm._v(" Стрес у клієнта ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 3,
          current: _vm.currentState === 3
        },on:{"click":function($event){return _vm.nextState(3, '')}}},[_vm._v(" Вибір об’єму препарату ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 4,
          current: _vm.currentState === 4
        },on:{"click":function($event){return _vm.nextState(4, '')}}},[_vm._v(" Олфен®-75 ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 5,
          current: _vm.currentState === 5
        },on:{"click":function($event){return _vm.nextState(5, '')}}},[_vm._v(" Генерична заміна ")]),_c('div',{staticClass:"training-nav__item",class:{
          success: _vm.state >= 6,
          current: _vm.currentState === 6
        },on:{"click":function($event){return _vm.nextState(6, '')}}},[_vm._v(" Перевірка знань ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisibleRightArrow),expression:"isVisibleRightArrow"}],staticClass:"training-nav__btn btn-right",on:{"click":function($event){return _vm.scrollMenu('right')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }