<template>
  <div class="module-theory">
    <button
      class="module-theory__back-btn"
      @click="nextState(4, 'next')"
    >
      <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 4.01953L2.37813 4.01953" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5.15112 7L2.00003 4L5.15112 1" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      Назад
    </button>
    <div class="module-theory__title">
      Чому іноді суспензії називаються інакше: 400, 600, 1200 та 1500 мг?
    </div>
    <div class="module-theory__text">
      Це <strong>вміст азитроміцину</strong> (не готової суспензії, а саме діючої речовини). 
    </div>
    <div class="module-theory__text">
      Щоб краще зрозуміти, давайте розглянемо приклад суспензії <nobr><strong>100 мг/5 мл, 20 мл:</strong></nobr>
    </div>
    <div class="module-theory__border-text">
      У <strong>5 мл</strong> суспензії міститься <strong>100 мг</strong> азитроміцину. Отже, у <strong>20 мл</strong> суспензії буде міститися <strong>в 4 рази більше</strong> (20 мл — це 4 рази по 5 мл).
    </div>
    <div class="module-theory__flex-text">
      <p class="module-theory__text">
        Відповідно, у <strong>20 мл</strong> готової суспензії буде міститися <strong><nobr>400 мг</nobr></strong> азитроміцину.
      </p>
      <p class="module-theory__green">
        <span><nobr>4х100=400 мг</nobr></span>
        азитроміцину 
        у готовій суспензії
      </p>
    </div>
    <div class="module-theory__next next-button" @click="nextState(6, 'next')">
      Далі
    </div>
  </div>
</template>

<script>
export default {
  name: "Theory5",
  methods: {
    nextState(state, direction) {
      this.$emit("changeState", { state, direction });
      gtag("event", "click", {
        event_category: state < 5 ? 'Кнопка "Назад"' : 'Кнопка "Далі"',
        event_label: 'М.5 - T.1 - Раздел " Сумамед®: форми випуску і дозування" - Э.4'
      });
      window.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontUL: "Commissioner-UltraLight";
$fontTh: "Commissioner-Thin";
$fontDemi: "Commissioner-Medium";

.module-theory {
  &__title {
    margin-bottom: 16px;
    font-family: $fontDemi;
    font-size: 32px;
    line-height: 39px;
    color: #00a03b;
    sup {
      font-family: $fontDemi;
    }
  }

  &__border-text {
    max-width: 400px;
    margin: 32px auto;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #212661;
    padding: 16px;
    border: 1px solid #005984;
    box-sizing: border-box;
    border-radius: 2px;
  }

  &__flex-text {
    display: flex;
    justify-content: space-between;
  }

  &__green {
    display: flex;
    align-items: center;
    padding: 7px 24px;
    background: #00A03B;
    border-radius: 2px;
    margin-left: 20px;
    font-weight: 600;
    font-family: $fontDemi;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;

    span {
      font-size: 18px;
      line-height: 22px;
      margin-right: 30px;
    }
  }

  .next-button {
    width: 262px;
    height: 64px;
    padding: 0 83px;
    margin: 64px auto 0;
    background-color: #aa198b;
    background-image: url("~@/assets/img/training-module5/arrow-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    border-radius: 90px;
    border: none;
    font-weight: normal;
    font-size: 22px;
    text-indent: -50px;
    text-align: center;
    line-height: 64px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-position: 110% center;
    }
  }
}

@media screen and (max-width: 767px) {
  .module-theory {
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 22px;
    }

    &__text {
      margin-bottom: 8px;
    }

    &__border-text {
      margin: 24px auto;
      padding: 7px;
      font-size: 14px;
    }

    &__flex-text {
      flex-direction: column;
    }

    &__green {
      margin-left: 0;
      margin-top: 16px;
      font-size: 14px;

      span {
        font-size: 16px;
      }
    }

    .next-button {
      width: 266px;
      height: 44px;
      margin: 48px auto 0;
      padding: 0 95px;
      background-size: 24px;
      font-size: 16px;
      line-height: 43px;
      text-indent: -40px;
    }
  }
}
</style>
